import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const getAppConfigAPI = createApi({
    reducerPath: 'getAppConfigAPI',
    baseQuery: getBaseQueryConfig(),
    endpoints: (builder) => ({
        getAppConfig: builder.query({
            query: () => "/config-settings/get-app-config",
            keepUnusedDataFor: 60 * 60 * 24,
        }),
    }),
});

export const { useGetAppConfigQuery } = getAppConfigAPI;
import { useState, useEffect, useMemo } from 'react';
import { useSlicesState } from '../redux/slices';

export const useFilteredMyExercises = (myExercises, input) => {
    const { uid } = useSlicesState()
    const [dataFilter, setDataFilter] = useState(myExercises)
    const [filterSelected, setFilterSelected] = useState("myExercises")

    const filterExercise = (filter) => {
        setFilterSelected(filter)
        filter === "other"
            ? setDataFilter(myExercises)
            : setDataFilter(myExercises?.filter(exercise => exercise.category === filter))
        filter === "fitool" && setDataFilter(myExercises?.filter(exercise => exercise.itsFromFitool))
        filter === "myExercises" && setDataFilter(myExercises?.filter(
            (exercise) => !exercise.itsFromFitool && exercise.idPersonalTrainer === uid
        ))

    }


    const filteredExercises = useMemo(() => {
        if (input.length > 0) {
            return myExercises?.filter((exercise) =>
                exercise.name.toLowerCase().includes(input)
            );
        } else {
            return myExercises;
        }
    }, [myExercises, input]);

    useEffect(() => {
        setDataFilter(filteredExercises);
    }, [filteredExercises]);

    return {
        dataFilter,
        filterExercise,
        filterSelected,
    };
}

import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { setCheckAuthAndToken, setInitialStateAuth, useSlicesActions } from "../../redux/slices";
import { deleteTokenLocalStorage } from "../../helpers/auth-helpers";
import { trainingAPI, userProfileAPI } from "../../api";
import { deleteUser } from "../../services/user.services";

export function useDeleteAcount() {
  const { lang } = useContext(LanguageContext);

  const { dispatch,
    setInitialStateSubscriptions,
    setInitialStateUsers,
    setInitialStateTraining,
    setInitialStateModals,
    setInitialStateFeedBack,
    setInitialStateExercise,
    setInitialStateNotifications,
    setInitialStateUserProfile,
    setInitialStateApp,
    setInitialStateBlocks,
  } = useSlicesActions()


  //No hace falta enviar ID, porque ya va en el token al server para Eliminar!


  const handleRemoveAcount = async() => {
    Swal.fire({
      title: lang.user.deleteAccount.alterTitle,
      icon: "warning",
      text: lang.user.deleteAccount.desciption,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.myExercises.textConfirmButton,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteUser()
        deleteTokenLocalStorage()
        dispatch(setInitialStateAuth())
        dispatch(setInitialStateSubscriptions())
        dispatch(setInitialStateUsers())
        dispatch(setInitialStateTraining())
        dispatch(setInitialStateModals())
        dispatch(setInitialStateFeedBack())
        dispatch(setInitialStateExercise())
        dispatch(setInitialStateNotifications())
        dispatch(setInitialStateUserProfile())
        dispatch(setInitialStateApp())
        dispatch(setInitialStateBlocks())
        dispatch(setCheckAuthAndToken(false))
        dispatch(trainingAPI.util.invalidateTags(["trainingAPI"]))
        dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
        window.location.reload();
      }
    });
    //Otra accion
  };




  return {
    handleRemoveAcount
  };
}


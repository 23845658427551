import React from "react";
import { Alert, Badge } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const AlertConLink = ({
  variant,
  Icon,
  msg,
  text,
  textButton,
  path,
  handleButton,
}) => {
  const history = useHistory();
  const handlePush = (path) => {
    history.push(path);
  };

  return (
    <>
      <Alert variant={variant} className="alert-dismissible solid fade show text-capitalize">
        <Icon />
        <strong>{msg}</strong> {text}
        <Badge
          type="button"
          variant="warning"
          className="badge-sm light ml-1"
          as="a"
          // href="#"
          onClick={handleButton ? () => handleButton() : () => handlePush(path)}
        >
          {textButton}
        </Badge>
      </Alert>
    </>
  );
};

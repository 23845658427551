import { Button, Dropdown } from "react-bootstrap";

export const TextInputWithProgression = ({
    name,
    exercise,
    getOptionValue,
    handleFormCreateExercise,
    handleInputChangeForExercises,
    multipleTrainingDate,
    activateTrainingProgressionsTemp,
    placeholder,
    inputType,
    itemTitle,
    value
}) => {
    
    return (
        <>
            <h6 className="text-primary mb-2">
                {itemTitle}
            </h6>
            {multipleTrainingDate?.length > 1 ? (
                <Dropdown drop='right'> {/* up | dwon | right | left */}
                    <Dropdown.Toggle
                        as='text'
                        id='tp-btn'
                        className="custom-dropdown-toggle"
                    >
                        <Button
                            variant="outline-info"
                            className="btn-xs"
                        >
                            <i className="fa fa-sliders"></i>
                        </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        padding: '10px',
                        width: 'max-content',
                        border: '1px solid #ccc',
                        boxShadow: '5px 6px 8px -2px rgba(8,32,115,0.59)'
                    }}>
                        <div className="bg-white m-0 p-1 rounded w-80">
                            {
                                multipleTrainingDate.map((date, dateIndex) => (
                                    <div key={dateIndex}>
                                        {multipleTrainingDate.length > 1 && <label style={{ fontSize: '0.8rem', marginBottom: '0', color: '#3d4465' }}>{`Fecha: ${date}`}</label>}
                                        <input
                                            type="text"
                                            name={name}
                                            className="form_test_entrenamiento"
                                            placeholder={placeholder}
                                            value={getOptionValue(exercise, name, date, activateTrainingProgressionsTemp, inputType)}
                                            onChange={(e) => handleInputChangeForExercises(e, exercise.idTemporalClonar, date)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <input
                    type="text"
                    name={name}
                    className="form_test_entrenamiento"
                    placeholder={placeholder}
                    value={value ? value : getOptionValue(exercise, name, null, activateTrainingProgressionsTemp, inputType)}
                    onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                />
            )}
        </>
    );
}


import { Button, Dropdown } from "react-bootstrap";

export const SelectInputWithProgressionForBlocks = ({
    name,
    block,
    getOptionValue,
    handleInputChangeForBlocks,
    multipleTrainingDate,
    activateTrainingProgressionsTemp,
    options,
    lang,
    index
}) => {
    return (
        <>
            {multipleTrainingDate?.length > 1 ? (
                <Dropdown drop='dwon'> {/* up | dwon | right | left */}
                    <Dropdown.Toggle
                        as='text'
                        id='tp-btn'
                        className="custom-dropdown-toggle"
                    >
                        <Button
                            variant="outline-info"
                            className="btn-xs"
                        >
                            <i className="fa fa-sliders"></i>
                        </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        padding: '10px',
                        width: 'max-content',
                        border: '1px solid #ccc',
                        boxShadow: '5px 6px 8px -2px rgba(8,32,115,0.59)'
                    }}>
                        <div className="bg-white m-0 p-1 rounded w-80">
                            {
                                multipleTrainingDate.map((date, dateIndex) => (
                                    <div key={dateIndex}>
                                        {multipleTrainingDate.length > 1 && <label style={{ fontSize: '0.8rem', marginBottom: '0', color: '#3d4465' }}>{`Fecha: ${date}`}</label>}
                                        <select
                                            defaultValue={"low"}
                                            className="form-control border border-primary"
                                            style={{ height: '2.5rem' }}
                                            name={name}
                                            value={getOptionValue(block, name, date, activateTrainingProgressionsTemp, 'select')}
                                            onChange={(e) => handleInputChangeForBlocks(e, index, date)}
                                        >
                                            <option disabled>{lang.myExercises.selectCategory}</option>
                                            {options.map((option, i) => (
                                                <option key={i} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ))
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <select
                    defaultValue={"low"}
                    className="form-control border border-primary"
                    style={{ height: '2.5rem' }}
                    name={name}
                    value={getOptionValue(block, name, null, activateTrainingProgressionsTemp, 'select')}
                    onChange={(e) => handleInputChangeForBlocks(e, index)}
                >
                    <option disabled>{lang.myExercises.selectCategory}</option>
                    {options.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            )}
        </>
    );
};
import { roles } from "../../domain/roles";
import { useStateRedux } from "../useStateAPI";
import { useGetNumberOfWorkoutsOtherUserQuery, useGetOneProfileQuery } from "../../api";
import { useSlicesState } from "../../redux/slices";

export const useGetOneProfile = () => {

    const { otherUserId } = useSlicesState()
    const skipFetching = !otherUserId

    const { isLoading: isLoadingGetOneProfile, isFetching } = useGetOneProfileQuery(otherUserId, {
        skip: skipFetching,
        refetchOnMountOrArgChange: 1800,
        refetchOnReconnect: true,
      });
    
      const { isLoading: isLoadingNumberWorkouts, isFetching: isFetchingNumberWorkouts } = useGetNumberOfWorkoutsOtherUserQuery(otherUserId, {
        skip: skipFetching,
        refetchOnMountOrArgChange: 1800,
        refetchOnReconnect: true,
      });


    const { dataUseGetOneProfile, dataGetNumberOfWorkoutsOtherUser} = useStateRedux()

    const otherUserProfile = {
        _id: dataUseGetOneProfile?._id,
        name: dataUseGetOneProfile?.name,
        aboutMe: dataUseGetOneProfile?.fullProfile?.aboutMe,
        country: dataUseGetOneProfile?.fullProfile?.country,
        role: dataUseGetOneProfile?.role,
        img: dataUseGetOneProfile?.fullProfile?.urlImage,
        yearsOfExperience: dataUseGetOneProfile?.fullProfile?.yearsOfExperience ? dataUseGetOneProfile?.fullProfile?.yearsOfExperience : 0,
        numberOfSubscriptions: dataUseGetOneProfile?.fullProfile?.numberOfSubscriptions,
        trainersContainerCreated: dataGetNumberOfWorkoutsOtherUser?.trainingContainers,
        trainingContainersComplete: dataGetNumberOfWorkoutsOtherUser?.trainingContainers - dataGetNumberOfWorkoutsOtherUser?.trainingContainersIncomplete,
        sportsAssociation: dataUseGetOneProfile?.sportsAssociation?.sports
    }

    const fullProfile = dataUseGetOneProfile?.fullProfile

    let sports = !dataUseGetOneProfile?.sportsAssociation?.sports ? [] : dataUseGetOneProfile?.sportsAssociation?.sports;

    const otherUserIsPersonalTrainer = () => dataUseGetOneProfile?.role?.includes(roles.personalTrainer)
    const otherUserIsAthlete = () => dataUseGetOneProfile?.role?.includes(roles.athlete)
    const otherUserIsYogaStretching = () => dataUseGetOneProfile?.role?.includes(roles.yogaStretching)

    const isLoading = isLoadingGetOneProfile || isFetching || isLoadingNumberWorkouts || isFetchingNumberWorkouts

    return {
        otherUserProfile,
        fullProfile,
        sports,
        otherUserIsPersonalTrainer,
        otherUserIsAthlete,
        otherUserIsYogaStretching,
        isLoading
    };
};



export const isVideoUrl = (video) => {
    return video.includes('youtube.com') || 
           video.includes('vimeo.com') || 
           video.includes('youtu.be') || 
           video.includes('drive.google');
  }
  
  export const isGifUrl = (video) => {
    return video.includes('.gif') || 
           video.includes('.webm') || 
           video.includes('.webp');
  }
  
import { useGetOneDataQuery } from "../../api";
import { useStateRedux } from "../useStateAPI";
import { useState } from "react";
import { useEffect } from "react";
import { useSlicesState } from "../../redux/slices";

export const useGetPhysiologicalDataOneUser = () => {

    const { otherUserId } = useSlicesState()
    const { isLoading: isLoadingData, isFetching } = useGetOneDataQuery(otherUserId, {
        refetchOnMountOrArgChange: 250,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      })
   
    const { dataGetPhysiologicalOtherUser } = useStateRedux()

    const physiologicalData = dataGetPhysiologicalOtherUser?.physiologicalData;
    const dataFormatedFromGraph = dataGetPhysiologicalOtherUser?.dataFormatedFromGraph;
    
    const [dataPhysiological, setDataPhysiological] = useState([]);
    const [height, setHeight] = useState(0);

    const isLoading = isLoadingData || isFetching
  
    useEffect(() => {
        setDataPhysiological(physiologicalData);
        setHeight(
          physiologicalData?.length > 0
            ? physiologicalData[physiologicalData?.length - 1]?.height
            : 0
        );
    }, [physiologicalData]);
    
    return {
        dataFormatedFromGraph,
        isLoading,
        dataPhysiological,
        height
    };
};



import React from 'react';
import { AmrapIcon, FireSVG, HiitSVG, RunSVG, StretchingSVG } from '../../assets/svg/svgs';

const TrainingOption = ({ condition, children, className = '' }) => {
    if (!condition) return null;
    return <span className={`${className}`}> {children}</span>;
};

export const TrainingOptions = ({ optionsTraining, lang }) => (
    <>
        <TrainingOption condition={optionsTraining?.heating} className="text-warning">
            <FireSVG widthProp={"20px"} heightProp={"20px"} color={"#ff8700"} /> {lang.generic.heating}
        </TrainingOption>
        <TrainingOption condition={optionsTraining?.activeRecovery} className="text-secondary">
            <RunSVG widthProp={"20px"} heightProp={"20px"} /> {lang.generic.activeRecovery}
        </TrainingOption>
        <TrainingOption condition={optionsTraining?.mobility} className="text-success">
            <AmrapIcon widthProp={"20px"} heightProp={"20px"} /> {lang.generic.mobility}
        </TrainingOption>
        <TrainingOption condition={optionsTraining?.stretching} className="text-warning">
            <StretchingSVG widthProp={"20px"} heightProp={"20px"} /> {lang.generic.stretching}
        </TrainingOption>
        <TrainingOption condition={optionsTraining?.activation} className="text-danger">
            <HiitSVG widthProp={"22px"} heightProp={"22px"} /> {lang.generic.activation}
        </TrainingOption>
        <TrainingOption condition={optionsTraining?.series} className="text-secondary">
            X{optionsTraining?.series}
        </TrainingOption>
    </>
);


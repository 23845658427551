import { useEffect } from "react";
import { useDeletePhysiologicalDataMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";

export function useDeletePhysiologicalData() {
    const [deletePhysiologicalData, { isError, isSuccess, error, data }] = useDeletePhysiologicalDataMutation();
  
    const handleDeleteMeasureData = async (id) => {
      await deletePhysiologicalData(id).unwrap()
    };
    
    useEffect(() => {
      if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
      if (isSuccess) showAlert(data?.msgCode, data?.ok);
    }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);
  
    return {
      handleDeleteMeasureData
    };
  }


export const TypesOfTraining = {
    SchduledTraining : 'schduledTraining', //entrenamiento programado
    SeriesTraining : 'seriesTraining', //entrenamiento con series
    BlockTraining : 'blockTraining', //entrenameinto por bloque
}


export const isSchduledTraining = (typesOfTraining) => typesOfTraining === TypesOfTraining.SchduledTraining

export const isSeriesTraining = (typesOfTraining) => typesOfTraining === TypesOfTraining.SeriesTraining

export const isBlockTraining = (typesOfTraining) => typesOfTraining === TypesOfTraining.BlockTraining
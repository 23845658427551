import React from 'react'
import { Button } from "react-bootstrap"
import { useSlicesActions } from "../../redux/slices"
import { NewExerciseSimpleMode } from '../exercise/NewExerciseSimpleMode'

export const CreateTrainingSimpleMode = ({
    blocks,
    exercises,
    handleRemoveBlocks,
    handleInputChangeForBlocks,
    handleInputChangeForExercises,
    handleRemoveExercise,
    exerciseIdsPerBlock,
    lang
}) => {

    const { dispatch, setModalListMyExercises, setBlockIdSelected } = useSlicesActions()

    return (
        <div className="d-flex flex-column" style={{ backgroundColor: ' rgba(11, 42, 151, 0.1)', borderRadius: '1rem', margin: '0.5rem', padding: '1rem', gap: '1rem' }}>
            {
                blocks?.map((block, index) => {
                    return (
                        <>
                            <div className='d-flex flex-column justify-content-between align-items-center' style={{ gap: '1rem' }}>
                                <div className='d-flex flex-column w-100'>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            style={{ height: '2.5rem' }}
                                            required={block.name.length < 1}
                                            placeholder={lang.placeHolders.nameBlock}
                                            value={block.name}
                                            onChange={(e) => handleInputChangeForBlocks(e, index)}

                                        />
                                        <div className="input-group-append" id="button-addon4">
                                            <button
                                                className="btn btn-xs btn-outline-info"
                                                onClick={() => {
                                                    dispatch(setBlockIdSelected(block?.idTemporalClonar))
                                                    dispatch(setModalListMyExercises(true))
                                                }}
                                            >
                                                <i className="fa fa-briefcase mr-2" />
                                                {lang.buttons.myExercises}
                                            </button>
                                            <Button
                                                variant="outline-danger btn-xs input-icon"
                                                onClick={() => handleRemoveBlocks(index, block._id)}
                                            >
                                                <i className="fa fa-trash" />
                                            </Button>

                                        </div>

                                    </div>

                                    <div className='m-auto' style={{ width: '90%' }}>
                                        {
                                            exerciseIdsPerBlock[block.idTemporalClonar]?.map((exerciseId, indexExercise) => (
                                                <NewExerciseSimpleMode
                                                    exercise={exercises.find((exercise) => exercise.idTemporalClonar === exerciseId)}
                                                    handleRemoveExercise={handleRemoveExercise}
                                                    handleInputChangeForExercises={handleInputChangeForExercises}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
        </div>
    )
}
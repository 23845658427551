import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import logo from "../assets/img/logo.png";
import logoText from "../assets/img/logo-text.png";

export const Maintenance = () => {
  return (

    <>
      <div className="mt-5">
        <PerfectScrollbar className="h-100">
          <div className="container h-100 ">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-6 ">
                <div className="text-center mb-5">
                  <img src={logo} alt="logo icono fitool" />
                  <img src={logoText} alt="logo text fitool" />
                </div>
                <div className="card border border-warning overflow">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form">
                        <h4 className="text-center mb-4 text-black">
                          FiTool está en Mantenimiento!
                        </h4>
                        <div className="new-account mt-3">
                          <p className="text-center text-black">
                            👷Estamos Trabajando para mejorar FiTool👷</p>
                        </div>
                        <div className="new-account mt-3">
                          <p className="text-center text-black">
                            Vuelve más tarde...</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

import { useEffect } from "react";
import { useGetMyPhysiologicalDataQuery } from "../../api";
import { useStateRedux } from "../useStateAPI";
import { useState } from "react";

export const useGetMyPhysiologicalData = () => {

    const { isLoading: isLoadingData, isFetching} = useGetMyPhysiologicalDataQuery(undefined, {
        refetchOnMountOrArgChange: 250,
        refetchOnFocus: false,
        refetchOnReconnect: true,
      })
   
    const { dataGetPhysiologicalData } = useStateRedux()

    const physiologicalData = dataGetPhysiologicalData?.physiologicalData;
    const dataFormatedFromGraph = dataGetPhysiologicalData?.dataFormatedFromGraph;
    
    const [dataPhysiological, setDataPhysiological] = useState([]);
    const [height, setHeight] = useState(0);

    const isLoading = isLoadingData || isFetching
  
    useEffect(() => {
        setDataPhysiological(physiologicalData);
        setHeight(
          physiologicalData?.length > 0
            ? physiologicalData[physiologicalData?.length - 1]?.height
            : 0
        );
    }, [physiologicalData]);
    
    return {
        dataFormatedFromGraph,
        isLoading,
        dataPhysiological,
        height
    };
};



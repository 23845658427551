import React from "react";
import { Alert } from "react-bootstrap";

export const AlertSinLink = ({ variant, Icon, msg, text, className }) => {
  return (
    <>
      <Alert
        variant={variant}
        className={`alert-dismissible solid fade show mt-2 ${className}`}
        // style={{ height: '150px' }}
      >
        <Icon />
        <strong>{msg}</strong> {text}
      </Alert>
    </>
  );
};

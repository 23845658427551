import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  InfoIcon,
} from "../../assets/svg/svgs";
import {
  AlertSinLink,
  SelectSearchSingleMultiple,
  ListIconsSports,
  ListIconsSportsCreateEditTraining,
  InputCalendar,
  EditBlock,
  Blocks,
} from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { useDeleteTraining, useGetMyProfile, useUpdateTraining } from "../../hooks";
import { isBlockTraining, isSchduledTraining, isSeriesTraining } from "../../assets/data/typesOfTrainings";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { getTextsEquipment } from "../../assets/data/validateEquipment";
import { scrollToTop } from "../../helpers/scrollToTop";
import { setTrainingIdSelected } from "../../redux/slices";
import { formatDateWithDayJS } from "../../helpers/formatDate";
import { isTrainingBlockInProgress } from "../../helpers/checkStatusTraining";

export const ModalViewTrainingBlock = ({ training, countTraining, idModal }) => {

  const { query, modalViewExercise, selectUser, uid, blockIsBeingEdited, toggleCreateTrainingSimpleMode } = useSlicesState()

  const { lang } = useContext(LanguageContext);
  const { isAthlete, myIdUSer, equipment } = useGetMyProfile()
  const { dispatch,
    setModalViewExercise,
    setBlockIsBeingEditedFalse,
    setIsCloneTrainingTrue,
    setTrainingToClone,
    setIsCreateExerciseInTrainingTrue,
    setToggleCreateTrainingSimpleMode
  } = useSlicesActions()

  const userEquipment = getTextsEquipment((uid === myIdUSer && isAthlete()) ? equipment : selectUser?.equipment);

  const [isEdit, setIsEdit] = useState(false);
  const [blockToEdit, setBlockToEdit] = useState({})

  const handleIsEdit = () => {
    setIsEdit(true);
  };

  const closeModal = () => {
    dispatch(setModalViewExercise(null));
    dispatch(setTrainingIdSelected(null));
    dispatch(setBlockIsBeingEditedFalse())
    setIsEdit(false);
    setBlockToEdit({})
    scrollToTop()
  };


  const {
    title,
    handleUpdateTraining,
    sports,
    valueSports,
    setvalueSports,
    target,
    handleUpdateTrainingContainer,
    aSingleTrainingDate,
    handleASingleTrainingDate,
    isSaveButtonDisabled,
  } = useUpdateTraining(training, closeModal)

  const { handleDeleteTraining } = useDeleteTraining()


  const blocks = training?.blocksId

  const checkExercise = (isComplete) => isComplete === false;

  const widthAndHeight = "20px";

  const retsultIsTrainingInProgress = isTrainingBlockInProgress(training);
  const { isProgress, blockCompleted, totalBlocks } = retsultIsTrainingInProgress;


  return (
    <>
      <Modal
        className="fade bd-example-modal-lg modal-dialog-scrollable"
        show={modalViewExercise === idModal}
        size="xl"
        backdropClassName="modalEditCreateExercise"

      >
        <Modal.Header>
          {!isEdit ? (
            <Modal.Title className="col-11 text-capitalize">
              {formatDateWithDayJS(training.startDate)}{" - "}
              {training.title}{" "}
              {
                isSeriesTraining(training.typesOfTraining) &&
                <span className="font-weight-bold"> x {training?.optionsSeriesTraining?.series}</span>
              }

              {training.isComplete && (
                <span className="text-success">{` ${lang.mySubscriptions.status.finished}`}</span>
              )}

              {(!training.isComplete && isSchduledTraining(training.typesOfTraining)) &&
                training?.exercisesId?.map((exercise) => exercise.isComplete).includes(true) && (
                  <span className="text-secondary">{` ${lang.generic.inProgress}`}</span>
                )}

              {(!training.isComplete && isSchduledTraining(training.typesOfTraining)) && //pendiente sin series
                training.exercisesId
                  ?.map((exercise) => exercise.isComplete)
                  .every(checkExercise) && (
                  <span className="text-warning">{` ${lang.generic.pending}`}</span>
                )}

              {(isSeriesTraining(training.typesOfTraining) && training?.optionsSeriesTraining?.seriesCompleted > 0 && !training.isComplete) && ( //en progreso con series
                <span className="text-secondary">{` ${lang.generic.inProgress}`}</span>
              )}

              {(isSeriesTraining(training.typesOfTraining) && training?.optionsSeriesTraining?.seriesCompleted === 0) && //pendiente con series
                (
                  <span className="text-warning ">{` ${lang.generic.pending}`}</span>
                )}

              {(isBlockTraining(training.typesOfTraining) && blockCompleted > 0 && !training?.isComplete) && ( //en progreso con bloques
                <span className="text-secondary">{` ${lang.generic.inProgress}`}</span>
              )}

              {(isBlockTraining(training.typesOfTraining) && blockCompleted === 0) && //pendiente con bloques
                (
                  <span className="text-warning ">{` ${lang.generic.pending}`}</span>
                )}

              {!isAthlete() && !isEdit && !training.isComplete && (
                <Button
                  as="a"
                  variant="warning ml-3"
                  href="#"
                  className="btn-xs"
                  onClick={() => handleIsEdit()}
                >
                  {/* {lang.buttons.edit} */}
                  <i className={`fa fa-edit`} />{" "}
                </Button>
              )}
              {!isAthlete() && !training.isComplete && (
                <Button
                  as="a"
                  variant="danger ml-2"
                  href="#"
                  className="btn-xs"
                  onClick={() => handleDeleteTraining(training._id)}
                >
                  <i className={`fa fa-trash`} />{" "}
                  {/* si esta editando guardar estado edtando y reemplaar title por inputs */}
                </Button>
              )}
              {
                !isAthlete() &&
                <Button
                  as="a"
                  variant="secondary ml-2"
                  href="#"
                  className="btn-xs"
                  onClick={() => {
                    if(training?.isSimpleMode){
                      if(!toggleCreateTrainingSimpleMode){
                        dispatch(setToggleCreateTrainingSimpleMode())
                      }
                    }
                    dispatch(setIsCreateExerciseInTrainingTrue())
                    dispatch(setIsCloneTrainingTrue())
                    dispatch(setTrainingToClone(training))
                    closeModal()
                  }}
                >
                  <i className={`fa fa-copy`} />{" "}
                </Button>
              }

              <br />
              <ListIconsSports trainingType={training.sports} className="ml-2 mr-2" widthAndHeight="24px" />
            </Modal.Title>
          ) : (
            <>
              <h4>{lang.viewExercise.editTraining}</h4>
            </>
          )}

          <Button variant="" className="close" onClick={() => closeModal()}>
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {isAthlete() && (
            <p className="text-primary text-capitalize">
              {lang.roles.personalTrainer}: {training.idPersonalTrainer?.name}
            </p>
          )}

          {training.target && !isEdit && (
            <>
              {(isSeriesTraining(training.typesOfTraining) && !training.isComplete) &&
                <p className="text-secondary text-center">
                  {/* {`${countTraining + lang.viewExercise.roundsOf + data.series + " Completadas"}`} */}
                  {training?.optionsSeriesTraining?.seriesCompleted > 0
                    ? training?.optionsSeriesTraining?.seriesCompleted + lang.viewExercise.roundsOf + training?.optionsSeriesTraining?.series + " Completadas"
                    : countTraining + lang.viewExercise.roundsOf + training?.optionsSeriesTraining?.series + " Completadas"
                  }
                </p>
              }
              {(isBlockTraining(training.typesOfTraining) && !training.isComplete) &&
                <p className={`text-center ${isProgress ? "text-secondary" : "text-warning"}`}>
                  {blockCompleted + lang.blocks.blocksOf + totalBlocks + " Completadas"
                  }
                </p>
              }
              {!training?.isSimpleMode &&
                <AlertSinLink
                  variant={"info"}
                  Icon={InfoIcon}
                  msg={`${lang.viewExercise.target}:`}
                  text={training.target}
                />
              }
            </>
          )}

          {isEdit && (
            <>
              <div className="row">
                <div className="form-group col-md-6 mt-2">
                  <div className="mb-2">
                    <input
                      type="text"
                      className="form_test_entrenamiento"
                      name="title"
                      required={title.length < 1}
                      placeholder={lang.placeHolders.createTraining}
                      value={title}
                      onChange={handleUpdateTraining}
                    />
                  </div>
                </div>

                <div className="form-group col-md-6 mt-2">
                  <div className="form-group">
                    <SelectSearchSingleMultiple
                      multiple={true}
                      data={sports}
                      search={false}
                      placeholder={lang.createTraining.trainingType}
                      values={valueSports}
                      setValues={setvalueSports}
                      closeOnSelect={false}
                    />
                    <br />
                    <ListIconsSportsCreateEditTraining valueSports={valueSports} widthAndHeight={widthAndHeight} />
                  </div>
                </div>

                {!training?.isSimpleMode &&
                  <div className="form-group col-md-6">
                    <div className="mb-2">
                      <h5 className="text-primary mb-2">
                        {lang.createTraining.trainingTarget}
                      </h5>
                      <textarea
                        className="form-control h-100 textarea-required"
                        rows="4"
                        id="target"
                        name="target"
                        value={target}
                        placeholder={lang.placeHolders.trainingTargetDescription}
                        onChange={handleUpdateTraining}
                        required={target?.length < 1}
                      ></textarea>
                    </div>
                  </div>
                }

                <div className="form-group col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      gap: '1rem',
                      border: '0px',
                      borderBottom: `2px solid #efefef`,
                      width: '90%',
                      fontSize: '1.2rem',
                      marginBottom: '0.5rem',
                      fontWeight: '500',
                      lineHeight: '1.2',
                      color: '#3d4465',
                    }}>
                    <h6 className="text-primary">{lang.placeHolders.dateTraining}</h6>
                    <InputCalendar
                      date={aSingleTrainingDate}
                      handleDate={handleASingleTrainingDate}
                      multiple={false}
                    />
                  </div>

                </div>
              </div>
            </>
          )}

          <div className="row pt-2">

            {blockIsBeingEdited &&
              <EditBlock
                training={training}
                block={blockToEdit}
                setBlockToEdit={setBlockToEdit}
              />
            }

            {!blockIsBeingEdited &&
              <Blocks
                blocks={blocks}
                training={training}
                userEquipment={userEquipment}
                setBlockToEdit={setBlockToEdit}
              />
            }

          </div>


        </Modal.Body>
        <Modal.Footer>
          {isEdit && (
            <>
              <Button variant="warning light" onClick={() => setIsEdit(false)}>
                {lang.buttons.cancel}
              </Button>
              <Button
                variant={`${isSaveButtonDisabled ? "dark" : "success light"}`}
                onClick={() => handleUpdateTrainingContainer(query)}
                disabled={isSaveButtonDisabled}
              >
                {lang.buttons.saveChanges}
              </Button>
            </>
          )}
          <Button variant="danger light" onClick={() => closeModal()}>
            {lang.buttons.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

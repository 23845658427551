import { useContext, useEffect } from "react";
import { useCreateExerciseMutation, useUpdateExerciseInBlockMutation, useUpdateExerciseInTrainingMutation, useUpdateExerciseMutation } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { adaptFormCreateExercise } from "../../adapters/exerciseFormat";
import { useSlicesState } from "../../redux/slices/hook";
import { optionsEquipment as equipmentOptions } from "../../assets/data/validateEquipment";
import { LanguageContext } from "../../context/LanguageContext";

export function useCreateAndUpdateExercise(closeModal) {
  const { lang } = useContext(LanguageContext);
  const { exerciseSelected, isEditingExerciseInTraining, query } = useSlicesState()

  const [createExercise, { isError, error, isLoading: isLoadingCreateExercise }] = useCreateExerciseMutation();
  const [updateExercise, { isError: isErrorUpdate, error: errorUpdate, isLoading: isLoadingUpdateExercise }] = useUpdateExerciseMutation();
  const [updateExerciseInTraining, { isError: isErrorUpdateInTraining, error: errorUpdateInTraining, isLoading: isLoadingUpdateExerciseInTraining }] = useUpdateExerciseInTrainingMutation();
  const [updateExerciseInBlock, { isError: isErrorUpdateInBlock, error: errorUpdateInBlock, isLoading: isLoadingUpdateExerciseInBlock }] = useUpdateExerciseInBlockMutation();

  const [formCreateExercise, handleFormCreateExercise, reset, setValues] = useForm({
    name: "",
    video: "",
    description: "",
    category: "other",
    options: {
      weight: false,
      weightInfo: 0,
      km: false,
      kmInfo: 1,
      time: false,
      timeInfo: "",
      hearRate: false,
      hearRateInfo: 30,
      cadence: false,
      cadenceInfo: 1,
      swimmingStyles: false,
      swimmingStylesInfo: "",
      meters: false,
      metersInfo: 1,
      power: false,
      powerInfo: 1,
      other: false,
      otherInfo: "",
      series: 1,
      repetitions: 0,
      heating: false,
      breakRecovery: false,
      breakInfo: 1,
      activeRecovery: false,
      rhythm: false,
      rhythmInfo: 1,
      isUsingMinutes: false,
      toTheRuling: false,
    },
    optionsEquipment: { ...equipmentOptions }
  });
  const {
    name,
    video,
    description,
    category,
    options,
    optionsEquipment,
  } = formCreateExercise;


  const handleCreateEerxice = async () => {
    delete formCreateExercise.optionsEquipment
    const exerciseTransform = adaptFormCreateExercise(formCreateExercise)
    await createExercise(exerciseTransform).unwrap()
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
    Swal.close();
    reset();
    closeModal();
  };

  const handleUpdateExercise = async (exerciseId) => {
    const { _id, idPersonalTrainer, ...dataWithoutIdAndidPersonalTrainer } = formCreateExercise;
    const exerciseTransform = adaptFormCreateExercise(dataWithoutIdAndidPersonalTrainer)

    if (!isEditingExerciseInTraining) {
      await updateExercise({ exerciseId, data: exerciseTransform }).unwrap()
        .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
      Swal.close();
    }

    if (isEditingExerciseInTraining && !exerciseSelected.blockId) {
      await updateExerciseInTraining({ exerciseId, data: exerciseTransform, trainingId: exerciseSelected.trainingId, query }).unwrap()
        .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
      Swal.close();
    }

    if (isEditingExerciseInTraining && exerciseSelected.blockId) {
      await updateExerciseInBlock({ exerciseId, data: exerciseTransform, trainingId: exerciseSelected.trainingId, blockId: exerciseSelected.blockId, query }).unwrap()
        .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
      Swal.close();
    }

    reset();
    closeModal();
  };


  useEffect(() => {
    if (exerciseSelected) {
      setValues({ ...exerciseSelected });
    }
  }, [exerciseSelected, setValues]);


  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorUpdate) showAlert(errorUpdate?.data?.msgCode, errorUpdate?.data?.ok);
    if (isErrorUpdateInTraining) showAlert(errorUpdateInTraining?.data?.msgCode, errorUpdateInTraining?.data?.ok);
    if (isErrorUpdateInBlock) showAlert(errorUpdateInBlock?.data?.msgCode, errorUpdateInBlock?.data?.ok);
  }, [
    error?.data?.msgCode,
    error?.data?.ok,
    errorUpdate?.data?.msgCode,
    errorUpdate?.data?.ok,
    errorUpdateInBlock?.data?.msgCode,
    errorUpdateInBlock?.data?.ok,
    errorUpdateInTraining?.data?.msgCode,
    errorUpdateInTraining?.data?.ok,
    isError,
    isErrorUpdate,
    isErrorUpdateInBlock,
    isErrorUpdateInTraining
  ]);

  useEffect(() => {
    const cleanup = () => {
      reset();
      closeModal();
    }
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingCreateExercise) {
      loadingAlert(lang.alerts.creatingExercise)
    }
    if (isLoadingUpdateExercise) {
      loadingAlert(lang.alerts.updatingExercise)
    }
    if (isLoadingUpdateExerciseInTraining) {
      loadingAlert(lang.alerts.updatingExercise)
    }
    if (isLoadingUpdateExerciseInBlock) {
      loadingAlert(lang.alerts.updatingExercise)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreateExercise, isLoadingUpdateExercise, isLoadingUpdateExerciseInTraining, isLoadingUpdateExerciseInBlock])


  return {
    name,
    video,
    description,
    category,
    options,
    reset,
    formCreateExercise,
    handleFormCreateExercise,
    handleCreateEerxice,
    handleUpdateExercise,
    optionsEquipment
  };
}


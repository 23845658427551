import { useEffect } from "react";
import { useCreateAndUpdateBlockNoteMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { useSlicesState } from "../../redux/slices";

export function useCreateAndUpdateBlockNotes(actualNote) {

  const [createAndUpdateBlockNotes, { isError, error }] = useCreateAndUpdateBlockNoteMutation();

  const { query } = useSlicesState()

  const [formBlocksNotes, handleFormBlocksNotes, resetForm] = useForm({
    note: actualNote ? actualNote : '',
  });

  const {
    note
  } = formBlocksNotes;

  const handleCreateAndUpdateBlockNote = async (idBlock, idTraining, idProfessional) => {
    await createAndUpdateBlockNotes({ idBlock, idTraining, idProfessional, query, dataMutation: { idBlock, data: formBlocksNotes } }).unwrap()
    resetForm()
  };

  const isDisabled = JSON.stringify(formBlocksNotes) === JSON.stringify({ note: actualNote });

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);
  return {
    handleCreateAndUpdateBlockNote,
    handleFormBlocksNotes,
    note,
    isDisabled
  };
}


import { useSelector } from "react-redux";

export const useSlicesState = () => {
    const {
        trainings,
        selectUser,
        importedExercises,
        dateTempTraining,
        exercisesSelected,
        isEditingExerciseInTraining,
        query,
        trainingIdSelected,
        isCreateExerciseInTraining,
        userSelected,
        isCloneTraining,
        trainingToClone,
        createTrainingFromCalendar,
        toggleActivateViewTrainingSimpleMode,
        toggleCreateTrainingSimpleMode
    } = useSelector(state => state.training)

    const { exerciseSelected } = useSelector(state => state.exercise)

    const {
        tempSelectRPE,
        tempUserIdSelected,
        messageToEdit,
        isEditMessage,
    } = useSelector(state => state.feedback)

    const {
        modalFinishTrainingRPE,
        modalFinishTrainingFeedback,
        modalViewExercise,
        modalViewNotifications,
        modalViewVideo,
        modalCreateExercise,
        modalListMyExercises,
        modalIconSearch,
        modalViewEquipment,
    } = useSelector(state => state.modals)

    const {
        startLogin,
        uid,
        token,
        checkAuthAndToken,
        startSigNup,
        rolePreSigNup,
    } = useSelector(state => state.auth)


    const { usersSelected } = useSelector(state => state.susbscriptions)
    const { otherUserId, editingProfile, otherCompleteUser } = useSelector(state => state.users)

    const { thereAreNewNotifications, tempNotificationId } = useSelector(state => state.notifications)

    const { trainingParameters } = useSelector(state => state.userprofile)

    const { openMenuSidebar } = useSelector(state => state.app)

    const { blockIdSelected, blockIsBeingEdited } = useSelector(state => state.blocks)


    return {
        trainings,
        selectUser,
        importedExercises,
        dateTempTraining,
        exercisesSelected,
        exerciseSelected,
        isEditingExerciseInTraining,
        query,
        trainingIdSelected,
        tempSelectRPE,
        modalFinishTrainingRPE,
        modalFinishTrainingFeedback,
        modalViewExercise,
        modalViewNotifications,
        tempUserIdSelected,
        messageToEdit,
        isEditMessage,
        startLogin,
        uid,
        token,
        checkAuthAndToken,
        startSigNup,
        rolePreSigNup,
        usersSelected,
        otherUserId,
        editingProfile,
        thereAreNewNotifications,
        tempNotificationId,
        otherCompleteUser,
        modalViewVideo,
        modalCreateExercise,
        modalListMyExercises,
        modalIconSearch,
        trainingParameters,
        openMenuSidebar,
        modalViewEquipment,
        isCreateExerciseInTraining,
        userSelected,
        blockIdSelected,
        blockIsBeingEdited,
        isCloneTraining,
        trainingToClone,
        createTrainingFromCalendar,
        toggleActivateViewTrainingSimpleMode,
        toggleCreateTrainingSimpleMode
    };
};
import React, { useContext, useState } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import { Filter, InfoIcon } from "../assets/svg/svgs";
import { AlertSinLink, Subscribers } from "../components";
import { LanguageContext } from "../context/LanguageContext";
import { statusSubscription } from "../domain/statusSubscription";
import { useSubscriptions } from "../hooks";
import { Loading } from '../components/Loading/Loading'
import { useSlicesState } from "../redux/slices";
import { isMobile } from "react-device-detect";

export const MySubscriptions = () => {
  const { lang } = useContext(LanguageContext);
  const { usersSelected } = useSlicesState()
  const {
    msgCode,
    isLoading,
    handleChange,
    input,
    setIsSelectdAll,
    isSelectdAll,
    filterStatus,
    handleActivateBulk,
    handleFinishBulk,
    handleReactivatedBulk,
    filterSubscriptions,
    setFilterFilterStatus,
    isAthlete,
    subscriptions
  } = useSubscriptions()

  const data = filterSubscriptions ? filterSubscriptions : subscriptions;

  const [dataFiltered, setDataFiltered] = useState(data);

  const thereIsFilter = (condition) => {
    return data?.some((suscription) => suscription.status === condition);
  };


  if (isLoading) return <Loading show={isLoading} />

  if (msgCode === "SE0011") {
    return (
      <>
        <AlertSinLink
          variant={"info"}
          Icon={InfoIcon}
          msg={lang.alerts.info}
          text={lang.mySubscriptions.noNubscribers}
        />
      </>
    );
  }

  return (
    <>
      <Row style={{ height: '87dvh' }}>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>{isAthlete() ? lang.mySubscriptions.titleMyProfesionals : lang.mySubscriptions.titleMyAthletes}</Card.Title>
              {
                ((subscriptions?.length > 5) || isMobile) &&
                <input
                  type="text"
                  className="form-control border-2 border-secondary ml-4 w-50"
                  placeholder={lang.placeHolders.searchMySubscriptions}
                  onChange={handleChange}
                  value={input}
                />
              }

            </Card.Header>

            <Card.Body className="tab-content pt-2 scroll_test_responsive scrollbar-color">
              {((!isAthlete() && subscriptions?.length > 5) || isMobile) && (
                <>
                  <div className="d-flex justify-content-start" style={{ gap: '0.3rem' }}>
                    <p className="text-primary">{lang.mySubscriptions.status.filterByStatus}</p>

                    <div>
                      <Button
                        variant="outline-info btn-xs"
                        style={{ marginTop: '-4px' }}
                        onClick={() => setIsSelectdAll(true)}
                      >
                        {lang.buttons.selectAllUsers}
                      </Button>
                      {isSelectdAll && (
                        <>
                          {filterStatus === statusSubscription.new && (
                            <Button
                              variant="success btn-xs ml-2"
                              onClick={() => handleActivateBulk(usersSelected)}
                            >
                              {lang.generic.activate}
                            </Button>
                          )}
                          {(filterStatus === statusSubscription.active ||
                            filterStatus === statusSubscription.reactivated) && (
                              <Button
                                variant="danger btn-xs ml-2"
                                onClick={() => handleFinishBulk(usersSelected)}
                              >
                                {lang.generic.finalize}
                              </Button>
                            )}
                          {filterStatus === statusSubscription.finished && (
                            <Button
                              variant="info btn-xs ml-2"
                              onClick={() => handleReactivatedBulk(usersSelected)}
                            >
                              {lang.generic.reactivate}
                            </Button>
                          )}
                        </>
                      )}
                    </div>

                    <Dropdown
                      className="dropdown text-right"
                      style={{ borderBottomWidth: "0px", padding: "0px", marginTop: '-5px' }}
                    >
                      <Dropdown.Toggle
                        variant="button"
                        style={{ borderBottom: "0px", padding: "0px" }}
                        data-toggle="dropdown"
                        type="button"
                        className="light sharp i-false dropdown-toggle btn btn-dark"
                      >
                        {Filter}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <Dropdown.Item
                          onClick={() => setFilterFilterStatus("all")}
                          className="text-secondary"
                        >
                          {lang.mySubscriptions.filter.all}
                        </Dropdown.Item>
                        {thereIsFilter(statusSubscription.new) && (
                          <Dropdown.Item
                            onClick={() => setFilterFilterStatus(statusSubscription.new)}
                            className="text-warning"
                          >
                            {lang.mySubscriptions.filter.news}
                          </Dropdown.Item>
                        )}
                        {thereIsFilter(statusSubscription.active) && (
                          <Dropdown.Item
                            onClick={() => setFilterFilterStatus(statusSubscription.active)}
                            className="text-success"
                          >
                            {lang.mySubscriptions.filter.active}
                          </Dropdown.Item>
                        )}
                        {thereIsFilter(statusSubscription.reactivated) && (
                          <Dropdown.Item
                            onClick={() =>
                              setFilterFilterStatus(statusSubscription.reactivated)
                            }
                            className="text-info"
                          >
                            {lang.mySubscriptions.filter.reactivated}
                          </Dropdown.Item>
                        )}
                        {thereIsFilter(statusSubscription.finished) && (
                          <Dropdown.Item
                            onClick={() =>
                              setFilterFilterStatus(statusSubscription.finished)
                            }
                            className="text-danger"
                          >
                            {lang.mySubscriptions.filter.finishid}
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
              <Subscribers
                data={data}
                filterStatus={filterStatus}
                setFilterFilterStatus={setFilterFilterStatus}
                setDataFiltered={setDataFiltered}
                dataFiltered={dataFiltered}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};






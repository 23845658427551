import { createSlice } from '@reduxjs/toolkit'


export const subscriptionsSlice = createSlice({
    name: 'susbscriptions',
    initialState: {
        usersSelected: [],
    },
    reducers: {
        setUsersSelected: (state, action) => {
            state.usersSelected = action.payload
        },
        setInitialStateSubscriptions: (state) => {
            Object.assign(state, subscriptionsSlice.getInitialState())
        }
    },
})

export const {
    setUsersSelected,
    setInitialStateSubscriptions
} = subscriptionsSlice.actions
import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { ModalCreateExercise } from '../modals/ModalCreateExercise';
import { ModalVideo } from '../modals/ModalVideo';
import { DynamicSVGIconList } from '../icon/DynamicSVGIconList';
import { InfoIcon } from '../../assets/svg/svgs';
import { AlertSinLink } from '../alerts/AlertSinLink';
import { useSlicesActions } from '../../redux/slices';
import { useDeleteExercise } from '../../hooks';
import { isBlockTraining } from '../../assets/data/typesOfTrainings';

export const ExerciseCard = ({
  exercise,
  index,
  training,
  handleUpdateExercise,
  isAthlete,
  dispatch,
  setModalViewVideo,
  handleTerminateExerciseInBlock,
  userEquipment,
  lang,
  filterOptionsEquipment,
  exercises,
  block,
  trainingIsSimpleMode
}) => {

  return (
    <Card
      className={`text-black bgl-white border-2 ${training?.optionsSeriesTraining
        ? "border-info"
        : (exercise?.isComplete || training?.isComplete || block?.isComplete) && !exercise?.optionsSeriesTraining
          ? "border-success"
          : "border-warning"
        }`}
    >

      <CardHeader
        exercise={exercise}
        index={index}
        handleUpdateExercise={handleUpdateExercise}
        isAthlete={isAthlete}
        exercises={exercises}
        typesOfTraining={training?.typesOfTraining}
        blockId={block?._id}
        trainingIsCompleted={training?.isComplete}
        blockIsCompleted={block?.isComplete}
        trainingIsSimpleMode={trainingIsSimpleMode}
      />

      <CardBody
        exercise={exercise}
        lang={lang}
        userEquipment={userEquipment}
        filterOptionsEquipment={filterOptionsEquipment}
      />
      <CardFooter
        exercise={exercise}
        training={training}
        isAthlete={isAthlete}
        dispatch={dispatch}
        setModalViewVideo={setModalViewVideo}
        handleTerminateExerciseInBlock={handleTerminateExerciseInBlock}
        lang={lang}
        trainingIsCompleted={training?.isComplete}
        blockIsCompleted={block?.isComplete}
      />
    </Card>
  );
};

const CardHeader = ({ exercise, index, handleUpdateExercise, isAthlete, exercises, typesOfTraining, blockId, trainingIsCompleted, blockIsCompleted, trainingIsSimpleMode }) => {

  const { handleDeleteExercise } = useDeleteExercise(null, true, blockId)
  const { dispatch, setBlockIdSelected, setIsEditingExerciseInTrainingTrue } = useSlicesActions()
  const [isCreateExercise, setIsCreateExercise] = useState(false);


  return (
    <Card.Header>
      <Card.Title className="text-primary">
        #{index + 1} {exercise?.name}
      </Card.Title>
      <div className='d-flex flex-row justify-content-end align-content-center' >
        {!exercise?.isComplete && !isAthlete() && !trainingIsCompleted && !blockIsCompleted && (
          <>
            <Button
              as="a"
              variant="warning ml-2"
              href="#"
              className="btn-xs"
              onClick={() => {
                dispatch(setBlockIdSelected(exercise?.blockId))
                dispatch(setIsEditingExerciseInTrainingTrue())
                handleUpdateExercise(exercise?._id, exercise)
              }}
            >
              <i className={`fa fa-edit`} />
            </Button>
          </>
        )}

        {!exercise?.isComplete && !isAthlete() && !trainingIsCompleted && !blockIsCompleted && isBlockTraining(typesOfTraining) && (
          <>
            <Button
              as="a"
              variant="danger ml-2"
              href="#"
              className="btn-xs"
              onClick={() => {
                dispatch(setBlockIdSelected(exercise?.blockId))
                handleDeleteExercise(exercise?._id)
              }}
            >
              <i className={`fa fa-trash`} />
            </Button>

          </>
        )}
        <ModalCreateExercise
          exercise={exercise}
          idModal={exercise?._id}
          listExercises={exercises}
          isCreateExercise={isCreateExercise}
          setIsCreateExercise={setIsCreateExercise}
          trainingIsSimpleMode={trainingIsSimpleMode}
        />
      </div>

    </Card.Header>
  )
};

const CardBody = ({ exercise, lang, userEquipment, filterOptionsEquipment }) => (
  <Card.Body className="mb-0 pl-2 pr-2 pt-1">
    {exercise?.description && (
      // <Card.Text className='text-secondary'>{exercise.description}</Card.Text>
      <AlertSinLink
        variant={"light"}
        Icon={InfoIcon}
        text={exercise?.description}
      />
    )}
    <DynamicSVGIconList exercise={exercise} lang={lang} />
    {
      exercise?.optionsEquipment && userEquipment?.length > 0 &&
      <>
        {
          filterOptionsEquipment(exercise?.optionsEquipment, userEquipment)?.length > 0 &&
          <h4 className="mt-3 text-capitalize">{lang.generic.equipment}</h4>
        }
        {
          filterOptionsEquipment(exercise?.optionsEquipment, userEquipment).map((equipment, index) => (
            <div key={index} className="d-flex flex-column rounded p-2 bg-white mb-2">
              <div className="d-flex flex-row justify-content-start align-items-center rounded p-2 bg-white mb-2" style={{ gap: '2rem' }}>
                <div>
                  <img
                    src={equipment.img}
                    className="img-fluid"
                    alt={equipment.label}
                    style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '5px' }}
                    loading="lazy"
                  />
                </div>
                <p style={{ fontSize: '0.8rem' }}>{equipment.value === "withChinUpBar" ? `${equipment.label.substring(0, 18)}...` : equipment.label}</p>
              </div>
            </div>
          ))
        }
      </>
    }
  </Card.Body>
);

const CardFooter = ({ exercise, training, isAthlete, dispatch, setModalViewVideo, handleTerminateExerciseInBlock, lang, trainingIsCompleted, blockIsCompleted }) => (
  <Card.Footer className={`bg-transparent border-0 text-white p-2 ${!isAthlete() && "d-flex justify-content-center align-items-center"}`}>
    {exercise?.video && (
      <>
        <Button
          as="a"
          variant="secondary"
          className="btn-card mt-3 btn-xs"
          onClick={() => dispatch(setModalViewVideo(exercise._id))}
        >
          {lang.buttons.viewVideo}
          <i className={`fa fa-play-circle ml-2`} />
        </Button>
        <ModalVideo
          video={exercise?.video}
          nombreEjercicio={exercise?.name}
          idModal={exercise._id}
        />
      </>
    )}
    {isAthlete() && !trainingIsCompleted && !blockIsCompleted && (
      <Button
        as="a"
        variant={
          exercise?.isComplete ? "success disabled" : "warning"
        }
        href="#"
        className="btn-card mt-3 float-right btn-xs"
        onClick={() => handleTerminateExerciseInBlock(exercise?._id, training?.idPersonalTrainer?._id, training?.startDate)}
      >
        <i className={`fa fa-check`} />
        {/* {exercise.isComplete
          ? lang.buttons.full
          : lang.buttons.toComplete} */}
      </Button>
    )}
  </Card.Footer>
);



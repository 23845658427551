import { useDeleteNotificationsMutation } from "../../api";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";

export function useDeleteNotifications() {
  const [deleteNotification, { isError, error }] = useDeleteNotificationsMutation();

  const handleRemoveNotifcation = (notificationId) => {
    deleteNotification({notificationId})
  }
 

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);


  return {
    handleRemoveNotifcation
  };
}


import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { LanguageContext } from "../context/LanguageContext";
import { useForm } from "../hooks/useForm";
import { LogoWraper } from "../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { forgotPassword } from "../services/auth.services.";

export const ForgotPassword = () => {
  const { lang } = useContext(LanguageContext);

  const [formForgtotPassword, handleFormForgtotPassword] = useForm({
    email: "",
  });

  const { email } = formForgtotPassword;

  const handleForgetPassword = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  return (
    <div className="mt-5">
      <PerfectScrollbar className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <LogoWraper />
              <div className="card border border-primary">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4 text-black">
                        {lang.forgotPassword.title}
                      </h4>
                      <form onSubmit={handleForgetPassword}>
                        <div className="form-group">
                          <label className="text-black">
                            <strong>{lang.generic.email}</strong>
                          </label>
                          <input
                            type="email"
                            name="email"
                            required
                            className="form-control border border-info"
                            placeholder={lang.placeHolders.enterYourEmail}
                            value={email}
                            onChange={handleFormForgtotPassword}
                            autoFocus={true}
                          />
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-primary text-white btn-block"
                          >
                            {lang.buttons.request}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="text-black">
                          {lang.forgotPassword.footer}
                          <Link className="text-primary" to={Routes.login}>
                            {lang.generic.login}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const userCardsAPI = createApi({
    reducerPath: 'userCardsAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["userCardsAPI"],
    endpoints: (builder) => ({
        getDataForOneUser: builder.query({
            query: (userId) => ({
                url: `/user-card/get-one-data/${userId}?limit=500&offset=0`, //FIXME: Paginación
            }),
            providesTags: ["userCardsAPI"],
            extraOptions: { maxRetries: 0 }
        }),
        createUserCard: builder.mutation({
            query: ({userId, note}) => (
                {
                url: `/user-card/create/${userId}`,
                method: 'POST',
                body: note
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
                const { data: resultCreateUserCard } = await queryFulfilled;
                const patchResult = dispatch(
                    userCardsAPI.util.updateQueryData('getDataForOneUser', userId, (oldData) => {
                        if(oldData?.msgCode === 'SE0020') {
                            dispatch(userCardsAPI.util.invalidateTags(["userCardsAPI"]))
                        } else {
                            return {
                                ...oldData,
                                userCardsDocs: [...oldData?.userCardsDocs, resultCreateUserCard?.userCard],
                                totalCount: oldData?.totalCount !== null && oldData?.totalCount !== undefined ? oldData.totalCount + 1 : 1
                            }
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
            
        }),
        updateUserCard: builder.mutation({
            query: ({note, cardId, userId}) => ({
                url: `/user-card/update/${cardId}`,
                method: 'PUT',
                body: note
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ cardId, userId,  ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    userCardsAPI.util.updateQueryData('getDataForOneUser', userId, (oldData) => {
                        const updatedData = Array.from(oldData?.userCardsDocs).map((userCard) =>
                            userCard._id === cardId ? { ...userCard, ...result?.note } : userCard
                        );
                        return { ...oldData, userCardsDocs: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteUserCard: builder.mutation({
            query: ({userCardId, userId}) => ({
                url: `/user-card/delete/${userCardId}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({userCardId, userId}, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    userCardsAPI.util.updateQueryData('getDataForOneUser', userId, (oldData) => {
                        const updatedData = oldData?.userCardsDocs?.filter((userCard) => userCard._id !== userCardId);
                        return {
                            ...oldData,
                            userCardsDocs: updatedData,
                            totalCount: oldData?.totalCount && oldData.totalCount - 1
                        };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const { useGetDataForOneUserQuery, useCreateUserCardMutation, useUpdateUserCardMutation, useDeleteUserCardMutation } = userCardsAPI;
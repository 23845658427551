export const usePath = (valor) => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - valor];

    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const token = searchParams.get("token");

  return {
    path,
    token
  };
};



import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useActivateSubscriptions } from "./useActivateSubscriptions";
import { statusSubscription } from "../../domain/statusSubscription";
import { useGetMySubscriptions } from "./useGetMySubscriptions";
import { useGetMyProfile } from "../users/useGetMyProfile";
import { useFinishedSubscriptions } from "./useFinishedSubscriptions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../routers/Routes";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useSubscriptions() {
  const { lang } = useContext(LanguageContext);
  const history = useHistory()

  const { uid } = useSlicesState()
  const { dispatch, setSelectUser, setUserIdSelected, setOtherUserId} = useSlicesActions()
  
  const {
    subscriptions,
    msgCode,
    isLoading
  } = useGetMySubscriptions()

  const { isAthlete } = useGetMyProfile()

  const { handleActivateReactivatedSusbcriptions } = useActivateSubscriptions()
  const { handleFinishedSusbcriptions } = useFinishedSubscriptions()

  //Buscador
  const [filterSubscriptions, setFilterSubscriptions] = useState(subscriptions);
  const [input, setInput] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value.toLowerCase());
  };


  const [filterStatus, setFilterFilterStatus] = useState("all");
  const [isSelectdAll, setIsSelectdAll] = useState(false);

  const handleActivateBulk = (data) => {
    Swal.fire({
      title: lang.generic.inAgreement,
      icon: "info",
      text: lang.mySubscriptions.modal.active,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: `${lang.generic.no}!`,
      confirmButtonText: lang.dataReactivate.textButtonTwo,
      confirmButtonColor: "#3fc3ee",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleActivateReactivatedSusbcriptions(data, statusSubscription.active)
        setFilterFilterStatus("all");
        setIsSelectdAll(false);
      }
    });
  };

  const handleReactivatedBulk = (data) => {
    Swal.fire({
      title: lang.generic.inAgreement,
      icon: "info",
      text: lang.mySubscriptions.modal.reactivated,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: `${lang.generic.no}!`,
      confirmButtonText: lang.generic.yesReactivate,
      confirmButtonColor: "#3fc3ee",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleActivateReactivatedSusbcriptions(data, statusSubscription.reactivated)
        setFilterFilterStatus("all");
        setIsSelectdAll(false);
      }
    });
  };

  const handleFinishBulk = (data) => {
    Swal.fire({
      title: lang.generic.inAgreement,
      icon: "warning",
      text: lang.mySubscriptions.modal.finished,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: `${lang.generic.no}!`,
      confirmButtonText: lang.generic.yesFinish,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFinishedSusbcriptions(data, statusSubscription.finished);
        setFilterFilterStatus("all");
        setIsSelectdAll(false);
      }
    });
  };

  const alertEnd = {
    title: `${lang.generic.areYouSure}`,
    text: `${lang.dataFinalice.text}`,
    icon: "warning",
    textButtonOne: `${lang.dataFinalice.textButtonOne}`,
    textButtonTwo: `${lang.dataFinalice.textButtonTwo}`,
    swalText: `${lang.dataFinalice.swalText}`,
    swalIcon: "success",
    fun: (id, action) => handleFinishedSusbcriptions(id, action),
  };

  const alertReactivate = {
    title: `${lang.generic.areYouSure}`,
    text: `${lang.dataReactivate.text}`,
    icon: "warning",
    textButtonOne: `${lang.dataReactivate.textButtonOne}`,
    textButtonTwo: `${lang.dataReactivate.textButtonTwo}`,
    swalText: `${lang.dataReactivate.swalText}`,
    swalIcon: "success",
    fun: (id, action) => handleActivateReactivatedSusbcriptions(id, action),
  };

  const handleViewProfile = (info) => {
    const idOtherProfile =
      uid === info.idInviting._id ? info.guestId._id : info.idInviting._id;
    dispatch(setOtherUserId(idOtherProfile));
    history.push(Routes.profileSuscriptor);
  };

  const handleUserCard = (info) => {
    const userSelected =
      uid === info.idInviting._id ? info.guestId : info.idInviting;
    // localStorage.setItem("userSelected", JSON.stringify(userSelected));
    dispatch(setOtherUserId(userSelected));
    history.push(Routes.userCard);
  };

  const handleCreateTraining = (otherUser) => {
    dispatch(setSelectUser(otherUser));
    history.push(Routes.createTraining);
  };

  const handleViewTraining = (otherUser) => {
    dispatch(setSelectUser(otherUser));
    dispatch(setUserIdSelected(otherUser))
    history.push(Routes.viewWorkoutPlanSuscriptor);
  };

  useEffect(() => {
    if (input.length > 0) {
      const filtrados = subscriptions?.filter((u) =>
        u.guestId.name.match(input)
      );
      setFilterSubscriptions(filtrados);
    }

    if (!input.length) {
      setFilterSubscriptions(subscriptions);
    }
  }, [input, subscriptions]);

  return {
    msgCode,
    isLoading,
    handleChange,
    input,
    setIsSelectdAll,
    isSelectdAll,
    filterStatus,
    handleActivateBulk,
    handleFinishBulk,
    handleReactivatedBulk,
    filterSubscriptions,
    setFilterFilterStatus,
    isAthlete,
    subscriptions,
    handleActivateReactivatedSusbcriptions,
    alertEnd,
    alertReactivate,
    handleViewProfile,
    handleUserCard,
    handleCreateTraining,
    handleViewTraining,
  };
}


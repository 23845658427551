import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../helpers/Alerts";
import { useForm, useGetMyProfile } from "..";
import { useAddChatIdTelegramMutation, useDeleteChatIdTelegramMutation, userProfileAPI } from "../../api";

export function useChatIdTelegram() {
  const dispatch = useDispatch();

  const {
    telegramChatIdInProfile
  } = useGetMyProfile()

  const [formAddChatIdTelegram, handleFormAddChatIdTelegram, reset] = useForm({
    telegramChatId: ''
  });
  const { telegramChatId } = formAddChatIdTelegram;

  const [addChatIdTelegram, { isError, isSuccess, error, data }] = useAddChatIdTelegramMutation();
  const [deleteChatIdTelegram, { isErrorDelete, isSuccessDelete, errorDelete, dateDelete }] = useDeleteChatIdTelegramMutation();


  const handleAddTelegramChatID = async (e) => {
    e.preventDefault();
    try {
      await addChatIdTelegram({ telegramChatId }).unwrap().then(() => {
        dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
      })
      reset()
    } catch (error) {
      console.log(error)
    }
  };

  const isDisable = telegramChatId.length < 1;
  const isUpdate = telegramChatIdInProfile && telegramChatId.length > 1;

  const handleDeleteTelegramChatID = async (e) => {
    e.preventDefault();
    try {
      await deleteChatIdTelegram().unwrap().then(() => {
        dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
      })
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorDelete) showAlert(errorDelete?.data?.msgCode, errorDelete?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
    if (isSuccessDelete) showAlert(dateDelete?.msgCode, dateDelete?.ok);
  }, [data?.msgCode, data?.ok, dateDelete?.msgCode, dateDelete?.ok, error?.data?.msgCode, error?.data?.ok, errorDelete?.data?.msgCode, errorDelete?.data?.ok, isError, isErrorDelete, isSuccess, isSuccessDelete]);

  return {
    formAddChatIdTelegram,
    handleFormAddChatIdTelegram,
    isDisable,
    handleAddTelegramChatID,
    isUpdate,
    telegramChatIdInProfile,
    handleDeleteTelegramChatID,
  };
}

import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useResponsive, useWhatsAppNotifications } from "../../hooks";

export const WhatsApp = ({ activeToggle }) => {
    const { lang } = useContext(LanguageContext)

    const {
        phoneWhatsApp,
        handleDeletePhoneWhatsApp
    } = useWhatsAppNotifications()

    const { isDesktop } = useResponsive();


    return (
        <div
            id="whats-app"
            className={`tab-pane fade mt-3 ${activeToggle === "whatsapp" ? "active show" : ""
                }`}
        >
            <div>

                <p className="fontSize9Rem">{lang.profile.notifications.stepsGetPhoneWhatsApp}</p>
                <ul className="list-group fontSize9Rem">
                    <li className="list-group-item">{`${isDesktop ? lang.profile.notifications.step1WhatsDesktop : lang.profile.notifications.step1WhatsAppMobile}`}</li>
                    <li className="list-group-item">{lang.profile.notifications.step2WhatsApp}</li>
                    <a href="https://wa.me/message/FTX4TXJWTA3UE1" target="_blank" rel="noreferrer" className="list-group-item">
                        <button className="btn btn-xs btn-success">{lang.buttons.configWhatsAppNumber}</button>
                    </a>
                    {isDesktop &&
                        <img
                            src="https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716079866/fitapp/other-app/jnnb8aqw4srs1fpncbuw.png"
                            className="img-fluid"
                            alt="qr-whatsapp"
                            style={{ objectFit: 'cover', borderRadius: '5px' }}
                            loading="lazy"
                        />
                    }

                </ul>


                {phoneWhatsApp &&
                    <form autoComplete="off">
                        <div className="form-row mt-3">
                            <div className="form-group col-12">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="telegramChatId"
                                        className="form-control text-capitalize"
                                        value={phoneWhatsApp}
                                        disabled
                                    />
                                    <div className="input-group-append">
                                        {
                                            phoneWhatsApp &&
                                            <button
                                                className="btn btn-warning"
                                                style={{ borderRadius: '0 1.25rem 1.25rem 0' }}
                                                onClick={(e) => handleDeletePhoneWhatsApp(e)}
                                            >
                                                {lang.buttons.delete}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                }
                <h6 className='mt-3 text-info' style={{ fontSize: '0.7rem' }}>{lang.profile.notifications.dataPrivacy}</h6>
            </div>
        </div>
    );
};
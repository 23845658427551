export const getValuesInputExersices = (exercise, optionName, date, activateTrainingProgressionsTemp, inputType) => {
    if (activateTrainingProgressionsTemp) {
      if (inputType === 'range') {
        return (exercise?.options[optionName][date] !== undefined ? exercise?.options[optionName][date] : 0)
      } else if (inputType === 'text') {
        return (exercise?.options[optionName][date] !== undefined ? exercise?.options[optionName][date] : '')
      }
    } else {
      if (inputType === 'range') {
        return (exercise?.options[optionName] !== undefined ? exercise?.options[optionName] : 0)
      } else if (inputType === 'text') {
        return (exercise?.options[optionName] !== undefined ? exercise?.options[optionName] : '')
      }
    }
  };
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Avatar, ModalIconSearch, ModalNotifications, SearchPrincipal } from "../../components";
import { LanguageContext } from "../../context/LanguageContext";
import { LogoutSVG, ProfileSVG } from "../../assets/svg/svgs";
import { Routes } from "../../routers/Routes";
import { useGetMyProfile, usePath } from "../../hooks";
import { useSlicesActions } from "../../redux/slices/hook";
import { trainingAPI, userProfileAPI } from "../../api";
import { deleteTokenLocalStorage } from "../../helpers/auth-helpers";
import { setCheckAuthAndToken, setInitialStateAuth } from "../../redux/slices";
import { isMobile } from "react-device-detect";


export const Header = () => {
  const { dispatch,
    setInitialStateSubscriptions,
    setInitialStateUsers,
    setInitialStateTraining,
    setInitialStateModals,
    setInitialStateFeedBack,
    setInitialStateExercise,
    setInitialStateNotifications,
    setInitialStateUserProfile,
    setInitialStateApp,
    setInitialStateBlocks
  } = useSlicesActions()
  const { lang } = useContext(LanguageContext);

  const {
    name,
    img,
    isPersonalTrainer,
    isAthlete,
    isYogaStretching,
  } = useGetMyProfile()

  const { path } = usePath(1);


  const hadleLogout = () => {
    deleteTokenLocalStorage()
    sessionStorage.clear()
    localStorage.clear()
    dispatch(setInitialStateAuth())
    dispatch(setInitialStateSubscriptions())
    dispatch(setInitialStateUsers())
    dispatch(setInitialStateTraining())
    dispatch(setInitialStateModals())
    dispatch(setInitialStateFeedBack())
    dispatch(setInitialStateExercise())
    dispatch(setInitialStateNotifications())
    dispatch(setInitialStateUserProfile())
    dispatch(setInitialStateApp())
    dispatch(setInitialStateBlocks())
    dispatch(setCheckAuthAndToken(false))
    dispatch(trainingAPI.util.invalidateTags(["trainingAPI"]))
    dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="dashboard_bar">
                {path === "dashboard" && lang.generic.dashboard}
                {path === "profile" && lang.generic.profile}
                {path === "my-gyms" && lang.generic.myGyms}
                {path === "my-teams" && lang.generic.myTeams}
                {path === "measure-details" && lang.generic.physiologicalData}
                {path === "subscriptions" && lang.generic.subscriptions}
                {path === "workout-plan" && lang.generic.training}
                {path === "create-training" && lang.generic.createWorkout}
                {path === "exercises" && lang.generic.exercices}
                {path === "big-calendar" && lang.generic.calendar}
                {path === "search-details" && lang.generic.searchDetails}
                {path === "rpe-example" && lang.RPE.title}
                {path === "workout-plan-suscriptor" && lang.generic.training}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              {!isMobile
                ? <SearchPrincipal />
                : <ModalIconSearch />
              }
              <ModalNotifications />

              <Dropdown as="li" className="nav-item dropdown header-profile" show={isOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle
                  variant=""
                  className="nav-link i-false"
                  href="#"
                  role="button"
                  // data-toggle="dropdown"
                  onClick={toggleDropdown}
                >
                  <Avatar name={name ? name : ''} img={img} size="39px" />
                  <div className="header-info">
                    <span className="text-black text-capitalize">
                      <strong>{name}</strong>
                    </span>

                    {isAthlete() && (
                      <p className="fs-12 mb-0">{lang.roles.athlete}</p>
                    )}

                    {isPersonalTrainer() && (
                      <p className="fs-12 mb-0">{lang.roles.personalTrainer}</p>
                    )}

                    {isYogaStretching() && (
                      <p className="fs-12 mb-0">{lang.roles.yogaStretching}</p>
                    )}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2" onMouseLeave={closeDropdown}>
                  <Link to={Routes.profile} className="dropdown-item ai-icon" onClick={closeDropdown}>
                    <ProfileSVG className={"text-primary"} />
                    <span className="ml-2">{lang.generic.profile}</span>
                  </Link>

                  <button
                    className="dropdown-item ai-icon"
                    onClick={() => { hadleLogout(); closeDropdown(); }}
                  >
                    <LogoutSVG className={"text-danger"} />
                    <span className="ml-2">{lang.buttons.logout}</span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

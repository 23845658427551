import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import {
    analyticsAPI,
    blockAPI,
    equipmentAssociationAPI,
    exerciseAPI,
    feedbackTrainingAPI,
    getAppConfigAPI,
    notificationsAPI,
    physiologicalDataAPI,
    sportAssociationAPI,
    subscriptionsAPI,
    trainingAPI,
    userAPI,
    userCardsAPI,
    userProfileAPI
} from '../../api';
import {
    appSlice,
    blocksSlice,
    exerciseSlice,
    feedbackSlice,
    modalsSlice,
    notificationsSlice,
    trainingSlice,
    userProfileSlice
} from '../slices';
import { authSlice } from '../slices/authSlice';
import { subscriptionsSlice } from '../slices/subscriptionsSlice';
import { usersSlice } from '../slices/usersSlice';


export const store = configureStore({
    reducer: {
        // Nuevos RTK
        [analyticsAPI.reducerPath]: analyticsAPI.reducer,
        [exerciseAPI.reducerPath]: exerciseAPI.reducer,
        [feedbackTrainingAPI.reducerPath]: feedbackTrainingAPI.reducer,
        [getAppConfigAPI.reducerPath]: getAppConfigAPI.reducer,
        [notificationsAPI.reducerPath]: notificationsAPI.reducer,
        [physiologicalDataAPI.reducerPath]: physiologicalDataAPI.reducer,
        [sportAssociationAPI.reducerPath]: sportAssociationAPI.reducer,
        [subscriptionsAPI.reducerPath]: subscriptionsAPI.reducer,
        [trainingAPI.reducerPath]: trainingAPI.reducer,
        [userAPI.reducerPath]: userAPI.reducer,
        [userCardsAPI.reducerPath]: userCardsAPI.reducer,
        [userProfileAPI.reducerPath]: userProfileAPI.reducer,
        [equipmentAssociationAPI.reducerPath]: equipmentAssociationAPI.reducer,
        [blockAPI.reducerPath]: blockAPI.reducer,

        // Nuevos Slice
        app: appSlice.reducer,
        auth: authSlice.reducer,
        exercise: exerciseSlice.reducer,
        feedback: feedbackSlice.reducer,
        modals: modalsSlice.reducer,
        notifications: notificationsSlice.reducer,
        susbscriptions: subscriptionsSlice.reducer,
        training: trainingSlice.reducer,
        userprofile: userProfileSlice.reducer,
        users: usersSlice.reducer,
        blocks: blocksSlice.reducer,

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            userProfileAPI.middleware,
            getAppConfigAPI.middleware,
            userAPI.middleware,
            sportAssociationAPI.middleware,
            physiologicalDataAPI.middleware,
            analyticsAPI.middleware,
            trainingAPI.middleware,
            subscriptionsAPI.middleware,
            userCardsAPI.middleware,
            exerciseAPI.middleware,
            feedbackTrainingAPI.middleware,
            notificationsAPI.middleware,
            equipmentAssociationAPI.middleware,
            blockAPI.middleware,
        ),
})
setupListeners(store.dispatch)
export const rpeData = [
  {
    number: "0",
    title: "Reposo Total",
    color: "#2e9ed0"
  },
  {
    number: "1",
    title: "Muy, Muy Ligero",
    color: "#2e9ed0"
  },
  {
    number: "2",
    title: "Muy Ligero",
    color: "#2e9ed0"
  },
  {
    number: "3",
    title: "Ligero",
    color: "#8acd00"
  },
  {
    number: "4",
    title: "Algo Pesado",
    color: "#8acd00"
  },
  {
    number: "5",
    title: "Pesado",
    description: "Se podría completar entre 4 y 6 repeticiones más.",
    color: "#ffd300"
  },
  {
    number: "6",
    title: "Más Pesado",
    description: "Se podría completar entre 4 y 6 repeticiones más.",
    color: "#ffd300"
  },
  {
    number: "7",
    title: "Muy pesado",
    description: "Se podría completar 3 repeticiones más.",
    color: "#ff5a14"
  },
  {
    number: "7.5",
    title: "Muy pesado",
    description: "Se podría completar entre 2 repeticiones más con seguridad, probablemente 3.",
    color: "#ff5a14"
  },
  {
    number: "8",
    title: "Muy pesado",
    description: "Se podría completar 2 repeticiones más.",
    color: "#ff5a14"
  },
  {
    number: "8.5",
    title: "Muy pesado",
    description: "Se podría completar una repetición más con total seguridad, probablemente 2.",
    color: "#ff5a14"
  },
  {
    number: "9",
    title: "Máximo",
    description: "Se podría completar 1 repetición más.",
    color: "#fd0023"
  },
  {
    number: "9.5",
    title: "Máximo",
    description: "Imposible completar más repeticiones, pero se podría aumentar ligeramente la carga.",
    color: "#fd0023"
  },
  {
    number: "10",
    title: "Extremo",
    description: "Imposible completar más repeticiones o aumentar la carga.",
    color: "#fd0023"
  },
];

export const getSessionStorageValue = (key, defaultValue) => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
};

export const setSessionStorageValue = (key, value) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error(`Error setting sessionStorage key "${key}":`, error);
    }
};

import React, { useContext, useEffect, useRef } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { AlertSinLink, CalendarWorkout, Loading, TrainingList, TrainingSimpleMode } from "../components";
import { LanguageContext } from "../context/LanguageContext";
import { InfoIcon } from "../assets/svg/svgs";
import { useGetMyProfile, useTrainingsRoot } from "../hooks";
import { useLocation } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { useSlicesActions, useSlicesState } from "../redux/slices/hook";
import "react-calendar/dist/Calendar.css";
import "dayjs/locale/es";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isAfterJuly2024 } from "../helpers/formatDate";

export const WorkoutPlan = () => {

  const history = useHistory();
  const tableRef = useRef();

  const { isCloneTraining, toggleActivateViewTrainingSimpleMode } = useSlicesState()

  const {
    dispatch,
    setCleanSelectUser,
    setTrainingIdSelected,
    setUserIdSelected,
    setToggleActivateViewTrainingSimpleMode,
  } = useSlicesActions()

  const { pathname } = useLocation()
  const { lang } = useContext(LanguageContext);
  const { isAthlete } = useGetMyProfile()

  const {
    isLoading,
    selectUser,
    trainings,
    trainingPending,
    trainingFinished,
  } = useTrainingsRoot()

  const withoutTRainings = isAthlete()
    ? pathname === Routes.workoutPlan
      ? lang.alerts.dontHaveTrainingThisMonth
      : lang.alerts.dontHaveTrainingFromThisProfessional
    : lang.alerts.workoutplanDetails

  const fileNameExcel = selectUser
    ? `${selectUser.name.replace(" ", "_")}_${new Date().getMonth()}-${new Date().getFullYear()}_training`
    : `${new Date().getMonth()}-${new Date().getFullYear()}_training`

  useEffect(() => {
    if (isCloneTraining) {
      history.push(Routes.createTraining)
    }
  }, [history, isCloneTraining])

  useEffect(() => {
    const cleanup = () => {
      if (isAthlete()) {
        dispatch(setTrainingIdSelected(null))
        dispatch(setUserIdSelected(null))
        dispatch(setCleanSelectUser())
      }
    };
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isLoading) return <Loading show={isLoading} />

  return (
    <>
      <div className="row">
        <div className="col-xl-9 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              {trainings?.length === 0 ? (
                <AlertSinLink
                  variant={"info"}
                  Icon={InfoIcon}
                  msg={lang.alerts.info}
                  text={withoutTRainings}
                />
              ) : (
                <div className="card plan-list">
                  <Tab.Container defaultActiveKey="Unifinshed">
                    <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                      <div className="mr-auto pr-3 mb-3">
                        <div
                          className="d-flex justify-content-start align-items-center flex-row flex-wrap"
                          style={{ gap: '1rem' }}
                        >
                          <h4 className="text-black fs-20 text-capitalize">
                            {lang.generic.training}{" "}
                            {selectUser &&
                              `de ${selectUser.name}`}{" "}
                          </h4>
                          {isAfterJuly2024() && (
                            toggleActivateViewTrainingSimpleMode
                              ? <>
                                <Button
                                  as="a"
                                  variant="outline-primary"
                                  className="btn-xs"
                                  style={{ marginTop: '-0.5rem' }}
                                  onClick={() => {
                                    dispatch(setToggleActivateViewTrainingSimpleMode())
                                  }}
                                >
                                  <i className={`fa fa-eye mr-2`} />
                                  {lang.buttons.normalView}
                                </Button>
                                <DownloadTableExcel
                                  filename={fileNameExcel}
                                  sheet="datos"
                                  currentTableRef={tableRef.current}
                                >
                                  <button
                                    className="btn 
                                   btn-outline-success btn-xs"
                                    style={{ marginTop: '-0.5rem' }}
                                  >
                                    {lang.buttons.downloadExcel}
                                  </button>
                                </DownloadTableExcel>
                              </>
                              : <Button
                                as="a"
                                variant="outline-secondary"
                                className="btn-xs"
                                style={{ marginTop: '-0.5rem' }}
                                onClick={() => {
                                  dispatch(setToggleActivateViewTrainingSimpleMode())
                                }}                              >
                                <i className={`fa fa-table mr-2`} />
                                {lang.buttons.simpleView}
                              </Button>
                          )

                          }


                        </div>
                        <p className="fs-13 mb-0 text-black">
                          {lang.workoutPlan.description}
                        </p>
                      </div>
                      <div className="card-action card-tabs mr-4 mt-3 mt-sm-0 mb-3">
                        <Nav as="ul" className="nav nav-tabs" role="tablist">
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              as="a"
                              eventKey="Unifinshed"
                              className="nav-link cursor-pointer"
                              data-toggle="tab"
                              to="#Unifinshed"
                              role="tab"
                              aria-selected="true"
                            >
                              {lang.generic.pendings}
                              <span className="smallTextWorkout">
                                {" "}
                                ({trainingPending?.length})
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item as="li" className="nav-item">
                            <Nav.Link
                              as="a"
                              eventKey="Finished"
                              className="nav-link cursor-pointer"
                              data-toggle="tab"
                              to="#Finished"
                              role="tab"
                            >
                              {lang.generic.finisheds}
                              <span className="smallTextWorkout">
                                {" "}
                                ({trainingFinished?.length})
                              </span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </div>

                    <Tab.Content className="card-body tab-content pt-2 scroll_test_responsive scrollbar-color thin">
                      <Tab.Pane
                        eventKey="Unifinshed"
                        className="tab-pane fade"
                        id="Unifinshed"

                      >
                        {trainingPending?.length === 0 ? (
                          <AlertSinLink
                            variant={"info"}
                            Icon={InfoIcon}
                            msg={lang.alerts.info}
                            text={lang.alerts.withoutTrainingPending}
                          />
                        ) : (
                          trainingPending?.map((training) => (
                            toggleActivateViewTrainingSimpleMode
                              ? <TrainingSimpleMode
                                key={training._id}
                                tableRef={tableRef}
                                training={training}
                              />
                              : <TrainingList
                                key={training._id}
                                training={training}
                                isComplete={false}
                              />
                          ))
                        )}
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="Finished"
                        className="tab-pane fade"
                        id="Finished"
                      >
                        {trainingFinished?.length === 0 ? (
                          <AlertSinLink
                            variant={"info"}
                            Icon={InfoIcon}
                            msg={lang.alerts.info}
                            text={lang.alerts.withoutTrainingFinished}
                          />
                        ) : (
                          trainingFinished?.map((training) => (
                            toggleActivateViewTrainingSimpleMode
                              ? <TrainingSimpleMode
                                key={training._id}
                                tableRef={tableRef}
                                training={training}
                              />
                              : <TrainingList
                                key={training._id}
                                training={training}
                                isComplete={true}
                              />

                          ))
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 calendar_small">
          <div className="row">
            <div className="col-xl-12">
              <div className="card flex-xl-column flex-md-row flex-column">
                <div className="card-body border-bottom pb-4 p-2 event-calender">
                  <CalendarWorkout
                    events={trainings}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { TrainingParameters } from "../assets/data/sports";

export const getParametersTraining = (sports) => {
  let uniqueParameters = [];
  let otherParameter = null;

  sports?.forEach((sport) => {
    const parameters = TrainingParameters[sport];

    parameters?.forEach((parameter) => {
      if (parameter === "other") {
        otherParameter = parameter;
      } else if (!uniqueParameters.includes(parameter)) {
        uniqueParameters.push(parameter);
      }
    });
  });

  // Añadir "other" al final, si existe
  if (otherParameter) {
    uniqueParameters.push(otherParameter);
  }

  return uniqueParameters;
};

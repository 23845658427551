import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const notificationsAPI = createApi({
    reducerPath: 'notificationsAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["notificationsAPI"],
    endpoints: (builder) => ({
        getMyNotifications: builder.query({
            query: () => `/notifications/get-my-notifications?limit=500`, //FIXME: Paginación
            providesTags: ["notificationsAPI"],
            extraOptions: { maxRetries: 1 }
        }),
        deleteNotifications: builder.mutation({
            query: ({ notificationId }) => ({
                url: `/notifications/delete/${notificationId}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ notificationId }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    notificationsAPI.util.updateQueryData('getMyNotifications', undefined, (oldData) => {
                        const updatedData = oldData?.notificationsList?.filter((notification) => notification._id !== notificationId);
                        return {
                            ...oldData,
                            notificationsList: updatedData,
                            notificationsCount: oldData?.notificationsCount && oldData.notificationsCount - 1
                        };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const { useGetMyNotificationsQuery, useDeleteNotificationsMutation } = notificationsAPI;
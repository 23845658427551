import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Avatar, IconSport } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { filterIdsAndReturnStatusSuscriber } from "../../helpers/filterIDs";
import { useCreateSubscription, useGetMySubscriptions } from "../../hooks";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import { ValidateSports } from "../../assets/data/sports";


export const ListSearchProfessional = (props) => {
  const history = useHistory();
  const { handreSubcriberAndRedirect } = useCreateSubscription()
  const { modalIconSearch } = useSlicesState()
  const {dispatch, setOtherUserId, setModalIconSearch} = useSlicesActions()

  const {
    subscriptions
  } = useGetMySubscriptions()

  const { name, yearsOfExperience, sports, imgProfile, _id } = props;


  const { lang } = useContext(LanguageContext);

  const widthAndHeight = "20px";

  const handleOtherProfile = () => {
    dispatch(setOtherUserId(_id));
    modalIconSearch && dispatch(setModalIconSearch(false));
    history.push(Routes.searchDetails);
  };


  return (
    <>
      <li>
        <div className="timeline-panel">
          <Link
            className="media mr-2"
            to="#"
            onClick={() => handleOtherProfile()}
          >
            <Avatar
              name={name ? name : ''}
              img={imgProfile}
              size="50px"
            />
          </Link>
          <div className="media-body ">
            <Link to="#" onClick={() => handleOtherProfile()}>
              <h6 className="mb-1 text-capitalize">
                {name}
                {/* <small> @{username}</small> */}
              </h6>
            </Link>
            <small className="d-block" onClick={() => handleOtherProfile()}>
              {yearsOfExperience}{" "}
              {yearsOfExperience > 1
                ? lang.profile.yearsOfExperience
                : lang.profile.oneYearOfExperience}
            </small>

            {sports?.length > 0 &&
              sports?.map((sport, index) => (
                <small key={index} className="text-muted">
                  {"  "}
                  {sport === ValidateSports.Run && IconSport(ValidateSports.Run, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.TrailRunner && IconSport(ValidateSports.TrailRunner, "35px", "35px")}
                  {sport === ValidateSports.Bike && IconSport(ValidateSports.Bike, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Swimm && IconSport(ValidateSports.Swimm, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Strong && IconSport(ValidateSports.Strong, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Funcional && IconSport(ValidateSports.Funcional, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Yoga && IconSport(ValidateSports.Yoga, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Stretching && IconSport(ValidateSports.Stretching, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Crossfit && IconSport(ValidateSports.Crossfit, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Calistenia && IconSport(ValidateSports.Calistenia, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Hiit && IconSport(ValidateSports.Hiit, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Tabata && IconSport(ValidateSports.Tabata, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.AMRAP && IconSport(ValidateSports.AMRAP, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.EMOM && IconSport(ValidateSports.EMOM, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.OTM && IconSport(ValidateSports.OTM, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Contortion && IconSport(ValidateSports.Contortion, widthAndHeight, widthAndHeight)}
                  {sport === ValidateSports.Free && IconSport(ValidateSports.Free, widthAndHeight, widthAndHeight)}
                </small>
              ))}

            {/* {gym && <small className="text-muted"> <GymLogo widthProp={widthAndHeight} heightProp={widthAndHeight} color="#cc232b" /> </small>}
            {team && <small className="text-muted"> <TeamLogo widthProp={widthAndHeight} heightProp={widthAndHeight} color="#ff8700" /> </small>} */}
          </div>
          <ul>
            <li>
              <Link
                to="#"
                className={`btn ${filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                  ? "btn-outline-warning"
                  : "btn-outline-success"
                  } btn-xxs   `}
                onClick={() =>
                  filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                    ? history.push(Routes.subscriptions)
                    : handreSubcriberAndRedirect(props._id)
                }
              >
                {filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                  ? lang.buttons.administrated
                  : lang.buttons.subscribe}
              </Link>
            </li>
            <li>
              <Link
                to={Routes.searchDetails}
                className="btn btn-outline-info btn-xxs mt-2"
                onClick={() => handleOtherProfile()}
              >
                {lang.buttons.viewProfile}
              </Link>
            </li>
          </ul>
        </div>
      </li>
    </>
  );
};

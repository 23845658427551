import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Routes } from "./Routes";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { Nav } from "../layouts/nav";
import { usePath } from "../hooks/usePath";
import { Loading } from "../components/Loading/Loading";
import { esVersionMayor } from '../helpers/checkVersionApp'
import { Maintenance } from '../views/Maintenance'
import { Update } from '../views/Update';

/// Css
import "../css/pages/index.css";
import "../css/pages/chart.css";
import "../css/pages/step.css";
import "../css/calendar.css";
import { useGetAppConfigQuery } from "../api";
import { useGetConfigApp, useUtmValues } from "../hooks";
import { useSlicesActions, useSlicesState } from "../redux/slices";
import { checkingAuthAndToken } from "../redux/thunks/authThunks";
import {
  BigCalendar,
  CreateTraining,
  Dashboard,
  ForgotPassword,
  Login,
  MyExercises,
  MyMeasureDetails,
  MySubscriptions,
  OtherUserMeasureDetails,
  PreSignup,
  Profile,
  RPE,
  ResetPassword,
  SearchDetails,
  SignUp,
  UserCard,
  WorkoutPlan
} from "../views/.";
import PWAInstallComponent from "../components/pwa-install/pwa-install";

export const AppRouter = () => {

  const { utmTestValue } = useUtmValues();
  const { uid, checkAuthAndToken, openMenuSidebar } = useSlicesState()
  const { dispatch } = useSlicesActions()

  const routesPublic = [
    { url: Routes.preSignup, component: PreSignup, private: false },
    { url: Routes.signup, component: SignUp, private: false },
    { url: Routes.login, component: Login, private: false },
    { url: Routes.forgotPassword, component: ForgotPassword, private: false },
    { url: Routes.resetPassword, component: ResetPassword, private: false },
  ];

  const routesPrivate = [
    { url: Routes.dashboard, component: Dashboard, private: true },
    { url: Routes.profile, component: Profile, private: true },
    { url: Routes.myMeasureDetails, component: MyMeasureDetails, private: true },
    { url: Routes.measureDetailsOtherUser, component: OtherUserMeasureDetails, private: true },
    { url: Routes.searchDetails, component: SearchDetails, private: true },
    { url: Routes.subscriptions, component: MySubscriptions, private: true },
    { url: Routes.workoutPlan, component: WorkoutPlan, private: true },
    { url: Routes.rpe, component: RPE, private: true },
    { url: Routes.createTraining, component: CreateTraining, private: true },
    { url: Routes.myExercises, component: MyExercises, private: true },
    { url: Routes.bigCalendar, component: BigCalendar, private: true },

    { url: Routes.profileSuscriptor, component: SearchDetails, private: true },
    { url: Routes.suscriptorMeasureDetails, component: MyMeasureDetails, private: true },

    { url: Routes.viewWorkoutPlanSuscriptor, component: WorkoutPlan, private: true },

    { url: Routes.userCard, component: UserCard, private: true },
  ];

  const { path } = usePath(2);

  let pagePath = false;

  const { isLoading, isFetching } = useGetAppConfigQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  })

  const { newVersionAppClient, inMaintenance, update } = useGetConfigApp()

  const actualVersionApp = "8.7.0"; //?: Cambiar siempre (igual a la ultima del core)

  useEffect(() => {
    dispatch(checkingAuthAndToken());
  }, [dispatch]);

  if (checkAuthAndToken) {
    return <Loading show={checkAuthAndToken} />;
  }

  //si mantenimiento desde backend
  if (inMaintenance && (utmTestValue === undefined || utmTestValue !== "true")) {
    return <Maintenance />;
  }


  //Actualizacion
  if (esVersionMayor(newVersionAppClient, actualVersionApp) && update) {
    return <Update actualVersion={actualVersionApp} newVersion={newVersionAppClient} />
  }


  return (
    <Suspense fallback={<Loading show={true} />}>
      <Loading show={isLoading || isFetching} />
      <Router>
        <PWAInstallComponent />
        <div
          id={`${!pagePath ? "main-wrapper" : ""}`}
          className={`${!pagePath ? "show" : "mh100vh"} ${!pagePath ? "right-profile" : ""
            } ${openMenuSidebar && "menu-toggle"}`}
        >
          {path === "app" && <Nav />}

          <div className={`${!pagePath && uid ? "content-body" : ""}`}>
            <div
              className={`${!pagePath && uid ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {routesPublic.map((route, i) => (
                  <PublicRoute
                    key={i}
                    exact
                    path={route.url}
                    component={route.component}
                    isAuthenticated={!!uid}
                  />
                ))}

                {routesPrivate.map((route, i) => (
                  <PrivateRoute
                    key={i}
                    exact
                    path={route.url}
                    component={route.component}
                    isAuthenticated={!!uid}
                  />
                ))}

                {!!uid ? (
                  <Redirect to={Routes.login} />
                ) : (
                  <Redirect to={Routes.dashboard} />
                )}
              </Switch>
            </div>
          </div>
          {/*  {!pagePath && <Footer />} */}
        </div>
      </Router>
    </Suspense>
  );
};

import React, { useContext, useEffect } from "react";
import {
  ActivityCard,
  AlertConLink,
  AlertSinLink,
  EvolutionWorkouts,
  SliderInfo,
} from "../components";
import {
  DaysWithoutTrainingSVG,
  ExercisesCreatedSVG,
  InfoIcon,
  NewSubscribersSVG,
  SubscriptorsSVG,
  WorkoutsCreatedSVG,
} from "../assets/svg/svgs";
import { LanguageContext } from "../context/LanguageContext";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { useDashboardData, useGetMyProfile } from "../hooks";
import { Loading } from "../components/Loading/Loading";
// import { useSlicesActions } from "../redux/slices";
// import { isMobile } from "react-device-detect";

const dataGyms = [
  {
    _id: "asdd2",
    name: "Gym 1",
    description: "Gym 1",
    img: "",
  },
  {
    _id: "asdd4",
    name: "Gym 2",
    description: "Gym 2",
    img: "",
  },
];

const dataTeams = [
  {
    _id: "asdd8",
    name: "Team 1",
    description: "Team 1",
    img: "",
  },
  {
    _id: "asdd9",
    name: "Team 2",
    description: "Team 2",
    img: "",
  },
  {
    _id: "asdd95",
    name: "Team 3",
    description: "Team 3",
    img: "",
  },
];

export const Dashboard = () => {

  const {
    lastUsers,
    isLoading: isLoadingDataDashboard,
    activeSubscriptions,
    lastSubscriptionsOfTheMonth,
    finishedSubscriptions,
    trainingContainers,
    lastTrainingsOfMonth,
    trainingContainersIncomplete,
    trainingsForActualsMonth,
    trainginsCompleted
  } = useDashboardData()

  // const { dispatch, setOpenMenuSidebarSwitch } = useSlicesActions()


  const { isAthlete } = useGetMyProfile()
  const { lang } = useContext(LanguageContext);

  const history = useHistory();

  const { profileUpdated, isLoading: isLoadingGetMyProfile, dataUserProfile } = useGetMyProfile()

  useEffect(() => {
    if (!isLoadingGetMyProfile && dataUserProfile && !profileUpdated) {
      Swal.fire({
        title: lang.generic.info,
        icon: "info",
        html: lang.profile.completeProfile,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: lang.buttons.perfectComplete,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(Routes.profile);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingGetMyProfile, dataUserProfile, profileUpdated]);

  // useEffect(() => {
  //   if (!isMobile) {
  //     setTimeout(() => {
  //       dispatch(setOpenMenuSidebarSwitch())
  //     }, 1000)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  if (isLoadingGetMyProfile || isLoadingDataDashboard) return <Loading show={isLoadingGetMyProfile || isLoadingDataDashboard} />

  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-12">
        <div className="row">
          {/* Cards */}
          <div className="col-sm-6">
            <ActivityCard
              Icon={SubscriptorsSVG}
              route={Routes.subscriptions}
              color="success"
              title={lang.generic.subscriptions}
              value={activeSubscriptions}
              text={`${activeSubscriptions > 1
                ? lang.generic.persons
                : lang.generic.person
                }`}
            />
          </div>

          {!isAthlete() ? (
            <div className="col-sm-6">
              <ActivityCard
                Icon={NewSubscribersSVG}
                route={Routes.subscriptions}
                color="warning"
                title={lang.dashboard.subscribersThisWeek}
                value={lastSubscriptionsOfTheMonth}
                text={`${lastSubscriptionsOfTheMonth > 1
                  ? lang.generic.news
                  : lang.generic.new
                  }`}
              />
            </div>
          ) : (
            <div className="col-sm-6">
              <ActivityCard
                Icon={WorkoutsCreatedSVG}
                route={Routes.bigCalendar}
                color="secondary"
                title={lang.generic.trainingThisMonth}
                value={lastTrainingsOfMonth}
                text={`${lastTrainingsOfMonth > 1
                  ? lang.generic.trainingPlu
                  : lang.generic.trainingSing
                  }`}
              />
            </div>
          )}

          {!isAthlete() ? (
            <div className="col-sm-6">
              <ActivityCard
                Icon={WorkoutsCreatedSVG}
                route={Routes.subscriptions}
                color="secondary"
                title={lang.generic.training}
                value={trainingContainers}
                text={`${trainingContainers > 1
                  ? lang.generic.createds
                  : lang.generic.created
                  }`}
              />
            </div>
          ) : (
            <div className="col-sm-6">
              <ActivityCard
                Icon={ExercisesCreatedSVG}
                route={Routes.workoutPlan}
                color="danger"
                title={lang.generic.training}
                value={trainginsCompleted}
                text={`${trainginsCompleted > 1
                  ? lang.generic.completes
                  : lang.generic.complete
                  }`}
              />
            </div>
          )}

          {!isAthlete() ? (
            <div className="col-sm-6">
              <ActivityCard
                Icon={ExercisesCreatedSVG}
                route={Routes.subscriptions}
                color="danger"
                title={lang.generic.subscriptions}
                value={finishedSubscriptions}
                text={`${finishedSubscriptions > 1
                  ? lang.generic.finishedPlu
                  : lang.generic.finishedSing
                  }`}
              />
            </div>
          ) : (
            <div className="col-sm-6">
              <ActivityCard
                Icon={DaysWithoutTrainingSVG}
                route={Routes.workoutPlan}
                color="warning"
                title={lang.generic.training}
                value={trainingContainersIncomplete}
                text={`${trainingContainersIncomplete > 1
                  ? lang.generic.incompletes
                  : lang.generic.incomplete
                  }`}
              />
            </div>
          )}
        </div>
      </div>

      {/* gráfico*/}
      <div className="col-xl-6 col-xxl-12">
        <div className="card">
          <div className="card-header d-sm-flex d-block pb-0 border-0">
            <div className="mr-auto pr-3 mb-sm-0 mb-3">
              <h4 className="text-black fs-20">
                {!isAthlete()
                  ? lang.dashboard.workoutsCreatedIn
                  : lang.dashboard.trainingIn}
                {new Date().getFullYear()}
              </h4>
            </div>
          </div>
          <div className="card-body pt-0 pb-0">
            <EvolutionWorkouts data={trainingsForActualsMonth} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-md-12 col-xl-6">
            <div className="card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div className="mr-auto pr-3">
                  <h4 className="text-black font-w600 fs-20">
                    {lang.generic.newUsers}
                  </h4>
                </div>
              </div>

              <div className="card-body pt-2">
                <div className="testimonial-one owl-carousel">
                  {lastUsers?.length > 0 ? ( //validacion cambiar para mostrar gyms y teams
                    <div className="testimonial-one owl-carousel">
                      {/* slide */}
                      <SliderInfo dataNewUsers={lastUsers} />
                    </div>
                  ) : (
                    <AlertSinLink
                      variant={"info"}
                      Icon={InfoIcon}
                      msg={lang.alerts.info}
                      text={lang.dashboard.lastUsers}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* mis gyms / teams */}
          <div className="col-md-12 col-xl-6">
            <div className="card">
              <div className="card-header d-sm-flex d-block pb-0 border-0">
                <div className="mr-auto pr-3">
                  <h4 className="text-black font-w600 fs-20">
                    {lang.dashboard.newTeamsAndGyms} Proximamente
                  </h4>
                </div>
              </div>
              <div className="card-body pt-2">
                {true ? ( //validacion cambiar para mostrar gyms y teams
                  <div className="testimonial-one owl-carousel">
                    <SliderInfo dataGyms={dataGyms} dataTeams={dataTeams} />
                  </div>
                ) : (
                  <AlertConLink
                    variant={"info"}
                    Icon={InfoIcon}
                    msg={lang.alerts.info}
                    text={lang.dashboard.alertNewTeamsAndGyms}
                    textButton={lang.buttons.create}
                    path={"/"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

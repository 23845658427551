import React from 'react'
import { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { IconSport } from '..';

const SportIcon = ({ valueSports, type, width, height }) => (
  valueSports.includes(type) && (
    <small className="text-muted ml-2">
      {IconSport(type, width, height)}
    </small>
  )
);

export const ListIconsSportsCreateEditTraining = ({ valueSports, widthAndHeight }) => {
  const { lang } = useContext(LanguageContext);
  const { typeTraining } = lang;

  return (
    <>
      <SportIcon valueSports={valueSports} type={typeTraining.run} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.trailrunner} width="42px" height="42px" />
      <SportIcon valueSports={valueSports} type={typeTraining.bike} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.swimm} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.funcional} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.strong} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.yoga} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.stretching} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.crossfit} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.calistenia} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.hiit} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.tabata} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.amrap} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.emom} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.otm} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.contortion} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon valueSports={valueSports} type={typeTraining.free} width={widthAndHeight} height={widthAndHeight} />
    </>
  )
}

//SVG
import {
  BikeSVG,
  FuncionalSVG,
  RunSVG,
  StretchingSVG,
  StrongSVG,
  SwimmSVG,
  YogaSVG,
  TrailRunningSVG,
  TabataIcon,
  AmrapIcon,
  HiitSVG,
  CrossfitSVG,
  CalisteniaSVG,
  EmomIcon,
  OtmIcon,
  FreetrainingIcon,
  // FreetrainingIcon,
} from "../svg/svgs";

export const ValidateSports = {
  Run: 'run',
  TrailRunner: 'trail-runner',
  Bike: 'bike',
  Swimm: 'swimm',
  Funcional: 'funcional',
  Strong: 'strong',
  Stretching: 'stretching',
  Yoga: 'yoga',
  Crossfit: 'crossfit',
  Calistenia: 'calistenia',
  Hiit: 'hiit',
  Tabata: 'tabata',
  AMRAP: 'amrap',
  EMOM: 'emom',
  OTM: 'otm',
  Contortion: 'contortion',
  Free: 'free',
};

export const sports = [
  {
    item: "Run",
    name: "Run",
    value: ValidateSports.Run,
    color: "secondary",
    icon: RunSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Trail Runner",
    name: "Trail Runner",
    value: ValidateSports.TrailRunner,
    color: "primary",
    icon: TrailRunningSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Ciclismo",
    name: "Ciclismo",
    value: ValidateSports.Bike,
    color: "danger",
    icon: BikeSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Natacion",
    name: "Natacion",
    value: ValidateSports.Swimm,
    color: "info",
    icon: SwimmSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Funcional",
    name: "Funcional",
    value: ValidateSports.Funcional,
    color: "primary",
    icon: FuncionalSVG,
    role: ["personalTrainer", "yogaStretching"]
  },
  {
    item: "Fuerza",
    name: "Fuerza",
    value: ValidateSports.Strong,
    color: "success",
    icon: StrongSVG,
    role: ["personalTrainer", "yogaStretching"]
  },
  {
    item: "Stretching",
    name: "Stretching",
    value: ValidateSports.Stretching,
    color: "warning",
    icon: StretchingSVG,
    role: ["personalTrainer", "yogaStretching"]
  },

  {
    item: "Yoga",
    name: "Yoga",
    value: ValidateSports.Yoga,
    color: "warning",
    icon: YogaSVG,
    role: ["personalTrainer", "yogaStretching"]
  },

  //
  {
    item: "Crossfit",
    name: "Crossfit",
    value: ValidateSports.Crossfit,
    color: "primary",
    icon: CrossfitSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Calistenia",
    name: "Calistenia",
    value: ValidateSports.Calistenia,
    color: "secondary",
    icon: CalisteniaSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Hiit",
    name: "Hiit",
    value: ValidateSports.Hiit,
    color: "danger",
    icon: HiitSVG,
    role: ["personalTrainer"]
  },
  {
    item: "Tabata",
    name: "Tabata",
    value: ValidateSports.Tabata,
    color: "warning",
    icon: TabataIcon,
    role: ["personalTrainer"]
  },
  {
    item: "AMRAP",
    name: "AMRAP",
    value: ValidateSports.AMRAP,
    color: "success",
    icon: AmrapIcon,
    role: ["personalTrainer"]
  },
  {
    item: "EMOM",
    name: "EMOM",
    value: ValidateSports.EMOM,
    color: "secondary",
    icon: EmomIcon,
    role: ["personalTrainer"]
  },
  {
    item: "OTM",
    name: "OTM",
    value: ValidateSports.OTM,
    color: "danger",
    icon: OtmIcon,
    role: ["personalTrainer"]
  },
  {
    item: "Contorsión",
    name: "Contorsión",
    value: ValidateSports.Contortion,
    color: "danger",
    icon: StretchingSVG,
    role: ["personalTrainer", "yogaStretching"]
  },
  {
    item: "Libre",
    name: "Libre",
    value: ValidateSports.Free,
    color: "info",
    icon: FreetrainingIcon,
    role: ["personalTrainer", "yogaStretching"]
  },
]


export const TrainingParameters = {
  [ValidateSports.Run]: ["time", "km", "weight", "meters", "hearRate", "rhythm", "other"],
  [ValidateSports.TrailRunner]: ["time", "km", "weight", "meters", "hearRate", "rhythm", "other"],
  [ValidateSports.Bike]: ["time", "km", "weight", "meters", "hearRate", "cadence", "power", "rhythm", "other"],
  [ValidateSports.Swimm]: ["time", "km", "weight", "meters", "hearRate", "swimmingStyles", "other"],
  [ValidateSports.Funcional]: ["weight", "meters", "hearRate", "time", "other"],
  [ValidateSports.Strong]: ["weight", "other"],
  [ValidateSports.Stretching]: ["weight", "time", "other"],
  [ValidateSports.Yoga]: ["time", "other"],
  [ValidateSports.Crossfit]: ["weight", "time", "other"],
  [ValidateSports.Calistenia]: ["weight", "time", "other"],
  [ValidateSports.Hiit]: ["weight", "time", "other"],
  [ValidateSports.Tabata]: ["weight", "time", "other"],
  [ValidateSports.AMRAP]: ["weight", "time", "other"],
  [ValidateSports.EMOM]: ["weight", "time", "other"],
  [ValidateSports.OTM]: ["weight", "time", "other"],
  [ValidateSports.Contortion]: ["weight", "time", "other"],
  [ValidateSports.Free]: ["time", "km", "weight", "meters", "hearRate", "cadence", "power", "rhythm", "other"]
};

export const exerciseOptions = [
  { value: "heating", color: "warning" },
  { value: "activeRecovery", color: "secondary" },
  { value: "toTheRuling", color: "danger" }
];

export const otherExerciseOptions = [
  { value: "breakRecovery", color: "info" },
  { value: "isUsingMinutes", color: "info" },
];

export const blockOptionsTraining = [
  { value: "heating", color: "warning" },
  { value: "activeRecovery", color: "secondary" },
  { value: "mobility", color: "info" },
  { value: "stretching", color: "danger" },
  { value: "activation", color: "success" },
];

export const otherBlockOptionsTraining = [
  { value: "breakRecovery", color: "info" },
  { value: "isUsingMinutes", color: "info" },
];



/* Parametros comunes entre todos:
heating
breakRecovery
activeRecovery

series
repetitions

Enfriamiento //?
Intensidad //? Baja Moderada Alta
Nivel de Dificultad //? Principiante Intermedio Avanzado
Equipamiento //!nuevo




*/


/*
Intensidad:
Baja
Moderada
Alta
Nivel de Dificultad:
Principiante
Intermedio
Avanzado
*/
import { useContext, useEffect } from "react";
import { useUpdateBlockInTrainingMutation } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { adaptUpdateBlock } from "../../adapters/exerciseFormat";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { LanguageContext } from "../../context/LanguageContext";


export function useUpdateBlock(blockToEdit, setBlockToEdit) {
  const { lang } = useContext(LanguageContext);
  const { query } = useSlicesState()
  const { dispatch, setBlockIsBeingEditedFalse } = useSlicesActions()

  const [updateBlockInTraining, { isError, error, isLoading }] = useUpdateBlockInTrainingMutation();

  const blockModel = {
    name: blockToEdit?.name,
    description: blockToEdit?.description,
    orderIndex: blockToEdit?.orderIndex,
    optionsTraining: {
      series: blockToEdit?.optionsTraining?.series,
      heating: blockToEdit?.optionsTraining?.heating,
      breakRecovery: blockToEdit?.optionsTraining?.breakRecovery,
      breakInfo: blockToEdit?.optionsTraining?.breakInfo,
      isUsingMinutes: blockToEdit?.optionsTraining?.isUsingMinutes,
      activeRecovery: blockToEdit?.optionsTraining?.activeRecovery,
      mobility: blockToEdit?.optionsTraining?.mobility,
      activation: blockToEdit?.optionsTraining?.activation,
      stretching: blockToEdit?.optionsTraining?.stretching,
      blockIntensity: blockToEdit?.optionsTraining?.blockIntensity,
    },
  }

  const [formUpdateBlock, handleFormUpdateBlock, reset] = useForm({ ...blockModel });
  const {
    name,
    description,
    optionsTraining,
  } = formUpdateBlock;


  const handleUpdateBlockInTraining = async (blockId) => {
    const blockTransform = adaptUpdateBlock(formUpdateBlock)

    await updateBlockInTraining({ blockId, trainingId: blockToEdit.trainingId, data: blockTransform, query }).unwrap()
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
    Swal.close();
    dispatch(setBlockIsBeingEditedFalse())
    setBlockToEdit({})
    reset();
  };

  const isDisabled = JSON.stringify(formUpdateBlock) === JSON.stringify({ ...blockModel })

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [
    error?.data?.msgCode,
    error?.data?.ok,
    isError
  ]);

  useEffect(() => {
    if (isLoading) {
        loadingAlert(lang.alerts.updatingBlock)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isLoading])

  return {
    name,
    description,
    reset,
    optionsTraining,
    formUpdateBlock,
    handleFormUpdateBlock,
    handleUpdateBlockInTraining,
    isDisabled,
    isLoadingUpdateBlockInTraining: isLoading
  };
}


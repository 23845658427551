import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const userProfileAPI = createApi({
    reducerPath: 'userProfileAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["userProfile"],
    endpoints: (builder) => ({
        getMyProfile: builder.query({
            query: () => "/users/get-my-profile",
            providesTags: ["userProfile"],
            keepUnusedDataFor: 3600
        }),
        updateUserProfile: builder.mutation({
            query: (newProfile) => ({
                url: "/user-profile/update",
                method: 'PATCH',
                body: newProfile
            }),
            invalidatesTags: ["userProfile"],
            extraOptions: { maxRetries: 0 }
        }),
        updateImageProfile: builder.mutation({
            query: (imgData) => ({
                url: "/user-profile/update-image",
                method: 'PUT',
                body: imgData
            }),
            invalidatesTags: ["userProfile"],
            extraOptions: { maxRetries: 0 }
        }),
        addChatIdTelegram: builder.mutation({
            query: (telegramChatId) => ({
                url: "/user-profile/add-chat-id-telegram",
                method: 'PUT',
                body: telegramChatId
            }),
            invalidatesTags: ["userProfile"],
            extraOptions: { maxRetries: 0 }
        }),
        deleteChatIdTelegram: builder.mutation({
            query: () => ({
                url: "/user-profile/delete-chat-id-telegram",
                method: 'DELETE',
            }),
            invalidatesTags: ["userProfile"],
            extraOptions: { maxRetries: 0 }
        }),
        deletePhoneWhatsApp: builder.mutation({
            query: () => ({
                url: "/user-profile/delete-phone-whats-app",
                method: 'DELETE',
            }),
            invalidatesTags: ["userProfile"],
            extraOptions: { maxRetries: 0 }
        }),
    }),
});

export const { useGetMyProfileQuery, useUpdateUserProfileMutation, useUpdateImageProfileMutation, useAddChatIdTelegramMutation, useDeleteChatIdTelegramMutation, useDeletePhoneWhatsAppMutation } = userProfileAPI;
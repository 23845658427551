import { useDeleteExerciseInBlockMutation, useDeleteExerciseMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useDeleteExercise(closeModal, removeFromBlock, blockId) {
  const { lang } = useContext(LanguageContext);
  const { dispatch, setBlockIdSelected } = useSlicesActions()
  const [deleteExercise, { isError, error }] = useDeleteExerciseMutation();

  const [deleteExerciseInBlock, { isError: isErrorInBlock, error: errorInBlock }] = useDeleteExerciseInBlockMutation();

  const { isEditingExerciseInTraining, trainingIdSelected, query, blockIdSelected } = useSlicesState()

  const handleRemoveExercise = (exerciseId) => {

    if (!isEditingExerciseInTraining) {
      deleteExercise(exerciseId)
    }

    dispatch(setBlockIdSelected(null))

  }

  const handleRemoveExerciseInBlock = (exerciseId) => {
    deleteExerciseInBlock({ idExercise: exerciseId, idTraining: trainingIdSelected, idBlock: blockId ? blockId : blockIdSelected, query })
    dispatch(setBlockIdSelected(null))
  }

  const handleDeleteExercise = (exerciseId) => {
    Swal.fire({
      title: lang.generic.areYouSure,
      icon: "warning",
      text: lang.myExercises.textDeleted,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.myExercises.textConfirmButton,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        removeFromBlock || blockIdSelected
          ? handleRemoveExerciseInBlock(exerciseId)
          : handleRemoveExercise(exerciseId)
      }
    });
    closeModal && closeModal()
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorInBlock) showAlert(errorInBlock?.data?.msgCode, errorInBlock?.data?.ok);
  }, [
    error?.data?.msgCode,
    error?.data?.ok,
    errorInBlock?.data?.msgCode,
    errorInBlock?.data?.ok,
    isError,
    isErrorInBlock,
  ]);


  return {
    handleDeleteExercise
  };
}


import { createSlice } from '@reduxjs/toolkit'


export const exerciseSlice = createSlice({
    name: 'exercise',
    initialState: {
        exerciseSelected: null,
    },
    reducers: {
        setExerciseSelected: (state, action) => {
            state.exerciseSelected = action.payload
        },
        setCleanExerciseSelected: (state) => {
            state.exerciseSelected = null
        },
        setInitialStateExercise: (state) => {
            Object.assign(state, exerciseSlice.getInitialState())
        },
    },
})

export const {
    exerciseSelected,
    setExerciseSelected,
    setCleanExerciseSelected,
    setInitialStateExercise,
} = exerciseSlice.actions
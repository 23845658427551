import { createSlice } from '@reduxjs/toolkit'


export const modalsSlice = createSlice({
    name: 'modals',
    initialState: {
        modalFinishTrainingRPE: null,
        modalFinishTrainingFeedback: null,
        modalViewExercise: null,
        modalViewNotifications: null,
        modalViewVideo: null,
        modalCreateExercise: null,
        modalListMyExercises: null,
        modalIconSearch: null,
        modalViewEquipment: null,
    },
    reducers: {
        setModalFinishTrainingRPE: (state, action) => {
            state.modalFinishTrainingRPE = action.payload
        },
        setModalViewEquipment: (state, action) => {
            state.modalViewEquipment = action.payload
        },

        setModalFinishTrainingFeedback: (state, action) => {
            state.modalFinishTrainingFeedback = action.payload
        },
        setModalViewExercise: (state, action) => {
            state.modalViewExercise = action.payload
        },
        setModalViewNotifications: (state, action) => {
            state.modalViewNotifications = action.payload
        },
        setModalViewVideo: (state, action) => {
            state.modalViewVideo = action.payload
        },
        setModalCreateExercise: (state, action) => {
            state.modalCreateExercise = action.payload
        },
        setModalListMyExercises: (state, action) => {
            state.modalListMyExercises = action.payload
        },
        setModalIconSearch: (state, action) => {
            state.modalIconSearch = action.payload
        },
        setInitialStateModals: (state) => {
            Object.assign(state, modalsSlice.getInitialState())
        }
    },
})

export const {
    setModalFinishTrainingRPE,
    setModalFinishTrainingFeedback,
    setModalViewExercise,
    setInitialStateModals,
    setModalViewNotifications,
    setModalViewVideo,
    setModalCreateExercise,
    setModalListMyExercises,
    setModalIconSearch,
    setModalViewEquipment,
} = modalsSlice.actions
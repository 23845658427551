import { useSelector } from "react-redux";
import { analyticsAPI, exerciseAPI, feedbackTrainingAPI, getAppConfigAPI, notificationsAPI, physiologicalDataAPI, subscriptionsAPI, trainingAPI, userAPI, userCardsAPI, userProfileAPI } from "../api";
import { useSlicesState } from "../redux/slices/hook";

export const useStateRedux = () => {

    const { trainingIdSelected, otherUserId } = useSlicesState();

    // const dataProfileT = useSelector((state) => state.userProfileAPI.queries['getMyProfile(undefined)']);
    const { data: dataUserProfile} = useSelector(userProfileAPI.endpoints.getMyProfile.select()); //.select(xxx.id)
    const { data: dataGetAppConfig} = useSelector(getAppConfigAPI.endpoints.getAppConfig.select());
    const { data: dataGetPhysiologicalData} = useSelector(physiologicalDataAPI.endpoints.getMyPhysiologicalData.select());
    const { data: dataGetPhysiologicalOtherUser} = useSelector(physiologicalDataAPI.endpoints.getOneData.select(otherUserId));
    const { data: dataUseGetOneProfile} = useSelector(userAPI.endpoints.getOneProfile.select(otherUserId));
    const { data: dataDashboardData} = useSelector(analyticsAPI.endpoints.getDashboardData.select());
    const { data: dataGetNumberOfWorkoutsOtherUser} = useSelector(trainingAPI.endpoints.getNumberOfWorkoutsOtherUser.select(otherUserId));
    const { data: dataGetMySubscriptions} = useSelector(subscriptionsAPI.endpoints.getMySubscriptions.select());
    const { data: dataGetDataForOneUser} = useSelector(userCardsAPI.endpoints.getDataForOneUser.select(otherUserId?._id));
    const { data: dataGetMyExercise} = useSelector(exerciseAPI.endpoints.getMyExercises.select());
    const { data: dataFeedBackTraining } = useSelector(feedbackTrainingAPI.endpoints.getFeedBackForTraining.select({trainingId: trainingIdSelected}));
    const { data: dataNotifications } = useSelector(notificationsAPI.endpoints.getMyNotifications.select())
  

    return {
        dataUserProfile,
        dataGetAppConfig,
        dataGetPhysiologicalData,
        dataUseGetOneProfile,
        dataDashboardData,
        dataGetNumberOfWorkoutsOtherUser,
        dataGetMySubscriptions,
        dataGetPhysiologicalOtherUser,
        dataGetDataForOneUser,
        dataGetMyExercise,
        dataFeedBackTraining,
        dataNotifications
    };
};



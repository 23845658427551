import React, { useContext, useEffect, useRef } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useFunctionCalendar } from "../hooks/useFunctionCalendar";
import { LanguageContext } from "../context/LanguageContext";
import { useSlicesActions, useSlicesState } from "../redux/slices/hook";
import html2PDF from "jspdf-html2canvas";
import { isTrainigSeriesInProgress, isTrainingBlockInProgress } from "../helpers/checkStatusTraining";
import { isBlockTraining } from "../assets/data/typesOfTrainings";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { TrainingSimpleMode } from "../components";
import { isAfterJuly2024 } from "../helpers/formatDate";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Routes } from "../routers/Routes";
// import "./EventCalendar.css"; // Importa tu CSS personalizado

// const BlockAndExerciseOptions = ({ options, isBlock, lang, children }) => {
//   const optionKeys = [
//     { key: 'heating', label: lang.generic.heating, className: 'text-warning' },
//     { key: 'activeRecovery', label: lang.generic.activeRecovery, className: 'text-info' },
//     { key: 'weight', label: `${lang.generic.weight}: ${options?.weightInfo}` },
//     { key: 'km', label: `${lang.generic.distance}: ${options?.kmInfo}` },
//     { key: 'time', label: `${lang.generic.time}: ${options?.timeInfo}` },
//     { key: 'hearRate', label: `${lang.generic.hearRateTwo}: ${options?.hearRateInfo} lpm` },
//     { key: 'cadence', label: `${lang.generic.cadence}: ${options?.cadenceInfo} cad` },
//     { key: 'swimmingStyles', label: `${lang.generic.swimmingStyles}: ${options?.swimmingStylesInfo}` },
//     { key: 'meters', label: `${lang.generic.meters}: ${options?.metersInfo}` },
//     { key: 'power', label: `${lang.generic.power}: ${options?.powerInfo}` },
//     { key: 'other', label: `${lang.generic.other}: ${options?.otherInfo}` },
//     !isBlock && options?.series > 1 && { key: 'series', label: `${lang.generic.series}: ${options?.series}`, className: 'text-primary' },
//     { key: 'breakRecovery', label: `${lang.generic.breakRecovery}: ${options?.breakInfo} ${options?.isUsingMinutes ? '"' : "'"}` },

//     { key: 'rhythm', label: `${lang.generic.rhythm}: ${options?.rhythmInfo}` },
//     { key: 'toTheRuling', label: `${lang.generic.toTheRuling}` },

//     { key: 'mobility', label: `${lang.generic.mobility}` },
//     { key: 'activation', label: `${lang.generic.activation}` },
//     { key: 'stretching', label: `${lang.generic.stretching}` },
//     { key: 'blockIntensity', label: `${lang.generic.intensity}: ${getBlockIntensity(options?.blockIntensity)}` },
//   ];

//   return (
//     <ul className="text-black text-left mb-2">
//       {optionKeys.map(({ key, label, className }) =>
//         options[key] ? <li key={label} className={className}>{label}</li> : null
//       )}
//       {children}
//     </ul>
//   );
// };

const EventCalendar = (event, lang) => {
  const { title, extendedProps } = event;

  const retsultIsTrainingInProgress = isTrainingBlockInProgress(extendedProps);
  const { blockCompleted } = retsultIsTrainingInProgress;
  return (
    <>
      <div>
        {
          isBlockTraining(extendedProps?.typesOfTraining) ?
            (
              <p className={`
                text-center text-white
                ${extendedProps?.isComplete && "bg-success"}
                ${isBlockTraining(extendedProps?.typesOfTraining) && blockCompleted > 0 && "bg-secondary"}
                ${isBlockTraining(extendedProps?.typesOfTraining) && blockCompleted === 0 && "bg-warning"}
                text-wrap rounded-lg mb-0
                `}
                style={{ fontSize: '0.8rem' }}
              >{title}</p>
            )
            :
            (
              <p
                className={`
           text-center text-white 
           ${extendedProps?.isComplete ? "bg-success" :
                    !extendedProps?.isComplete && (extendedProps?.exercisesId?.some(e => e.isComplete) || isTrainigSeriesInProgress(extendedProps)) ? "bg-secondary" : "bg-warning"}
           text-wrap rounded-lg mb-0
         `}
                style={{ fontSize: '0.8rem' }}
              >
                {title}
              </p>
            )
        }
      </div>
      {
        isBlockTraining(extendedProps?.typesOfTraining) ?
          (
            extendedProps?.blocksId?.map((block) => (
              <div key={block?._id} >
                <p className="text-left text-primary mb-0" style={{ fontSize: '0.8rem' }}>
                  {block?.name} {block?.optionsTraining?.series > 0 && `(x ${block?.optionsTraining?.series})`}
                </p>
                <div className="ml-2">
                  {
                    block?.exercisesId?.map((exercise, index) => (
                      <Exercise key={exercise?._id} exercise={exercise} lang={lang} index={index} />
                    ))
                  }
                </div>

              </div>
            ))

          )
          :
          (
            extendedProps?.exercisesId?.map((exercise, index) => (
              <Exercise key={exercise?._id} exercise={exercise} index={index} lang={lang} />
            ))
          )
      }
    </>
  );
};

const Exercise = ({ exercise, index, lang }) => {
  return (
    <div>
      <p className="text-left text-info mb-0" style={{ fontSize: '0.8rem' }}>
        #{index + 1}{" "}
        {exercise?.name}
        {exercise?.options?.repetitions > 0 && ` (x${exercise?.options?.repetitions})`}
        {exercise?.options?.series > 1 && ` (x${exercise?.options?.series})`}
      </p>
    </div>
  )
}

export const BigCalendar = () => {
  const history = useHistory();
  const { lang } = useContext(LanguageContext);
  const { trainings, toggleActivateViewTrainingSimpleMode, selectUser, isCloneTraining } = useSlicesState();
  const { dispatch, setCleanSelectUser, setToggleActivateViewTrainingSimpleMode } = useSlicesActions();

  const calendarBigRef = useRef();
  const cardBody = useRef();
  const tableRef = useRef();

  const { customButtons } = useFunctionCalendar(calendarBigRef);

  const dataMapper = trainings?.map((trainin) => ({
    ...trainin,
    start: trainin.startDate,
  }));

  const dataConColor = dataMapper?.forEach((d) => {
    d["color"] = "#fff";
  });

  const handleDownloadPDF = () => {
    const chunks = document.querySelectorAll('.fc-toolbar-chunk');
    if (chunks.length >= 3) {
      chunks[2].style.display = 'none';
    }
    html2PDF(cardBody.current, {
      jsPDF: {
        format: 'a2',
        orientation: "h",
      },
      imageType: 'image/jpeg',
      output: `./pdf/generate.pdf`
    }).then(() => {
      chunks[2].style.display = '';
    });
  };

  const fileNameExcel = selectUser
    ? `${selectUser.name.replace(" ", "_")}_${new Date().getMonth()}-${new Date().getFullYear()}_training`
    : `${new Date().getMonth()}-${new Date().getFullYear()}_training`;

    useEffect(() => {
      if (isCloneTraining) {
        history.push(Routes.createTraining)
      }
    }, [history, isCloneTraining])

  useEffect(() => {
    const cleanup = () => {
      dispatch(setCleanSelectUser());
    };
    return () => {
      cleanup();
    };
  }, [dispatch, setCleanSelectUser]);

  return (
    <>
      <Row color={12}>
        <div className="d-flex justify-content-start align-items-center" style={{ gap: '1rem' }}>
          <Button
            as="a"
            variant="success"
            className="btn-card btn-xs ml-4"
            onClick={handleDownloadPDF}
          >
            {lang.bigCalendar.downloadCalendar}
            <i className="fa fa-download ml-2" />
          </Button>
          {
            isAfterJuly2024() && trainings?.length > 0 && (
              toggleActivateViewTrainingSimpleMode ? (
                <>
                  <Button
                    as="a"
                    variant="outline-primary"
                    className="btn-xs"
                    onClick={() => {
                      dispatch(setToggleActivateViewTrainingSimpleMode());
                    }}
                  >
                    <i className="fa fa-eye mr-2" />
                    {lang.buttons.normalView}
                  </Button>
                  <DownloadTableExcel
                    filename={fileNameExcel}
                    sheet="datos"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-outline-success btn-xs">
                      {lang.buttons.downloadExcel}
                    </button>
                  </DownloadTableExcel>
                </>
              ) : (
                <Button
                  as="a"
                  variant="outline-secondary"
                  className="btn-xs"
                  onClick={() => {
                    dispatch(setToggleActivateViewTrainingSimpleMode());
                  }}
                >
                  <i className="fa fa-table mr-2" />
                  {lang.buttons.simpleView}
                </Button>
              )
            )

          }
        </div>
      </Row>
      <Row className="mt-2" color={12}>
        <Col lg={12}>
          <Card>
            <Card.Body ref={cardBody}>
              {toggleActivateViewTrainingSimpleMode ? (
                trainings?.map((training) => (
                  <TrainingSimpleMode key={training._id} tableRef={tableRef} training={training} />
                ))
              ) : (
                <div className="demo-app-calendar calendar_big" id="mycalendartest">
                  <FullCalendar
                    ref={calendarBigRef}
                    defaultView="dayGridMonth"
                    customButtons={customButtons}
                    eventDisplay="block"
                    height={"auto"}
                    headerToolbar={{
                      start: 'title',
                      center: '',
                      end: 'today prev,next'
                    }}
                    rerenderDelay={10}
                    locale={esLocale}
                    eventDurationEditable={false}
                    editable={false}
                    droppable={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={dataMapper}
                    eventContent={(ev) => EventCalendar(ev.event, lang)}
                    themeSystem={"standard"}
                    eventBackgroundColor={dataConColor}
                    showNonCurrentDates={true}
                    firstDay={1}
                    fixedWeekCount={false}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
};

import { createSlice } from '@reduxjs/toolkit'


export const userProfileSlice = createSlice({
    name: 'userprofile',
    initialState: {
        trainingParameters: [],
    },
    reducers: {
        setTrainingParameters: (state, action) => {
            state.trainingParameters = action.payload
        },
        setInitialStateUserProfile: (state) => {
            Object.assign(state, userProfileSlice.getInitialState())
        },
    },
})

export const {
    setTrainingParameters,
    setInitialStateUserProfile
} = userProfileSlice.actions
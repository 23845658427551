import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const subscriptionsAPI = createApi({
    reducerPath: 'subscriptionsAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["subscriptionsAPI"],
    endpoints: (builder) => ({
        getMySubscriptions: builder.query({
            query: () => `/subscription/get-my-subscriptions?limit=300&offset=0`, //FIXME: Paginación
            providesTags: ["subscriptionsAPI"],
        }),
        createSubscription: builder.mutation({
            query: (userId) => ({
                url: `/subscription/create/${userId}`,
                method: 'POST',
            }),
            invalidatesTags: ["subscriptionsAPI"],
            extraOptions: { maxRetries: 0 }
        }),
        activeReactivatedSubscription: builder.mutation({
            query: (subscriptionsData) => ({
                url: "/subscription/activate",
                method: 'PUT',
                body: subscriptionsData
            }),
            // invalidatesTags: ["subscriptionsAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async (subscriptionsData, { dispatch, queryFulfilled }) => {
                const { status, listIdSubscriptions } = subscriptionsData;
                const patchResult = dispatch(
                    subscriptionsAPI.util.updateQueryData('getMySubscriptions', undefined, (oldData) => {
                        const updatedData = Array.from(oldData?.subscriptions).map((subscription) => {
                            if (listIdSubscriptions?.includes(subscription._id)) {
                                return { ...subscription, status }
                            } else {
                                return subscription
                            }
                        }
                        );
                        return { ...oldData, subscriptions: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        finishedSubscription: builder.mutation({
            query: (subscriptionsData) => ({
                url: "/subscription/finished",
                method: 'PUT',
                body: subscriptionsData
            }),
            // invalidatesTags: ["subscriptionsAPI"],
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async (subscriptionsData, { dispatch, queryFulfilled }) => {
                const { status, listIdSubscriptions } = subscriptionsData;
                const patchResult = dispatch(
                    subscriptionsAPI.util.updateQueryData('getMySubscriptions', undefined, (oldData) => {
                        const updatedData = Array.from(oldData?.subscriptions).map((subscription) => {
                            if (listIdSubscriptions?.includes(subscription._id)) {
                                return { ...subscription, status }
                            } else {
                                return subscription
                            }
                        }
                        );
                        return { ...oldData, subscriptions: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),

    }),
});

export const { useGetMySubscriptionsQuery, useCreateSubscriptionMutation, useActiveReactivatedSubscriptionMutation, useFinishedSubscriptionMutation } = subscriptionsAPI;
import React, { useContext, useRef } from "react";
import Calendar from "@fullcalendar/react";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useFunctionCalendar } from "../../hooks/useFunctionCalendar";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routers/Routes";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import { useGetMyProfile } from "../../hooks";
import { useSlicesActions } from "../../redux/slices/hook";
import { isTrainigSeriesInProgress, isTrainingBlockInProgress } from "../../helpers/checkStatusTraining";
import { isBlockTraining } from "../../assets/data/typesOfTrainings";

export const CalendarWorkout = ({ events: trainings }) => {
  const { lang } = useContext(LanguageContext);
  const { isAthlete } = useGetMyProfile()
  const { dispatch, setDateTempTraining, setModalViewExercise, setTrainingIdSelected, setEreateTrainingFromCalendarTrue } = useSlicesActions()

  const dataMapper = trainings?.map((trainin) => ({
    ...trainin,
    start: trainin.startDate,
  }));


  const history = useHistory();

  //Modifico color eventos
  const dataConColor = dataMapper?.forEach((training, i) => {
    //color entrenamiento completados
    if (training.isComplete) {
      training["color"] = "#2BC155";
    }

    //color entrenamiento en progreso
    if (
      !training.isComplete &&
      training.exercisesId?.map((exercise) => exercise.isComplete).includes(true)
    ) {
      training["color"] = "#A02CFA";
    }

    //color entrenamiento pendientes
    const checkExercise = (e) => e === true;
    if (
      !training.isComplete &&
      training.exercisesId?.map((exercise) => !exercise.isComplete).every(checkExercise)
    ) {
      training["color"] = "#FFBC11";
    }

    if (
      isTrainigSeriesInProgress(training)
    ) {
      training["color"] = "#A02CFA";
    }

    const retsultIsTrainingInProgress = isTrainingBlockInProgress(training);
    const { blockCompleted } = retsultIsTrainingInProgress;

    if ( //En progreso con bloques
      isBlockTraining(training.typesOfTraining) && blockCompleted > 0 && !training.isComplete
    ) {
      training["color"] = "#A02CFA";
    }

    if ( //pendientes con bloques
      isBlockTraining(training.typesOfTraining) && blockCompleted === 0) {
      training["color"] = "#FFBC11";
    }

  });

  const handlerCreateTraining = (date) => {
    if (!isAthlete()) {
      dispatch(setEreateTrainingFromCalendarTrue())
      dispatch(setDateTempTraining(new Date(date)));
      history.push(Routes.createTraining);
    }
  };

  const veiwBigCalendar = () => {
    history.push(Routes.bigCalendar);
  };

  const calendarRef = useRef();

  //custom hook botones calendario
  const { customButtons } = useFunctionCalendar(calendarRef);


  return (
    <>
      <Calendar
        ref={calendarRef}
        defaultView="dayGridMonth"
        customButtons={customButtons}
        eventDisplay="block"
        height={"auto"}
        headerToolbar={{
          start: 'title',
          center: '',
          end: 'today prev,next'
        }}
        rerenderDelay={10}
        locale={esLocale}
        eventDurationEditable={false}
        editable={false}
        droppable={false}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        events={dataMapper}
        eventClick={(ev) => dispatch(setModalViewExercise(ev.event.extendedProps._id), dispatch(setTrainingIdSelected(ev.event.extendedProps._id)))}
        dateClick={(info) => handlerCreateTraining(info.date)}
        themeSystem={"standard"}
        eventBackgroundColor={dataConColor}
        showNonCurrentDates={false}
      // fixedWeekCount={false}
      // weekNumbers={false}
      // weekends={false}

      />
      <Button
        onClick={() => veiwBigCalendar()}
        variant="secondary"
        className="btn-block"
      >
        {lang.bigCalendar.viewBigCalendar}
      </Button>
    </>
  );
};

import { useEffect, useState } from "react";
import { useTrainingsRoot } from "./training/useTrainingsRoot";
import { useSlicesActions } from "../redux/slices/hook";

export const useFunctionCalendar = (calendarRef) => {
  const { dispatch, setTrainingIdSelected } = useSlicesActions()
  const [customButtons, setCustomButtons] = useState({});
  const {
    increaseMonthn,
    subtractMonth,
    actualMonth,
  } = useTrainingsRoot()



  useEffect(() => {
    let calendarApi = calendarRef?.current?.getApi();

    const nextMonth = () => {
      dispatch(setTrainingIdSelected(null))
      calendarApi.next();
      increaseMonthn();
    };

    const prevMonth = () => {
      dispatch(setTrainingIdSelected(null))
      calendarApi.prev();
      subtractMonth();
    };

    const currentMonth = () => {
      dispatch(setTrainingIdSelected(null))
      calendarApi.today();
      actualMonth();
    };

    let optionCustomButtons = {
      today: {
        text: "Hoy",
        click: () => currentMonth(),
      },
      next: {
        click: () => nextMonth(),
      },
      prev: {
        click: () => prevMonth(),
      },
    };

    setCustomButtons(optionCustomButtons);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { customButtons };
};

import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useSlicesState } from "../../redux/slices";

export const useCloneTraining = () => {
    const [newTraining, setNewTraining] = useState({})
    const [blocksToClone, setBlocksToClone] = useState([])
    const [exercisesToClone, setExercisesToClone] = useState([])
    const { isCloneTraining, trainingToClone } = useSlicesState()

    useEffect(() => {
        if (isCloneTraining) {

            const { _id, idPersonalTrainer, idAthlete, itsFree, active, isComplete, typesOfTraining, blocksId, exercisesId, ...restTraining } = trainingToClone;
            const newTraining = {
                ...restTraining,
            }
            setNewTraining(newTraining)

            const newBlocks = blocksId.map(block => {
                const { _id, trainingId, idPersonalTrainer, isComplete, optionsTraining, exercisesId, ...restBlock } = block;
                return {
                    ...restBlock,
                    idTemporalClonar: _id,
                    optionsTraining: {
                        ...optionsTraining,
                    }
                }
            });

            setBlocksToClone(newBlocks);
            const newExercises = blocksId.flatMap(block => {
                const { exercisesId } = block;
                return exercisesId.map(exercise => {
                    const { _id, trainingId, idPersonalTrainer, blockId, isComplete, options, optionsEquipment, exercisesId, ...restExercise } = exercise;
                    const newIdTemporalExercise = uuidv4();
                    return {
                        ...restExercise,
                        idTemporalClonar: newIdTemporalExercise,
                        blockIdTemp: blockId,
                        options: {
                            ...options,
                        },
                        optionsEquipment: {
                            withDumbbells: false,
                            withKettlebell: false,
                            withBank: false,
                            withDisk: false,
                            withRack: false,
                            withResistanceBand: false,
                            withChinUpBar: false,
                            withJumpBox: false,
                            withWall: false,
                            withJumpRope: false,
                            withRoller: false,
                            withBar: false,
                            withAbdominalWheel: false,
                            withOther: false
                        }
                    };
                });
            });
            setExercisesToClone(newExercises);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCloneTraining, trainingToClone])

    return {
        newTraining,
        blocksToClone,
        exercisesToClone
    }
}
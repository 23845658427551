import React, { useContext } from "react";
import ReactPlayer from "react-player";
import { Badge, Button, Modal } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import { InfoIcon } from "../../assets/svg/svgs";
import { AlertSinLink } from "../alerts/AlertSinLink";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import { isGifUrl, isVideoUrl } from "../../helpers/checkTypeMedia";

function formatGoogleDriveUrl(url) {
  const match = url?.match(/drive.google.com\/file\/d\/([^/]+)/);
  if (match) {
    return 'https://drive.google.com/uc?export=view&id=' + match[1];
  }
  return url;
}

export const ModalVideo = ({
  video,
  nombreEjercicio,
  idModal,
}) => {
  const { lang } = useContext(LanguageContext);
  const { modalViewVideo } = useSlicesState()
  const { dispatch, setModalViewVideo } = useSlicesActions()
  const videoUrl = formatGoogleDriveUrl(video);

  const closeModalVideo = () => {
    dispatch(setModalViewVideo(null))
  }

  return (
    <>
      <Modal
        className="fade bd-example-modal-md"
        show={modalViewVideo === idModal}
        size="md"
        backdropClassName="modalVideo"
      >
        <Modal.Header>
          <Modal.Title className="col-11 text-capitalize">
            {nombreEjercicio}
          </Modal.Title>

          <Button
            variant=""
            className="close"
            onClick={() => closeModalVideo()}
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        {
          isVideoUrl(video) &&
          <Modal.Body className="modal_video">
            <ReactPlayer
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
              playing={true}
              muted={true}
              url={videoUrl}
            />
          </Modal.Body>
        }

        { //si no es video es gif?
          (!isVideoUrl(video) && !isGifUrl(video)) &&
          <Modal.Body className="h-100">
            <AlertSinLink
              variant={"secondary"}
              Icon={InfoIcon}
              msg={lang.alerts.info}
              text={lang.viewExercise.alertGif}
            />
            <Badge
              variant="secondary"
              className="badge-md light ml-1"
              as="a"
              href={video}
              target="_blank"
            >
              {lang.buttons.viewVideo}
            </Badge>
          </Modal.Body>
        }

        { //si no es video es gif?
          (!isVideoUrl(video) && isGifUrl(video)) &&
          <Modal.Body className="h-100">
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={video}
                alt="Gif del Entrenamiento"
                loading="lazy"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'cover'
                }}
              />
            </div>
          </Modal.Body>
        }

        <Modal.Footer>
          {
            video.includes('drive.google') &&
            <>
              <h6 className="text-sm-left text-xs text-info">
                {lang.modalVideo.descriptionErrorVideo}
                <Badge
                  variant="info"
                  className="badge-xs light ml-1 text-capitalize"
                  as="a"
                  href={videoUrl}
                  target="_blank"
                >
                  {lang.buttons.viewVideo}
                </Badge>
              </h6>
            </>
          }
          <Button
            variant="danger light"
            onClick={() => closeModalVideo()}
            className="btn-md"
          >
            {lang.buttons.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import React from 'react'
import { ExerciseCard } from "../cards/ExerciseCard";
import { ViewExerciseSimpleMode } from "../cards/ViewExerciseSimpleMode";

export const ExerciseList = ({
    exercises,
    training,
    handleUpdateExercise,
    isAthlete,
    dispatch,
    setModalViewVideo,
    handleTerminateExerciseInBlock,
    userEquipment,
    lang,
    filterOptionsEquipment,
    block,
    trainingIsSimpleMode
}) => {

    return (
        <div className={`${!trainingIsSimpleMode && "row p-0"}`}>
            {!trainingIsSimpleMode && exercises?.map((exercise, index) => (
                <div
                    className="col-12 col-md-12 col-xl-4"
                    key={exercise._id}
                >
                    <ExerciseCard
                        exercise={exercise}
                        index={index}
                        training={training}
                        handleUpdateExercise={handleUpdateExercise}
                        isAthlete={isAthlete}
                        dispatch={dispatch}
                        setModalViewVideo={setModalViewVideo}
                        handleTerminateExerciseInBlock={handleTerminateExerciseInBlock}
                        userEquipment={userEquipment}
                        lang={lang}
                        filterOptionsEquipment={filterOptionsEquipment}
                        block={block}
                        trainingIsSimpleMode={trainingIsSimpleMode}
                    />
                </div>
            ))}

            {trainingIsSimpleMode &&
                <ViewExerciseSimpleMode
                    exercises={exercises}
                    training={training}
                    handleUpdateExercise={handleUpdateExercise}
                    isAthlete={isAthlete}
                    setModalViewVideo={setModalViewVideo}
                    handleTerminateExerciseInBlock={handleTerminateExerciseInBlock}
                    lang={lang}
                    block={block}
                    trainingIsSimpleMode={trainingIsSimpleMode}
                />
            }
        </div>
    );
};
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Modal } from "react-bootstrap";
import { ListNotifications, } from "..";
import { BellSVG, InfoIcon } from "../../assets/svg/svgs";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { AlertSinLink } from "../alerts/AlertSinLink";
import { useGetNotifications } from "../../hooks";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import "react-perfect-scrollbar/dist/css/styles.css";

export const ModalNotifications = () => {
  const { lang } = useContext(LanguageContext);
  const { thereAreNewNotifications, modalViewNotifications } = useSlicesState()
  const { dispatch, setModalViewNotifications, setThereAreNewNotificationsFalse} = useSlicesActions()
  const { notificationsCount, notificationsList } = useGetNotifications()

  // const handleTest = () => {
  //   dispatch(getNotifications());
  // };

  const closeModal = () => {
    dispatch(setModalViewNotifications(false))
    dispatch(setThereAreNewNotificationsFalse())
  }

  return (
    <>
      <div as="li" className="nav-item dropdown notification_dropdown ml-3">
        <div
          variant=""
          className="nav-link ai-icon i-false"
          href="#"
          role="button"
          data-toggle="dropdown"
          onClick={() => dispatch(setModalViewNotifications(true))}
        >
          <BellSVG />
          <div
            className={` ${thereAreNewNotifications && "pulse-css" } `}
          />{" "}
        </div>

        <Modal
          className="fade"
          show={modalViewNotifications}
          backdropClassName="modalNotifications"
          onHide={() => closeModal()}
        >
          <Modal.Header>
            <Modal.Title>{lang.notifications.title}</Modal.Title>
            <Button
              onClick={() => closeModal()}
              variant=""
              className="close"
            >
              <span>x</span>
            </Button>
          </Modal.Header>
          <Modal.Body>
            <PerfectScrollbar className="widget-media dz-scroll p-3 ps custom-scrollbar thin">
              {
                notificationsCount === 0
                  ? (
                    <AlertSinLink
                      variant={"secondary"}
                      Icon={InfoIcon}
                      msg={lang.alerts.info}
                      text={lang.notifications.alert}
                    />
                  ) :
                  (
                    <ul className="timeline">
                      {notificationsList?.map((notification) => (
                        <ListNotifications key={notification._id} notification={notification} />
                      ))}
                    </ul>
                  )
              }
            </PerfectScrollbar>
          </Modal.Body>

          {/* <MoreResultSearch fun={() => handleTest()} /> */}

          <Modal.Footer>
            <Button
              onClick={() => closeModal()}
              variant="danger light"
              className="btn-xs"
            >
              {lang.buttons.close}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

import { roles } from "../../domain/roles";
import { sports as sportList } from "../../assets/data/sports";
import { exportSportsAccordingRole } from "../../helpers/exportSportsAccordingRole";
import { useGetOneProfile } from "./useGetOneProfile";
import { useHistory } from "react-router-dom";
import { usePath } from "../usePath";
import { Routes } from "../../routers/Routes";
import { useSlicesState } from "../../redux/slices";

export const useSearchUserDetails = () => {
    const history = useHistory();
    const { path } = usePath(1);

    const { otherUserId } = useSlicesState()

    const { otherUserProfile, fullProfile, sports } = useGetOneProfile()    
  
    // eslint-disable-next-line array-callback-return
    const deportesParaPersonalTrainer = exportSportsAccordingRole(roles.personalTrainer, sportList).filter((sport) => {
        for (const sp of sports) {
            if (sp === sport.value) return sport;
        }
    });

    // eslint-disable-next-line array-callback-return
    const deportesParaStretching = exportSportsAccordingRole(roles.yogaStretching, sportList).filter((sport) => {
        for (const sp of sports) {
            if (sp === sport.value) return sport;
        }
    });
   
  
    const skipFetching = !otherUserId
 

    if (path === "search-details" && skipFetching) {
      history.push(Routes.dashboard)
    }
  
    return {
        otherUserProfile,
        fullProfile,
        sports,
        deportesParaPersonalTrainer,
        deportesParaStretching,
        skipFetching,
        idUserSearch: otherUserId
    };
};



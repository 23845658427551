import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { Card } from "react-bootstrap";
import { rpeData } from "../assets/data/rpe";
import { RpeMessage } from "../components";


export const RPE = () => {
  const { lang } = useContext(LanguageContext);


  return (
    <>
      <Card className="col-12 col-xl-8 m-auto">
        <Card.Header>
          <Card.Title>
            {lang.RPE.title}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <p>{lang.RPE.description}</p>

          {
            rpeData.map((rpe, index) => (
              <RpeMessage
                key={index}
                number={rpe.number}
                title={rpe.title}
                description={rpe?.description}
                color={rpe.color}
              />
            ))
          }
        </Card.Body>
      </Card>
    </>
  );
};

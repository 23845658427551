import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext';

export const CheckBoxParameters = (options, param, onChange, color, text, isVisible) => {
  const { lang } = useContext(LanguageContext);

  if (!isVisible) return null;

  return (
    <div key={param} className="col-md-12">
      <div className={`custom-control custom-checkbox mb-3 checkbox-${color || "secondary"} check-md`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={param}
          name={param}
          value={options[param]}
          checked={options[param]}
          onChange={onChange}
        />
        <label className="custom-control-label" htmlFor={param}>
          {lang.generic[param]} {text}
        </label>
      </div>
    </div>
  )
}


export const CheckBoxParametersForDinamicForm = ({ options, param, onChange, color, index, text, isVisible }) => {
  const { lang } = useContext(LanguageContext);

  if (!isVisible) return null;

  return (
    <div key={param} className="col-md-12">
      <div className={`custom-control custom-checkbox checkbox-${color || "secondary"} check-xs`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={`${param}${index}`}
          name={param}
          value={options[param]}
          checked={options[param]}
          onChange={(e) => onChange(e, index)}
        />
        <label className="custom-control-label" htmlFor={`${param}${index}`} style={{ fontSize: '0.8rem' }}>
          {lang.generic[param]} {text}
        </label>
      </div>
    </div>
  )
}


export const CheckBoxParametersForDinamicFormForExercises = ({ options, param, onChange, color, index, text, exerciseId, isVisible }) => {
  const { lang } = useContext(LanguageContext);

  if (!isVisible) return null;

  return (
    <div key={param} className="col-md-12">
      <div className={`custom-control custom-checkbox checkbox-${color || "secondary"} check-xs`}>
        <input
          type="checkbox"
          className="custom-control-input"
          id={`${param}${index}${exerciseId}`}
          name={param}
          value={options[param]}
          checked={options[param]}
          onChange={(e) => onChange(e, exerciseId)}
        />
        <label className="custom-control-label" htmlFor={`${param}${index}${exerciseId}`} style={{ fontSize: '0.8rem' }}>
          {lang.generic[param]} {text}
        </label>
      </div>
    </div>
  )
}
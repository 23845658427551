import React, { useContext, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { LanguageContext } from "../../context/LanguageContext";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import { useSubscriptions } from "../../hooks";
import { AlertSinLink } from "../alerts/AlertSinLink";
import { InfoIcon } from "../../assets/svg/svgs";
import { ProfileStatusOptions } from "./ProfileStatusOptions";
import { statusSubscription } from "../../domain/statusSubscription";
import { Button } from "react-bootstrap";
import { EquipmentView } from "../equipamentView/EquipmentView";
import { getTextsEquipment } from "../../assets/data/validateEquipment";
import { roles } from "../../domain/roles";

export const Subscribers = ({ data, filterStatus, setDataFiltered, dataFiltered }) => {

    const { lang } = useContext(LanguageContext);
    const { uid } = useSlicesState()
    const { dispatch, setUsersSelected } = useSlicesActions()
    const {
        alertEnd,
        alertReactivate,
        handleViewProfile,
        handleUserCard,
        handleCreateTraining,
        handleViewTraining,
        isAthlete,
        handleActivateReactivatedSusbcriptions
    } = useSubscriptions()

    useEffect(() => {
        const dataFiltered =
            filterStatus === "all" ? data : data?.filter((sus) => sus.status === filterStatus);
        setDataFiltered(dataFiltered);
        dispatch(setUsersSelected(dataFiltered));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch, filterStatus, setUsersSelected]);

    if (data?.length < 1) return (
        <AlertSinLink
            variant={"secondary"}
            Icon={InfoIcon}
            msg={lang.alerts.info}
            text={lang.alerts.noResults}
        />
    )

    return (
        <div className="px-4" style={{ minHeight: '300px' }}>
            {
                dataFiltered?.map((subscription) => (
                    <div
                        key={subscription?._id} 
                        className={`d-flex justify-content-between align-items-center flex-row flex-wrap border-dark border-bottom py-2`} style={{
                        gap: `${isMobile && "0.5rem"}`,
                        justifyContent: `${isMobile && "start"}`
                    }}>

                        <ProfileStatusOptions
                            subscription={subscription}
                            handleViewProfile={handleViewProfile}
                            isAthlete={isAthlete}
                            handleUserCard={handleUserCard}
                            handleCreateTraining={handleCreateTraining}
                            alertEnd={alertEnd}
                            alertReactivate={alertReactivate}
                            handleActivateReactivatedSusbcriptions={handleActivateReactivatedSusbcriptions}
                            handleViewTraining={handleViewTraining}
                        />

                        {subscription.status === statusSubscription.active ||
                            subscription.status === statusSubscription.reactivated ? (
                            <Button
                                variant="outline-info"
                                onClick={() =>
                                    handleViewTraining(
                                        uid === subscription.idInviting._id
                                            ? subscription.guestId
                                            : subscription.idInviting
                                    )
                                }
                            >
                                {lang.generic.training}
                            </Button>
                        ) : (
                            <Button variant="outline-dark" disabled>
                                {lang.generic.training}
                            </Button>
                        )}

                        {
                            !isAthlete() && (
                                subscription.status === statusSubscription.active ||
                                    subscription.status === statusSubscription.reactivated ? (
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() =>
                                            handleCreateTraining(
                                                uid === subscription.idInviting._id
                                                    ? subscription.guestId
                                                    : subscription.idInviting
                                            )
                                        }
                                    >
                                        {lang.buttons.createWorkouts}
                                    </Button>
                                ) : (
                                    <Button variant="outline-dark" disabled>
                                        {lang.buttons.createWorkouts}
                                    </Button>
                                )
                            )
                        }

                        {!isAthlete() && (
                            <>
                                {
                                    subscription?.idInviting?.role?.includes(roles.athlete) &&
                                    <EquipmentView
                                        equipmentData={getTextsEquipment(subscription?.idInviting?.equipment)}
                                        userId={subscription?.idInviting?._id}
                                        athleteName={subscription?.idInviting?.name}
                                        suscriptionStatus={subscription?.status}
                                    />
                                }
                                {
                                    subscription?.guestId?.role?.includes(roles.athlete) &&
                                    <EquipmentView
                                        equipmentData={getTextsEquipment(subscription?.guestId?.equipment)}
                                        userId={subscription?.guestId?._id}
                                        athleteName={subscription?.guestId?.name}
                                        suscriptionStatus={subscription?.status}
                                    />
                                }
                            </>
                        )}

                        {!isAthlete() && (
                            subscription.status === statusSubscription.active ||
                                subscription.status === statusSubscription.reactivated ? (
                                <Button
                                    variant="outline-info"
                                    onClick={() => handleUserCard(subscription)}
                                >
                                    {lang.mySubscriptions.file}
                                </Button>
                            ) : (
                                <Button variant="outline-dark" disabled>
                                    {lang.mySubscriptions.file}
                                </Button>
                            )
                        )

                        }

                    </div>
                ))
            }
        </div>
    )
}
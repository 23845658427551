import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const sportAssociationAPI = createApi({
    reducerPath: 'sportAssociationAPI',
    baseQuery: getBaseQueryConfig(),
    endpoints: (builder) => ({
        createSportAssociation: builder.mutation({
            query: (sportAssociationData) => ({
                url: "/sport-association/create",
                method: 'POST',
                body: sportAssociationData
            }),
            extraOptions: { maxRetries: 0 }
        }),
    }),
});

export const { useCreateSportAssociationMutation } = sportAssociationAPI;
import { useEffect } from "react";
import { useReqUserDataMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesState } from "../../redux/slices";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routers/Routes";

export function useReqPhysiologicalData() {
  const { otherUserId } = useSlicesState()
  const [reqPhysiologicalData, { isError, isSuccess, error, data }] = useReqUserDataMutation();
  const history = useHistory();
  

  const handleReqPhysiologicalData = async () => {
    await reqPhysiologicalData(otherUserId).unwrap()
    setTimeout(() => {
      history.push(Routes.subscriptions);
    }, 2000);
  };
  
  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);
    return {
      handleReqPhysiologicalData
    };
  }


import { useEffect } from "react";
import { useLazyGetFeedBackForTrainingQuery } from "../../api";
import { useSlicesState } from "../../redux/slices/hook";
import { useStateRedux } from "../useStateAPI";
import { showAlert } from "../../helpers/Alerts";

export const useGetFeedBackTraining = () => {

  const { trainingIdSelected, modalFinishTrainingFeedback } = useSlicesState()

  const [trigger, { data, error, isError, isFetching, isLoading: isLoadingGetFeedBackTraining }] = useLazyGetFeedBackForTrainingQuery({
    pollingInterval: false,
    refetchOnReconnect: false,
    refetchOnFocus: true,
    cacheTime: 0,
    disable: modalFinishTrainingFeedback === null,
  });

  const { dataFeedBackTraining } = useStateRedux()

  const messages = dataFeedBackTraining?.messages
  const isLoading = isFetching || isLoadingGetFeedBackTraining


  useEffect(() => {
    if (trainingIdSelected) trigger({ trainingId: trainingIdSelected })
  }, [trainingIdSelected, trigger])

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);

  return {
    data,
    error,
    messages,
    isLoading
  };
};



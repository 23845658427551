import React from "react";

export const Options = ({ options }) => {

  const sortedOptions = Object.values(options).sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );

  return (
    <>
      {sortedOptions.map(({ label, value }, i) => (
        <option className="text-capitalize" key={i} value={value}>
          {label}
        </option>
      ))}
    </>
  );
};

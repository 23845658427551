import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

export const AgeRange = ({ ageRange, setAgeRange }) => {
  const onSkipSlide = (value) => {
    setAgeRange(parseInt(value[0]));
  };

  return (
    <>
      <div className="slider" id="SnappingTOValues">
        <Nouislider
          start={[1]}
          range={{
            min: 1,
            max: 55,
          }}
          onSlide={onSkipSlide}
        />
        {ageRange ? <div> {parseInt(ageRange)}</div> : <div>1</div>}
      </div>
    </>
  );
};

export const DynamicForm = ({ options, dynamicFormValues, handleDynamicFormChange }) => {

    return (
        <form>
            {options?.map((option, index) => (
                <div key={index}>
                    {option.type === 'checkbox' && (
                        <div key={index} className="d-flex flex-row justify-content-between align-items-center rounded p-2 mt-1" style={{ border: "1px solid #d5d5d5" }}>
                            <p className="m-0 p-0">{option.name}</p>
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`customSwitch-${option.value}`}
                                    checked={dynamicFormValues && dynamicFormValues[option.value]}
                                    onChange={(e) => handleDynamicFormChange({ target: { name: option.value, type: 'checkbox', checked: e.target.checked } })} />
                                <label className="custom-control-label" htmlFor={`customSwitch-${option.value}`}></label>
                            </div>
                        </div>
                    )}

                    {option.type === 'number' &&
                        <div key={index} className="d-flex flex-row justify-content-between align-items-center rounded p-2" style={{ border: "1px solid #d5d5d5" }}>
                            <p className="m-0 p-0" style={{width: '50%'}}>{option.name} en Kg.</p>
                            <input
                                type="number"
                                style={{ border: '0px', fontSize: '1.1rem', width: '50%' }}
                                name={option.item}
                                id={option.item}
                                placeholder="2.5"
                                step="0.1"
                                min="0" max="500"
                                value={dynamicFormValues && dynamicFormValues[option.value]}
                                onChange={(e) => handleDynamicFormChange({ target: { name: option.value, type: 'number', value: e.target.value } })}
                            />

                        </div>
                    }

                    {option.type === 'string' &&
                        <div className="form-group border-color-textarea mt-1">
                            <textarea
                                className="form-control "
                                rows="4"
                                id={option.item}
                                name={option.item}
                                placeholder={'Detalles...'}
                                value={dynamicFormValues && dynamicFormValues[option.value]}
                                onChange={(e) => handleDynamicFormChange({ target: { name: option.value, type: 'string', value: e.target.value } })}></textarea>
                        </div>
                    }
                </div>
            ))}
        </form>
    );
};

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { Telegram } from "../tabs/Telegram";
import { WhatsApp } from "../tabs/WhatsApp";



export const CardConfigNotifications = () => {
  const { lang } = useContext(LanguageContext);
  const [activeToggle, setActiveToggle] = useState("whatsapp");
  return (
    <>
      <div>
        <div className="card">
          <div className="card-body">
            <h4 className="text-primary">
              {lang.notifications.title}
            </h4>
            <p className="fontSize9Rem">{lang.profile.notifications.turnOnNotifications}</p>
            <div className="profile-tab">
              <div className="custom-tab-1">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    onClick={() => setActiveToggle("whatsapp")}
                  >
                    <Link
                      to="#whats-app"
                      data-toggle="tab"
                      className={`text-capitalize nav-link ${activeToggle === "whatsapp" ? "active show" : ""
                        }`}
                    >
                      {lang.generic.whatsApp}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#telegram"
                      data-toggle="tab"
                      onClick={() => setActiveToggle("telegram")}
                      className={`text-capitalize nav-link ${activeToggle === "telegram" ? "active show" : ""
                        }`}
                    >
                      {lang.generic.telegram}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">

                  <WhatsApp activeToggle={activeToggle} />

                  <Telegram activeToggle={activeToggle} />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
import React, { useContext } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ListIconsSports, ModalFinishTraining, ModalSelectRPE, ModalViewTraining, ModalViewTrainingBlock } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { isBlockTraining, isSchduledTraining, isSeriesTraining } from "../../assets/data/typesOfTrainings";
import { useGetMyProfile, useTerminateTraining } from "../../hooks";
import { useSlicesActions } from "../../redux/slices/hook";
import "dayjs/locale/es";
import { isTrainingBlockInProgress } from "../../helpers/checkStatusTraining";
dayjs.locale('es');

export const TrainingList = ({ isComplete, training }) => {

  const { isAthlete } = useGetMyProfile()
  const { dispatch, setTrainingIdSelected, setModalFinishTrainingFeedback, setModalViewExercise, setUserIdSelected } = useSlicesActions()
  const { _id, startDate, title, exercisesId, sports, typesOfTraining, optionsSeriesTraining } = training;
  const { handleScheduledTraining, handleSeriesTraining } = useTerminateTraining(training)

  const series = optionsSeriesTraining?.series
  const seriesCompleted = optionsSeriesTraining?.seriesCompleted

  const { lang } = useContext(LanguageContext);

  const checkExercise = (e) => e === true;

  const handleViewFeedback = (id) => {
    dispatch(setModalFinishTrainingFeedback(id));
  };

  const retsultIsTrainingInProgress = isTrainingBlockInProgress(training);
  const { blockCompleted, blockPending, totalBlocks } = retsultIsTrainingInProgress;

  return (
    <div
      className="d-flex border-bottom flex-wrap pt-3 list-row align-items-center mb-2"
      key={_id}
    >
      <div className="col-xl-5 col-xxl-8 col-lg-6 col-sm-8 d-flex align-items-center">
        <div
          className={`list-icon ${isComplete ? "bg-light" : "bgl-primary "
            } mr-3 mb-3`}
        >
          <p
            className={`fs-24 ${isComplete ? "text-black" : "text-primary"
              } mb-0 mt-2`}
          >
            {dayjs(startDate).format("DD")}
          </p>
          <span
            className={`fs-14 ${isComplete ? "text-black" : "text-primary"}`}
          >
            {dayjs(startDate).format("ddd")}
          </span>
        </div>
        <div className="info mb-3" onClick={() => dispatch(setModalViewExercise(_id))}>
          <h4 className="fs-20 ">
            <Link to="#" className="text-black">
              {title}
            </Link>
          </h4>

          {isComplete && (
            <span className="text-success font-w600">
              {lang.mySubscriptions.status.finished}
            </span>
          )}

          {(!isComplete && isSchduledTraining(typesOfTraining)) && //pendiente sin series
            exercisesId?.map((e) => !e.isComplete).every(checkExercise) && (
              <span className="text-warning font-w600">
                {lang.generic.pending}
              </span>
            )}

          {(!isComplete && isSchduledTraining(typesOfTraining)) && //en progreso sin series
            exercisesId?.map((e) => e.isComplete).includes(true) && (
              <span className="text-secondary font-w600">
                {lang.generic.inProgress}
              </span>
            )}


          {(isSeriesTraining(typesOfTraining) && seriesCompleted === 0) && //pendiente con series
            (
              <span className="text-warning font-w600">
                {lang.generic.pending + " " + seriesCompleted + lang.viewExercise.roundsOf + series}
              </span>
            )}

          {(isBlockTraining(typesOfTraining) && blockCompleted === 0) && //pendiente con bloques
            (
              <span className="text-warning font-w600">
                {lang.generic.pending + " " + blockCompleted + lang.blocks.blocksOf + blockPending}
              </span>
            )}


          {(isSeriesTraining(typesOfTraining) && seriesCompleted > 0 && !isComplete) && ( //en progreso con series
            <span className="text-secondary font-w600">
              {lang.generic.inProgress} {seriesCompleted > 0 ? seriesCompleted : seriesCompleted} {lang.viewExercise.roundsOf} {series}
            </span>
          )}

          {(isBlockTraining(typesOfTraining) && blockCompleted > 0 && !isComplete) && ( //en progreso con bloques
            <span className="text-secondary font-w600">
              {lang.generic.inProgress} {blockCompleted > 0 && blockCompleted} {lang.blocks.blocksOf} {totalBlocks}
            </span>
          )}

        </div>
      </div>
      <div className="col-xl-2 col-xxl-4 col-lg-2 col-sm-4 activities mb-3 mr-auto pl-3 pr-3 text-sm-center">
        <ListIconsSports trainingType={sports} className="mr-1" widthAndHeight="24px" />

        {/* <span className="text-warning ml-2">Running</span> */}
      </div>
      <div className="col-xl-5 col-xxl-12 col-lg-4 col-sm-12 d-flex align-items-center">
        <Button
          /* to="/workoutplan" */
          className="btn mb-3 play-button rounded mr-3 border-0"
          onClick={() => {
            dispatch(setTrainingIdSelected(_id))
            dispatch(setModalViewExercise(_id))
          }}
        >
          <i className="las la-caret-right scale-2 mr-3" />
          {/* {isBlockTraining(training.typesOfTraining) ? lang.buttons.viewBlocks : lang.buttons.viewExercise} */}
          {lang.buttons.viewTraining}
        </Button>

        {
          isBlockTraining(training.typesOfTraining)
            ? (
              <ModalViewTrainingBlock
                training={training}
                idModal={_id}
                countTraining={seriesCompleted}
              />
            )
            : (
              <ModalViewTraining //TODO: eliminar a futuro cuando ya no existan entrenamientos que no sean en bloques
                training={training}
                idModal={_id}
                countTraining={seriesCompleted}
              />
            )
        }



        {(isComplete && !isSeriesTraining(typesOfTraining)) && (
          <Button
            /* to="/workoutplan" */
            as="a"
            className="btn mb-3 play-button rounded mr-3 ml-1 border-0"
            onClick={() => {
              dispatch(setTrainingIdSelected(_id))
              if (isAthlete()) dispatch(setUserIdSelected(training?.idPersonalTrainer))
              handleViewFeedback(_id)
            }}
          >
            <i className="las la-comment scale-2 mr-3" />
            {lang.buttons.viewFeedBack}
          </Button>
        )}

        {(isSeriesTraining(typesOfTraining) && (seriesCompleted > 0 || seriesCompleted > 0)) && (
          <Button
            /* to="/workoutplan" */
            as="a"
            className="btn mb-3 play-button rounded mr-3 ml-1 border-0"
            onClick={() => {
              dispatch(setTrainingIdSelected(_id))
              if (isAthlete()) dispatch(setUserIdSelected(training?.idPersonalTrainer))
              handleViewFeedback(_id)
            }}
          >
            <i className="las la-comment scale-2 mr-3" />
            {lang.buttons.viewFeedBack}
          </Button>
        )}

        {(isAthlete() && !isComplete && isSchduledTraining(typesOfTraining)) && (
          <Button
            as="a"
            className={`btn mb-3 rounded mr-3 ml-1 border-0 btn-info ${(!exercisesId?.map((exercise) => exercise.isComplete).every(checkExercise)) &&
              "disabled"
              }`}
            onClick={() => {
              dispatch(setTrainingIdSelected(_id))
              if (isAthlete()) dispatch(setUserIdSelected(training?.idPersonalTrainer))
              handleScheduledTraining(_id, training?.idPersonalTrainer?._id)
            }}
          >
            <i className="las la-check scale-2 mr-3" />
            {lang.buttons.endUp}
          </Button>
        )}

        {(isAthlete() && !isComplete && isSeriesTraining(typesOfTraining)) && (
          <Button
            as="a"
            className={`btn mb-3 rounded mr-3 ml-1 border-0 btn-info`}
            onClick={() => {
              dispatch(setTrainingIdSelected(_id))
              if (isAthlete()) dispatch(setUserIdSelected(training?.idPersonalTrainer))
              handleSeriesTraining(_id, training?.idPersonalTrainer?._id)
            }}
          >
            <i className="las la-check scale-2 mr-3" />
            {isComplete
              ? lang.buttons.viewFeedBack
              : `Completar Serie ${seriesCompleted > 0 ? seriesCompleted + 1 : seriesCompleted + 1} de ${series}`
            }
          </Button>
        )}

        <ModalSelectRPE idModal={_id} />
        <ModalFinishTraining idModal={_id} />
      </div>
    </div>
  );
};



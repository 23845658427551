import { createSlice } from '@reduxjs/toolkit'
import { getSessionStorageValue, setSessionStorageValue } from '../../helpers/sessionStorage';

const isToggleActivateViewTrainingSimpleMode = getSessionStorageValue('isToggleActivateViewTrainingSimpleMode', false);
const isToggleCreateTrainingSimpleMode = getSessionStorageValue('isToggleCreateTrainingSimpleMode', false);

export const trainingSlice = createSlice({
    name: 'training',
    initialState: {
        trainings: [],
        selectUser: null,
        userSelected: null,
        importedExercises: [],
        dateTempTraining: null,
        exercisesSelected: [],
        isEditingExerciseInTraining: false,
        isCreateExerciseInTraining: false,
        query: null,
        trainingIdSelected: null,
        isCloneTraining: false,
        trainingToClone: null,
        createTrainingFromCalendar: false,
        toggleActivateViewTrainingSimpleMode: isToggleActivateViewTrainingSimpleMode,
        toggleCreateTrainingSimpleMode: isToggleCreateTrainingSimpleMode,
    },
    reducers: {
        setTrainings: (state, action) => {
            state.trainings = action.payload
        },
        setSelectUser: (state, action) => {
            state.selectUser = action.payload
        },
        setUserSelected: (state, action) => {
            state.userSelected = action.payload
        },
        setImportedExercises: (state, action) => {
            state.importedExercises = action.payload
        },
        setDateTempTraining: (state, action) => {
            state.dateTempTraining = action.payload
        },
        setCleanSelectUser: (state) => {
            state.selectUser = null
        },
        setExercisesSelected: (state, action) => {
            state.exercisesSelected = action.payload
        },
        setIsEditingExerciseInTrainingTrue: (state) => {
            state.isEditingExerciseInTraining = true
        },
        setIsEditingExerciseInTrainingFalse: (state) => {
            state.isEditingExerciseInTraining = false
        },
        setQuery: (state, action) => {
            state.query = action.payload
        },
        setTrainingIdSelected: (state, action) => {
            state.trainingIdSelected = action.payload
        },
        setInitialStateTraining: (state) => {
            Object.assign(state, trainingSlice.getInitialState())
        },
        setIsCreateExerciseInTrainingTrue: (state) => {
            state.isCreateExerciseInTraining = true
        },
        setIsCreateExerciseInTrainingFalse: (state) => {
            state.isCreateExerciseInTraining = false
        },
        setIsCloneTrainingTrue: (state) => {
            state.isCloneTraining = true
        },
        setIsCloneTrainingFalse: (state) => {
            state.isCloneTraining = false
        },
        setTrainingToClone: (state, action) => {
            state.trainingToClone = action.payload
        },
        setEreateTrainingFromCalendarTrue: (state) => {
            state.createTrainingFromCalendar = true
        },
        setEreateTrainingFromCalendarFalse: (state) => {
            state.createTrainingFromCalendar = false
        },
        setToggleActivateViewTrainingSimpleMode: (state) => {
            state.toggleActivateViewTrainingSimpleMode = !state.toggleActivateViewTrainingSimpleMode
            setSessionStorageValue('isToggleActivateViewTrainingSimpleMode', state.toggleActivateViewTrainingSimpleMode)
        },
        setToggleCreateTrainingSimpleMode: (state) => {
            state.toggleCreateTrainingSimpleMode = !state.toggleCreateTrainingSimpleMode
            setSessionStorageValue('isToggleCreateTrainingSimpleMode', state.toggleCreateTrainingSimpleMode)
        }
    },
})



export const {
    setTrainings,
    setSelectUser,
    setImportedExercises,
    setDateTempTraining,
    setCleanSelectUser,
    setExercisesSelected,
    setIsEditingExerciseInTrainingTrue,
    setIsEditingExerciseInTrainingFalse,
    setQuery,
    setTrainingIdSelected,
    setInitialStateTraining,
    setIsCreateExerciseInTrainingTrue,
    setIsCreateExerciseInTrainingFalse,
    setUserSelected,
    setIsCloneTrainingTrue,
    setIsCloneTrainingFalse,
    setTrainingToClone,
    setEreateTrainingFromCalendarTrue,
    setEreateTrainingFromCalendarFalse,
    setToggleActivateViewTrainingSimpleMode,
    setToggleCreateTrainingSimpleMode
} = trainingSlice.actions
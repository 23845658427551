import React, { useContext } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export const ModalViewEquipment = ({ idModal, equipamentData, athleteName }) => {
  const { lang } = useContext(LanguageContext);
  const { modalViewEquipment } = useSlicesState()
  const { dispatch, setModalViewEquipment } = useSlicesActions()


  return (
    <>
      <Modal
        className="fade bd-example-modal-md"
        show={modalViewEquipment === idModal}
        size="md"
        backdropClassName="modalRPE"
      >
        <Modal.Header>
          <Modal.Title className="col-11 text-capitalize">
            {lang.equipment.modalViewEquipmentTitle} {athleteName}
          </Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => dispatch(setModalViewEquipment(null))}
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="">
          <Card.Body
            style={{ height: 'auto', maxHeight: '42vh', backgroundColor: '#f9f9f9', borderRadius: '1rem' }}
            className="tab-content scroll_test_responsive scrollbar-color p-2"
          >
            <div>
              {
                equipamentData?.map((equipment, index) => (
                  <div key={index} className="d-flex flex-column rounded p-2 bg-white mb-2">
                    <div className="d-flex flex-row justify-content-start align-items-center rounded p-2 bg-white mb-2" style={{ gap: '2rem' }}>
                      <div>
                        <img
                          src={equipment.img}
                          className="img-fluid"
                          alt={equipment.name}
                          style={{ width: '80px', height: '80px', objectFit: 'cover', borderRadius: '5px' }}
                          loading="lazy"
                        />
                      </div>
                      <p className="">{equipment.label}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </Card.Body>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch(setModalViewEquipment(null))}
            className="btn-md"
          >
            {lang.buttons.close}
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

import { useStateRedux } from "../useStateAPI";

export const useGetConfigApp = () => {
   
    const { dataGetAppConfig } = useStateRedux()

    const limitSignupProfesional = dataGetAppConfig?.limitSignupProfesional;
    const newVersionAppClient =dataGetAppConfig?.newVersionAppClient
    const inMaintenance = dataGetAppConfig?.inMaintenance
    const update = dataGetAppConfig?.update
    return {
        dataGetAppConfig,
        limitSignupProfesional, //FIXME: QUEDA PENDIENTE USAR
        newVersionAppClient,
        inMaintenance,
        update,
    };
};
import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { AutocompleteItem } from "..";
import { SearchIcon } from "../../assets/svg/svgs";
import { LanguageContext } from "../../context/LanguageContext";
import { NoSearchResult } from "../search/NoSearchResult";
import { useUserSearch } from "../../hooks";

export const ModalIconSearch = () => {
  const { lang } = useContext(LanguageContext);

  const {
    searchTerm,
    data,
    error,
    isFetching,
    handleSearchInputChange,
    handleInputFocus,
    handleInputBlur,
    inputOnFocus,
    modalIconSearch,
    closeModalIconSearch,
    dispatch,
    setModalIconSearch
  } = useUserSearch()

  return (
    <>
      <div
        as="li"
        className="nav-item notification_dropdown ml-2 d-lg-none"
        onClick={() => dispatch(setModalIconSearch(true))}
      >
        <div className="nav-link  ai-icon i-false">
          <SearchIcon color="#3B4CB8" />
        </div>
      </div>

      <Modal
        className="fade bd-example-modal-sm"
        size="sm"
        backdropClassName="modalEditCreateExercise"
        show={modalIconSearch}
      >
        <Modal.Header>
          <Modal.Title>{lang.generic.search}</Modal.Title>

          <Button
            variant=""
            className="close text-danger"
            onClick={() => closeModalIconSearch()}
          >
            <span className="font-sm">x</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className={`form-control border ${error ? "border-danger" : "border-secondary"}`}
            value={searchTerm}
            onChange={handleSearchInputChange}
            autoComplete="off"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder={lang.placeHolders.search}
          />
          {isFetching && <NoSearchResult text={lang.generic.searching} color="info" />}

          <section>
            {inputOnFocus &&
              (data?.length > 0 && searchTerm?.length > 2) ?
              (
                <div className="widget-media scroll_test_responsive  custom-scrollbar thin testBusqueda">
                  <ul
                    className="timeline scrollbar-color">
                    {data?.map((item) => (
                      <AutocompleteItem 
                      key={item._id} 
                      {...item}
                      />
                    ))}
                  </ul>
                </div>

              ) : (
                (searchTerm?.length > 2 && data?.length === 0 && inputOnFocus) &&
                <NoSearchResult text={lang.search.noSearchResults} color="warning" />
              )
            }
          </section>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger light" onClick={() => closeModalIconSearch()}>
            {lang.buttons.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import React from "react";

export const InputRange = ({ name, min, max, text, fun, value, customStyle, step }) => {
  return (
    <div className={`d-flex justify-content-between align-items-center ${customStyle}`}>
      <input
        name={name}
        className={`form-range mr-3`}
        style={{ width: `${step === 0.5 ? "80%" : "100%"}` }}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={fun}
      />
      <div className="d-flex justify-content-between align-items-center">
        <p>{value}</p>
        {text && <p className="ml-1" style={{ fontSize: '0.8rem' }}>{text}</p>}
      </div>
    </div>
  );
};

import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from 'uuid';
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { adaptCreateBlock, adaptCreateBlockWithDate } from "../../adapters/exerciseFormat";
import { formatDateForCreateTraining, formatDateForCreateTrainingFormCalendar } from "../../helpers/formatDate";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { statusSubscription } from "../../domain/statusSubscription";
import { TypesOfTraining } from "../../assets/data/typesOfTrainings";
import { analyticsAPI, useCreateTrainingMutation } from "../../api";
import { useForm } from "../useForm";
import { useGetMyProfile } from "../users/useGetMyProfile";
import { useGetMySubscriptions } from "../subscriptions/useGetMySubscriptions";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { loadingAlert, showAlert } from "../../helpers/Alerts";
import { getTextsEquipment, optionsEquipment } from "../../assets/data/validateEquipment";
import { useCloneTraining } from "./useCloneTraining";
import { getLocalStorageValue, setLocalStorageValue } from "../../helpers/localStorage";

export const useCreateTraining = () => {
    const { lang } = useContext(LanguageContext);

    const history = useHistory();

    const [createTraining, { isError, error, isSuccess, isLoading: isLoadingCreateTraining, data }] = useCreateTrainingMutation()

    const {
        selectUser,
        dateTempTraining,
        importedExercises,
        exercisesSelected,
        uid,
        trainingToClone,
        toggleCreateTrainingSimpleMode
    } = useSlicesState()

    const {
        dispatch,
        setCleanSelectUser,
        setImportedExercises,
        setDateTempTraining,
        setExercisesSelected,
        setIsCreateExerciseInTrainingTrue,
        setModalCreateExercise,
        setUserSelected,
        setExerciseSelected,
        setIsCloneTrainingFalse,
        setTrainingToClone
    } = useSlicesActions()


    const { sports: dataSports, profileUpdated } = useGetMyProfile();
    const { subscriptions, msgCode, iHaveActiveSubscriptions, isLoading } = useGetMySubscriptions();

    const [formCreateTraining, handleCreateTraining, reset, setFormCreateTraining] = useForm({
        title: "",
        target: "",
        typesOfTraining: TypesOfTraining.BlockTraining,
        activateTrainingProgressionsTemp: false,
        trainingProgressionsTemp: 2
    });

    const { title, target, activateTrainingProgressionsTemp, trainingProgressionsTemp } = formCreateTraining;

    const [athletes, setAthletes] = useState([]);
    const [sportInStore, setSportInStore] = useState([]);
    const [sports, setSports] = useState([]);
    const [exercises, setExercises] = useState([]);
    const [exerciseIdsPerBlock, setExerciseIdsPerBlock] = useState({});
    const [valuesUsers, setValuesUsers] = useState([]);
    const [valueSports, setvalueSports] = useState([]);

    const blockModel = {
        name: `Bloque N° 1`,
        description: "",
        orderIndex: 1,
        optionsTraining: {
            series: 1,
            heating: false,
            breakRecovery: false,
            breakInfo: 1,
            isUsingMinutes: false,
            activeRecovery: false,
            mobility: false,
            activation: false,
            stretching: false,
            blockIntensity: "low"
        },
    }


    const { blocksToClone, exercisesToClone } = useCloneTraining()
    const [blocks, setBlocks] = useState([{ ...blockModel, idTemporalClonar: uuidv4() }]);
    const [aSingleTrainingDate, setASingleTrainingDate] = useState(dayjs().format('DD-MM-YYYY'));
    const [multipleTrainingDate, setMultipleTrainingDate] = useState([dayjs().format('DD-MM-YYYY'), dayjs().add(7, 'day').format('DD-MM-YYYY')]);
    const [isAlertCalendarMulti, setIsAlertCalendarMulti] = useState(false)
    const [listUserWithEquipment, setListUserWithEquipment] = useState({})
    const [activeAccordionDinamicBlocks, setActiveAccordionDinamicBlocks] = useState(0);


    const isDisabled = (exercises.length === 0 || title.length < 1 || target.length < 1)
    const widthAndHeight = "20px";

    const handleImportTraining = () => {
        setFormCreateTraining({
            title: trainingToClone.title,
            target: trainingToClone.target,
            typesOfTraining: TypesOfTraining.BlockTraining,
            activateTrainingProgressionsTemp: false,
            trainingProgressionsTemp: 2
        });
        setvalueSports([...trainingToClone.sports])
        setBlocks([...blocksToClone])
        setExercises([...exercisesToClone])
        dispatch(setIsCloneTrainingFalse())
        dispatch(setTrainingToClone(null))
    }

    const handleContinueTraining = () => {

        const trainingTemp = getLocalStorageValue('tempTraining')
        const exercisesTemp = getLocalStorageValue('tempExercises')
        const blocksTemp = getLocalStorageValue('tempBlocks')
        const valueSportsTemp = getLocalStorageValue('tempSports')

        setFormCreateTraining({
            title: trainingTemp.title,
            target: trainingTemp.target,
            typesOfTraining: TypesOfTraining.BlockTraining,
            activateTrainingProgressionsTemp: trainingTemp.activateTrainingProgressionsTemp,
            trainingProgressionsTemp: trainingTemp.trainingProgressionsTemp
        });
        setvalueSports([...valueSportsTemp])
        setBlocks([...blocksTemp])
        setExercises([...exercisesTemp])
    }

    const userSelected = selectUser
        ? { ...selectUser, equipment: getTextsEquipment(selectUser?.equipment) }
        : listUserWithEquipment?.length === 1 ? { ...listUserWithEquipment[0], equipment: getTextsEquipment(listUserWithEquipment[0]?.equipment) } : []

    const startDate = activateTrainingProgressionsTemp
        ? multipleTrainingDate
        : aSingleTrainingDate

    const handleASingleTrainingDate = (date) => {
        setASingleTrainingDate(dayjs(date).format('DD-MM-YYYY'));
        // setIsRequiredDate(false)
    };

    const handleMultipleTrainingDate = (selectedDates) => {
        let formattedDates = selectedDates.map(date => dayjs(date).format('DD-MM-YYYY'));
        const newSelectedDatesCount = formattedDates.length;

        if (newSelectedDatesCount <= trainingProgressionsTemp) {
            setMultipleTrainingDate(formattedDates);
            setIsAlertCalendarMulti(false)
        } else {
            setMultipleTrainingDate([...multipleTrainingDate]);
            setIsAlertCalendarMulti(true)
            console.error('Se ha superado el número máximo de fechas seleccionables.');
        }
        // setIsRequiredDate(false)
    };

    const handleInputChangeForExercises = (event, exerciseId, date) => {
        const { name, value } = event.target;
        const list = JSON.parse(JSON.stringify(exercises));
        const exerciseIndex = exercises.findIndex(
            (exercise) => exercise.idTemporalClonar === exerciseId
        );
        if (exerciseIndex !== -1) {
            const exercise = { ...list[exerciseIndex] };
            if (activateTrainingProgressionsTemp && date) {
                exercise.options[name][date] = value;
            } else {
                if (name in exercise) {
                    exercise[name] = value;
                } else if ('options' in exercise) {
                    exercise.options[name] = value;
                }
            }
            list[exerciseIndex] = exercise;
            setExercises(list);
        } else {
            console.warn("Ejercicio con id", exerciseId, "no encontrado");
        }
    };

    const handleInputCheckboxForExercises = (event, exerciseId) => {
        const { target } = event;
        const { name, checked } = target;
        const list = JSON.parse(JSON.stringify(exercises));
        const exerciseIndex = exercises.findIndex(
            (exercise) => exercise.idTemporalClonar === exerciseId
        );

        if (exerciseIndex !== -1) {
            const exercise = { ...list[exerciseIndex] };
            if (name in exercise) {
                exercise[name] = checked;
            } else if ('options' in exercise && name in exercise.options) {
                exercise.options[name] = checked;
            } else if ('optionsEquipment' in exercise && name in exercise.optionsEquipment) {
                exercise.optionsEquipment[name] = checked;
            }
            setExercises(list);
        } else {
            console.warn("Índice", exerciseIndex, "fuera de rango en ejercicios");
        }
    };

    const handleInputCheckBoxForBlocks = (event, index) => {
        const { target } = event;
        const { name, checked } = target;
        const list = JSON.parse(JSON.stringify(blocks));
        if (index >= 0 && index < list.length) {
            const block = list[index];
            if (name in block) {
                block[name] = checked;
            } else if ('optionsTraining' in block && name in block.optionsTraining) {
                block.optionsTraining[name] = checked;
            }
            setBlocks(list);
        } else {
            console.warn("Índice", index, "fuera de rango en bloques");
        }
    };

    const handleCloneExercise = (idExercise) => {
        dispatch(setIsCreateExerciseInTrainingTrue())
        const exerciseToClone = exercises.filter(exercise => exercise?.idTemporalClonar === idExercise)[0];
        let newExerciseClone = { ...exerciseToClone, idTemporalClonar: uuidv4() };
        setExercises([...exercises, newExerciseClone]);
        dispatch(setModalCreateExercise(newExerciseClone?.idTemporalClonar));
    };

    const transformExercise = (exercise, dates) => {
        const newWeightInfo = {};
        const newKmInfo = {};
        const newHearRateInfo = {};
        const newCadenceInfo = {};
        const newMetersInfo = {};
        const newPowerInfo = {};
        const newRhythmInfo = {};
        const newBreakInfo = {};
        const newSeries = {};
        const newRepetitions = {};
        const newOtherInfo = {};
        const newTimeInfo = {};
        const newSwimmingStylesInfo = {};

        dates.forEach(date => {
            newWeightInfo[date] = 0;
            newKmInfo[date] = 1;
            newHearRateInfo[date] = 30;
            newCadenceInfo[date] = 1;
            newMetersInfo[date] = 1;
            newPowerInfo[date] = 1;
            newRhythmInfo[date] = 1;
            newBreakInfo[date] = 1;
            newSeries[date] = 1;
            newRepetitions[date] = 0;
            newOtherInfo[date] = "";
            newTimeInfo[date] = "";
            newSwimmingStylesInfo[date] = "";
        });

        return {
            ...exercise,
            options: {
                ...exercise.options,
                weightInfo: newWeightInfo,
                kmInfo: newKmInfo,
                hearRateInfo: newHearRateInfo,
                cadenceInfo: newCadenceInfo,
                metersInfo: newMetersInfo,
                powerInfo: newPowerInfo,
                rhythmInfo: newRhythmInfo,
                breakInfo: newBreakInfo,
                series: newSeries,
                repetitions: newRepetitions,
                otherInfo: newOtherInfo,
                timeInfo: newTimeInfo,
                swimmingStylesInfo: newSwimmingStylesInfo,
            }
        };
    };

    const handleAddExercise = (blockIdTemp) => {
        dispatch(setIsCreateExerciseInTrainingTrue())
        let idTemp = uuidv4()
        setExercises([
            ...exercises,
            {
                idTemporalClonar: idTemp,
                blockIdTemp,
                name: "",
                description: "",
                video: "",
                options: {
                    weight: false,
                    weightInfo: activateTrainingProgressionsTemp ? {} : 0,
                    km: false,
                    kmInfo: activateTrainingProgressionsTemp ? {} : 1,
                    time: false,
                    timeInfo: activateTrainingProgressionsTemp ? {} : "",
                    hearRate: false,
                    hearRateInfo: activateTrainingProgressionsTemp ? {} : 30,
                    cadence: false,
                    cadenceInfo: activateTrainingProgressionsTemp ? {} : 1,
                    swimmingStyles: false,
                    swimmingStylesInfo: activateTrainingProgressionsTemp ? {} : "",
                    meters: false,
                    metersInfo: activateTrainingProgressionsTemp ? {} : 1,
                    power: false,
                    powerInfo: activateTrainingProgressionsTemp ? {} : 1,
                    other: false,
                    otherInfo: activateTrainingProgressionsTemp ? {} : "",
                    series: activateTrainingProgressionsTemp ? {} : 1,
                    repetitions: activateTrainingProgressionsTemp ? {} : 0,
                    heating: false,
                    breakRecovery: false,
                    breakInfo: activateTrainingProgressionsTemp ? {} : 1,
                    activeRecovery: false,
                    rhythm: false,
                    rhythmInfo: activateTrainingProgressionsTemp ? {} : 1,
                    isUsingMinutes: false,
                    toTheRuling: false,
                },
                optionsEquipment: { ...optionsEquipment },
            },
        ]);
        dispatch(setModalCreateExercise(idTemp));
    };

    const handleEditExerciseInTrainingCreation = (idExercise, exercise) => {
        dispatch(setIsCreateExerciseInTrainingTrue())
        dispatch(setExerciseSelected({ ...exercise, optionsEquipment: exercise?.optionsEquipment ? exercise?.optionsEquipment : optionsEquipment }))
        dispatch(setModalCreateExercise(idExercise));
    };

    const handleRemoveExercise = (idExercise) => {
        const newList = exercises.filter(exercise => exercise?.idTemporalClonar !== idExercise);
        setExercises(newList);
        const updatedExerciseIdsPerBlock = Object.entries(exerciseIdsPerBlock).reduce((acc, [blockId, exerciseIds]) => {
            acc[blockId] = exerciseIds.filter(exerciseId => exerciseId !== idExercise);
            return acc;
        }, {});
        setExerciseIdsPerBlock(updatedExerciseIdsPerBlock);
        dispatch(setModalCreateExercise(null));
        if (idExercise) {
            dispatch(setExercisesSelected(exercisesSelected?.filter((exercise) => exercise?.idTemporalClonar !== idExercise)))
        }
    };

    const handleAddBlocks = () => {
        setBlocks([
            ...blocks,
            {
                ...blockModel,
                idTemporalClonar: uuidv4(),
                name: `Bloque N° ${blocks.length + 1}`,
                orderIndex: blocks.length + 1,
                exercises: exercises,
            },
        ]);
        setActiveAccordionDinamicBlocks(blocks.length);
    };

    const handleCloneBlocks = (blockId, index) => {
        let blockWithdNewId = {
            ...blocks[index],
            name: `Bloque N° ${blocks.length + 1} (copia)`,
            idTemporalClonar: uuidv4(),
            orderIndex: blocks.length + 1
        };
        const exercisesToClone = exercises.filter(exercise => exercise.blockIdTemp === blockId);
        const clonedExercises = exercisesToClone.map(exercise => ({
            ...exercise,
            idTemporalClonar: uuidv4(),
            blockIdTemp: blockWithdNewId.idTemporalClonar
        }));

        setBlocks([...blocks, blockWithdNewId]);
        setExercises([...exercises, ...clonedExercises]);
        setActiveAccordionDinamicBlocks(blocks.length);
    };

    const handleRemoveBlocks = (index, idBlock) => {
        const newList = blocks.filter((_, i) => i !== index);
        setBlocks(newList);
        // if (idBlock) {  //Para eliminar Ejercicio del state cuando esta importado
        //     dispatch(setExercisesSelected(exercisesSelected?.filter((exercise) => exercise._id !== idBlock)))
        // }
    };

    const handleInputChangeForBlocks = (event, index, date) => {
        const { name, value } = event.target;
        const list = JSON.parse(JSON.stringify(blocks));
        if (index < list.length) {
            const block = { ...list[index] };
            if (activateTrainingProgressionsTemp && date) {
                if (!block.optionsTraining[name]) {
                    block.optionsTraining[name] = {};
                }
                block.optionsTraining[name][date] = value;
            } else {
                if (name in block) {
                    block[name] = value;
                } else if ('optionsTraining' in block) {
                    block.optionsTraining[name] = value;
                }
            }
            list[index] = block;
            setBlocks(list);
        } else {
            console.warn("Bloque en el índice", index, "no encontrado");
        }
    };

    const handleCreateTrainingContainer = async () => {

        if (!selectUser && valuesUsers?.length < 1) return Swal.fire("Oops 😥", 'Dedes seleccionar mínimo un atleta', "warning");
        if (valueSports?.length < 1) return Swal.fire("Oops 😥", 'Dedes seleccionar mínimo un Tipo de Entrenamiento', "warning");

        const { activateTrainingProgressionsTemp, trainingProgressionsTemp, ...newCleanTraining } = formCreateTraining;

        const trainings = [];
        if (activateTrainingProgressionsTemp && !toggleCreateTrainingSimpleMode) {
            multipleTrainingDate.forEach(date => {
                const training = {
                    ...newCleanTraining,
                    startDate: formatDateForCreateTraining(date),
                    sports: valueSports,
                    athletes: selectUser ? [selectUser._id] : valuesUsers,
                    blocks: blocks?.map(block => {
                        const { _id, idTemporalClonar, finishDate, blockNote, ...restBlock } = block;
                        return adaptCreateBlockWithDate(restBlock, date);
                    })
                };
                trainings.push(training);
            });
        }

        if (!activateTrainingProgressionsTemp && !toggleCreateTrainingSimpleMode) {
            const training = {
                ...newCleanTraining,
                startDate: dateTempTraining ? formatDateForCreateTrainingFormCalendar(dateTempTraining) : formatDateForCreateTraining(startDate),
                sports: valueSports,
                athletes: selectUser ? [selectUser._id] : valuesUsers,
                blocks: blocks?.map(block => {
                    const { _id, idTemporalClonar, finishDate, blockNote, ...restBlock } = block;
                    return adaptCreateBlock(restBlock)
                })
            }
            trainings.push(training);
        }

        if (!activateTrainingProgressionsTemp && toggleCreateTrainingSimpleMode) {
            const training = {
                ...newCleanTraining,
                startDate: dateTempTraining ? formatDateForCreateTrainingFormCalendar(dateTempTraining) : formatDateForCreateTraining(startDate),
                target: title,
                sports: valueSports,
                isSimpleMode: true,
                athletes: selectUser ? [selectUser._id] : valuesUsers,
                blocks: blocks?.map(block => {
                    const { _id, idTemporalClonar, finishDate, blockNote, ...restBlock } = block;
                    return adaptCreateBlock(restBlock)
                })
            }
            trainings.push(training);
        }

        try {
            await createTraining({ trainings }).unwrap();
            Swal.close();
            localStorage.removeItem('tempTraining')
            localStorage.removeItem('tempExercises')
            localStorage.removeItem('tempBlocks')
            localStorage.removeItem('tempSports')
            setLocalStorageValue('isOngoingTraining', false)
            dispatch(setCleanSelectUser(null));
            dispatch(setDateTempTraining(null));
            dispatch(setExercisesSelected([]));
            setExercises([]);
            setValuesUsers([]);
            setvalueSports([]);
            setBlocks([]);
            setExerciseIdsPerBlock({});
            setIsAlertCalendarMulti(false);
            reset();
            setBlocks([{ ...blockModel, idTemporalClonar: uuidv4() }]);
            dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]));
        } catch (error) {
            showAlert(error?.data?.msgCode, error?.data?.ok);
        }
    };


    const showAlertIHaveActiveSubscriptions = async () => {
        const result = await Swal.fire({
            title: lang.createTraining.alterRedirectWithoutSubscriptionsTitle,
            text: lang.createTraining.alterRedirectWithoutSubscriptionsTtext,
            icon: "info",
            showCancelButton: false,
            confirmButtonText: "OK",
        });

        if (result.isConfirmed) {
            history.push(Routes.subscriptions)
        }
    };

    if (!isLoading && subscriptions && !iHaveActiveSubscriptions && dataSports?.length > 0) {
        showAlertIHaveActiveSubscriptions();
    }

    useEffect(() => {
        if (activateTrainingProgressionsTemp) {
            setBlocks((prevBlocks) =>
                prevBlocks.map((block) => {
                    const newSeries = {};
                    const newBreakInfo = {};
                    const newBlockIntensity = {}

                    multipleTrainingDate.forEach((date) => {
                        newSeries[date] = 1;
                        newBreakInfo[date] = 1;
                        newBlockIntensity[date] = "low";
                    });

                    return {
                        ...block,
                        optionsTraining: {
                            ...block.optionsTraining,
                            series: newSeries,
                            breakInfo: newBreakInfo,
                            blockIntensity: newBlockIntensity,
                        },
                    };
                })
            );
        } else {
            setBlocks((prevBlocks) =>
                prevBlocks.map((block) => ({
                    ...block,
                    optionsTraining: {
                        ...block.optionsTraining,
                        series: 1,
                        breakInfo: 1,
                        blockIntensity: "low",
                    },
                }))
            );
        }
    }, [activateTrainingProgressionsTemp, multipleTrainingDate]);

    useEffect(() => {
        if (activateTrainingProgressionsTemp) {
            setExercises(prevExercises =>
                prevExercises.map(exercise => {
                    const newWeightInfo = {};
                    const newKmInfo = {};
                    const newHearRateInfo = {};
                    const newCadenceInfo = {};
                    const newMetersInfo = {};
                    const newPowerInfo = {};
                    const newRhythmInfo = {};
                    const newBreakInfo = {};
                    const newSeries = {};
                    const newRepetitions = {};
                    const newOtherInfo = {};
                    const newTimeInfo = {};
                    const newSwimmingStylesInfo = {};

                    multipleTrainingDate.forEach(date => {
                        newWeightInfo[date] = 0;
                        newKmInfo[date] = 1;
                        newHearRateInfo[date] = 30;
                        newCadenceInfo[date] = 1;
                        newMetersInfo[date] = 1;
                        newPowerInfo[date] = 1;
                        newRhythmInfo[date] = 1;
                        newBreakInfo[date] = 1;
                        newSeries[date] = 1;
                        newRepetitions[date] = 0;
                        newOtherInfo[date] = "";
                        newTimeInfo[date] = "";
                        newSwimmingStylesInfo[date] = "";
                    });

                    return {
                        ...exercise,
                        options: {
                            ...exercise.options,
                            weightInfo: newWeightInfo,
                            kmInfo: newKmInfo,
                            hearRateInfo: newHearRateInfo,
                            cadenceInfo: newCadenceInfo,
                            metersInfo: newMetersInfo,
                            powerInfo: newPowerInfo,
                            rhythmInfo: newRhythmInfo,
                            breakInfo: newBreakInfo,
                            series: newSeries,
                            repetitions: newRepetitions,
                            otherInfo: newOtherInfo,
                            timeInfo: newTimeInfo,
                            swimmingStylesInfo: newSwimmingStylesInfo,
                        }
                    };
                })
            );
        } else {
            setExercises(prevExercises =>
                prevExercises.map(exercise => ({
                    ...exercise,
                    options: {
                        ...exercise.options,
                        weightInfo: 0,
                        kmInfo: 1,
                        hearRateInfo: 30,
                        cadenceInfo: 1,
                        metersInfo: 1,
                        powerInfo: 1,
                        rhythmInfo: 1,
                        breakInfo: 1,
                        series: 1,
                        repetitions: 0,
                        otherInfo: "",
                        timeInfo: "",
                        swimmingStylesInfo: "",
                    }
                }))
            );
        }
    }, [activateTrainingProgressionsTemp, multipleTrainingDate]);

    useEffect(() => {
        if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
        if (isSuccess) showAlert("SE0039", data?.ok);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);


    useEffect(() => {
        if (msgCode === "SE0011") {
            Swal.fire({
                title: lang.createTraining.alertTitleNoSubscriptions,
                icon: "warning",
                html: lang.createTraining.alertTextNoSubscriptions,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: lang.createTraining.alertConfirmButton,
            });
            history.push(Routes.subscriptions);
        } else {
            const athletes = subscriptions
                ?.filter(
                    (subscription) =>
                        subscription.status !== statusSubscription.finished &&
                        subscription.status !== statusSubscription.new
                )
                // eslint-disable-next-line array-callback-return
                .map((subscription) => {
                    const idOtherProfile =
                        uid === subscription.idInviting._id
                            ? subscription.guestId._id
                            : subscription.idInviting._id;
                    const idOtherName =
                        uid === subscription.idInviting._id
                            ? subscription.guestId.name
                            : subscription.idInviting.name;
                    const equipment = uid === subscription.idInviting._id
                        ? subscription.guestId?.equipment ? subscription.guestId?.equipment : []
                        : subscription.idInviting?.equipment ? subscription.idInviting?.equipment : []
                    // const athlete =
                    //     uid === subscription.idInviting._id
                    //         ? subscription.guestId
                    //         : subscription.idInviting;

                    if (
                        subscription.status === statusSubscription.active ||
                        subscription.status === statusSubscription.reactivated
                    ) {
                        return {
                            value: idOtherProfile,
                            name: idOtherName,
                            equipment: equipment
                        };
                    }
                });

            setAthletes(athletes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, msgCode, subscriptions, uid]);

    useEffect(() => {
        if (dataSports) {
            setSportInStore(dataSports);
            const sports = sportInStore?.map((sport) => {
                return {
                    value: sport.toLowerCase(),
                    name: sport,
                };
            });
            setSports(sports);
        }
    }, [dataSports, sportInStore]);

    useEffect(() => {
        if (!profileUpdated) history.push(Routes.dashboard)
    }, [history, profileUpdated])

    useEffect(() => {
        if (activateTrainingProgressionsTemp && importedExercises.length > 0) {
            const transformedExercises = importedExercises.map(exercise => transformExercise(exercise, multipleTrainingDate));
            setExercises(prevExercises => [...prevExercises, ...transformedExercises]);
        } else if (!activateTrainingProgressionsTemp && importedExercises.length > 0) {
            setExercises(prevExercises => [...prevExercises, ...importedExercises]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importedExercises]);



    useEffect(() => {
        dispatch(setImportedExercises([]))
        setExercises([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMultipleTrainingDate([dayjs().format('DD-MM-YYYY'), dayjs().add(7, 'day').format('DD-MM-YYYY')])
    }, [trainingProgressionsTemp]);

    useEffect(() => {
        if (!dataSports) {
            Swal.fire({
                title: lang.generic.info,
                icon: "info",
                html: lang.profile.completeProfile,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: lang.buttons.perfectComplete,
            }).then((result) => {
                if (result.isConfirmed) {
                    history.push(Routes.profile);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSports]);

    useEffect(() => {
        if (valuesUsers?.length === 1) {
            setListUserWithEquipment(athletes?.filter(athlete => athlete?.value === valuesUsers[0]))
        } else {
            setListUserWithEquipment({})
        }
    }, [athletes, valuesUsers])

    useEffect(() => {
        valuesUsers?.length === 1
            ? dispatch(setUserSelected(userSelected))
            : dispatch(setUserSelected(null))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listUserWithEquipment])

    useEffect(() => {
        const updatedExerciseIdsPerBlock = blocks.reduce((acc, block) => {
            acc[block.idTemporalClonar] = exercises.filter(
                (exercise) => exercise.blockIdTemp === block.idTemporalClonar
            ).map((exercise) => exercise.idTemporalClonar); // Solo los id
            return acc;
        }, {});
        setExerciseIdsPerBlock(updatedExerciseIdsPerBlock);
    }, [exercises, blocks]);

    useEffect(() => {
        const updatedBlocks = blocks.map(block => ({
            ...block,
            exercises: exercises.filter(exercise => exercise.blockIdTemp === block.idTemporalClonar)
        }));
        setBlocks(updatedBlocks);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exercises])

    useEffect(() => {
        if (activateTrainingProgressionsTemp && dateTempTraining !== "") {
            dispatch(setDateTempTraining(null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activateTrainingProgressionsTemp])

    useEffect(() => {
        if (isLoadingCreateTraining) {
            loadingAlert(lang.alerts.creatingWorkouts)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingCreateTraining])

    useEffect(() => {
        if (formCreateTraining?.title !== "" && blocks?.length > 0 && exercises?.length > 0) {
            setLocalStorageValue('tempTraining', formCreateTraining)
            setLocalStorageValue('tempExercises', exercises)
            setLocalStorageValue('tempBlocks', blocks)
            setLocalStorageValue('tempSports', valueSports)
            setLocalStorageValue('isOngoingTraining', true)
        }
    }, [formCreateTraining, exercises, blocks, valueSports])

    useEffect(() => {
        if (toggleCreateTrainingSimpleMode && activateTrainingProgressionsTemp) {
            setFormCreateTraining({
                ...formCreateTraining,
                activateTrainingProgressionsTemp: false
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleCreateTrainingSimpleMode])



    return {
        exercises,
        title,
        target,
        startDate,
        dateTempTraining,
        valueSports,
        athletes,
        trainingProgressionsTemp,
        activateTrainingProgressionsTemp,
        widthAndHeight,
        selectUser,
        valuesUsers,
        setValuesUsers,
        sports,
        setvalueSports,
        isDisabled,
        setCleanSelectUser,
        handleAddExercise,
        handleInputChangeForExercises,
        handleInputCheckboxForExercises,
        handleCloneExercise,
        handleRemoveExercise,
        handleCreateTraining,
        handleCreateTrainingContainer,
        dispatch,
        aSingleTrainingDate,
        handleASingleTrainingDate,
        multipleTrainingDate,
        handleMultipleTrainingDate,
        blocks,
        handleCloneBlocks,
        handleAddBlocks,
        handleRemoveBlocks,
        handleInputChangeForBlocks,
        handleInputCheckBoxForBlocks,
        handleEditExerciseInTrainingCreation,
        exerciseIdsPerBlock,
        isAlertCalendarMulti,
        activeAccordionDinamicBlocks,
        setActiveAccordionDinamicBlocks,
        handleImportTraining,
        handleContinueTraining
    };
};
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { Avatar } from "../Avatar/Avatar";
import { roles } from "../../domain/roles";
import { statusSubscription } from "../../domain/statusSubscription";
import { Badge, Dropdown } from "react-bootstrap";
import { TresPuntos } from "../../assets/svg/svgs";
import { launchAlert } from "../../helpers/Alerts";

export const ProfileStatusOptions = ({
    subscription,
    handleViewProfile,
    isAthlete,
    handleUserCard,
    handleCreateTraining,
    alertEnd,
    alertReactivate,
    handleActivateReactivatedSusbcriptions,
    handleViewTraining
  }) => {
  
  
    const history = useHistory()
    const { uid } = useSlicesState()
    const { lang } = useContext(LanguageContext);
    const { dispatch, setOtherUserId, setOtherCompleteUser } = useSlicesActions()
  
    const setUserAndRedirect = (userProps) => {
      dispatch(setOtherCompleteUser(userProps))
      history.push(Routes.measureDetailsOtherUser)
    }
  
    return (
      <div className="d-flex justify-content-between align-items-center flex-row flex-wrap" style={{ gap: '1rem' }}>
        <Link
          className="d-flex align-items-center"
          to="#"
          onClick={() => handleViewProfile(subscription)}
        >
          <Avatar
            name={uid === subscription.idInviting._id
              ? subscription.guestId.name
              : subscription.idInviting.name}
            img={uid === subscription.idInviting._id
              ? subscription.guestId?.fullProfile?.urlImage
              : subscription.idInviting?.fullProfile?.urlImage}
            size="45px"
            className={'mr-2 mr-0 ml-0'}
          />
  
          <span className="w-space-no text-capitalize">
            {uid === subscription.idInviting._id
              ? subscription.guestId.name
              : subscription.idInviting.name}
          </span>
          {!subscription?.idInviting?.role?.includes(roles.athlete) &&
            !subscription?.guestId?.role?.includes(roles.athlete) && (
              <span className="ml-2 text-info">(Prof.)</span>
            )}
  
        </Link>
  
        <>
          {subscription.status === statusSubscription.active && (
            <Badge variant="success light">
              {lang.mySubscriptions.status.active}
            </Badge>
          )}
  
          {subscription.status === statusSubscription.reactivated && (
            <Badge variant="info light">
              {lang.mySubscriptions.status.reactivated}
            </Badge>
          )}
  
          {subscription.status === statusSubscription.finished && (
            <Badge variant="danger light">
              {lang.mySubscriptions.status.finished}
            </Badge>
          )}
  
          {!isAthlete() &&
            subscription.status === statusSubscription.new && (
              <Badge variant="warning light">
                {lang.mySubscriptions.status.new}
              </Badge>
            )}
  
          {isAthlete() &&
            subscription.status === statusSubscription.new && (
              <Badge variant="warning light">
                {lang.mySubscriptions.status.pending}
              </Badge>
            )}
        </>
  
        <>
          {(subscription.status === statusSubscription.active ||
            subscription.status === statusSubscription.reactivated) && (
              <Dropdown>
                <Dropdown.Toggle
                  variant={
                    subscription.status === statusSubscription.active
                      ? "success"
                      : "info"
                  }
                  className="light sharp i-false"
                >
                  {TresPuntos}
                </Dropdown.Toggle>
                {!isAthlete() ? (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleViewProfile(subscription)}
                    >
                      {lang.buttons.viewProfile}
                    </Dropdown.Item>
  
                    <Dropdown.Item
                      onClick={() => {
                        dispatch(setOtherUserId(uid === subscription.idInviting._id ? subscription.guestId._id : subscription.idInviting._id))
                        setUserAndRedirect(uid === subscription.idInviting._id ? subscription.guestId : subscription.idInviting)
                      }}
                    >
                      {lang.generic.physiologicalData}
                    </Dropdown.Item>
  
                    <Dropdown.Item
                      onClick={() => handleUserCard(subscription)}
                    >
                      {lang.mySubscriptions.file}
                    </Dropdown.Item>
                    {/* falta  */}
                    <Dropdown.Item
                      onClick={() =>
                        handleCreateTraining(
                          uid === subscription.idInviting._id
                            ? subscription.guestId
                            : subscription.idInviting
                        )
                      }
                    >
                      {lang.buttons.createWorkouts}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        launchAlert(
                          alertEnd,
                          uid === subscription.idInviting._id
                            ? subscription.guestId.name
                            : subscription.idInviting.name,
                          subscription._id,
                          statusSubscription.finished
                        )
                      }
                    >
                      {lang.generic.finalize}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleViewProfile(subscription)}
                    >
                      {lang.buttons.viewProfile}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleViewTraining(
                        uid === subscription.idInviting._id
                          ? subscription.guestId
                          : subscription.idInviting
                      )}
                    >
                      {lang.generic.seeWorkouts}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        launchAlert(
                          alertEnd,
                          uid === subscription.idInviting._id
                            ? subscription.guestId.name
                            : subscription.idInviting.name,
                          subscription._id,
                          statusSubscription.finished
                        )
                      }
                    >
                      {lang.generic.finalize}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            )}
  
          {subscription.status === statusSubscription.finished &&
            !isAthlete() && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="danger"
                  className="light sharp i-false"
                >
                  {TresPuntos}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      launchAlert(
                        alertReactivate,
                        subscription.guestId.name,
                        subscription._id,
                        statusSubscription.reactivated
                      )
                    }
                  >
                    {lang.generic.reactivate}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          {!isAthlete() &&
            subscription.status === statusSubscription.new && (
              <Dropdown>
                <Dropdown.Toggle
                  variant="warning"
                  className="light sharp i-false"
                >
                  {TresPuntos}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleViewProfile(subscription)}
                  >
                    {lang.buttons.viewProfile}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleActivateReactivatedSusbcriptions(
                        subscription._id,
                        statusSubscription.active
                      )
                    }
                  >
                    {lang.generic.activate}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
        </>
  
      </div>
    )
  }
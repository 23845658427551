import React from "react";

import logo from "../../assets/img/logo.png";
import logoText from "../../assets/img/logo-text.png";
import { Link } from "react-router-dom";

export const LogoWraper = () => {
  return (
    <Link to="/">
      <div className="text-center mb-3 mt-3">
        <img src={logo} alt="logo icono fitool" />
        <img src={logoText} alt="logo text fitool" />
      </div>
    </Link>
  );
};

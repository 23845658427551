import { createSlice } from '@reduxjs/toolkit'


export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState: {
        tempSelectRPE: null,
        tempUserIdSelected: null,
        messageToEdit: null,
        isEditMessage: false,
    },
    reducers: {
        setTempSelectRPE: (state, action) => {
            state.tempSelectRPE = action.payload
        },
        setCleanTempSelectRPE: (state) => {
            state.tempSelectRPE = null
        },
        setUserIdSelected: (state, action) => {
            state.tempUserIdSelected = action.payload
        },
        setMessageToEdit: (state, action) => {
            state.messageToEdit = action.payload
        },
        setIsEditMessage: (state, action) => {
            state.isEditMessage = action.payload
        },
        setInitialStateFeedBack: (state) => {
            Object.assign(state, feedbackSlice.getInitialState())
        }
    },
})

export const {
    setTempSelectRPE,
    setCleanTempSelectRPE,
    setUserIdSelected,
    setMessageToEdit,
    setIsEditMessage,
    setInitialStateFeedBack,
} = feedbackSlice.actions
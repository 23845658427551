import { useEffect, useState } from "react";
import { useLazySearchUsersQuery } from "../../api";
import { useDebounce } from "use-debounce";
import { useSlicesActions, useSlicesState } from "../../redux/slices";

export function useUserSearch() {
  const { modalIconSearch } = useSlicesState()
  const { dispatch, setModalIconSearch } = useSlicesActions()
  const [searchTerm, setSearchTerm] = useState("");
  const [inputOnFocus, setInputOnFocus] = useState(false)
  const [isDesktop] = useState(window.innerWidth > 992);
  const [trigger, { data, error, isFetching }] = useLazySearchUsersQuery({
    pollingInterval: false,
    refetchOnReconnect: false,
    refetchOnFocus: false,
  });

  const [debouncedSearchTerm] = useDebounce(searchTerm, 350)

  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

  };

  const closeModalIconSearch = () => {
    dispatch(setModalIconSearch(false))
  }

  useEffect(() => {
    if (searchTerm.length > 0) {
      trigger(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  const handleInputFocus = () => {
    setInputOnFocus(true);
  };

  const handleInputBlur = () => {
    if (!isDesktop) closeModalIconSearch()
    setTimeout(() => {
      setInputOnFocus(false);
      setSearchTerm("")
    }, 100);
  };

  return {
    searchTerm,
    data,
    error,
    isFetching,
    handleSearchInputChange,
    handleInputFocus,
    handleInputBlur,
    inputOnFocus,
    modalIconSearch,
    closeModalIconSearch,
    dispatch,
    setModalIconSearch
  };
}

import { createSlice } from '@reduxjs/toolkit'


export const appSlice = createSlice({
    name: 'app',
    initialState: {
        openMenuSidebar: false,
    },
    reducers: {
        setOpenMenuSidebarSwitch: (state) => {
            state.openMenuSidebar = !state.openMenuSidebar
        },
        setInitialStateApp: (state) => {
            Object.assign(state, appSlice.getInitialState())
        }
    },
})

export const {
    setOpenMenuSidebarSwitch,
    setInitialStateApp,
} = appSlice.actions
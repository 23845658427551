import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const exerciseAPI = createApi({
    reducerPath: 'exerciseAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["exerciseAPI"],
    endpoints: (builder) => ({
        getMyExercises: builder.query({
            query: () => `/exercise/get-my-exercises?limit=600&offset=0`, //FIXME: Paginación
            providesTags: ["exerciseAPI"]
        }),
        createExercise: builder.mutation({
            query: (exerciseData) => (
                {
                    url: `/exercise/create-my-my-exercise`,
                    method: 'POST',
                    body: exerciseData
                }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async (result, { dispatch, queryFulfilled }) => {
                try {
                    const { data: resultCreateExercise } = await queryFulfilled;
                    dispatch(
                        exerciseAPI.util.updateQueryData('getMyExercises', undefined, (oldData) => {
                            if (oldData?.msgCode === 'SE0026') {
                                dispatch(exerciseAPI.util.invalidateTags(["exerciseAPI"]))
                                return;
                            } else {
                                return {
                                    ...oldData,
                                    exercises: [...oldData?.exercises, resultCreateExercise?.newExercise],
                                    totalCount: oldData?.totalCount !== null && oldData?.totalCount !== undefined ? oldData.totalCount + 1 : 1
                                };
                            }
                        })
                    );
                } catch (error) {
                    console.error("Error actualizando la caché:", error);
                    // Maneja el error adecuadamente aquí, si es necesario.
                }
            }

        }),
        updateExercise: builder.mutation({
            query: (dataMutation) => ({
                url: `/exercise/update/${dataMutation?.exerciseId}`,
                method: 'PUT',
                body: dataMutation?.data
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ exerciseId, ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    exerciseAPI.util.updateQueryData('getMyExercises', undefined, (oldData) => {
                        const updatedData = Array.from(oldData?.exercises).map((exercise) =>
                            exercise._id === exerciseId ? { ...exercise, ...result?.data } : exercise
                        );
                        return { ...oldData, exercises: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deleteExercise: builder.mutation({
            query: (exerciseId) => ({
                url: `/exercise/delete/${exerciseId}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async (exerciseId, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    exerciseAPI.util.updateQueryData('getMyExercises', undefined, (oldData) => {
                        const updatedData = oldData?.exercises?.filter((exercise) => exercise._id !== exerciseId);
                        return {
                            ...oldData,
                            exercises: updatedData,
                            totalCount: oldData?.totalCount && oldData.totalCount - 1
                        };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const { useGetMyExercisesQuery, useCreateExerciseMutation, useUpdateExerciseMutation, useDeleteExerciseMutation } = exerciseAPI;
import React from "react";
import { IconSport } from "..";

export const TrSportsDetails = ({ color, title, value }) => {
  return (
    <>
      <tr>
        <td>
          <div className="media align-items-center w-50">
            <span className={`activity-icon bgl-${color} mr-0"`}>
              {/* <Icon className="text-success" size={"2.5em"} /> */}
              {
                IconSport(value)
              }
            </span>
          </div>
        </td>
        <td>
          <p className="mb-2 font-weight-bold text-center">{title}</p>
        </td>
      </tr>
    </>
  );
};

import React from "react";
import { Button } from "react-bootstrap";
import { formatDateWithDayJS } from "../../helpers/formatDate";
import { useSlicesActions } from "../../redux/slices/hook";
import { useDeleteFeedBack } from "../../hooks";

export const Messages = ({
  message,
  date,
  iAm,
  idMessage,
  idTraining,
}) => {
  const { dispatch, setIsEditMessage, setMessageToEdit } = useSlicesActions()
  const { handleDeleteFeedBack } = useDeleteFeedBack()

  const setParamsEdit = () => {
    dispatch(setIsEditMessage(true))
    dispatch(setMessageToEdit({ idMessage, message }));
  };

  const includeRPE = message.includes('RPE')

  return (
    <>
      <li>
        <div className={`timeline-badge primary ${iAm && "success"}`}></div>
        <div className="timeline-panel text-muted">
          <span>
            {formatDateWithDayJS(date)} {`${iAm ? "Yo" : "Atleta"}`}
          </span>
          <h6 className="mb-0">{message}</h6>

          {iAm && (
            <div className="buttonsMessages">
              {!includeRPE && <Button
                className="mr-2"
                variant="success btn-xs"
                onClick={() => setParamsEdit()}
              >
                <i className="fa fa-edit" />
              </Button>}


              <Button
                variant="danger btn-xs"
                onClick={() => handleDeleteFeedBack(idMessage)}
              >
                <i className="fa fa-remove" />
              </Button>
            </div>
          )}
        </div>
      </li>
    </>
  );
};

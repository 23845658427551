import { useDeleteBlockInTrainingMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesState } from "../../redux/slices/hook";

export function useDeleteBlock() {
  const { lang } = useContext(LanguageContext);
  const [deleteBlockInTraining, { isError, error }] = useDeleteBlockInTrainingMutation();

  const { query } = useSlicesState()

  const handleRemoveBlockInTraining = (idBlock) => {
    deleteBlockInTraining({ idBlock, query })
  }

  const handleRemoveBlock = (blockId) => {
    Swal.fire({
      title: lang.generic.areYouSure,
      icon: "warning",
      text: lang.blocks.textDeleted,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.myExercises.textConfirmButton,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRemoveBlockInTraining(blockId)
      }
    });
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);


  return {
    handleRemoveBlock
  };
}


import { useSelector } from "react-redux";
import { trainingAPI, useGetTrainingFromAthletaQuery } from "../../api";
import { useGetMyProfile } from "../users/useGetMyProfile";
import { useEffect, useMemo } from "react";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { showAlert } from "../../helpers/Alerts";


export const useGetTrainingFromAthleta = (query) => {
  const { isPersonalTrainer, isYogaStretching } = useGetMyProfile()

  const shouldSkip = !(isPersonalTrainer() || isYogaStretching());

  const { dispatch, setTrainings, setQuery } = useSlicesActions()

  const { trainings: trainingsState } = useSlicesState()

  const memoizedQuery = useMemo(() => query, [query]);


  const { isLoading: isLoadingData, isFetching, isError, error } = useGetTrainingFromAthletaQuery((memoizedQuery), {
    skip: shouldSkip,
    refetchOnMountOrArgChange: 1800,
    refetchOnFocus: true,
    refetchOnReconnect: false,
  });


  const { data: dataGetTrainingFromAthleta } = useSelector(trainingAPI.endpoints.getTrainingFromAthleta.select(memoizedQuery));

  const trainings = dataGetTrainingFromAthleta?.trainings

  const isLoadingGetTrainingFromAthleta = isLoadingData || isFetching

  useEffect(() => {
    if (trainings) dispatch(setTrainings(trainings))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trainings])

  useEffect(() => {
    if (query) dispatch(setQuery(query))
  }, [dispatch, query, setQuery])

  useEffect(() => {
    if (isError) {
      if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    }
  }, [error, isError])

  return {
    isLoadingGetTrainingFromAthleta,
    trainingsFromAtlethe: trainingsState,
    trainingPendingFromAtlethe: trainingsState?.filter((training) => !training.isComplete),
    trainingFinishedFromAtlethe: trainingsState?.filter((training) => training.isComplete),
  }
};



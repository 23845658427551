import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Avatar, ModalFinishTraining } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { notificationsType } from "../../domain/notificationsType";
import { formatDateWithDayJS, isDocumentUpdated } from "../../helpers/formatDate";
import { Routes } from "../../routers/Routes";
import { useSlicesActions } from "../../redux/slices";
import { useDeleteNotifications } from "../../hooks";
import logoFitool from '../../assets/img/logo.png'

export const ListNotifications = ({ notification }) => {
  const { lang } = useContext(LanguageContext);
  const { dispatch, setModalFinishTrainingFeedback, setTempNotificationId, setTrainingIdSelected, setUserIdSelected, setModalViewNotifications } = useSlicesActions()
  const { handleRemoveNotifcation } = useDeleteNotifications()

  const history = useHistory();

  const { type, from, trainingId } = notification;
  const { fullProfile, name } = from;

  const idModal = `${trainingId?._id}${notification?._id}`

  const handleOpenModal = () => {
    dispatch(setTrainingIdSelected(trainingId?._id));
    dispatch(setUserIdSelected(from))
    dispatch(setModalFinishTrainingFeedback(trainingId?._id));
    dispatch(setTempNotificationId(notification?._id));
  };

  return (
    <>
      <li>
        <div className={`timeline-panel rounded p-1 ${type === notificationsType.updatesNews && "bgl-primary"}`}>
          <div className="media mr-2">
            {
              type === notificationsType.updatesNews ?
                (
                  <img alt="images" width={50} src={logoFitool} />
                ) : (
                  <Avatar
                    name={name ? name : ''}
                    img={fullProfile?.urlImage}
                    size="50px"
                  />
                )
            }

          </div>
          <div className="media-body ">
            {type === notificationsType.subscriptionReq && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1">
                  {lang.notifications.newSubscriberRequest}
                </h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  {lang.notifications.newSubscriberRequestSmall}
                </small>
              </>
            )}

            {type === notificationsType.newSubscriber && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1">{lang.notifications.newSubscriber}</h6>
                <small className="d-block text-secondary text-capitalize">
                  {name}
                </small>
              </>
            )}

            {type === notificationsType.newWorkout && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1">{lang.notifications.newWorkout}</h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  <span className="">{lang.notifications.newWorkoutSmall}</span>
                  <span className="text-primary">{formatDateWithDayJS(notification?.trainingId?.startDate)}.</span>

                </small>
              </>
            )}

            {type === notificationsType.updatedWorkout && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-info">
                  {lang.notifications.updatedTraining}
                </h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  <span className="">{lang.notifications.updatedTrainingSmall}</span>
                  <span className="text-primary text-capitalize">{notification?.trainingId?.title} </span>
                  <span className="">{lang.notifications.assignedOnDate}</span>
                  <span className="text-primary">{formatDateWithDayJS(notification?.trainingId?.startDate)}.</span>
                </small>
              </>
            )}

            {type === notificationsType.fullTraining && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-info">
                  {lang.notifications.fullTraining}
                </h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  {lang.notifications.fullTrainingSmall}
                </small>
                <span
                  className="text-primary cursor-pointer fontNotification"
                  onClick={() => handleOpenModal()}
                >
                  {lang.buttons.viewFeedBack}
                </span>
              </>
            )}

            {type === notificationsType.newCommentTraining && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1">
                  {lang.notifications.newCommentTraining}
                </h6>
                <small className="d-block text-secondary text-capitalize">
                  {trainingId?.title}
                </small>
                <span
                  className="text-primary cursor-pointer fontNotification"
                  onClick={() => handleOpenModal()}
                >
                  {lang.buttons.viewFeedBack}
                </span>
              </>
            )}

            {type === notificationsType.reqPhysiologicalData && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-info">
                  {lang.notifications.requestPhysiologicalData}
                </h6>
                <small>
                  {lang.notifications.requestPhysiologicalDataSmall}{" "}
                </small>
                <span
                  className="text-primary cursor-pointer fontNotification"
                  onClick={() => {
                    dispatch(setModalViewNotifications(false))
                    history.push(Routes.profile)
                  }}
                >
                  {lang.notifications.uploadData}
                </span>
              </>
            )}

            {type === notificationsType.reqEquipment && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-info">
                  {lang.notifications.requestEquipmentData}
                </h6>
                <small>
                  {lang.notifications.requestPhysiologicalDataSmall}{" "}
                </small>
                <span
                  className="text-primary cursor-pointer fontNotification"
                  onClick={() => {
                    dispatch(setModalViewNotifications(false))
                    history.push(Routes.profile)
                  }}
                >
                  {lang.notifications.uploadData}
                </span>
              </>
            )}

            {type === notificationsType.blockNotes && (
              <>
                {
                  isDocumentUpdated(notification?.blockId?.blockNote?.createdAt, notification?.blockId?.blockNote?.updatedAt)
                    ? (
                      <>
                        <small>{formatDateWithDayJS(notification.createdAt)}</small>
                        <h6 className="mb-1 text-info">
                          {lang.notifications.updatedNote}
                        </h6>
                        <small className="d-block">
                          <span className="text-secondary text-capitalize">{name}</span>
                          <span className="">{lang.notifications.descriptionUpdatedNote}</span>
                          <span className="text-primary text-capitalize">{notification?.blockId?.name} </span>
                          <span className="">{lang.notifications.ofTraining}</span>
                          <span className="text-primary text-capitalize">{notification?.trainingId?.title} </span>
                          <span className="">{lang.notifications.assignedOnDate}</span>
                          <span className="text-primary">{formatDateWithDayJS(notification?.trainingId?.startDate)}.</span>
                        </small>

                      </>
                    )
                    : (
                      <>
                        <small>{formatDateWithDayJS(notification.createdAt)}</small>
                        <h6 className="mb-1 text-info">
                          {lang.notifications.newNote}
                        </h6>
                        <small className="d-block">
                          <span className="text-secondary text-capitalize">{name}</span>
                          <span className="">{lang.notifications.addedANoteInTheBlock}</span>
                          <span className="text-primary text-capitalize">{notification?.blockId?.name} </span>
                          <span className="">{lang.notifications.ofTraining}</span>
                          <span className="text-primary text-capitalize">{notification?.trainingId?.title} </span>
                          <span className="">{lang.notifications.assignedOnDate}</span>
                          <span className="text-primary">{formatDateWithDayJS(notification?.trainingId?.startDate)}.</span>
                        </small>

                      </>
                    )
                }

              </>
            )}

            {type === notificationsType.updatesNews && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-info">
                  {lang.notifications.newUpdate}
                </h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  {lang.notifications.newUpdateDescription}
                </small>
                <a className="text-secondary" href="https://fitool.app/update-news" target="_black" rel="noreferrer">{lang.notifications.newUpdateSeeNews}</a>


              </>
            )}

            {type === notificationsType.endOfSubscription && (
              <>
                <small>{formatDateWithDayJS(notification.createdAt)}</small>
                <h6 className="mb-1 text-danger">
                  {lang.notifications.endOfSubscription}
                </h6>
                <small className="d-block">
                  <span className="text-secondary text-capitalize">{name}</span>
                  {lang.notifications.endOfSubscriptionSmall}
                </small>
              </>
            )}
          </div>
          <div className="ml-auto">
            {(type === notificationsType.fullTraining ||
              type === notificationsType.newCommentTraining) && (
                <Link
                  to="#"
                  className="btn btn-outline-info btn-xs sharp mr-1"
                  onClick={() => handleOpenModal()}
                >
                  <i className="fa fa-eye"></i>
                </Link>
              )}
            <Link
              to="#"
              className="btn btn-outline-danger btn-xs sharp"
              onClick={() => handleRemoveNotifcation(notification?._id)}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        </div>
      </li>

      <ModalFinishTraining
        idModal={idModal}
      />
    </>
  );
};

import React, { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Routes } from "../../routers/Routes";
import { LanguageContext } from "../../context/LanguageContext";
import { RWebShare } from "react-web-share";
import { useGetMyProfile } from "../../hooks";
import { useSlicesActions } from "../../redux/slices";

const SidebarMemo = () => {
  const { dispatch, setOpenMenuSidebarSwitch } = useSlicesActions()
  const {
    isAthlete,
    // isPersonalTrainer,
    // acountIsFreeForEver,
    // trainersContainerCreated,
    // trainingContainersComplete
  } = useGetMyProfile()

  // const showDonationButton = acountIsFreeForEver && (
  //   (isPersonalTrainer() && trainersContainerCreated >= 10) ||
  //   (isAthlete() && trainingContainersComplete >= 10)
  // );


  const { lang } = useContext(LanguageContext);

  const date = new Date().getFullYear();

  const [expandedItems, setExpandedItems] = useState({});

  const handlerCloseSidebar = () => dispatch(setOpenMenuSidebarSwitch())

  const handleMainItemClick = (itemId) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = {};
      Object.keys(prevExpandedItems).forEach((key) => {
        newExpandedItems[key] = false;
      });
      newExpandedItems[itemId] = !prevExpandedItems[itemId];
      return newExpandedItems;
    });
  };


  return (
    <div className="deznav"> {/* menu desplegado */}
      <PerfectScrollbar className="deznav-scroll">
        <section className="metismenu" id="menu">
          <li
            onClick={() => handleMainItemClick(1)}
            className={expandedItems[1] ? 'mm-active' : ''}
          >
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-networking mr-2"></i>
              <span className="nav-text">{lang.generic.dashboard}</span>
            </Link>
            <ul className={`mm-collapse ${expandedItems[1] ? 'mm-show' : ''}`}>
              <li>
                <NavLink
                  activeClassName="mm-active"
                  onClick={() => handlerCloseSidebar()}
                  to={Routes.dashboard}
                >
                  {lang.generic.dashboard}
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            onClick={() => handleMainItemClick(2)}
            className={expandedItems[2] ? 'mm-active' : ''}
          >
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-user-9 mr-2"></i>
              <span className="nav-text">{lang.generic.subscriptions}</span>
            </Link>
            <ul className={`mm-collapse ${expandedItems[2] ? 'mm-show' : ''}`}>
              <li>
                <NavLink
                  activeClassName="mm-active"
                  onClick={() => handlerCloseSidebar()}
                  to={Routes.subscriptions}
                >
                  {lang.generic.subscriptions}
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            onClick={() => handleMainItemClick(3)}
            className={expandedItems[3] ? 'mm-active' : ''}
          >
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-app mr-2"></i>
              <span className="nav-text">{lang.sidebar.nameApp}</span>
            </Link>
            <ul className={`mm-collapse ${expandedItems[3] ? 'mm-show' : ''}`}>
              {!isAthlete() && (
                <>
                  <li>
                    <NavLink
                      activeClassName="mm-active"
                      onClick={() => handlerCloseSidebar()}
                      to={Routes.createTraining}
                    >
                      {lang.generic.createWorkout}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="mm-active"
                      onClick={() => handlerCloseSidebar()}
                      to={Routes.myExercises}
                    >
                      {lang.generic.myExercises}
                    </NavLink>
                  </li>
                </>
              )}
              {isAthlete() && (
                <>
                  <li>
                    <NavLink
                      activeClassName="mm-active"
                      onClick={() => handlerCloseSidebar()}
                      to={Routes.workoutPlan}
                    >
                      {lang.generic.training}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="mm-active"
                      onClick={() => handlerCloseSidebar()}
                      to={Routes.bigCalendar}
                    >
                      {lang.generic.calendar}
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink
                  activeClassName="mm-active"
                  onClick={() => handlerCloseSidebar()}
                  to={Routes.rpe}
                >
                  {lang.RPE.title}
                </NavLink>
              </li>
            </ul>
          </li>

          <li
            onClick={() => handleMainItemClick(4)}
            className={expandedItems[4] ? 'mm-active' : ''}
          >
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-settings-2 mr-2"></i>
              <span className="nav-text">{lang.generic.config}</span>
            </Link>
            <ul className={`mm-collapse ${expandedItems[4] ? 'mm-show' : ''}`}>
              <li>
                <NavLink
                  activeClassName="mm-active"
                  onClick={() => handlerCloseSidebar()}
                  to={Routes.profile}
                >
                  {lang.generic.profile}
                </NavLink>
              </li>
              {isAthlete() && (
                <li>
                  <NavLink
                    activeClassName="mm-active"
                    onClick={() => handlerCloseSidebar()}
                    to={Routes.myMeasureDetails}
                  >
                    {lang.generic.physiologicalData}
                  </NavLink>
                </li>
              )}
            </ul>
          </li>

          <li
            onClick={() => handleMainItemClick(5)}
            className={expandedItems[5] ? 'mm-active' : ''}
          >
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-help-1 mr-1"></i>
              <span className="nav-text">{lang.generic.help}</span>
            </Link>
            <ul className={`mm-collapse ${expandedItems[5] ? 'mm-show' : ''}`}>
              {!isAthlete() ? (
                <>
                  <li>
                    <a
                      href="https://fitool.app/instructivos-entrenadores"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.instructions}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fitool.app/novedades-grupo-telegram-entrenadores"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.groupTelegram}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fitool.app/novedades-grupo-whast-app-entrenadores"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.groupWhastApp}
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <a
                      href="https://fitool.app/instructivos-atletas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.instructions}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fitool.app/novedades-grupo-telegram-atletas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.groupTelegram}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fitool.app/novedades-grupo-whast-app-atletas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {lang.generic.groupWhastApp}
                    </a>
                  </li>
                </>
              )}
              <li>
                <a
                  href="https://fitool.app/contacto-desde-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  {lang.generic.contact}
                </a>
              </li>
            </ul>
          </li>
        </section>

        {!isAthlete() && (
          <Link className="add-menu-sidebar pointer" to={Routes.createTraining} onClick={() => handlerCloseSidebar()}>
            <img src='https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244024/fitapp/other-app/dqbqgjr9rlkj47otyaiu.png' loading="lazy" alt="" className="mr-3" />
            <p className="font-w500 mb-0">{lang.buttons.createWorkouts}</p>
          </Link>
        )}

        {isAthlete() && (
          <Link className="add-menu-sidebar pointer" to={Routes.workoutPlan} onClick={() => handlerCloseSidebar()}>
            <img src='https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244024/fitapp/other-app/dqbqgjr9rlkj47otyaiu.png' loading="lazy" alt="" className="mr-3" />
            <p className="font-w500 mb-0">{lang.generic.toTrain}</p>
          </Link>
        )}

        <div className="copyright n-0">
          <RWebShare
            data={{
              text: lang.sharedApp.description,
              url: "https://fitool.app/shared-app",
              title: lang.sidebar.nameApp
            }}
            closeText={lang.buttons.close}
          >
            <button
              className={`btn btn-outline-secondary btn-block mt-2`}
            >
              <i className="fa fa-share-alt mr-2" />
              {lang.buttons.sharedApp}
            </button>
          </RWebShare>

        </div>

        {/* {
          showDonationButton &&
          <div className="copyright">
            <a
              className="btn btn-outline-info btn-block"
              style={{ marginTop: '-1.5rem' }}
              href="https://fitool.app/donations"
              target="_blank"
              rel="noreferrer">
              <i className="fa fa-heart mr-2" />
              {lang.buttons.donations}
            </a>
          </div>
        } */}

        <div className="copyright">
          <p>
            <strong>{lang.sidebar.nameApp}</strong> © {date}{" "}
            {lang.sidebar.rightsReserved}
          </p>
          <a href="https://fitool.app/info-desde-app-menu" target="_blank" rel="noreferrer">
            {lang.sidebar.version}
          </a>
        </div>


      </PerfectScrollbar>



    </div>
  );
};

export const Sidebar = React.memo(SidebarMemo);

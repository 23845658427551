import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';
import { trainingAPI } from './trainingAPI';

export const blockAPI = createApi({
    reducerPath: 'blockAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["blockAPI"],
    endpoints: (builder) => ({
        deleteExerciseInBlock: builder.mutation({
            query: ({ idExercise }) => ({
                url: `/blocks/detele-exercise-in-block/${idExercise}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idExercise, idTraining, idBlock, query }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = oldData?.trainings.map((training) => {
                            if (training._id === idTraining) {
                                return {
                                    ...training,
                                    blocksId: training.blocksId.map((block) => {
                                        if (block._id === idBlock) {
                                            return {
                                                ...block,
                                                exercisesId: block.exercisesId.filter((exercise) => exercise._id !== idExercise),
                                            };
                                        }
                                        return block;
                                    }).filter((block) => block.exercisesId.length > 0),
                                };
                            }
                            return training;
                        }).filter((training) => training.blocksId.length > 0);
                        return { ...oldData, trainings: updatedData };
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        terminateExerciseInBlock: builder.mutation({
            query: ({ idTraining, idExercise, startDate }) => ({
                url: `/exercise/terminate/${idExercise}/${idTraining}/${startDate}`,
                method: 'PUT',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idExercise, idTraining, idProfessional, query }, { dispatch, queryFulfilled }) => {
                const updateCache = (queryName, query) => {
                    const patchResult = dispatch(
                        trainingAPI.util.updateQueryData(queryName, query, (oldData) => {
                            const updatedData = Array.from(oldData?.trainings).map((training) => {
                                if (training._id === idTraining) {
                                    const updatedBlocks = training.blocksId.map((block) => {
                                        if (block.exercisesId.some((exercise) => exercise._id === idExercise)) {
                                            const updatedExercises = block.exercisesId.map((exercise) => {
                                                if (exercise._id === idExercise) {
                                                    return {
                                                        ...exercise,
                                                        isComplete: true
                                                    };
                                                }
                                                return exercise;
                                            });

                                            const isBlockComplete = updatedExercises.every((exercise) => exercise.isComplete);
                                            return {
                                                ...block,
                                                exercisesId: updatedExercises,
                                                isComplete: isBlockComplete
                                            };
                                        }
                                        return block;
                                    });

                                    const isTrainingComplete = updatedBlocks.every((block) => block.isComplete);

                                    return {
                                        ...training,
                                        blocksId: updatedBlocks,
                                        isComplete: isTrainingComplete
                                    };
                                }
                                return training;
                            });
                            return { ...oldData, trainings: updatedData };
                        }),
                    );
                    return patchResult;
                };

                const patchResult1 = updateCache('getTrainingFromProfessional', { ...query, userId: query.userId || idProfessional });
                const patchResult2 = updateCache('getMyWorkout', { ...query, userId: undefined });

                try {
                    await queryFulfilled;
                } catch {
                    patchResult1.undo();
                    patchResult2.undo();
                }
            }
        }),
        updateExerciseInBlock: builder.mutation({
            query: (dataMutation) => ({
                url: `/exercise/update/${dataMutation?.exerciseId}`,
                method: 'PUT',
                body: dataMutation?.data
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ exerciseId, trainingId, blockId, query, ...result }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    trainingAPI.util.updateQueryData('getTrainingFromAthleta', query, (oldData) => {
                        const updatedData = Array.from(oldData?.trainings).map((training) => {
                            if (training._id === trainingId) {
                                const updatedBlocks = training.blocksId.map((block) => {
                                    if (block._id === blockId) {
                                        const updatedExercises = block.exercisesId.map((exercise) => {
                                            if (exercise._id === exerciseId) {
                                                return { ...exercise, ...result?.data };
                                            }
                                            return exercise;
                                        });
                                        return { ...block, exercisesId: updatedExercises };
                                    }
                                    return block;
                                });
                                return { ...training, blocksId: updatedBlocks };
                            }
                            return training;
                        });
                        return { ...oldData, trainings: updatedData };
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        createAndUpdateBlockNote: builder.mutation({
            query: ({ dataMutation }) => ({
                url: `/block-notes/create/${dataMutation?.idBlock}`,
                method: 'POST',
                body: dataMutation?.data,
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idBlock, idTraining, idProfessional, query, ...result }, { dispatch, queryFulfilled }) => {
                const updateCache = (queryName, query) => {
                    const patchResult = dispatch(
                        trainingAPI.util.updateQueryData(queryName, query, (draftOldData) => {
                            const trainingToUpdate = draftOldData.trainings.find((training) => training._id === idTraining);
                            if (trainingToUpdate) {
                                const updatedBlocks = trainingToUpdate.blocksId.map((block) => {
                                    if (block._id === idBlock) {
                                        if (result?.dataMutation?.data?.note) {
                                            return {
                                                ...block,
                                                blockNote: {
                                                    note: result?.dataMutation?.data?.note,
                                                },
                                            };
                                        } else {
                                            return block;
                                        }
                                    }
                                    return block;
                                });

                                trainingToUpdate.blocksId = updatedBlocks;
                            }

                            return draftOldData;
                        })
                    );

                    return patchResult;
                };

                const patchResult1 = updateCache('getTrainingFromProfessional', { ...query, userId: query.userId || idProfessional });
                const patchResult2 = updateCache('getMyWorkout', { ...query, userId: undefined });

                try {
                    await queryFulfilled;
                } catch {
                    patchResult1.undo();
                    patchResult2.undo();
                }
            },
        }),



    }),
});

export const {
    useDeleteExerciseInBlockMutation,
    useTerminateExerciseInBlockMutation,
    useUpdateExerciseInBlockMutation,
    useCreateAndUpdateBlockNoteMutation
} = blockAPI;
export const getValuesInputBlocks = (block, optionName, date, activateTrainingProgressionsTemp, inputType) => {
  if (activateTrainingProgressionsTemp) {
      if (inputType === 'range') {
          return (block?.optionsTraining[optionName][date] !== undefined ? block?.optionsTraining[optionName][date] : 1)
      } else if (inputType === 'string' || inputType === 'select') {
          return (block?.optionsTraining[optionName][date] !== undefined ? block?.optionsTraining[optionName][date] : '')
      }
  } else {
      if (inputType === 'range') {
          return (block?.optionsTraining[optionName] !== undefined ? block?.optionsTraining[optionName] : 1)
      } else if (inputType === 'string' || inputType === 'select') {
          return (block?.optionsTraining[optionName] !== undefined ? block?.optionsTraining[optionName] : '')
      }
  }
};
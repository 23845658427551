import { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { Button, Card } from "react-bootstrap";
import { ListIconsForCardExercises } from "../icon/ListIconsForCardExercises";
import { ModalCreateExercise } from "../modals/ModalCreateExercise";

export const CardPreviewExercise = ({
    exercise,
    exercises,
    index,
    handleEditExerciseInTrainingCreation,
    handleCloneExercise,
    handleRemoveExercise,
    handleInputChangeForExercises,
    handleInputCheckboxForExercises,
    blockId,
    multipleTrainingDate,
    activateTrainingProgressionsTemp
}) => {

    // eslint-disable-next-line no-unused-vars
    const [isCreateExercise, setIsCreateExercise] = useState(false);
    const withEquipment = Object.values(exercise?.optionsEquipment).some(value => value === true);

    const { lang } = useContext(LanguageContext);



    return (
        <Card
            className={`text-black bgl-white border-2 border-dark flex-fill`}
            style={{
                margin: '0.5rem',
                height: 'fit-content',
            }}
        >
            <Card.Header className='p-3'>
                <Card.Title className="text-primary name-exercise-card p-0" >
                    #{index + 1}
                    {
                        (typeof exercise?.options?.repetitions === 'object'
                            ? Object.values(exercise?.options?.repetitions)[0] > 0
                            : exercise?.options?.repetitions > 0
                        ) && ` (x${typeof exercise?.options?.repetitions === 'object'
                            ? Object.values(exercise?.options?.repetitions)[0]
                            : exercise?.options?.repetitions
                        }) `
                    }
                    {` ${exercise?.name}`}
                </Card.Title>


                <Button
                    variant="outline-warning ml-2"
                    className="btn-xs"
                    onClick={() =>
                        handleEditExerciseInTrainingCreation(exercise?.idTemporalClonar, exercise)
                    }
                >
                    <i className={`fa fa-edit`} />
                </Button>

                <ModalCreateExercise
                    exercise={exercise}
                    idModal={exercise?.idTemporalClonar}
                    listExercises={exercises}
                    handleInputChangeForExercises={handleInputChangeForExercises}
                    handleInputCheckboxForExercises={handleInputCheckboxForExercises}
                    index={index}
                    blockId={blockId}
                    multipleTrainingDate={multipleTrainingDate}
                    activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                    setIsCreateExercise={setIsCreateExercise}
                />


            </Card.Header>
            <Card.Body className=" mb-0 p-3">

                <ListIconsForCardExercises exercise={exercise} lang={lang} />


                {
                    withEquipment && <p className="text-primary" style={{ fontSize: '0.8rem' }}>{lang.equipment.equipmentHasBeenSelected}</p>
                }


            </Card.Body>
            <Card.Footer>
                <div className='d-flex justify-content-between'>

                    <Button
                        variant="outline-success"
                        className="btn-xs"
                        onClick={() => handleCloneExercise(exercise?.idTemporalClonar)}
                    >
                        <i className={`fa fa-copy`} />
                    </Button>


                    <Button
                        variant="outline-danger"
                        className="btn-xs"
                        onClick={() => handleRemoveExercise(exercise?.idTemporalClonar)}
                    >
                        <i className={`fa fa-trash`} />
                    </Button>

                </div>

            </Card.Footer>
        </Card>
    )
}
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'
import { TrainingOptions } from '../trainingOptions/TrainingOptions'
import { useDeleteBlock, useGetMyProfile, useTerminateBlock, useTerminateExercise } from '../../hooks'
import { LanguageContext } from '../../context/LanguageContext'
import { useSlicesActions } from '../../redux/slices'
import { BreakTwoSVG, InfoIcon, IntensitySVG } from '../../assets/svg/svgs'
import { AlertSinLink } from '../alerts/AlertSinLink'
import { ExerciseList } from '../exercise/ExerciseList'
import { filterOptionsEquipment, optionsEquipment } from '../../assets/data/validateEquipment'
import { AccordionBlockNote } from '../accordion/AccordionBlockNote'
import { BlockNoteUpdate } from '../BlockNoteUpdate/BlockNoteUpdate'

export const Blocks = ({ blocks, training, userEquipment, setBlockToEdit }) => {
    const { lang } = useContext(LanguageContext);
    const { isAthlete } = useGetMyProfile()
    const { dispatch,
        setExerciseSelected,
        setModalViewVideo,
        setModalCreateExercise,
        setBlockIsBeingEditedTrue,
    } = useSlicesActions()

    const { handleRemoveBlock } = useDeleteBlock()
    const { handleTerminateBlockInTraining } = useTerminateBlock()
    const { handleTerminateExerciseInBlock } = useTerminateExercise()

    const handleUpdateExercise = (idExercise, exercise) => {
        dispatch(setExerciseSelected({
            ...exercise,
            optionsEquipment: exercise?.optionsEquipment ? exercise?.optionsEquipment : optionsEquipment,
            options: {
                ...exercise.options,
                isUsingMinutes: exercise.options.isUsingMinutes ? exercise.options.isUsingMinutes : false,
                hearRateInfo: exercise.options.hearRateInfo === 0 ? 30 : exercise.options.hearRateInfo,
                kmInfo: exercise.options.kmInfo === 0 ? 1 : exercise.options.kmInfo,
                cadenceInfo: exercise.options.cadenceInfo === 0 ? 1 : exercise.options.cadenceInfo,
                metersInfo: exercise.options.metersInfo === 0 ? 1 : exercise.options.metersInfo,
                powerInfo: exercise.options.powerInfo === 0 ? 1 : exercise.options.powerInfo,
                series: exercise.options.series === 0 ? 1 : exercise.options.series,
                breakInfo: exercise.options.breakInfo === 0 ? 1 : exercise.options.breakInfo,
                rhythmInfo: exercise.options.rhythmInfo === 0 ? 1 : exercise.options.rhythmInfo,
                toTheRuling: exercise.options.toTheRuling ? exercise.options.toTheRuling : false,

            }
        }))
        dispatch(setModalCreateExercise(idExercise));

    };

    const [activeAccordionDinamicBlocks, setActiveAccordionDinamicBlocks] = useState(0);
    const [activeBlockNote, setActiveBlockNote] = useState("");
    const [isEditBlocknote, setIsEditBlocknote] = useState(false)


    useEffect(() => {
        const firstIncompleteBlockIndex = blocks.findIndex(block => !block.isComplete);
        if (firstIncompleteBlockIndex !== -1) {
            setActiveAccordionDinamicBlocks(firstIncompleteBlockIndex);
        }
    }, [blocks]);

    return (
        <>
            <Accordion
                className="accordion accordion-rounded-stylish accordion-bordered col-12 p-0"
                activeKey={`${activeAccordionDinamicBlocks}`}
            >
                <div className="d-flex flex-column" style={{ gap: '1rem' }}>
                    {
                        blocks?.map((block, index) => (
                            <div
                                className="col-12"
                                key={block._id}
                            >
                                <Card.Header
                                    className={`${block.isComplete
                                        ? "border-success"
                                        : "border-warning"
                                        }
                                        d-flex flex-row justify-content-between align-items-center pt-3 pb-3
                                        `}
                                    style={{
                                        border: `2px solid`,
                                        borderRadius: '1rem',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        borderBottomLeftRadius: activeAccordionDinamicBlocks === index ? '0px' : '1rem',
                                        borderBottomRightRadius: activeAccordionDinamicBlocks === index ? '0px' : '1rem',
                                    }}>
                                    <div
                                        onClick={() =>
                                            setActiveAccordionDinamicBlocks(
                                                activeAccordionDinamicBlocks === index ? -1 : index
                                            )
                                        }
                                    >
                                        <Card.Title className="text-primary">
                                            #{index + 1} {block.name} {!training?.isSimpleMode && <TrainingOptions optionsTraining={block?.optionsTraining} lang={lang} />}
                                        </Card.Title>
                                    </div>

                                    <div>
                                        {!block.isComplete && !isAthlete() && (
                                            <div className='mr-3'>
                                                <Button
                                                    as="a"
                                                    variant="warning disabled"
                                                    href="#"
                                                    className="btn-card btn-xs"
                                                >
                                                    <i className={`fa fa-exclamation-circle mr-2`} />
                                                    {lang.generic.pending}
                                                </Button>

                                                <Button
                                                    as="a"
                                                    variant="warning ml-4"
                                                    href="#"
                                                    className="btn-xs"
                                                    onClick={() => {
                                                        dispatch(setBlockIsBeingEditedTrue())
                                                        setBlockToEdit(block)
                                                    }}
                                                >
                                                    <i className={`fa fa-edit`} />{" "}
                                                </Button>

                                                <Button
                                                    as="a"
                                                    variant="danger ml-2"
                                                    href="#"
                                                    className="btn-xs"
                                                    onClick={() =>
                                                        handleRemoveBlock(block._id)
                                                    }
                                                >
                                                    <i className={`fa fa-trash`} />{" "}
                                                </Button>
                                            </div>
                                        )}


                                        {block.isComplete && !isAthlete() && (
                                            <div className='mr-3'>
                                                <Button
                                                    as="a"
                                                    variant="light disabled"
                                                    href="#"
                                                    className="btn-card btn-xs"
                                                >
                                                    <i
                                                        className={`fa fa-check mr-2`}
                                                    />
                                                    {lang.buttons.full}
                                                </Button>
                                            </div>
                                        )}

                                        {!block.isComplete && isAthlete() && (
                                            <div className='mr-3'>
                                                <Button
                                                    as="a"
                                                    variant="warning"
                                                    href="#"
                                                    className="btn-xs"
                                                    onClick={() =>
                                                        handleTerminateBlockInTraining(block._id, block.trainingId, block.idPersonalTrainer)
                                                    }
                                                >
                                                    <i className={`fa fa-check mr-1`} />{" "}
                                                    {lang.buttons.toComplete}
                                                </Button>
                                            </div>
                                        )}
                                        {block.isComplete && isAthlete() && (
                                            <div className='mr-3'>
                                                <Button
                                                    as="a"
                                                    variant="success disabled"
                                                    href="#"
                                                    className="btn-xs"
                                                >
                                                    <i className={`fa fa-check mr-1`} />{" "}
                                                    {lang.buttons.full}
                                                </Button>
                                            </div>
                                        )}


                                        <Accordion.Toggle
                                            as={Card.Text}
                                            eventKey={`${index}`}
                                            onClick={() =>
                                                setActiveAccordionDinamicBlocks(
                                                    activeAccordionDinamicBlocks === index ? -1 : index
                                                )
                                            }
                                        >
                                            <span
                                                className="accordion__header--indicator h4 cursor-pointer"
                                            // style={{ marginRight: '4rem', fontWeight: 'bold' }}
                                            >{activeAccordionDinamicBlocks === index
                                                ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                                                : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                                                }</span>

                                        </Accordion.Toggle>
                                    </div>

                                </Card.Header>

                                <Accordion.Collapse
                                    eventKey={`${index}`}
                                    className={`accordion__body border-2 ${block.isComplete
                                        ? "border-success"
                                        : "border-warning"
                                        }`}
                                >
                                    <Card.Body className=" mb-0">
                                        <div className="d-flex flex-row justify-content-start align-items-center rounded" style={{ gap: '2rem' }}>
                                            {block?.optionsTraining?.breakRecovery && block?.optionsTraining?.breakInfo >= 1 && !training?.isSimpleMode && (
                                                <p>
                                                    <BreakTwoSVG color="orange" widthProp={"20px"} heightProp={"20px"} /> {lang.blocks.pauseAtTheEndOfTheBlock}
                                                    {block?.optionsTraining?.breakInfo} {block?.optionsTraining?.isUsingMinutes ? lang.generic.minutes : lang.generic.seconds}
                                                </p>
                                            )}
                                            {
                                                block?.optionsTraining?.blockIntensity && !training?.isSimpleMode &&
                                                <p><IntensitySVG color={"red"} widthProp={"30px"} heightProp={"30px"} />{lang.generic.intensity}: <span className={`
                            ${block?.optionsTraining?.blockIntensity === "low" && "text-success"}
                            ${block?.optionsTraining?.blockIntensity === "half" && "text-warning"}
                            ${block?.optionsTraining?.blockIntensity === "high" && "text-danger"}
                            `}
                                                >{lang.generic[block?.optionsTraining?.blockIntensity]}</span></p>
                                            }
                                        </div>
                                        {block.description && !training?.isSimpleMode && (
                                            <>
                                                <AlertSinLink
                                                    variant={"light"}
                                                    Icon={InfoIcon}
                                                    text={block.description}
                                                />
                                            </>
                                        )}

                                        <ExerciseList
                                            exercises={block?.exercisesId}
                                            training={training}
                                            handleUpdateExercise={handleUpdateExercise}
                                            isAthlete={isAthlete}
                                            dispatch={dispatch}
                                            setModalViewVideo={setModalViewVideo}
                                            handleTerminateExerciseInBlock={handleTerminateExerciseInBlock}
                                            userEquipment={userEquipment}
                                            lang={lang}
                                            filterOptionsEquipment={filterOptionsEquipment}
                                            block={block}
                                            trainingIsSimpleMode={training?.isSimpleMode}
                                        />
                                        {
                                            !isAthlete() && block?.blockNote && block?.blockNote?.note !== "" &&
                                            <AccordionBlockNote
                                                activeBlockNote={activeBlockNote}
                                                blockId={block._id}
                                                setActiveBlockNote={setActiveBlockNote}
                                                setIsEditBlocknote={setIsEditBlocknote}
                                                blockNote={block?.blockNote?.note}
                                                training={training}
                                            />
                                        }
                                        {
                                            isAthlete() && block?.blockNote && block?.blockNote?.note !== "" && !isEditBlocknote &&
                                            <AccordionBlockNote
                                                activeBlockNote={activeBlockNote}
                                                blockId={block._id}
                                                setActiveBlockNote={setActiveBlockNote}
                                                setIsEditBlocknote={setIsEditBlocknote}
                                                blockNote={block?.blockNote?.note}
                                                training={training}
                                            />
                                        }

                                        {
                                            // eslint-disable-next-line no-mixed-operators
                                            (isAthlete() && !block?.blockNote || isEditBlocknote) &&
                                            <BlockNoteUpdate
                                                block={block}
                                                setIsEditBlocknote={setIsEditBlocknote}
                                                training={training}
                                            />
                                        }


                                    </Card.Body>
                                </Accordion.Collapse>
                            </div>
                        ))
                    }
                </div>
            </Accordion >
        </>
    )
}
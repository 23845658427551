import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Messages, Spinner } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { AlertSinLink } from "../alerts/AlertSinLink";
import { InfoIcon } from "../../assets/svg/svgs";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { useCreateAndUpdateFeedback, useGetFeedBackTraining, useGetMyProfile } from "../../hooks";
import { animateScroll } from "react-scroll";


export const ModalFinishTraining = ({ idModal }) => {
  const { lang } = useContext(LanguageContext);
  const { isAthlete } = useGetMyProfile()
  const { modalFinishTrainingFeedback, tempNotificationId, isEditMessage, uid } = useSlicesState()
  const { dispatch, setModalFinishTrainingFeedback, setModalFinishTrainingRPE } = useSlicesActions()

  const { messages, isLoading } = useGetFeedBackTraining()

  const {
    handleCloseModal,
    sendMessage,
    message,
    handleFormCreateFeedback,
    isDisabled,
    handleUpdateFeedBackMessage,
  } = useCreateAndUpdateFeedback();

  useEffect(() => {
    animateScroll.scrollToBottom({
      containerId: "DZ_W_TimeLine",
      duration: 100
    });
  }, [messages]);

  return (
    <>
      <Modal
        className="fade bd-example-modal-md"
        show={modalFinishTrainingFeedback === idModal || modalFinishTrainingFeedback + tempNotificationId === idModal}
        size="md"
        backdropClassName="modalFinishTrainingMessage"
      >
        <Modal.Header>
          <Modal.Title className="col-11">
            {lang.modalFinishTraining.title}
          </Modal.Title>

          <Button
            variant=""
            className="close"
            onClick={() => handleCloseModal()}
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="test_450">

          {(messages?.length === 0) &&

            <AlertSinLink
              variant={"secondary"}
              className={"position-absolute mr-4"}
              Icon={InfoIcon}
              msg={lang.alerts.info}
              text={!isAthlete() ? lang.modalFinishTraining.alertWithoutFeedbackProfessional : lang.modalFinishTraining.alertWithoutFeedbackAthelte}
            />

          }

          {
            isLoading ? (
              <Spinner />
            ) : (
              <PerfectScrollbar
                style={{ height: "250px" }}
                id="DZ_W_TimeLine"
                className="widget-timeline dz-scroll height370 ps ps--active-y"
              >
                <ul className="timeline">
                  {messages?.length > 0 &&
                    messages?.map((message) => (
                      <Messages
                        key={message._id}
                        message={message.message}
                        date={message.createdAt}
                        iAm={message.from === uid}
                        idMessage={message._id}
                        idTraining={idModal}
                      />
                    ))}
                </ul>
              </PerfectScrollbar>
            )
          }


          <form onSubmit={sendMessage}>
            <div className={`form-group mt-5`}>
              <textarea
                className="form-control"
                rows="4"
                value={message || ""}
                name="message"
                onChange={handleFormCreateFeedback}
                placeholder={lang.modalFinishTraining.placeholder}
              ></textarea>
            </div>
          </form>

        </Modal.Body>

        <Modal.Footer>
          {isAthlete() &&
            <Button
              variant="info light"
              onClick={() => {
                dispatch(setModalFinishTrainingFeedback(null))
                dispatch(setModalFinishTrainingRPE(modalFinishTrainingFeedback))
              }}
              className="btn-md"
            >
              {lang.buttons.selectOtherRPE}
            </Button>
          }

          <Button
            variant="danger light"
            onClick={() => handleCloseModal()}
            className="btn-md"
          >
            {lang.buttons.close}
          </Button>

          {!isEditMessage ? (
            <Button
              as="a"
              variant={`${isDisabled ? "light disabled" : "success light"}`}
              onClick={() => sendMessage()}
              className="btn-md"
            >
              {lang.modalFinishTraining.buttonSendComentary}
            </Button>
          ) : (
            <Button
              as="a"
              variant={`${isDisabled ? "light disabled" : "secondary light"}`}
              onClick={() => handleUpdateFeedBackMessage()}
              className="btn-md"
            >
              {lang.buttons.update}
            </Button>
          )}
        </Modal.Footer>


      </Modal>
    </>
  );
};

import Swal from "sweetalert2";
import { API } from "../api/api";

export const forgotPassword = async (email) => {
  try {
    const resp = await API.patch("/auth/forgot-password", { email });

    if (resp.data) {
      Swal.fire({
        title: "Perfecto 💪",
        text: "Revise su Correo Electrónico",
        icon: "success",
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Perfecto, gracias",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location = "/auth/login";
        }
      });
    }
  } catch (error) {
    Swal.fire("Oops 😥", error.response.data.msg, "error");
  }
};

export const resetPassword = async (newPassword, resetLink) => {
  try {
    const resp = await API.put(`/auth/reset-password?token=${resetLink}`, {
      newPassword
    });

    if (resp.data) {
      Swal.fire({
        title: "Perfecto 💪",
        icon: "success",
        text: resp.data.msg,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Perfecto, gracias",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location = "/auth/login";
        }
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      Swal.fire({
        title: "Oops 😥",
        icon: "error",
        text: 'Vuelva a solicitar una nueva contraseña',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Perfecto, gracias",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location = "/auth/forgot-password";
        }
      });
    } else {
      Swal.fire("Oops 😥", error.response.data.msg, "error");
    }
  }
};

import { useGetMyExercisesQuery } from "../../api";
import { useStateRedux } from "../useStateAPI";

export const useGetMyexercises = () => {

    const { isLoading: isLoadingData, isFetching } = useGetMyExercisesQuery(undefined, {
        refetchOnMountOrArgChange: 3800 * 2,
        refetchOnFocus: false,
        refetchOnReconnect: false,
    });

    const { dataGetMyExercise } = useStateRedux()

    const myExercises = dataGetMyExercise?.exercises
    const totalCountMyExercise = dataGetMyExercise?.totalCount
    const totalCountExercisesFItool = dataGetMyExercise?.totalCount
    const ok = dataGetMyExercise?.ok
    const msgCode = dataGetMyExercise?.msgCode
    const isLoading = isLoadingData || isFetching 

    return {
        myExercises,
        totalCountMyExercise,
        totalCountExercisesFItool,
        ok,
        msgCode,
        isLoading
    };
};



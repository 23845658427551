import React from "react";
import { roles } from "../../domain/roles";
import { LottieComponent } from "../Lottie/LottieComponent";

export const CardRole = ({ animation, title, description, role, fun }) => {

  const style ={
    height: '100px',
    cursor: role === roles.nutritionist && 'auto'
  }

  return (
    <>
      {/* Card Perfil */}
      <div
        onClick={role === roles.nutritionist ? null : () => fun()}
        className={`media border rounded py-3 cajagrid ${
          role === roles.nutritionist && "cajagrid_disabled"
        }`}
      >
        <LottieComponent icon={animation} style={style}/>
        <div className="pr-3 mr-auto media-body">
          <h6 className="fs-16 font-w600 mb-0 text-black text-center">
            {title}
          </h6>
          <p className="fs-12 text-center">{description}</p>
        </div>
      </div>
    </>
  );
};

const notificationCodes = {
    GEN0001: 'Internal Server Error',
    GEN0002: 'Datos guardado correctamente',
    GEN0003: 'Datos actualizado correctamente',
    GEN0004: 'Datos eliminado correctamente',
    GU0001: 'No se permiten más registros por el momento, ¡contáctanos!',
    GU0002: 'No tienes permiso para realizar esta acción',
    GU0003: 'Llegaste al límite de suscripciones',
    ST0001: 'Token no válido',
    ST0002: 'Usuario Inactivo, póngase en contacto',
    SE0001: 'Un correo ha sido enviado con instrucciones para restablecer su contraseña.',
    SE0002: 'Su contraseña ha sido actualizada correctamente. Pruebe Iniciar Sesión',
    SE0003: 'Intento de cambio de contraseña con el campo resetLInk Erroneo',
    SE0004: 'Solicitud enviada correctamente.',
    SE0005: 'Entrenamiento sin Feedback',
    SE0006: 'No se ha encontrado el Recurso',
    SE0007: 'Se han activado la/las suscripciones pero con errores.',
    SE0008: 'Se ha activado la/las suscripciones.',
    SE0009: 'Se han finalizado la/las suscripciones pero con errores.',
    SE0010: 'Se han finalizado la/las suscripciones.',
    SE0011: 'Actualmente no tienes Suscripciones.',
    SE0012: 'Usted ya posee una suscripción con este Profesional, pero la misma está desactivada, Dígale a Entrenador que vuelva a activar la misma',
    SE0013: 'Ya hay una suscripción pendiente, tu atleta a creado la solicitud, revise su panel de control.',
    SE0014: 'Usted ya ha invitado a este usuario, le enviaremos un nuevo correo nuevamente para informale sobre su invitación.',
    SE0015: 'Usted ya posee una suscripción activa con el usuario seleccionado.',
    SE0016: 'Usted ya posee una suscripción con el usuario seleccionado, pero la misma está desactivada, vuelva a activarla desde su Panel de Control.',
    SE0017: 'La suscripción ya está activa o reactivada.',
    SE0018: 'La suscripción ya está finalizada.',
    SE0019: 'User-Card No encontrado',
    SE0020: 'Usuario no tiene User-Card',
    SE0021: 'Imágen de Perfil Actualizada.',
    SE0022: 'Compruebe sus datos',
    SE0023: 'Compruebe sus datos',
    SE0024: 'Perfil con Role no admitido para búsquedas',
    SE0025: 'Pruebe con otro correo electrónico',
    SE0026: 'Actualmente no tienes Ejercicios en My Exercise',
    SE0027: 'Se han creado los Entrenamientos pero con errores, solo para algunos Atletas.',
    SE0028: 'Error al crear un Entrenamiento',
    SE0029: 'Usted ya marcó el entrenamiento como completado, no puede actualizar el comentario.',
    SE0030: 'Entrenamiento ya fue Marcado como finalizado',
    SE0031: 'Aún te quedan Ejercicios sin completar',
    SE0032: 'Error al crear los entrenamientos',
    SE0033: 'Usuario no tiene datos de Entrenamientos',
    SE0034: 'Error al Actualizar la Imágen',
    SE0035: 'Prueba con otro Chat-Id de Telegram',
    SE0036: 'Error al Actualizar el Entrenamiento',
    SE0037: 'Aun te quedan Blocks sin completar',
    SE0038: 'Entrenamiento Completado',
    SE0039: 'Entrenamiento Creado',
    UT0001: 'No tienes permisos para realizar esta acción',
  };
  
  export const getNotificationMessage = (code) => {
    if (notificationCodes[code]) {
      return notificationCodes[code];
    } else {
      return 'Ha Ocurrido un Error';
    }
  }

  
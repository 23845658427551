import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import logo from "../assets/img/logo.png";
import logoText from "../assets/img/logo-text.png";

export const Update = ({ actualVersion, newVersion }) => {

  const handleUpdateClick = () => {
    if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'UPDATE_CACHE' });
    }
  }

  useEffect(() => {
    function handleMessage(event) {
      if (event.data && event.data.type === 'CACHE_UPDATED') {
        window.location.reload();
      }
    }

    navigator.serviceWorker.addEventListener('message', handleMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <div className="mt-5">
        <PerfectScrollbar className="h-100">
          <div className="container h-100 ">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-6 ">
                <div className="text-center mb-5">
                  <img src={logo} alt="logo icono fitool" />
                  <img src={logoText} alt="logo text fitool" />
                </div>
                <div className="card border border-info overflow">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form">
                        <h4 className="text-center mb-4 text-black">
                          Nueva Actualización Disponible 💪
                        </h4>


                        <div className="new-account mt-3">
                          <p className="text-center text-black">
                            Preciona el botón Actualizar para disponer de las últimas novedades de FiTool.
                          </p>
                        </div>
                        <Button
                          onClick={handleUpdateClick}
                          className="btn-lg btn-block"
                          variant="info btn-lg btn-block"
                        >
                          Actualizar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <p className="text-black">
                    Versión Actual: {actualVersion} | Nueva Versión: {newVersion}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { RpeMessage } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { rpeData } from "../../assets/data/rpe";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export const ModalSelectRPE = ({idModal}) => {
  const { lang } = useContext(LanguageContext);
  const { modalFinishTrainingRPE } = useSlicesState()
  const { dispatch, setModalFinishTrainingRPE } = useSlicesActions()

  return (
    <>
      <Modal
        className="fade bd-example-modal-md"
        show={modalFinishTrainingRPE === idModal}
        size="md"
        backdropClassName="modalRPE"
      >
        <Modal.Header>
          <Modal.Title className="col-11">
            {lang.modalFinishTraining.title}
          </Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => dispatch(setModalFinishTrainingRPE(null))}
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body className="test_450">
          <PerfectScrollbar
            style={{ height: "400px" }}
            className="widget-timeline dz-scroll height370 ps ps--active-y"
          >
            <div>
              <h5>{lang.modalFinishTraining.HowDidYouFeel}</h5>
              {
                rpeData?.map((rpe) => (
                  <RpeMessage
                    key={rpe.number}
                    number={rpe.number}
                    title={rpe.title}
                    description={rpe?.description}
                    color={rpe.color}
                  />
                ))
              }
            </div>
          </PerfectScrollbar>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch(setModalFinishTrainingRPE(null))}
            className="btn-md"
          >
            {lang.buttons.close}
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

import React from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";

import "../../css/select-search.css";

export const SelectSearchSingleMultiple = ({
  multiple,
  search,
  placeholder,
  values,
  setValues,
  data = [],
  closeOnSelect,
}) => {

  return (
    <>
      <div>
        <SelectSearch
          multiple={multiple}
          printOptions={"on-focus"}
          closeOnSelect={closeOnSelect}
          options={data}
          value={values}
          onChange={setValues}
          search={search}
          filterOptions={fuzzySearch}
          placeholder={placeholder}
          //renderOption={(...options) => <Test test={options[1]} />}
        />
      </div>
    </>
  );
};

/*
printOptions 	string 	auto 	Can be: auto, always, never, on-focus. 
This property controls when the options list should be rendered.
*/

import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const feedbackTrainingAPI = createApi({
    reducerPath: 'feedbackTrainingAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["feedbackTrainingAPI"],
    endpoints: (builder) => ({
        getFeedBackForTraining: builder.query({
            query: ({ trainingId }) => `/feedback-training/find/${trainingId}?limit=500&offset=0`, //FIXME: Paginación
            providesTags: ["feedbackTrainingAPI"]
        }),
        createFeedBack: builder.mutation({
            query: ({ idTo, idTraining, message }) => ({
                url: `/feedback-training/create/${idTo}/${idTraining}`,
                method: 'POST',
                body: message
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idTraining }, { dispatch, queryFulfilled }) => {
                const { data: resultCreateFeedBack } = await queryFulfilled;
                const patchResult = dispatch(
                    feedbackTrainingAPI.util.updateQueryData('getFeedBackForTraining', { trainingId: idTraining }, (oldData) => {
                        return {
                            ...oldData,
                            messages: [...oldData?.messages, resultCreateFeedBack?.feedBackTraining],
                            totalCount: oldData?.totalCount !== null && oldData?.totalCount !== undefined ? oldData.totalCount + 1 : 1
                        }
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        updateFeedBackMessage: builder.mutation({
            query: ({ idFeedBack, message }) => ({
                url: `/feedback-training/update/${idFeedBack}`,
                method: 'PUT',
                body: message
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idFeedBack, idTraining }, { dispatch, queryFulfilled }) => {
                const { data: resultCreateFeedBack } = await queryFulfilled;
                const patchResult = dispatch(
                    feedbackTrainingAPI.util.updateQueryData('getFeedBackForTraining', { trainingId: idTraining }, (oldData) => {
                        const updatedData = Array.from(oldData?.messages).map((message) =>
                            message._id === idFeedBack ? { ...message, ...resultCreateFeedBack?.feedBackTraining } : message
                        );
                        return { ...oldData, messages: updatedData };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        deteleFeedBackMesasge: builder.mutation({
            query: ({ idFeedBack }) => ({
                url: `/feedback-training/delete/${idFeedBack}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async ({ idFeedBack, idTraining }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    feedbackTrainingAPI.util.updateQueryData('getFeedBackForTraining', { trainingId: idTraining }, (oldData) => {
                        const updatedData = oldData?.messages?.filter((message) => message._id !== idFeedBack);
                        return {
                            ...oldData,
                            messages: updatedData,
                            totalCount: oldData?.totalCount && oldData.totalCount - 1
                        };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const {
    useLazyGetFeedBackForTrainingQuery,
    useCreateFeedBackMutation,
    useUpdateFeedBackMessageMutation,
    useDeteleFeedBackMesasgeMutation
} = feedbackTrainingAPI;
const pathLogin = "/auth/";
const pathApp = "/app/";
const pathError = "/error/";

export const Routes = {
  //Publics
  preSignup: `${pathLogin}pre-signup`,
  signup: `${pathLogin}signup`,
  login: `${pathLogin}login`,
  forgotPassword: `${pathLogin}forgot-password`,
  resetPassword: `${pathLogin}reset-password`,

  //Private
  dashboard: `${pathApp}dashboard`,
  profile: `${pathApp}profile`,
  //profileDetails: `${pathApp}profile-details`,
  myGym: `${pathApp}my-gyms`,
  myTeams: `${pathApp}my-teams`,
  myMeasureDetails: `${pathApp}measure-details`,
  measureDetailsOtherUser: `${pathApp}measure-details-other-user`,
  subscriptions: `${pathApp}subscriptions`,
  workoutPlan: `${pathApp}workout-plan`,
  createTraining: `${pathApp}create-training`,
  myExercises: `${pathApp}exercises`,
  bigCalendar: `${pathApp}big-calendar`,
  rpe: `${pathApp}rpe-example`,
  
  //private and hidden
  profileSuscriptor: `${pathApp}profile-suscriptor`, //ver perfil de suscriptor
  suscriptorMeasureDetails: `${pathApp}suscriptor-measure-details`, //ver datos fisiologicos de atleta
  viewWorkoutPlanSuscriptor: `${pathApp}workout-plan-suscriptor`, //ver entrenamiento de suscriptor
  searchDetails: `${pathApp}search-details`, //resultado de busqueda
  userCard: `${pathApp}user-card`,

  //Others
  error404: `${pathError}404`,
};

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { useCreatePhysiologicalData } from "../../hooks";

export const CardMeasure = ({ img }) => {
  const { lang } = useContext(LanguageContext);

  const [activeToggleMeasure, setActiveToggleMeasure] = useState("medidas");

  const {
    handleCreatePhysiologicalData,
    weight,
    height,
    armMeasure,
    chestMeasure,
    waistMeasure,
    adbomenMeasure,
    buttocksMeasure,
    hipMeasure,
    legMeasure,
    handleFormMeasureValues
  } = useCreatePhysiologicalData()

  const isDisabled = weight === 0 || height === 0 ;

  return (
    <>
      <div>
        <div className="card">
          <div className="card-body">
            <div className="profile-tab">
              <div className="custom-tab-1">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    onClick={() => setActiveToggleMeasure("medidas")}
                  >
                    <Link
                      to="#medidas"
                      data-toggle="tab"
                      className={`nav-link ${
                        activeToggleMeasure === "medidas" ? "active show" : ""
                      }`}
                    >
                      {lang.profile.athlete.measures}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#profile-settings"
                      data-toggle="tab"
                      onClick={() => setActiveToggleMeasure("comoMedir")}
                      className={`nav-link ${
                        activeToggleMeasure === "comoMedir" ? "active show" : ""
                      }`}
                    >
                      {lang.generic.indications}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    id="medidas"
                    className={`tab-pane fade ${
                      activeToggleMeasure === "medidas" ? "active show" : ""
                    }`}
                  >
                    <div className="pt-3">
                      <div className="settings-form">
                        <h4 className="text-primary">
                          {lang.profile.athlete.titleMeasures}
                        </h4>
                        <p className="text-muted">
                          {lang.profile.athlete.descriptionMeasures}
                        </p>

                        <form autoComplete="off" onSubmit={handleCreatePhysiologicalData}>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>{lang.generic.height}</label>
                              <input
                                type="number"
                                name="height"
                                className="form-control"
                                placeholder="min 50"
                                value={height}
                                onChange={handleFormMeasureValues}
                                min={50}
                                max={300}
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>{lang.generic.weightInKg}</label>
                              <input
                                type="number"
                                name="weight"
                                placeholder="min 30"
                                value={weight}
                                onChange={handleFormMeasureValues}
                                className="form-control"
                                min={30}
                                max={250}
                                required
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>{lang.generic.arm}</label>
                              <input
                                type="number"
                                name="armMeasure"
                                className="form-control"
                                placeholder="0"
                                min={0}
                                value={armMeasure}
                                onChange={handleFormMeasureValues}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>{lang.generic.chest}</label>
                              <input
                                type="number"
                                name="chestMeasure"
                                placeholder="0"
                                min={0}
                                value={chestMeasure}
                                onChange={handleFormMeasureValues}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>{lang.generic.waist}</label>
                              <input
                                type="number"
                                name="waistMeasure"
                                className="form-control"
                                placeholder="0"
                                min={0}
                                value={waistMeasure}
                                onChange={handleFormMeasureValues}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>{lang.generic.abdomen}</label>
                              <input
                                type="number"
                                name="adbomenMeasure"
                                placeholder="0"
                                min={0}
                                value={adbomenMeasure}
                                onChange={handleFormMeasureValues}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>{lang.generic.buttocks}</label>
                              <input
                                type="number"
                                name="buttocksMeasure"
                                className="form-control"
                                placeholder="0"
                                min={0}
                                value={buttocksMeasure}
                                onChange={handleFormMeasureValues}
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label>{lang.generic.hip}</label>
                              <input
                                type="number"
                                name="hipMeasure"
                                placeholder="0"
                                min={0}
                                value={hipMeasure}
                                onChange={handleFormMeasureValues}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label>{lang.generic.leg}</label>
                              <input
                                type="number"
                                name="legMeasure"
                                className="form-control"
                                placeholder="0"
                                min={0}
                                value={legMeasure}
                                onChange={handleFormMeasureValues}
                              />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <button
                                className="btn btn-warning btn-block"
                                type="submit"
                                disabled={isDisabled}
                              >
                                {lang.buttons.save}
                              </button>
                            </div>
                            <div className="form-group col-md-6">
                              <Link
                                to={Routes.myMeasureDetails}
                                className="btn btn-info btn-block"
                              >
                                {lang.buttons.history}
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div
                    id="profile-settings"
                    className={`tab-pane fade ${
                      activeToggleMeasure === "comoMedir" ? "active show" : ""
                    }`}
                  >
                    <h4 className="text-primary pt-3">
                      {lang.profile.athlete.howToMeasure}
                    </h4>

                    <p className="text-muted">
                      {lang.profile.athlete.howToMeasureDetails}
                    </p>

                    <img src={img} className="img-fluid" alt="Como Medir" loading="lazy"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

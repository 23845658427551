import React, { createContext, useState } from "react";

import es from "../assets/lang/es.json";
import en from "../assets/lang/en.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState(es);

  const changeLanguage = (lang) => {
    if (lang === "es") setLang(es);
    if (lang === "en") setLang(en);
  };

  return (
    <LanguageContext.Provider
      value={{
        lang,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { CardRole, LogoWraper } from "../components";
import personalTrainer from "../assets/animations/personalTrainer.json";
import atlethe from "../assets/animations/athlete.json";
import stretching from "../assets/animations/yoga.json";
import nutricionist from "../assets/animations/nutricionist.json";
import { LanguageContext } from "../context/LanguageContext";
import { roles } from "../domain/roles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { setRolePreSigNup, useSlicesActions } from "../redux/slices";

const CardRoles = () => {
  const history = useHistory();
  const { dispatch } = useSlicesActions()
  const { lang } = useContext(LanguageContext);

  const profileData = [
    {
      animation: personalTrainer,
      title: lang.roles.personalTrainer,
      description: lang.roles.descriptionPersonalTrainer,
      role: roles.personalTrainer,
    },
    {
      animation: atlethe,
      title: lang.roles.athlete,
      description: lang.roles.descriptionAthlete,
      role: roles.athlete,
    },
    {
      animation: stretching,
      title: lang.roles.yogaStretching,
      description: lang.roles.descriptionyogaStretching,
      role: roles.yogaStretching,
    },
    {
      animation: nutricionist,
      title: lang.roles.nutritionist,
      description: lang.roles.descriptionNutritionist,
      role: roles.nutritionist,
    },
  ];

  const handlePreSigNup = (role) => {
    dispatch(setRolePreSigNup(role));
    history.push(Routes.signup);
  };

  return profileData.map((perfil, i) => (
    <CardRole
      key={i}
      animation={perfil.animation}
      title={perfil.title}
      description={perfil.description}
      role={perfil.role}
      fun={() => handlePreSigNup(perfil.role)}
    />
  ));
};

export const PreSignup = () => {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="mt-5">
      <PerfectScrollbar className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <LogoWraper />
              <div className="card border border-primary">
                <div className="card-header d-flex border-0 pb-2 flex-column">
                  <h4 className="text-center mb-4 text-black">
                    {lang.preSignup.title}
                  </h4>
                </div>
                <div
                  className="card-body loadmore-content dz-scroll pb-0 pt-0  ps ps--active-y gridPresignup"
                  id="TrendingIngridientsContent"
                >
                  <CardRoles />
                </div>
                <div className="card-header d-sm-flex d-block border-0 pb-4">
                  <div className="mr-auto pr-3">
                    <div className="new-account mt-3  ">
                      <p className="text-black">
                        {lang.preSignup.footer}
                        <Link className="text-primary" to={Routes.login}>
                          {lang.generic.login}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

import { useContext, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { ValidateEquipment } from "../../assets/data/validateEquipment";
import { DynamicForm } from "../form/DynamicFormEquipment";
import { LanguageContext } from "../../context/LanguageContext";

export const AccordionComponent = ({ item, dynamicFormValues, handleDynamicFormChange }) => {
    const { lang } = useContext(LanguageContext)
    const [open, setOpen] = useState(false);

    const toggleAccordion = () => {
        setOpen(!open);
    };

    return (
        <Accordion style={{ marginBottom: '-1rem', }}
            defaultActiveKey="0"
        >
            <Card style={{ border: "1px solid #0b2a97" }}>
                <Card.Header style={{ padding: "0.5rem 1rem" }}>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="0"
                        onClick={toggleAccordion}
                        style={{ fontSize: "0.8rem", padding: 0 }}
                    >
                        {`${lang.equipment.optionsTo} ${item.name}`}
                        <span className="accordion__header--indicator h4">{open
                            ? <i className="fa fa-caret-up text-primary" style={{ fontSize: '1rem' }} />
                            : <i className="fa fa-caret-down text-primary" style={{ fontSize: '1rem' }} />
                        }</span>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0" in={open}>
                    <Card.Body style={{ padding: "0.5rem 0.5rem", borderTop: "1px solid #0b2a97" }}>

                        {
                            ValidateEquipment.Dumbbells === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                        {
                            ValidateEquipment.Kettlebell === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                        {
                            ValidateEquipment.Disk === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                        {
                            ValidateEquipment.ChinUpBar === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                        {
                            ValidateEquipment.Bar === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                        {
                            ValidateEquipment.Other === item.value &&
                            <DynamicForm
                                options={item.options}
                                dynamicFormValues={dynamicFormValues}
                                handleDynamicFormChange={handleDynamicFormChange}
                            />
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

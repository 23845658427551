import axios from "axios";

const url = process.env.REACT_APP_API_SERVER;
const customHeader = process.env.REACT_APP_CUSTOM_HEADER


export const API = axios.create({
  baseURL: url,
  headers: {
    "X-Custom-Header": customHeader
  }
});

import { useEffect, useState } from "react";

export const useUtmValues = () => {

    const [utmTestValue, setTestValue] = useState(null)

    const getUtmValue = (utmParam) => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(utmParam);
    };

    useEffect(() => {
      setTestValue(getUtmValue("utm_test"))
    }, [])
    

    return {
        utmTestValue,
    };
};
import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext';
import { useCreateAndUpdateBlockNotes } from '../../hooks/blockNotes/useCreateAndUpdateBlockNotes';

export const BlockNoteUpdate = ({
    block,
    setIsEditBlocknote,
    training
}) => {

    const { lang } = useContext(LanguageContext);
    const { note, handleFormBlocksNotes, handleCreateAndUpdateBlockNote, isDisabled } = useCreateAndUpdateBlockNotes(block?.blockNote?.note)


    return (
        <div className={`input-group ${training?.isSimpleMode && "mt-4"}`}>
            <textarea
                className="form-control h-100 overflow-auto"
                rows="4"
                id="note"
                name="note"
                value={note}
                onChange={handleFormBlocksNotes}
                placeholder={lang.blockNotes.placeholder}
            ></textarea>
            <div className="input-group-append">
                <button
                    className="btn btn-success"
                    style={{ borderRadius: '0 1.25rem 1.25rem 0' }}
                    disabled={isDisabled}
                    onClick={(e) => {
                        handleCreateAndUpdateBlockNote(block._id, block.trainingId, block.idPersonalTrainer)
                        setIsEditBlocknote(false)
                    }}
                >
                    {lang.buttons.save}
                </button>
            </div>
        </div>
    )
}

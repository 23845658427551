import React from "react";
import { AutocompleteItem } from "..";
import { NoSearchResult } from "./NoSearchResult";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useUserSearch } from "../../hooks";


export const SearchPrincipal = () => {
  const { lang } = useContext(LanguageContext);
  const {
    searchTerm,
    data,
    error,
    isFetching,
    handleSearchInputChange,
    handleInputFocus,
    handleInputBlur,
    inputOnFocus,
  } = useUserSearch()
  
  
  return (
    <>
      <li className="nav-item ">
        <div className={`search_container search-area d-xl-inline-flex ${error ? "searchBorderError" : "searchBorder"} hiddenSearch`} style={{
            position: 'relative',
            display: 'flex',
            backgroundColor: 'transparent',
            alignItems: 'stretch',
            width: '100%',
        }}>
          <div className="input-group-append ">
            <span className="input-group-text iconSearch" style={{backgroundColor: 'transparent'}}>
              <span >
                <i className="flaticon-381-search-2" />
              </span>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            style={{backgroundColor: 'transparent'}}
            value={searchTerm}
            onChange={handleSearchInputChange}
            autoComplete="off"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder={lang.placeHolders.search}
          />
          {isFetching && <NoSearchResult text={lang.generic.searching} color="info" />}
          <section>
            {inputOnFocus &&
              (data?.length > 0 && searchTerm?.length > 2) ?
                (
                  <div className="widget-media scroll_test_responsive  custom-scrollbar thin testBusqueda">
                    <ul
                      className="timeline scrollbar-color">
                      {data?.map((item) => (
                        <AutocompleteItem key={item._id} {...item}/>
                      ))}
                    </ul>
                  </div>

                ) : (
                  (searchTerm?.length > 2 && data?.length === 0 && inputOnFocus) &&
                  <NoSearchResult text={lang.search.noSearchResults} color="warning" />
                )
            }
          </section>
        </div>
      </li>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Accordion, Badge, Button, Card, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { ModalVideo, Options, RangeInputWithProgression, TextInputWithProgression } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { categoriesExercises } from '../../assets/data/categoriesExcercise'
import { useCreateAndUpdateExercise, useDeleteExercise, usePath } from "../../hooks";
import { isURL } from "../../helpers/isURL";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { CheckBoxParameters, CheckBoxParametersForDinamicFormForExercises } from "../checkboxs/CheckBoxParameters";
import { exerciseOptions, otherExerciseOptions } from "../../assets/data/sports";
import { getTextsEquipment } from "../../assets/data/validateEquipment";
import { getValuesInputExersices } from "../../helpers/getValuesInputExersices";

export const ModalCreateExercise = ({
  idModal,
  isCreateExercise,
  setIsCreateExercise,
  exercise,
  setIInputSearchExercise,
  index,
  handleInputChangeForExercises,
  handleInputCheckboxForExercises,
  multipleTrainingDate,
  activateTrainingProgressionsTemp,
  trainingIsSimpleMode = false
}) => {
  const { lang } = useContext(LanguageContext);
  const { path } = usePath(1);

  const cantDeleteEdit = exercise?.itsFromFitool && path === "exercises"

  const { isEditingExerciseInTraining, modalCreateExercise, trainingParameters, isCreateExerciseInTraining, selectUser, userSelected: userSelectedInTrainincCreated } = useSlicesState()

  const { dispatch, setCleanExerciseSelected, setIsEditingExerciseInTrainingFalse, setModalCreateExercise, setIsCreateExerciseInTrainingFalse, setBlockIdSelected, setModalViewVideo } = useSlicesActions()

  const [activeAccordionParameters, setActiveAccordionParameters] = useState(false)
  const [activeAccordionEquipment, setActiveAccordionEquipment] = useState(false)
  const [activeComponents, setActiveComponents] = useState([]);

  const closeModal = () => {
    dispatch(setModalCreateExercise(null));
    if (!isEditingExerciseInTraining && !isCreateExerciseInTraining) setIsCreateExercise(false);
    reset();
    dispatch(setCleanExerciseSelected());
    dispatch(setIsEditingExerciseInTrainingFalse())
    dispatch(setIsCreateExerciseInTrainingFalse())
    dispatch(setBlockIdSelected(null))
  };

  const {
    category,
    options,
    reset,
    formCreateExercise,
    handleFormCreateExercise,
    handleCreateEerxice,
    handleUpdateExercise,
    optionsEquipment,
  } = useCreateAndUpdateExercise(closeModal)

  const name = isCreateExerciseInTraining ? exercise?.name : formCreateExercise?.name
  const video = isCreateExerciseInTraining ? exercise?.video : formCreateExercise?.video
  const description = isCreateExerciseInTraining ? exercise?.description : formCreateExercise?.description

  const repetitions = isCreateExerciseInTraining ? exercise?.options?.repetitions : formCreateExercise?.options?.repetitions
  const series = isCreateExerciseInTraining ? exercise?.options?.series : formCreateExercise?.options?.series
  const breakRecovery = isCreateExerciseInTraining ? exercise?.options?.breakRecovery : formCreateExercise?.options?.breakRecovery
  const breakInfo = isCreateExerciseInTraining ? exercise?.options?.breakInfo : formCreateExercise?.options?.breakInfo
  const isUsingMinutes = isCreateExerciseInTraining ? exercise?.options?.isUsingMinutes : formCreateExercise?.options?.isUsingMinutes

  const weight = isCreateExerciseInTraining ? exercise?.options?.weight : formCreateExercise?.options?.weight
  const weightInfo = isCreateExerciseInTraining ? exercise?.options?.weightInfo : formCreateExercise?.options?.weightInfo
  const km = isCreateExerciseInTraining ? exercise?.options?.km : formCreateExercise?.options?.km
  const kmInfo = isCreateExerciseInTraining ? exercise?.options?.kmInfo : formCreateExercise?.options?.kmInfo
  const rhythm = isCreateExerciseInTraining ? exercise?.options?.rhythm : formCreateExercise?.options?.rhythm
  const rhythmInfo = isCreateExerciseInTraining ? exercise?.options?.rhythmInfo : formCreateExercise?.options?.rhythmInfo
  const time = isCreateExerciseInTraining ? exercise?.options?.time : formCreateExercise?.options?.time
  const hearRate = isCreateExerciseInTraining ? exercise?.options?.hearRate : formCreateExercise?.options?.hearRate
  const hearRateInfo = isCreateExerciseInTraining ? exercise?.options?.hearRateInfo : formCreateExercise?.options?.hearRateInfo
  const cadence = isCreateExerciseInTraining ? exercise?.options?.cadence : formCreateExercise?.options?.cadence
  const cadenceInfo = isCreateExerciseInTraining ? exercise?.options?.cadenceInfo : formCreateExercise?.options?.cadenceInfo
  const swimmingStyles = isCreateExerciseInTraining ? exercise?.options?.swimmingStyles : formCreateExercise?.options?.swimmingStyles
  const meters = isCreateExerciseInTraining ? exercise?.options?.meters : formCreateExercise?.options?.meters
  const metersInfo = isCreateExerciseInTraining ? exercise?.options?.metersInfo : formCreateExercise?.options?.metersInfo
  const power = isCreateExerciseInTraining ? exercise?.options?.power : formCreateExercise?.options?.power
  const powerInfo = isCreateExerciseInTraining ? exercise?.options?.powerInfo : formCreateExercise?.options?.powerInfo
  const other = isCreateExerciseInTraining ? exercise?.options?.other : formCreateExercise?.options?.other
  const timeInfo = isCreateExerciseInTraining ? exercise?.options?.timeInfo : formCreateExercise?.options?.timeInfo
  const swimmingStylesInfo = isCreateExerciseInTraining ? exercise?.options?.swimmingStylesInfo : formCreateExercise?.options?.swimmingStylesInfo
  const otherInfo = isCreateExerciseInTraining ? exercise?.options?.otherInfo : formCreateExercise?.options?.otherInfo



  const { handleDeleteExercise } = useDeleteExercise(closeModal, false)

  const userSelected = selectUser && { ...selectUser, equipment: getTextsEquipment(selectUser?.equipment) }

  const isRequired = (inputName) => {
    return inputName === "";
  };

  const athlete = isEditingExerciseInTraining
    ? userSelected
    : isCreateExerciseInTraining && !userSelectedInTrainincCreated
      ? userSelected
      : userSelectedInTrainincCreated

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputConfigurations = [
    {
      name: 'weightInfo',
      condition: weight,
      component: (
        <RangeInputWithProgression
          name="weightInfo"
          value={weightInfo ? weightInfo : 0}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={0}
          max={500}
          text={lang.createTraining.kgOfLoad}
          inputType="range"
          itemTitle={lang.createTraining.suggestedWeight}
        />
      ),
    },
    {
      name: 'kmInfo',
      condition: km,
      component: (
        <RangeInputWithProgression
          name="kmInfo"
          value={kmInfo ? kmInfo : 1}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={1}
          max={300}
          text={"Km's"}
          inputType="range"
          itemTitle={lang.createTraining.suggestedKM}
        />
      ),
    },
    {
      name: 'cadenceInfo',
      condition: cadence,
      component: (
        <RangeInputWithProgression
          name="cadenceInfo"
          value={cadenceInfo ? cadenceInfo : 1}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={1}
          max={500}
          text="CAD"
          inputType="range"
          itemTitle={lang.createTraining.suggestedCadence}
        />
      ),
    },
    {
      name: 'rhythmInfo',
      condition: rhythm,
      component: (
        <RangeInputWithProgression
          name="rhythmInfo"
          value={rhythmInfo ? rhythmInfo : 1}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={1}
          max={150}
          text="Km/h"
          inputType="range"
          itemTitle={lang.generic.rhythm}
        />
      ),
    },
    {
      name: 'metersInfo',
      condition: meters,
      component: (
        <RangeInputWithProgression
          name="metersInfo"
          value={metersInfo ? metersInfo : 1}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={1}
          max={100}
          text="m's"
          inputType="range"
          itemTitle={lang.createTraining.suggestedMeters}
        />
      ),
    },
    {
      name: 'powerInfo',
      condition: power,
      component: (
        <RangeInputWithProgression
          name="powerInfo"
          value={powerInfo ? powerInfo : 1}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={1}
          max={500}
          text="P"
          inputType="range"
          itemTitle={lang.createTraining.suggestedPower}
        />
      ),
    },
    {
      name: 'hearRateInfo',
      condition: hearRate,
      component: (
        <RangeInputWithProgression
          name="hearRateInfo"
          value={hearRateInfo ? hearRateInfo : 30}
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          min={30}
          max={250}
          text="lpm"
          inputType="range"
          itemTitle={lang.generic.hearRate}
        />
      ),
    },
    {
      name: 'timeInfo',
      condition: time,
      component: (
        <TextInputWithProgression
          name="timeInfo"
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleInputChangeForExercises={handleInputChangeForExercises}
          handleFormCreateExercise={handleFormCreateExercise}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          placeholder={`${lang.placeHolders.suggestedTime}...`}
          inputType="text"
          itemTitle={lang.placeHolders.suggestedTime}
          value={timeInfo}
        />
      ),
    },
    {
      name: 'swimmingStyles',
      condition: swimmingStyles,
      component: (
        <TextInputWithProgression
          name="swimmingStylesInfo"
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleFormCreateExercise={handleFormCreateExercise}
          handleInputChangeForExercises={handleInputChangeForExercises}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          placeholder={`${lang.placeHolders.suggestedSwimStyle}...`}
          inputType="text"
          itemTitle={lang.placeHolders.suggestedSwimStyle}
          value={swimmingStylesInfo}
        />
      ),
    },
    {
      name: 'otherInfo',
      condition: other,
      component: (
        <TextInputWithProgression
          name="otherInfo"
          exercise={exercise}
          getOptionValue={getValuesInputExersices}
          handleFormCreateExercise={handleFormCreateExercise}
          handleInputChangeForExercises={handleInputChangeForExercises}
          multipleTrainingDate={multipleTrainingDate}
          activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
          placeholder={`${lang.placeHolders.anotherParameter}...`}
          inputType="text"
          itemTitle={lang.placeHolders.anotherParameter}
          value={otherInfo}
        />
      ),
    },
  ];

  const [isBlurred, setIsBlurred] = useState(false);

  const handleBlur = () => {
    setIsBlurred(true);
  };

  const handleFocus = () => {
    setIsBlurred(false);
  };


  useEffect(() => {
    inputConfigurations.forEach((config) => {
      if (config.condition && !activeComponents.includes(config.name)) {
        setActiveComponents((prev) => [...prev, config.name]);
      } else if (!config.condition && activeComponents.includes(config.name)) {
        setActiveComponents((prev) => prev.filter((name) => name !== config.name));
      }
    });
  }, [inputConfigurations, activeComponents]);



  return (
    <>
      <Modal
        className="fade bd-example-modal-lg"
        show={modalCreateExercise === true || modalCreateExercise === idModal}
        size="lg"
        backdropClassName="modalEditCreateExercise"
      >
        <Modal.Header className="mb-2">
          <Modal.Title>
            {isCreateExercise ? lang.buttons.createExercise : `${lang.generic.exercice} ${name}`}
          </Modal.Title>

          <Button variant="" className="close" onClick={() => closeModal()}>
            <span>x</span>
          </Button>
        </Modal.Header>

        {(!isEditingExerciseInTraining && !isCreateExerciseInTraining) && !cantDeleteEdit &&
          <div className="w-75 m-auto">

            <select
              className="form-control border border-primary"
              name="category"
              value={category}
              onChange={handleFormCreateExercise}
            >
              <option value="none">
                {lang.myExercises.selectCategory}
              </option>
              <Options options={categoriesExercises} />
            </select>

          </div>
        }


        {trainingIsSimpleMode
          ? (
            <Modal.Body>
              <div className="row">
                <div className="mb-2 mt-2 col-12 col-xl-6">
                  <input
                    type="text"
                    name="name"
                    className="form_test_entrenamiento"
                    required={name?.length < 1}
                    placeholder={lang.placeHolders.nameExercise}
                    value={name}
                    onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                  />
                </div>

                <div className="mb-2 mt-2 col-12 col-xl-6">
                  <input
                    type="text"
                    name="video"
                    className="form_test_entrenamiento"
                    placeholder={lang.placeHolders.urlVideo}
                    value={video}
                    onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                  />
                  <span>
                    {video !== "" && !isURL(video) && <p className="text-sm-left text-xs text-secondary m-0">{lang.viewExercise.invalidURL}</p>}
                    <h6 className="text-sm-left text-xs">
                      {lang.createTraining.urlVideoDescription}
                      {(isBlurred && isURL(video)) || (path === "create-training" && isURL(video)) ? (
                        <Badge
                          variant="secondary"
                          className="badge-xs light ml-1 text-capitalize cursor-pointer"
                          onClick={() => dispatch(setModalViewVideo(name))}
                        >
                          {lang.buttons.viewVideo}
                        </Badge>
                      ) : null}
                      <ModalVideo
                        video={video}
                        nombreEjercicio={name}
                        idModal={name}
                      />
                    </h6>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      responsive={true}
                      overlay={
                        <Popover id={`test`}>
                          <Popover.Title as="h3">{lang.myExercises.popoverTitle}</Popover.Title>
                          <Popover.Content>
                            {lang.myExercises.popoverDescription}
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Badge
                        variant="info cursor-pointer"
                        className="badge-xs light ml-1"
                      >
                        {lang.myExercises.popoverBadge}
                      </Badge>
                    </OverlayTrigger>

                  </span>
                </div>
              </div>
            </Modal.Body>
          ) :
          (
            <Modal.Body>
              {
                cantDeleteEdit ?
                  (
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        src={video}
                        alt="Gif del Entrenamiento"
                        loading="lazy"
                        style={{
                          maxWidth: '50%',
                          maxHeight: '50%',
                          objectFit: 'cover'
                        }}
                      />
                    </div>
                  )
                  :
                  (

                    <div className="accordion__body--text">
                      <div className="row">
                        <div className="mb-2 mt-2 col-12 col-md-6 col-xl-4">
                          <input
                            type="text"
                            name="name"
                            className="form_test_entrenamiento"
                            required={name?.length < 1}
                            placeholder={lang.placeHolders.nameExercise}
                            value={name}
                            onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                          />
                        </div>

                        {exercise?.itsFromFitool ?
                          (
                            <div className="mb-2 mt-2 col-12 col-md-6 col-xl-4">
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  src={exercise?.video}
                                  alt="Gif del Entrenamiento"
                                  loading="lazy"
                                  style={{
                                    maxWidth: '50%',
                                    maxHeight: '50%',
                                    objectFit: 'cover'
                                  }}
                                />
                              </div>
                            </div>
                          )
                          :
                          (
                            <div className="mb-2 mt-2 col-12 col-md-6 col-xl-4">
                              <input
                                type="text"
                                name="video"
                                className="form_test_entrenamiento"
                                placeholder={lang.placeHolders.urlVideo}
                                value={video}
                                onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                                onBlur={handleBlur}
                                onFocus={handleFocus}
                              />
                              <span>
                                {video !== "" && !isURL(video) && <p className="text-sm-left text-xs text-secondary m-0">{lang.viewExercise.invalidURL}</p>}
                                <h6 className="text-sm-left text-xs">
                                  {lang.createTraining.urlVideoDescription}
                                  {(isBlurred && isURL(video)) || (path === "create-training" && isURL(video)) ? (
                                    <Badge
                                      variant="secondary"
                                      className="badge-xs light ml-1 text-capitalize cursor-pointer"
                                      onClick={() => dispatch(setModalViewVideo(name))}
                                    >
                                      {lang.buttons.viewVideo}
                                    </Badge>
                                  ) : null}
                                  <ModalVideo
                                    video={video}
                                    nombreEjercicio={name}
                                    idModal={name}
                                  />
                                </h6>

                                <OverlayTrigger
                                  trigger="click"
                                  placement="bottom"
                                  responsive={true}
                                  overlay={
                                    <Popover id={`test`}>
                                      <Popover.Title as="h3">{lang.myExercises.popoverTitle}</Popover.Title>
                                      <Popover.Content>
                                        {lang.myExercises.popoverDescription}
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Badge
                                    variant="info cursor-pointer"
                                    className="badge-xs light ml-1"
                                  >
                                    {lang.myExercises.popoverBadge}
                                  </Badge>
                                </OverlayTrigger>

                              </span>
                            </div>
                          )
                        }

                        <div className="mb-2 mt-2 col-12 col-md-12 col-xl-4">
                          <h5 className="text-primary mb-2">
                            {lang.createTraining.shortDescriptionExercise}
                          </h5>
                          <textarea
                            name="description"
                            className="form-control h-50"
                            rows="4"
                            placeholder={lang.createTraining.shortDescriptionExercise.toLowerCase()}
                            value={description}
                            onChange={handleInputChangeForExercises ? (e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar) : handleFormCreateExercise}
                          ></textarea>
                        </div>

                        <div className="col-12">
                          <hr className="mb-2 mt-1" />
                          <h5 className="text-primary mb-3 text-center">{lang.createTraining.optionsToExercice}</h5>
                          <div className="d-flex flex-column flex-md-row">
                            <div className="mb-2 col-4">
                              {isCreateExerciseInTraining
                                ? (
                                  exerciseOptions?.map(param =>
                                    CheckBoxParametersForDinamicFormForExercises({
                                      options: exercise?.options,
                                      param: param.value,
                                      onChange: handleInputCheckboxForExercises,
                                      color: param.color,
                                      index: index,
                                      exerciseId: exercise?.idTemporalClonar,
                                      isVisible: true,

                                    })
                                  )
                                )
                                :
                                (
                                  exerciseOptions?.map(param => {
                                    return (
                                      CheckBoxParameters(options, param.value, handleFormCreateExercise, param.color, null, true)
                                    )
                                  }

                                  )
                                )
                              }

                            </div>



                            <div className="d-flex flex-column col-12 col-md-8">
                              <div className={`d-flex flex-column flex-md-row ${activateTrainingProgressionsTemp ? "justify-content-start" : "justify-content-between"} align-items-center`}>
                                <h5 className="text-info mr-3 col-12 col-md-3 ">{lang.generic.repetitions}</h5>
                                <RangeInputWithProgression
                                  name="repetitions"
                                  value={repetitions}
                                  exercise={exercise}
                                  getOptionValue={getValuesInputExersices}
                                  handleInputChangeForExercises={handleInputChangeForExercises}
                                  handleFormCreateExercise={handleFormCreateExercise}
                                  multipleTrainingDate={multipleTrainingDate}
                                  activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                  min={0}
                                  max={50}
                                  inputType="range"
                                />
                              </div>
                              <div className={`d-flex flex-column flex-md-row ${activateTrainingProgressionsTemp ? "justify-content-start" : "justify-content-between"} align-items-center mt-1`}>
                                <h5 className="text-info mr-3 col-12 col-md-3">{lang.generic.series}</h5>
                                <RangeInputWithProgression
                                  name="series"
                                  value={series}
                                  exercise={exercise}
                                  getOptionValue={getValuesInputExersices}
                                  handleInputChangeForExercises={handleInputChangeForExercises}
                                  handleFormCreateExercise={handleFormCreateExercise}
                                  multipleTrainingDate={multipleTrainingDate}
                                  activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                  min={1}
                                  max={50}
                                  inputType="range"
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-12">
                          <hr className="mb-2 mt-1" />
                          <h5 className="text-info mb-3 text-center">{lang.createTraining.breakAtFinish}</h5>
                          <div className="d-flex flex-column flex-md-row">
                            <div className="mb-2 col-4">
                              {isCreateExerciseInTraining
                                ? (
                                  otherExerciseOptions?.map(param => {
                                    const isVisible = param.value !== "isUsingMinutes" || exercise.options.breakRecovery;

                                    return (
                                      CheckBoxParametersForDinamicFormForExercises({
                                        options: exercise?.options,
                                        param: param.value,
                                        onChange: handleInputCheckboxForExercises,
                                        color: param.color,
                                        index: index,
                                        exerciseId: exercise?.idTemporalClonar,
                                        isVisible: isVisible
                                      })
                                    )
                                  }

                                  )
                                )
                                :
                                (
                                  otherExerciseOptions?.map(param => {
                                    // eslint-disable-next-line no-mixed-operators
                                    const isVisible = param.value !== "isUsingMinutes" || breakRecovery && path !== "exercises";
                                    return (
                                      CheckBoxParameters(options, param.value, handleFormCreateExercise, param.color, null, isVisible)
                                    )
                                  }
                                  )
                                )
                              }
                            </div>

                            {(isEditingExerciseInTraining || isCreateExerciseInTraining) && breakRecovery &&
                              <div className="d-flex flex-column col-12 col-md-8">
                                <div className="d-flex flex-column flex-md-row justify-content-start align-items-center">
                                  <RangeInputWithProgression
                                    name="breakInfo"
                                    value={breakInfo ? breakInfo : 1}
                                    exercise={exercise}
                                    getOptionValue={getValuesInputExersices}
                                    handleInputChangeForExercises={handleInputChangeForExercises}
                                    handleFormCreateExercise={handleFormCreateExercise}
                                    multipleTrainingDate={multipleTrainingDate}
                                    activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                    min={1}
                                    max={60}
                                    step={isUsingMinutes ? 0.5 : 1}
                                    text={isUsingMinutes ? '"' : "'"}
                                    inputType="range"
                                  />
                                </div>
                              </div>
                            }

                          </div>
                        </div>


                        {/** aca arranca los render dondiconales */}
                        <div className="col-12 mt-2">
                          <Accordion
                            className=''
                            defaultActiveKey='0'
                          >
                            <div className='accordion__item'>
                              <Card.Header style={{ width: '100%', backgroundColor: '#f4f5f9', borderRadius: '0.8rem', paddingTop: '8px', paddingBottom: '8px' }}>
                                <Accordion.Toggle
                                  as={Card.Text}
                                  eventKey={"test"}
                                  className={`${activeAccordionParameters ? '' : 'collapsed'}`}
                                  onClick={() => setActiveAccordionParameters(!activeAccordionParameters)}
                                >
                                  <span className='accordion__header--text text-primary d-flex align-items-center justify-content-center'>
                                    {lang.createTraining.paramsTitle}
                                  </span>
                                  <span className="accordion__header--indicator h4 cursor-pointer">{activeAccordionParameters
                                    ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                                    : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                                  }</span>
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey={"test"}>
                                <div className='accordion__body--text'>
                                  <h6 className="text-sm-center text-xs">{lang.myExercises.descriptionCustomExercise}</h6>
                                  <hr className="mb-2 mt-1" />
                                  <div className="row mt-2">
                                    {isCreateExerciseInTraining
                                      ? (
                                        trainingParameters?.map(param =>
                                          <div key={param} className="col-md-4 mb-1">
                                            {CheckBoxParametersForDinamicFormForExercises({
                                              options: exercise?.options,
                                              param: param,
                                              onChange: handleInputCheckboxForExercises,
                                              color: "secondary",
                                              index: index,
                                              exerciseId: exercise?.idTemporalClonar,
                                              isVisible: true,
                                            })}
                                            <p></p>
                                          </div>
                                        )
                                      )
                                      : (

                                        trainingParameters?.map(param =>
                                          <div key={param} className="col-md-4 mb-1">
                                            {CheckBoxParameters(options, param, handleFormCreateExercise, null, null, true)}
                                          </div>
                                        )
                                      )
                                    }
                                  </div>

                                  {
                                    (isEditingExerciseInTraining || isCreateExerciseInTraining) &&
                                    <div className="container">
                                      <div className="row">
                                        {activeComponents.map((name) => {
                                          const inputConfig = inputConfigurations.find((config) => config.name === name);
                                          return <div className={`mb-2 col-12 col-md-6 ${activateTrainingProgressionsTemp && "d-flex flex-row justify-content-start align-items-center"}`}
                                            style={{ gap: `${activateTrainingProgressionsTemp ? "0.5rem" : "0"}` }}
                                            key={name}
                                          >
                                            {inputConfig.component}
                                          </div>;
                                        })}
                                      </div>
                                    </div>
                                  }


                                </div>
                              </Accordion.Collapse>
                            </div>
                          </Accordion>

                          {athlete?.equipment?.length > 0 &&
                            <Accordion
                              className='accordion accordion-header-bg '
                              defaultActiveKey='0'
                            >
                              <div className='accordion__item'>
                                <Card.Header style={{ width: '100%', backgroundColor: '#f4f5f9', borderRadius: '0.8rem', paddingTop: '8px', paddingBottom: '8px' }}>
                                  <Accordion.Toggle
                                    as={Card.Text}
                                    eventKey={"test"}
                                    className={`${activeAccordionEquipment ? '' : 'collapsed'}`}
                                    onClick={() => setActiveAccordionEquipment(!activeAccordionEquipment)}
                                  >
                                    <span className='accordion__header--text text-primary d-flex align-items-center justify-content-center'>
                                      {lang.equipment.selectEquipment}
                                    </span>
                                    <span className='accordion__header--indicator'></span>
                                    <span className="accordion__header--indicator h4 cursor-pointer">{activeAccordionEquipment
                                      ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                                      : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                                    }</span>
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={"test"}>
                                  <div className='accordion__body--text'>
                                    <h6 className="text-sm-center text-xs">{lang.equipment.selectEquipmentDescription}</h6>
                                    <hr className="mb-2 mt-1" />
                                    <div className="row mt-2">
                                      {isCreateExerciseInTraining
                                        ? (
                                          athlete?.equipment?.map((option) =>
                                            <div key={option.value} className="col-md-4 mb-1">
                                              {CheckBoxParametersForDinamicFormForExercises({
                                                options: exercise?.optionsEquipment,
                                                param: option.value,
                                                onChange: handleInputCheckboxForExercises,
                                                color: "secondary",
                                                text: option.label,
                                                index: index,
                                                exerciseId: exercise?.idTemporalClonar,
                                                isVisible: true,
                                              })}
                                            </div>
                                          )
                                        )
                                        : (
                                          athlete?.equipment?.map(option =>
                                            <div key={option.value} className="col-md-4 mb-1">
                                              {CheckBoxParameters(
                                                optionsEquipment,
                                                option.value,
                                                handleFormCreateExercise,
                                                "secondary",
                                                option.label,
                                                true)}
                                            </div>
                                          )
                                        )
                                      }
                                    </div>
                                  </div>
                                </Accordion.Collapse>
                              </div>
                            </Accordion>
                          }
                        </div>
                      </div>
                    </div>
                  )
              }

            </Modal.Body>
          )
        }





        <Modal.Footer>
          <Button onClick={() => closeModal()} variant={!isCreateExerciseInTraining ? "warning light" : "primary"}>
            {!isCreateExerciseInTraining ? lang.buttons.close : lang.buttons.save}
          </Button>

          {!isCreateExercise && !isCreateExerciseInTraining && !cantDeleteEdit && (
            <Button
              onClick={() => {
                handleUpdateExercise(exercise._id)
                setIInputSearchExercise && setIInputSearchExercise('')
              }}
              disabled={JSON.stringify(exercise) === JSON.stringify(formCreateExercise) || isRequired(name)}
              variant="info"
            >
              {lang.buttons.update}
            </Button>
          )}
          {!isCreateExercise && !isCreateExerciseInTraining && !cantDeleteEdit && (
            <Button
              onClick={() => {
                handleDeleteExercise(exercise._id)
                setIInputSearchExercise && setIInputSearchExercise('')
              }}
              variant="danger"
            >
              {lang.buttons.delete}
            </Button>
          )}

          {isCreateExercise && !isCreateExerciseInTraining && (
            <Button
              variant="primary"
              onClick={handleCreateEerxice}
              disabled={isRequired(name)}
            >
              {lang.buttons.save}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
import React, { useContext } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'
import { LanguageContext } from '../../context/LanguageContext';
import { blockOptionsTraining, otherBlockOptionsTraining } from '../../assets/data/sports';
import { CheckBoxParametersForDinamicForm } from '../checkboxs/CheckBoxParameters';
import { useSlicesActions, useSlicesState } from '../../redux/slices';
import { ModalListMyExercises } from '../modals/ModalListMyExercises';
import { CardPreviewExercise } from '../cards/CardPreviewExercise';
import { blockIntensity } from '../../assets/data/blockIntensity';
import { RangeInputWithProgressionForBlocks } from '../Inputs/RangeInputWithProgressionForBlocks';
import { getValuesInputBlocks } from '../../helpers/getValuesInputBlocks';
import { SelectInputWithProgressionForBlocks } from '../Inputs/SelectInputWithProgressionForBlocks';
import { CreateTrainingSimpleMode } from '../createTraining/CreateTrainingSimpleMode';


export const DinamicBlocks = ({
    blocks,
    handleCloneBlocks,
    handleAddBlocks,
    handleRemoveBlocks,
    handleInputChangeForBlocks,
    handleInputCheckBoxForBlocks,
    handleAddExercise,
    exercises,
    handleInputChangeForExercises,
    handleCloneExercise,
    handleEditExerciseInTrainingCreation,
    handleRemoveExercise,
    handleInputCheckboxForExercises,
    exerciseIdsPerBlock,
    activeAccordionDinamicBlocks,
    setActiveAccordionDinamicBlocks,
    multipleTrainingDate,
    activateTrainingProgressionsTemp
}) => {
    const { lang } = useContext(LanguageContext);

    const { toggleCreateTrainingSimpleMode } = useSlicesState()
    const { dispatch, setModalListMyExercises, setBlockIdSelected } = useSlicesActions()

    return (
        <>
            {
                toggleCreateTrainingSimpleMode ?
                    (
                        <CreateTrainingSimpleMode
                            blocks={blocks}
                            exercises={exercises}
                            handleRemoveBlocks={handleRemoveBlocks}
                            handleInputChangeForBlocks={handleInputChangeForBlocks}
                            handleInputChangeForExercises={handleInputChangeForExercises}
                            handleRemoveExercise={handleRemoveExercise}
                            exerciseIdsPerBlock={exerciseIdsPerBlock}
                            lang={lang}
                        />
                    )
                    :
                    (
                        <Accordion
                            className="accordion accordion-rounded-stylish accordion-bordered"
                            activeKey={`${activeAccordionDinamicBlocks}`}
                        >
                            <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
                                {blocks?.map((block, index) => {
                                    return (
                                        (
                                            <div className="" key={block?.idTemporalClonar}>
                                                <Card.Header className={`${activeAccordionDinamicBlocks === index
                                                    ? "border-secondary"
                                                    : "border-dark"
                                                    }`}
                                                    style={{
                                                        border: "2px solid",
                                                        borderRadius: '1rem',
                                                        paddingTop: '3px',
                                                        paddingBottom: '3px',
                                                        borderBottomLeftRadius: activeAccordionDinamicBlocks === index ? '0px' : '1rem',
                                                        borderBottomRightRadius: activeAccordionDinamicBlocks === index ? '0px' : '1rem',
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Text}
                                                        eventKey={`${index}`}
                                                        className={`border-2 ${activeAccordionDinamicBlocks === index
                                                            ? "border-secondary"
                                                            : "collapsed border-dark"
                                                            } accordion__header--primary `}
                                                        onClick={() =>
                                                            setActiveAccordionDinamicBlocks(
                                                                activeAccordionDinamicBlocks === index ? -1 : index
                                                            )
                                                        }
                                                    >
                                                        <span className="accordion__header--text">
                                                            {`
                                                ${block.name.length < 1 ? lang.placeHolders.nameBlock : ""} 
                                                ${block.name} 
                                                ${block.optionsTraining.heating ? lang.generic.heating : ''}
                                                ${block.optionsTraining.activeRecovery ? lang.generic.activeRecovery : ''}
                                                ${block.optionsTraining.stretching ? lang.generic.stretching : ''}
                                                ${block.optionsTraining.activation ? lang.generic.activation : ''}
                                                ${block.optionsTraining.mobility ? lang.generic.mobility : ''}
                                                `}
                                                        </span>

                                                        <span
                                                            className="accordion__header--indicator h4 cursor-pointer"
                                                        // style={{ marginRight: '4rem', fontWeight: 'bold' }}
                                                        >{activeAccordionDinamicBlocks === index
                                                            ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                                                            : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                                                            }</span>

                                                    </Accordion.Toggle>

                                                </Card.Header>
                                                <Accordion.Collapse
                                                    eventKey={`${index}`}
                                                    className={`accordion__body border-2 ${activeAccordionDinamicBlocks === index
                                                        ? "border-secondary"
                                                        : "border-dark"
                                                        }`}
                                                >

                                                    <Card.Body
                                                        style={{
                                                            paddingBottom: '0.5rem',
                                                            paddingTop: '0.5rem',
                                                        }}>


                                                        <div className="row">

                                                            <div className="mb-2 mt-2 col-12 col-md-12 col-xl-3">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form_test_entrenamiento"
                                                                    required={block.name.length < 1}
                                                                    placeholder={lang.placeHolders.nameBlock}
                                                                    value={block.name}
                                                                    onChange={(e) => handleInputChangeForBlocks(e, index)}
                                                                />

                                                                <div className="mt-2">
                                                                    <textarea
                                                                        className="form-control h-100 "
                                                                        rows="2"
                                                                        id="description"
                                                                        name="description"
                                                                        value={block.description}
                                                                        placeholder={lang.blocks.optionalShortDescriptionExercise}
                                                                        onChange={(e) => handleInputChangeForBlocks(e, index)}
                                                                        required={block.description.length < 1}
                                                                    ></textarea>
                                                                </div>

                                                                <h5 className="text-primary mt-2">{lang.createTraining.optionsToExercice}</h5>
                                                                <div className="d-flex flex-column flex-md-row">
                                                                    <div className="mb-2">
                                                                        {blockOptionsTraining?.map(param =>
                                                                            CheckBoxParametersForDinamicForm({
                                                                                options: block.optionsTraining,
                                                                                param: param.value,
                                                                                onChange: handleInputCheckBoxForBlocks,
                                                                                color: param.color,
                                                                                index: index,
                                                                                isVisible: true,
                                                                            })
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className={`d-flex ${activateTrainingProgressionsTemp && "d-flex flex-row justify-content-start align-items-center"}`}
                                                                    style={{ gap: '0.5rem' }}
                                                                >
                                                                    <h5 className="text-info mt-0 mb-0 ">{lang.generic.series}</h5>
                                                                    <RangeInputWithProgressionForBlocks
                                                                        index={index}
                                                                        name="series"
                                                                        value={block?.optionsTraining?.series}
                                                                        block={block}
                                                                        getOptionValue={getValuesInputBlocks}
                                                                        handleInputChangeForBlocks={handleInputChangeForBlocks}
                                                                        multipleTrainingDate={multipleTrainingDate}
                                                                        activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                                                        min={1}
                                                                        max={50}
                                                                        inputType="range"
                                                                    />

                                                                </div>

                                                                <div
                                                                    className="d-flex flex-column"
                                                                    style={{ gap: '0.5rem' }}
                                                                >
                                                                    <h5 className="text-info mt-0 mb-0">
                                                                        {lang.createTraining.breakAtFinish}
                                                                    </h5>

                                                                    <div className="d-flex flex-column align-items-center">

                                                                        {otherBlockOptionsTraining?.map(param => {
                                                                            const isVisible = param.value !== "isUsingMinutes" || block.optionsTraining.breakRecovery;

                                                                            return (
                                                                                CheckBoxParametersForDinamicForm({
                                                                                    options: block.optionsTraining,
                                                                                    param: param.value,
                                                                                    onChange: handleInputCheckBoxForBlocks,
                                                                                    color: param.color,
                                                                                    index: index,
                                                                                    isVisible: isVisible
                                                                                })
                                                                            )
                                                                        }


                                                                        )}

                                                                    </div>
                                                                    {block.optionsTraining.breakRecovery && (
                                                                        <RangeInputWithProgressionForBlocks
                                                                            index={index}
                                                                            name="breakInfo"
                                                                            value={block?.optionsTraining?.breakInfo}
                                                                            block={block}
                                                                            getOptionValue={getValuesInputBlocks}
                                                                            handleInputChangeForBlocks={handleInputChangeForBlocks}
                                                                            multipleTrainingDate={multipleTrainingDate}
                                                                            activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                                                            min={1}
                                                                            max={60}
                                                                            step={block.optionsTraining.isUsingMinutes ? 0.5 : 1}
                                                                            text={block.optionsTraining.isUsingMinutes ? '"' : "'"}
                                                                            inputType="range"
                                                                        />

                                                                    )}
                                                                </div>

                                                                <div
                                                                    className={`mt-4 ${activateTrainingProgressionsTemp && "d-flex flex-row justify-content-start align-items-center"}`}
                                                                    style={{ gap: '0.5rem' }}
                                                                >
                                                                    {activateTrainingProgressionsTemp && <h5 className="text-info mt-0 mb-0 ">{lang.generic.intensity}</h5>}
                                                                    <SelectInputWithProgressionForBlocks
                                                                        name="blockIntensity"
                                                                        block={block}
                                                                        getOptionValue={getValuesInputBlocks}
                                                                        handleInputChangeForBlocks={handleInputChangeForBlocks}
                                                                        multipleTrainingDate={multipleTrainingDate}
                                                                        activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                                                        options={blockIntensity}
                                                                        index={index}
                                                                        lang={lang}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mb-2 mt-2 col-12 col-xl-9">
                                                                <Card
                                                                    className='card shadow-none scroll_test_responsive scrollbar-color'
                                                                    style={{
                                                                        border: "1px solid",
                                                                        borderColor: "#efefef",
                                                                        height: '100%',
                                                                        maxHeight: '50vh',
                                                                        backgroundColor: '#efefef',
                                                                    }}
                                                                >
                                                                    <Card.Header className='bg-white' style={{ padding: '0.5rem' }}>Ejercicios del Bloque
                                                                        <div className=" d-flex flex-row" style={{ gap: '0.5rem' }}>
                                                                            <div>
                                                                                <button
                                                                                    className={`btn btn-xs btn-outline-success btn-block`}
                                                                                    onClick={() => handleAddExercise(block.idTemporalClonar)}
                                                                                >
                                                                                    <i className="fa fa-plus mr-2" />
                                                                                    {lang.createTraining.buttonCreateNewExercise}
                                                                                </button>
                                                                            </div>
                                                                            <div>
                                                                                <button
                                                                                    className="btn btn-xs btn-outline-info btn-block"
                                                                                    onClick={() => {
                                                                                        dispatch(setBlockIdSelected(block?.idTemporalClonar))
                                                                                        dispatch(setModalListMyExercises(true))
                                                                                    }}
                                                                                >
                                                                                    <i className="fa fa-briefcase mr-2" />
                                                                                    {lang.buttons.myExercises}
                                                                                </button>

                                                                            </div>
                                                                        </div>

                                                                    </Card.Header>
                                                                    <Card.Body
                                                                        style={{
                                                                            padding: '0.5rem',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'grid',
                                                                                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                                                                                gridTemplateRows: 'repeat(auto-fill, minmax(100px, 1fr))',
                                                                                gridColumnGap: '0px',
                                                                                gridRowGap: '0px',
                                                                                gap: '0.5rem',
                                                                            }}
                                                                        >

                                                                            {
                                                                                exerciseIdsPerBlock[block.idTemporalClonar]?.map((exerciseId, indexExercise) => (
                                                                                    <CardPreviewExercise
                                                                                        key={exerciseId}
                                                                                        index={indexExercise}
                                                                                        exercise={exercises.find((exercise) => exercise.idTemporalClonar === exerciseId)}
                                                                                        exercises={exercises}
                                                                                        handleEditExerciseInTrainingCreation={handleEditExerciseInTrainingCreation}
                                                                                        handleCloneExercise={handleCloneExercise}
                                                                                        handleRemoveExercise={handleRemoveExercise}
                                                                                        handleInputChangeForExercises={handleInputChangeForExercises}
                                                                                        handleInputCheckboxForExercises={handleInputCheckboxForExercises}
                                                                                        blockId={block?.idTemporalClonar}
                                                                                        multipleTrainingDate={multipleTrainingDate}
                                                                                        activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
                                                                                    />
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </Card.Body>
                                                                </Card>
                                                            </div>

                                                        </div>

                                                        <Card.Footer className='p-0 pt-2'>
                                                            <div className="row col-12 col-md-6 col-xl-4 m-auto p-0">
                                                                <div className="col-4">
                                                                    <Button
                                                                        variant="outline-success btn-xs"
                                                                        onClick={() => handleCloneBlocks(block?.idTemporalClonar, index)}
                                                                    >
                                                                        <i className="fa fa-copy" />

                                                                    </Button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Button
                                                                        variant="outline-primary btn-xs"
                                                                        onClick={() => handleAddBlocks(index)}
                                                                    >
                                                                        <i className="fa fa-plus" />

                                                                    </Button>
                                                                </div>
                                                                <div className="col-4">
                                                                    <Button
                                                                        variant="outline-danger btn-xs"
                                                                        onClick={() => handleRemoveBlocks(index, block._id)}
                                                                    >
                                                                        <i className="fa fa-trash" />
                                                                    </Button>
                                                                </div>
                                                            </div>

                                                        </Card.Footer>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </div>
                                        ))
                                }
                                )
                                }
                            </div>
                        </Accordion >
                    )
            }
            <ModalListMyExercises />
        </>
    )
}







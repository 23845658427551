import React from 'react'
import { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import { IconSport } from '..';

const SportIcon = ({ trainingType, type, className, width, height }) => (
  trainingType?.includes(type) && (
    <span className={className}>
      {IconSport(type, width, height)}
    </span>
  )
);

export const ListIconsSports = ({ trainingType, className, widthAndHeight }) => {
  const { lang } = useContext(LanguageContext);
  const { typeTraining } = lang;

  return (
    <>
      <SportIcon trainingType={trainingType} type={typeTraining.run} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.trailrunner} className={className} width="45px" height="45px" />
      <SportIcon trainingType={trainingType} type={typeTraining.bike} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.swimm} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.funcional} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.strong} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.yoga} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.stretching} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.crossfit} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.calistenia} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.hiit} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.tabata} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.amrap} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.emom} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.otm} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.contortion} className={className} width={widthAndHeight} height={widthAndHeight} />
      <SportIcon trainingType={trainingType} type={typeTraining.free} className={className} width={widthAndHeight} height={widthAndHeight} />
    </>
  )
}

export const adaptFormCreateExercise = (formCreateExercise) => {
  const adaptedForm = JSON.parse(JSON.stringify(formCreateExercise));
  adaptedForm.options.series = Number(adaptedForm.options.series);
  adaptedForm.options.repetitions = Number(adaptedForm.options.repetitions);
  adaptedForm.options.breakInfo = Number(adaptedForm.options.breakInfo);
  adaptedForm.options.weightInfo = Number(adaptedForm.options.weightInfo);
  adaptedForm.options.kmInfo = Number(adaptedForm.options.kmInfo);
  adaptedForm.options.hearRateInfo = Number(adaptedForm.options.hearRateInfo);
  adaptedForm.options.cadenceInfo = Number(adaptedForm.options.cadenceInfo);
  adaptedForm.options.metersInfo = Number(adaptedForm.options.metersInfo);
  adaptedForm.options.powerInfo = Number(adaptedForm.options.powerInfo);
  adaptedForm.options.rhythmInfo = Number(adaptedForm.options.rhythmInfo);
  return adaptedForm;
};

export const adaptFormCreateExerciseWithDate = (formCreateExercise, date) => {
  const adaptedForm = JSON.parse(JSON.stringify(formCreateExercise));
  adaptedForm.options.series = adaptedForm.options.series[date] ? Number(adaptedForm.options.series[date]) : 1;
  adaptedForm.options.repetitions = adaptedForm.options.repetitions[date] ? Number(adaptedForm.options.repetitions[date]) : 0;
  adaptedForm.options.breakInfo = adaptedForm.options.breakInfo[date] ? Number(adaptedForm.options.breakInfo[date]) : 1;
  adaptedForm.options.weightInfo = adaptedForm.options.weightInfo[date] ? Number(adaptedForm.options.weightInfo[date]) : 0;
  adaptedForm.options.kmInfo = adaptedForm.options.kmInfo[date] ? Number(adaptedForm.options.kmInfo[date]) : 1;
  adaptedForm.options.hearRateInfo = adaptedForm.options.hearRateInfo[date] ? Number(adaptedForm.options.hearRateInfo[date]) : 30;
  adaptedForm.options.cadenceInfo = adaptedForm.options.cadenceInfo[date] ? Number(adaptedForm.options.cadenceInfo[date]) : 1;
  adaptedForm.options.metersInfo = adaptedForm.options.metersInfo[date] ? Number(adaptedForm.options.metersInfo[date]) : 1;
  adaptedForm.options.powerInfo = adaptedForm.options.powerInfo[date] ? Number(adaptedForm.options.powerInfo[date]) : 1;
  adaptedForm.options.rhythmInfo = adaptedForm.options.rhythmInfo[date] ? Number(adaptedForm.options.rhythmInfo[date]) : 1;
  adaptedForm.options.swimmingStylesInfo = adaptedForm.options.swimmingStylesInfo[date] ? String(adaptedForm.options.swimmingStylesInfo[date]) : "";
  adaptedForm.options.otherInfo = adaptedForm.options.otherInfo[date] ? String(adaptedForm.options.otherInfo[date]) : "";
  adaptedForm.options.timeInfo = adaptedForm.options.timeInfo[date] ? String(adaptedForm.options.timeInfo[date]) : "";
  return adaptedForm;
};

export const adaptCreateBlock = (block) => {
  const adaptedBlock = JSON.parse(JSON.stringify(block));
  adaptedBlock.optionsTraining.series = Number(block.optionsTraining.series);
  adaptedBlock.optionsTraining.breakInfo = Number(block.optionsTraining.breakInfo);
  adaptedBlock.exercises = adaptedBlock.exercises?.map(exercise => {
    const { _id, idPersonalTrainer, idTemporalClonar, blockIdTemp, category, itsFromFitool, ...restExercise } = exercise;
    return adaptFormCreateExercise(restExercise)
  })
  return adaptedBlock;
};

export const adaptUpdateBlock = (block) => {
  const adaptedBlock = JSON.parse(JSON.stringify(block));
  adaptedBlock.optionsTraining.series = Number(block.optionsTraining.series);
  adaptedBlock.optionsTraining.breakInfo = Number(block.optionsTraining.breakInfo);
  // adaptedBlock.exercises = adaptedBlock.exercises?.map(exercise => {
  //   const { _id, idPersonalTrainer, idTemporalClonar, blockIdTemp, category, ...restExercise } = exercise;
  //   return adaptFormCreateExercise(restExercise)
  // })
  return adaptedBlock;
};


export const adaptCreateBlockWithDate = (block, date) => {
  const adaptedBlock = JSON.parse(JSON.stringify(block));
  adaptedBlock.optionsTraining.series = Number(adaptedBlock.optionsTraining.series[date])
  adaptedBlock.optionsTraining.breakInfo = Number(adaptedBlock.optionsTraining.breakInfo[date])
  adaptedBlock.optionsTraining.blockIntensity = adaptedBlock.optionsTraining.blockIntensity[date]
  adaptedBlock.exercises = adaptedBlock.exercises?.map(exercise => {
    const { _id, idPersonalTrainer, idTemporalClonar, blockIdTemp, category, itsFromFitool, ...restExercise } = exercise;
    return adaptFormCreateExerciseWithDate(restExercise, date);
  });
  return adaptedBlock;
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../helpers/Alerts";
import { useGetMyProfile } from "..";
import { useDeletePhoneWhatsAppMutation, userProfileAPI } from "../../api";

export function useWhatsAppNotifications() {
  const dispatch = useDispatch();

  const {
    phoneWhatsApp
  } = useGetMyProfile()

  const [deleteChatIdTelegram, { isErrorDelete, isSuccessDelete, errorDelete, dateDelete }] = useDeletePhoneWhatsAppMutation();

  const handleDeletePhoneWhatsApp = async (e) => {
    e.preventDefault();
    try {
      await deleteChatIdTelegram().unwrap().then(() => {
        dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
      })
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    if (isErrorDelete) showAlert(errorDelete?.data?.msgCode, errorDelete?.data?.ok);
    if (isSuccessDelete) showAlert(dateDelete?.msgCode, dateDelete?.ok);
  }, [dateDelete?.msgCode, dateDelete?.ok, errorDelete?.data?.msgCode, errorDelete?.data?.ok, isErrorDelete, isSuccessDelete]);

  return {
    phoneWhatsApp,
    handleDeletePhoneWhatsApp,
  };
}

export const notificationsType = {
  subscriptionReq: "subscriptionReq",
  newSubscriber: "newSubscriber",
  newWorkout: "newWorkout",
  updatedWorkout: "updatedWorkout",
  fullTraining: "fullTraining",
  newCommentTraining: "newCommentTraining",
  reqPhysiologicalData: "reqPhysiologicalData",
  reqEquipment: "reqEquipment",
  endOfSubscription: "endOfSubscription",
  updatesNews: 'updateNews',
  blockNotes: 'blockNotes',
};
import React, { useContext, useRef } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Card, Table } from "react-bootstrap";
import {
  AlertConLink,
  MeasuresChart,
  TrMeasures,
} from "../components";
import { LanguageContext } from "../context/LanguageContext";
import { InfoIcon } from "../assets/svg/svgs";
import { Routes } from "../routers/Routes";
import { useGetMyPhysiologicalData } from "../hooks";
import { Loading } from "../components/Loading/Loading";

export const MyMeasureDetails = () => {
  const { lang } = useContext(LanguageContext);
  const tableRef = useRef(null)

  const {
    dataFormatedFromGraph,
    isLoading,
    dataPhysiological,
    height,
  } = useGetMyPhysiologicalData()

  const dates = dataPhysiological?.map((measure) =>
    dayjs(measure.createdAt).format("MM/YY")
  );


  const colors = [
    "#00ffff",
    "#cc232b",
    "#2367de",
    "#00b033",
    "#c28600",
    "#ede200",
    "#d85195",
    "#ff8700",
  ];

  if (isLoading) {
    return (<Loading show={isLoading} />)
  }

  if (dataPhysiological?.length === 0) {
    return (
      <>
        <AlertConLink
          variant={"info"}
          Icon={InfoIcon}
          msg={lang.alerts.info}
          text={lang.alerts.measureDetails}
          textButton={lang.notifications.requestPhysiologicalData}
          path={Routes.profile}
        />
      </>
    );
  }

  return (
    <>
      {
        dataPhysiological?.length > 0 &&
        <>
          <Card>
            <Card.Header>
              <Card.Subtitle className="text-primary">
                {lang.generic.height} {height} {lang.generic.cm}
              </Card.Subtitle>
            </Card.Header>

            <Card.Body>
              <MeasuresChart
                data={dataFormatedFromGraph}
                dates={dates}
                colors={colors}
              />
            </Card.Body>
            <Card.Footer>{lang.profile.athlete.footerGrapgh}</Card.Footer>
          </Card>

          <Card>
            <Card.Header>
              <Card.Title>{lang.generic.dataHistorytitle}</Card.Title>

              <Link
                to={Routes.profile}
                className="btn btn-outline-info btn-sm"
              >
                {lang.buttons.updatePhysiologicalData}
              </Link>

              <DownloadTableExcel
                filename="datos_fisiologicos"
                sheet="datos"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-outline-success btn-sm float-right">{lang.buttons.downloadExcel}</button>
              </DownloadTableExcel>
            </Card.Header>

            <Card.Body>
              <Table
                ref={tableRef}
                responsive
                bordered
                className="verticle-middle"
              >
                <thead>
                  <tr>
                    <th scope="col">{lang.generic.date}</th>
                    <th scope="col">{lang.generic.weight}</th>
                    <th scope="col">{lang.generic.arm}</th>
                    <th scope="col">{lang.generic.chest}</th>
                    <th scope="col">{lang.generic.waist}</th>
                    <th scope="col">{lang.generic.abdomen}</th>
                    <th scope="col">{lang.generic.buttocks}</th>
                    <th scope="col">{lang.generic.hip}</th>
                    <th scope="col">{lang.generic.leg}</th>
                    <th scope="col">{lang.generic.actions}</th>
                  </tr>
                </thead>
                <tbody>
                  {dataPhysiological?.map((data, i) => (
                    <TrMeasures
                      key={data._id}
                      id={data._id}
                      fecha={data.createdAt}
                      peso={data.weight}
                      brazo={data.armMeasure}
                      pecho={data.chestMeasure}
                      cintura={data.waistMeasure}
                      abdomen={data.adbomenMeasure}
                      gluteos={data.buttocksMeasure}
                      cadera={data.hipMeasure}
                      pierna={data.legMeasure}
                    />
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </>
      }
    </>
  );
};

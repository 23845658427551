import { createSlice } from '@reduxjs/toolkit'


export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        startLogin: false,
        uid: undefined,
        token: undefined,
        checkAuthAndToken: true,
        startSigNup: false,
        rolePreSigNup: undefined,
    },
    reducers: {
        setStartLogin: (state, action) => {
            state.startLogin = action.payload
        },
        setUid: (state, action) => {
            state.uid = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setCheckAuthAndToken: (state, action) => {
            state.checkAuthAndToken = action.payload
        },
        setStartSigNup: (state, action) => {
            state.startSigNup = action.payload
        },
        setRolePreSigNup: (state, action) => {
            state.rolePreSigNup = action.payload
        },
        setInitialStateAuth: (state) => {
            Object.assign(state, authSlice.getInitialState())
        }
    },
})

export const {
    setStartLogin,
    setUid,
    setToken,
    setCheckAuthAndToken,
    setStartSigNup,
    setRolePreSigNup,
    setInitialStateAuth,
} = authSlice.actions
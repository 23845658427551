import React, { useContext } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { formatDate } from "../../helpers/formatDate";
import { LanguageContext } from "../../context/LanguageContext";
import { ModalVideo } from "../modals/ModalVideo";
import { useGetMyProfile } from "../../hooks";
import { useSlicesActions, useSlicesState } from "../../redux/slices";


export const TrainingSimpleMode = ({ training, tableRef }) => {

  const { lang } = useContext(LanguageContext);

  const { toggleCreateTrainingSimpleMode } = useSlicesState()
  const {
    dispatch,
    setIsCreateExerciseInTrainingTrue,
    setIsCloneTrainingTrue,
    setTrainingToClone,
    setToggleCreateTrainingSimpleMode,
  } = useSlicesActions()




  return (
    <div
      className="mb-3"
      style={{ border: '1px solid #eeeeee', borderRadius: '0.8rem' }}
    >
      <Table
        responsive
        className="header-border"
        ref={tableRef}

      >
        <thead >


          <tr >
            <CumstomTh text={`${training?.title} ${formatDate(training?.startDate)}`} clone={false} />
            <CumstomTh
              text={lang.generic.exercices}
              training={training}
              clone={true}
              dispatch={dispatch}
              setIsCreateExerciseInTrainingTrue={setIsCreateExerciseInTrainingTrue}
              setIsCloneTrainingTrue={setIsCloneTrainingTrue}
              setTrainingToClone={setTrainingToClone}
              toggleCreateTrainingSimpleMode={toggleCreateTrainingSimpleMode}
              setToggleCreateTrainingSimpleMode={setToggleCreateTrainingSimpleMode}
            />
          </tr>

        </thead>
        {
          training?.blocksId?.map((block, index) => (
            <tbody key={index}>

              <tr>

                <CustomTdBlock block={block} lang={lang} />

                <CustomTdExercise
                  exercises={block?.exercisesId}
                  lang={lang}
                  isVideo={false}
                />

              </tr>
            </tbody>
          ))
        }
      </Table>
    </div>
  );
};



const CumstomTh = ({
  training,
  text,
  clone,
  dispatch,
  setIsCreateExerciseInTrainingTrue,
  setIsCloneTrainingTrue,
  setTrainingToClone,
  toggleCreateTrainingSimpleMode,
  setToggleCreateTrainingSimpleMode
}) => {
  const { isAthlete } = useGetMyProfile()
  return (
    <>
      <th
        className="text-primary text-capitalize"
        style={{ width: 'min-content', fontSize: '0.8rem', fontWeight: 'normal' }}
      >{text}
        {
          clone && !isAthlete() &&
          <Button
            as="a"
            variant="outline-secondary float-right absolute"
            href="#"
            className="btn-xs"
            onClick={() => {
              if (training?.isSimpleMode) {
                if (!toggleCreateTrainingSimpleMode) {
                  dispatch(setToggleCreateTrainingSimpleMode())
                }
              }
              dispatch(setIsCreateExerciseInTrainingTrue())
              dispatch(setIsCloneTrainingTrue())
              dispatch(setTrainingToClone(training))
            }}
          >
            <i className={`fa fa-copy`} />
          </Button>
        }
      </th>

    </>
  )
}

const CustomTdBlock = ({ block, lang }) => {
  return (
    <td>
      <ul>
        <li
          className="text-capitalize"
          style={{ fontSize: '0.8rem', fontWeight: 'normal' }}
        > {`${block?.name} (x${block?.optionsTraining?.series})`}<br />
          {block?.optionsTraining?.heating && (
            <span className="text-warning">({lang.generic.heating})</span>
          )}
          {block?.optionsTraining?.mobility && (
            <span className="text-success">({lang.generic.mobility})</span>
          )}
          {block?.optionsTraining?.activation && (
            <span className="text-danger">({lang.generic.activation})</span>
          )}
          {block?.optionsTraining?.stretching && (
            <span className="text-warning">({lang.generic.stretching})</span>
          )}
        </li>

      </ul>
    </td>
  )

};

const CustomTdExercise = ({ exercises, lang, isVideo }) => {
  return (
    <td>
      <ul>
        {!isVideo && exercises?.map((exercise, index) => (
          <LiExercise
            key={index}
            exercise={exercise}
            lang={lang}
          />
        ))}

      </ul>
    </td>


  );
};



export const LiExercise = ({ exercise, lang }) => {

  const { dispatch, setModalViewVideo } = useSlicesActions()
  return (
    <>
      <li
        className="text-capitalize"
        style={{ fontSize: '0.8rem', fontWeight: 'normal' }}
      >{`${exercise?.name} ${exercise?.options?.repetitions > 1 ? `(x${exercise?.options?.repetitions})` : ""}`}

        {
          exercise?.description && <span className="text-info" style={{ fontSize: '0.7rem' }}> ( {exercise?.description})</span>
        }
        {
          exercise?.video &&
          <Badge
            variant="secondary"
            className="badge-xs light ml-1 text-capitalize cursor-pointer"
            onClick={() => dispatch(setModalViewVideo(exercise?._id))}
          >
            {lang.generic.video}
          </Badge>
        }
      </li>
      <ModalVideo
        video={exercise?.video}
        nombreEjercicio={exercise?.name}
        idModal={exercise?._id}
      />
    </>
  )
}

import { useSelector } from "react-redux";
import { trainingAPI, useGetMyWorkoutQuery, useGetTrainingFromProfessionalQuery } from "../../api";
import { useGetMyProfile } from "../users/useGetMyProfile";
import { useEffect, useMemo } from "react";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { showAlert } from "../../helpers/Alerts";


export const useGetTrainingFromProfessional = (query) => {
  const { selectUser, trainings: trainingsState } = useSlicesState()
  const { isAthlete } = useGetMyProfile()

  const shouldSkipFromProfesional = !isAthlete() || !selectUser;

  const { dispatch, setQuery, setTrainings } = useSlicesActions()

  const memoizedQuery = useMemo(() => query, [query]);

  const { isLoading: isLoadingDataFromProfessional, isFetching: isFetchingFromProfessional, isError: isErrorFromProfessional, error: errorFromProfessional } = useGetTrainingFromProfessionalQuery((memoizedQuery), {
    skip: shouldSkipFromProfesional,
    refetchOnMountOrArgChange: 1800,
    refetchOnFocus: false,
    refetchOnReconnect: false,
    enabled: false,
  });

  const shouldSkipMyWorkout = !isAthlete() || selectUser;

  const { isLoading: isLoadingDataMyWorkout, isFetching: isFetchingMyWorkout, isError: isErrorMyWorkout, error: errorMyWorkout } = useGetMyWorkoutQuery((memoizedQuery), {
    skip: shouldSkipMyWorkout,
    refetchOnMountOrArgChange: 1800,
    refetchOnFocus: true,
    refetchOnReconnect: false,
    enabled: false,
  });


  const { data: dataGetTrainingFromProfesional } = useSelector(trainingAPI.endpoints.getTrainingFromProfessional.select(memoizedQuery));
  const { data: dataGetMyWorkout } = useSelector(trainingAPI.endpoints.getMyWorkout.select(memoizedQuery));

  const trainingsFromProfesional = dataGetTrainingFromProfesional?.trainings
  const trainingsGetMyWorkout = dataGetMyWorkout?.trainings

  const isLoadingGetTrainingFromProfesional = isLoadingDataFromProfessional || isFetchingFromProfessional
  const isLoadingGetMyWorkout = isLoadingDataMyWorkout || isFetchingMyWorkout

  useEffect(() => {
    if (trainingsFromProfesional && selectUser) dispatch(setTrainings(trainingsFromProfesional))
    if (trainingsGetMyWorkout && !selectUser) dispatch(setTrainings(trainingsGetMyWorkout))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trainingsFromProfesional, trainingsGetMyWorkout, dataGetTrainingFromProfesional])


  useEffect(() => {
    if (query) dispatch(setQuery(query))
  }, [dispatch, query, setQuery])

  useEffect(() => {
    if (isErrorFromProfessional) showAlert(errorFromProfessional?.data?.msgCode, errorFromProfessional?.data?.ok);
    if (isErrorMyWorkout) showAlert(errorMyWorkout?.data?.msgCode, errorMyWorkout?.data?.ok);

  }, [errorFromProfessional, errorMyWorkout?.data?.msgCode, errorMyWorkout?.data?.ok, isErrorFromProfessional, isErrorMyWorkout])

  return {
    isLoadingGetTrainingFromProfesional,
    isLoadingGetMyWorkout,
    trainingsFromProfessional: trainingsState,
    trainingPendingFromProfessional: trainingsState?.filter((training) => !training.isComplete),
    trainingFinishedFromProfessional: trainingsState?.filter((training) => training.isComplete),

  };
};



import { analyticsAPI, useTerminateBlockMutation } from "../../api";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useTerminateBlock() {
  const [terminateBlock, { isError, error }] = useTerminateBlockMutation();
  const { dispatch } = useSlicesActions()

  const { query } = useSlicesState()

  const handleTerminateBlockInTraining = (idBlock, idTraining, idProfessional) => {
    terminateBlock({ idBlock, idTraining, idProfessional, query })
    dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
  }

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);


  return {
    handleTerminateBlockInTraining
  };
}


import { useState } from "react";

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const updateNestedObject = (obj, key, value) => {
    if (key in obj) {
      return { ...obj, [key]: value };
    } else {
      for (let k in obj) {
        if (typeof obj[k] === 'object') {
          let nestedObj = updateNestedObject(obj[k], key, value);
          if (nestedObj) {
            return { ...obj, [k]: nestedObj };
          }
        }
      }
    }
    return null;
  }

  const handleInputChange = ({ target }) => {
    let value;

    if (target.type === "checkbox") {
      value = target.checked;
    } else if (target.type === "number") {
      value = Number(target.value);
    } else {
      value = target.value;
    }

    let updatedValues = updateNestedObject(values, target.name, value);
    if (updatedValues) {
      setValues(updatedValues);
    }
  };

  return [values, handleInputChange, reset, setValues];
};

import React from "react";
import { Header, NavHader, Sidebar } from ".";

export const Nav = () => {
  return (
    <>
      <NavHader />
      <Sidebar />
      <Header />
    </>
  );
};

export default Nav;

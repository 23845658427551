import React, { useContext } from 'react'
import { Button, Card } from 'react-bootstrap'
import { useSlicesActions } from '../../redux/slices'
import { LanguageContext } from '../../context/LanguageContext';
import { blockOptionsTraining, otherBlockOptionsTraining } from '../../assets/data/sports';
import { CheckBoxParametersForDinamicForm } from '../checkboxs/CheckBoxParameters';
import { RangeInputWithProgressionForBlocks } from '../Inputs/RangeInputWithProgressionForBlocks';
import { SelectInputWithProgressionForBlocks } from '../Inputs/SelectInputWithProgressionForBlocks';
import { blockIntensity } from '../../assets/data/blockIntensity';
import { useUpdateBlock } from '../../hooks';
import { getValuesInputBlocks } from '../../helpers/getValuesInputBlocks';

export const EditBlock = ({ block, setBlockToEdit, training }) => {
    const { lang } = useContext(LanguageContext);

    const {
        name,
        description,
        reset,
        optionsTraining,
        handleFormUpdateBlock,
        handleUpdateBlockInTraining,
        isDisabled,
    } = useUpdateBlock(block, setBlockToEdit)

    const { dispatch, setBlockIsBeingEditedFalse } = useSlicesActions()

    return (
        <div
            className="col-12"
        >
            <Card className={`text-black bgl-white border-2 border-info`} >
                <Card.Header>
                    <Card.Title className="text-primary">
                        {`Editar: ${name}`}
                    </Card.Title>
                    <div>
                        <Button
                            as="a"
                            variant="success"
                            href="#"
                            className="btn-card btn-xs mr-4"
                            disabled={isDisabled}
                            onClick={() => {
                                handleUpdateBlockInTraining(block._id)
                                dispatch(setBlockIsBeingEditedFalse())
                                setBlockToEdit({})
                            }}
                        >
                            <i className={`fa fa-save mr-2`} />
                            {lang.buttons.update}
                        </Button>

                        <Button
                            as="a"
                            variant="info "
                            href="#"
                            className="btn-card btn-xs"
                            onClick={() => {
                                dispatch(setBlockIsBeingEditedFalse())
                                setBlockToEdit({})
                                reset()
                            }}
                        >
                            <i className={`fa fa-ban mr-2`} />
                            {lang.buttons.cancel}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>

                    <div className="row">

                        <div className={`mt-2 ${training?.isSimpleMode ? "col-12" : "col-12 col-xl-6"}`}>
                            <input
                                type="text"
                                name="name"
                                className="form_test_entrenamiento"
                                required={name?.length < 1}
                                placeholder={lang.placeHolders.nameBlock}
                                value={name}
                                onChange={handleFormUpdateBlock}
                            />
                        </div>
                        {!training?.isSimpleMode &&
                            <div className="mt-2 col-12 col-xl-6">
                                <textarea
                                    className="form-control h-100 "
                                    rows="2"
                                    id="description"
                                    name="description"
                                    value={description}
                                    placeholder={lang.blocks.optionalShortDescriptionExercise}
                                    onChange={handleFormUpdateBlock}
                                    required={description?.length < 1}
                                ></textarea>
                            </div>
                        }

                        {!training?.isSimpleMode &&
                            <div className="mt-2 col-12 col-xl-6">
                                <h5 className="text-primary mt-2">{lang.createTraining.optionsToExercice}</h5>
                                <div className="d-flex flex-column flex-md-row">
                                    <div className="mb-2">
                                        {blockOptionsTraining?.map(param =>
                                            CheckBoxParametersForDinamicForm({
                                                options: optionsTraining,
                                                param: param.value,
                                                onChange: handleFormUpdateBlock,
                                                color: param.color,
                                                isVisible: true,
                                            })
                                        )}
                                    </div>
                                </div>

                                <div
                                    className={`d-flex`}
                                    style={{ gap: '0.5rem' }}
                                >
                                    <h5 className="text-info mt-0 mb-0 ">{lang.generic.series}</h5>
                                    <RangeInputWithProgressionForBlocks
                                        name="series"
                                        value={optionsTraining?.series}
                                        getOptionValue={() => null}
                                        handleInputChangeForBlocks={handleFormUpdateBlock}
                                        min={1}
                                        max={50}
                                        inputType="range"
                                    />

                                </div>
                            </div>
                        }
                        {!training?.isSimpleMode &&
                            <div className="mt-2 col-12 col-xl-6">
                                <div
                                    className="d-flex flex-column"
                                    style={{ gap: '0.5rem' }}
                                >
                                    <h5 className="text-info mt-0 mb-0">
                                        {lang.createTraining.breakAtFinish}
                                    </h5>

                                    <div className="d-flex flex-column align-items-center">

                                        {otherBlockOptionsTraining?.map(param => {
                                            const isVisible = param.value !== "isUsingMinutes" || optionsTraining?.breakRecovery;

                                            return (
                                                CheckBoxParametersForDinamicForm({
                                                    options: optionsTraining,
                                                    param: param.value,
                                                    onChange: handleFormUpdateBlock,
                                                    color: param.color,
                                                    isVisible: isVisible
                                                })
                                            )
                                        }


                                        )}

                                    </div>
                                    {optionsTraining?.breakRecovery && (
                                        <RangeInputWithProgressionForBlocks
                                            name="breakInfo"
                                            value={optionsTraining?.breakInfo}
                                            block={block}
                                            getOptionValue={() => null}
                                            handleInputChangeForBlocks={handleFormUpdateBlock}
                                            min={1}
                                            max={60}
                                            step={optionsTraining?.isUsingMinutes ? 0.5 : 1}
                                            text={optionsTraining?.isUsingMinutes ? '"' : "'"}
                                            inputType="range"
                                        />

                                    )}
                                </div>

                                <div
                                    className={`mt-4`}
                                    style={{ gap: '0.5rem' }}
                                >
                                    <h5 className="text-info mt-0 mb-0 ">{lang.generic.intensity}</h5>
                                    <SelectInputWithProgressionForBlocks
                                        name="blockIntensity"
                                        block={block}
                                        getOptionValue={getValuesInputBlocks}
                                        handleInputChangeForBlocks={handleFormUpdateBlock}
                                        options={blockIntensity}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        }

                    </div>

                </Card.Body>
            </Card>
        </div>
    )
}

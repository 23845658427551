import React from "react";
import {
  CardAboutMeDetailSearch,
  CardDetailSearchTraining,
  CardOtherProfileDetail,
} from "../components";
import { useGetOneProfile, useSearchUserDetails } from "../hooks";
import { Loading } from '../components/Loading/Loading'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../routers/Routes";

export const SearchDetails = () => {

  const history = useHistory();

  const {
    fullProfile,
    sports,
    deportesParaPersonalTrainer,
    deportesParaStretching,
    otherUserProfile
  } = useSearchUserDetails()

  const {
    otherUserIsPersonalTrainer,
    otherUserIsYogaStretching,
    isLoading
  } = useGetOneProfile()


  if (isLoading) return <Loading show={isLoading}/>
  if (!otherUserProfile?.name) history.push(Routes.subscriptions);

  return (
    <>
      {
        <div className="row">
          <CardOtherProfileDetail {...otherUserProfile} />
          {fullProfile?.aboutMe && <CardAboutMeDetailSearch {...otherUserProfile} />}
          {sports.length > 0 && otherUserIsPersonalTrainer() && (
            <CardDetailSearchTraining training={deportesParaPersonalTrainer} />
          )}
          {sports.length > 0 && otherUserIsYogaStretching() && (
            <CardDetailSearchTraining training={deportesParaStretching} />
          )}
        </div>
      }
    </>
  );
};

export const filterIdsAndReturnStatusSuscriber = (subscriptions = [], id) => {
  const iDsidInviting = subscriptions?.map((user) => user.idInviting._id);
  const iDsGuestId = subscriptions?.map((user) => user.guestId._id);

  const myIdSusbcriptions = iDsidInviting.concat(iDsGuestId);

  const filterIds = myIdSusbcriptions.filter((valor, indice) => {
    return myIdSusbcriptions.indexOf(valor) === indice;
  });

  return filterIds.includes(id);
};

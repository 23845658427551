import { useDeleteTrainingMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesState } from "../../redux/slices/hook";

export function useDeleteTraining() {
  const { lang } = useContext(LanguageContext);
  const { query } = useSlicesState()
  const [deleteTraining, { isError, isSuccess, error, data }] = useDeleteTrainingMutation();

  const handleDeleteTraining = (trainingId) => {
    Swal.fire({
      title: lang.generic.areYouSure,
      icon: "warning",
      text: lang.viewExercise.alertDeleteTraining.text,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.viewExercise.alertDeleteTraining.textButtonOne,
      confirmButtonText: lang.viewExercise.alertDeleteTraining.textButtonTwo,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTraining({ trainingId, query, alertError })
      }
    });
  };

  const alertError = () => {
    Swal.fire({
      title: lang.viewExercise.alertDeleteTraining.titleErrorDelete,
      icon: "warning",
      text: lang.viewExercise.alertDeleteTraining.textErrorDelete,
    })
  }

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);


  return {
    handleDeleteTraining
  };
}


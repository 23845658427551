import { useStateRedux } from "../useStateAPI";
import { useGetDataForOneUserQuery } from "../../api/userCardsAPI";
import { useSlicesState } from "../../redux/slices";

export const useGetDataForOneUser = () => {

  const { otherUserId } = useSlicesState()

  const { isLoading: isLoadingData, isFetching } = useGetDataForOneUserQuery(otherUserId?._id, {
    refetchOnMountOrArgChange: 1800,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  })

  const { dataGetDataForOneUser } = useStateRedux()

  const userCards = dataGetDataForOneUser?.userCardsDocs
  const ok = dataGetDataForOneUser?.ok
  const msgCode = dataGetDataForOneUser?.msgCode

  const isLoading = isLoadingData || isFetching  

  return {
    userCards,
    isLoading,
    userSelect: otherUserId,
    ok,
    msgCode,
  };
};



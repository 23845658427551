import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const url = process.env.REACT_APP_API_SERVER;
const customHeader = process.env.REACT_APP_CUSTOM_HEADER

export const getBaseQueryConfig = () => {
    return fetchBaseQuery({baseUrl: url,
        prepareHeaders: (headers, { getState }) => {
            headers.set("x-token", `${getState().auth.token}`);
            headers.set("X-Custom-Header", customHeader);
            return headers;
        },
    })
}
import { useDeleteUserCardMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesState } from "../../redux/slices";

export function useDeleteUserCard() {
  const { lang } = useContext(LanguageContext);
  const { otherUserId } = useSlicesState()
  const [deleteUserCard, { isError, isSuccess, error, data }] = useDeleteUserCardMutation();
  
    const handleRemoveUserCard = (userCardId) => {
      Swal.fire({
        title: lang.generic.areYouSure,
        icon: "warning",
        text: lang.userCard.textDeleted,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        cancelButtonText: lang.generic.no,
        confirmButtonText: lang.userCard.textConfirmButton,
        confirmButtonColor: "#e64942",
        customClass: {
          cancelButton: "order-1",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          deleteUserCard({userCardId, userId: otherUserId?._id})
        }
      });
    };

    useEffect(() => {
      if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);
  
  
    return {
      handleRemoveUserCard
    };
  }


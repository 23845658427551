import { useEffect } from "react";
import { useDeteleFeedBackMesasgeMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesState } from "../../redux/slices/hook";

export function useDeleteFeedBack() {
  const { trainingIdSelected } = useSlicesState()

  const [deleteFeedBack, { isError, error }] = useDeteleFeedBackMesasgeMutation();

  const handleDeleteFeedBack = async (idFeedBack) => {
    await deleteFeedBack({ idFeedBack, idTraining: trainingIdSelected, }).unwrap()
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);


  return {
    handleDeleteFeedBack
  };
}


import Swal from "sweetalert2";
import { API } from "../api";

export const deleteUser = async () => {
    try {
        await API.delete(`/users/delete`);
    } catch (error) {
        console.log(error);
        Swal.fire("Oops 😥", error.response.data.msg, "error");
    }
};
import { useState, useEffect, useMemo, useContext } from "react";
import { useCreateEquipmentAssociationMutation, userProfileAPI } from "../../api";
import { useGetMyProfile } from "..";
import { useDispatch } from "react-redux";
import { loadingAlert, showAlert } from "../../helpers/Alerts";
import { getTrueKeys } from "../../helpers/getTrueKeys";
import { ValidateEquipment } from "../../assets/data/validateEquipment";
import { useForm } from '../../hooks'
import Swal from "sweetalert2";
import { LanguageContext } from "../../context/LanguageContext";

export function useEquipmentAssociation() {
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [createEquipmentAssociation, { isError, isSuccess, error, isLoading, data }] = useCreateEquipmentAssociationMutation();
  const { equipment: originalEquipment } = useGetMyProfile()

  const actualEquipment = getTrueKeys(originalEquipment ? originalEquipment : []);

  const [equipment, setEquipment] = useState(actualEquipment ? [...actualEquipment] : []);

  const [dynamicFormValues, handleDynamicFormChange] = useForm({
    weightDumbbells: originalEquipment?.dumbbells ? originalEquipment?.dumbbellOptions?.weight : 1,
    withPairDumbbells: originalEquipment?.dumbbells && originalEquipment?.dumbbellOptions?.withPair ? true : false,
    weightKettlebell: originalEquipment?.kettlebell ? originalEquipment?.kettlebellOptions?.weight : 1,
    weightDisk: originalEquipment?.disk ? originalEquipment?.diskOptions?.weight : 1,
    fullyStretched: originalEquipment?.chinUpBar ? originalEquipment?.chinUpBarOptions?.fullyStretched : false,
    spaceAbove: originalEquipment?.chinUpBar ? originalEquipment?.chinUpBarOptions?.spaceAbove : false,
    swayFromSideToSide: originalEquipment?.chinUpBar ? originalEquipment?.chinUpBarOptions?.swayFromSideToSide : false,
    jumpsAndSwings: originalEquipment?.chinUpBar ? originalEquipment?.chinUpBarOptions?.jumpsAndSwings : false,
    weightBar: originalEquipment?.bar ? originalEquipment?.barOptions?.weight : 1,
    otherDetails: originalEquipment?.other ? originalEquipment?.otherOptions?.details : ""
  });

  const [response, setRsponse] = useState({})

  const handleCreateEquipmentAssociation = async () => {
    if (equipment?.includes(ValidateEquipment.Other) && dynamicFormValues?.otherDetails?.length === 0) return Swal.fire("Oops 😥", 'Complete los detalles', "warning");
    await createEquipmentAssociation(response).unwrap().then(() => {
      dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
    })
    Swal.close();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formMemorized = useMemo(() => dynamicFormValues, []);
  const formOptionsISCganged = formMemorized === dynamicFormValues
  const isDisabled = JSON.stringify(equipment) === JSON.stringify(actualEquipment) && formOptionsISCganged


  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    setRsponse({
      "dumbbells": equipment?.includes(ValidateEquipment.Dumbbells),
      "kettlebell": equipment?.includes(ValidateEquipment.Kettlebell),
      "disk": equipment?.includes(ValidateEquipment.Disk),
      "chinUpBar": equipment?.includes(ValidateEquipment.ChinUpBar),
      "bar": equipment?.includes(ValidateEquipment.Bar),
      "other": equipment?.includes(ValidateEquipment.Other),
      // "inGym": equipment?.includes(ValidateEquipment.Gym),
      "bank": equipment?.includes(ValidateEquipment.Bank),
      "rack": equipment?.includes(ValidateEquipment.Rack),
      "resistanceBand": equipment?.includes(ValidateEquipment.ResistanceBand),
      "jumpBox": equipment?.includes(ValidateEquipment.JumpBox),
      "wall": equipment?.includes(ValidateEquipment.Wall),
      "jumpRope": equipment?.includes(ValidateEquipment.JumpRope),
      "roller": equipment?.includes(ValidateEquipment.Roller),
      "abdominalWheel": equipment?.includes(ValidateEquipment.AbdominalWheel),
      "dumbbellOptions": {
        "withPair": dynamicFormValues.withPairDumbbells ? dynamicFormValues.withPairDumbbells : false,
        "weight": dynamicFormValues.weightDumbbells
      },
      "kettlebellOptions": {
        "weight": dynamicFormValues.weightKettlebell
      },
      "diskOptions": {
        "weight": dynamicFormValues.weightDisk
      },
      "chinUpBarOptions": {
        "fullyStretched": dynamicFormValues.fullyStretched,
        "spaceAbove": dynamicFormValues.spaceAbove,
        "swayFromSideToSide": dynamicFormValues.swayFromSideToSide,
        "jumpsAndSwings": dynamicFormValues.jumpsAndSwings
      },
      "barOptions": {
        "weight": dynamicFormValues.weightBar
      },
      "otherOptions": {
        "details": dynamicFormValues.otherDetails
      },
    })
  }, [equipment, dynamicFormValues])

  useEffect(() => {
    if (isLoading) {
      loadingAlert(lang.alerts.associatingEquipment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    equipment,
    originalEquipment,
    setEquipment,
    dynamicFormValues,
    handleDynamicFormChange,
    handleCreateEquipmentAssociation,
    isDisabled
  };
}

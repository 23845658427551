import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { CardSportItemSelect } from "..";
import { LanguageContext } from "../../context/LanguageContext";

import { useSportAssociation } from "../../hooks";

export const CardSelectTraining = ({ training }) => {
  const { lang } = useContext(LanguageContext)

  const {
    sports,
    setSports,
    handleCreateSportAssociation,
    isDisabled
  } = useSportAssociation()


  return (
    <>
      <div>
        <div className="card overflow-hidden ">
          <div className="card-body">
            <div className="profile-skills ">
              <h4 className="text-primary">
                {lang.profile.personalTrainer.titleSelectTrainning}
              </h4>
              <p className="text-muted">
                {lang.profile.personalTrainer.subtitleSelectTraining}
              </p>

              <Card.Body
                className="scroll_test_responsive scrollbar-color p-2"
                style={{ height: 'auto', maxHeight: '50dvh', backgroundColor: '#f9f9f9', borderRadius: '1rem' }}
              >
                {training?.map((sport, i) => (
                  <CardSportItemSelect
                    key={i}
                    color={sport.color}
                    title={sport.item}
                    value={sport.value}
                    action={sport.item}
                    setSports={setSports}
                    sports={sports}
                  />
                ))}
              </Card.Body>
              <Card.Footer style={{border: 'none'}}>
                {sports.length > 0 && (
                  <Button
                    className="float-right btn-sm"
                    variant="secondary xs"
                    onClick={() => handleCreateSportAssociation()}
                    disabled={isDisabled}
                  >
                    {lang.buttons.save}
                  </Button>
                )}
              </Card.Footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ValidateEquipment = {
  Dumbbells: 'dumbbells',
  Kettlebell: 'kettlebell',
  Bank: 'bank',
  Disk: 'disk',
  Rack: 'rack',
  ResistanceBand: 'resistanceBand',
  ChinUpBar: 'chinUpBar',
  JumpBox: 'jumpBox',
  Wall: 'wall',
  JumpRope: 'jumpRope',
  Roller: 'roller',
  Bar: 'bar',
  AbdominalWheel: 'abdominalWheel',
  Other: 'other',
  // Gym: 'inGym'
};

export const equipment = [
  {
    item: "Mancuernas",
    name: "Mancuernas",
    value: ValidateEquipment.Dumbbells,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/es4rrkomchosyqzhxgqn.webp",
    withOptions: true,
    options: [
      {
        type: "number",
        item: "weightDumbbells",
        name: "Peso",
        value: "weightDumbbells",
      },
      {
        type: "checkbox",
        item: "withPairDumbbells",
        name: "Par",
        value: "withPairDumbbells",
      },
    ]
  },
  {
    item: "Pesa Rusa",
    name: "Pesa Rusa",
    value: ValidateEquipment.Kettlebell,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/mrtfhrrxwzjmi2goal4g.webp",
    withOptions: true,
    options: [
      {
        type: "number",
        item: "weightKettlebell",
        name: "Peso",
        value: "weightKettlebell",
      }
    ]
  },
  {
    item: "Banco",
    name: "Banco",
    value: ValidateEquipment.Bank,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/fkh3viyepwfq5usnyqak.webp"
  },
  {
    item: "Disco",
    name: "Disco",
    value: ValidateEquipment.Disk,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/xoggyen1sq3qd3kjw3aa.webp",
    withOptions: true,
    options: [
      {
        type: "number",
        item: "weightDisk",
        name: "Peso",
        value: "weightDisk",
      }
    ]
  },
  {
    item: "Rack",
    name: "Rack",
    value: ValidateEquipment.Rack,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/yz1pzgavvzgnm2n6yous.webp"
  },
  {
    item: "Banda de Resistencia",
    name: "Banda de Resistencia",
    value: ValidateEquipment.ResistanceBand,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/qu8rpsjyqshzqdonhwha.webp"
  },
  {
    item: "Barra de Dominadas",
    name: "Barra de Dominadas",
    value: ValidateEquipment.ChinUpBar,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/rxagjwedse7eqtqmnsba.webp",
    withOptions: true,
    options: [
      {
        type: "checkbox",
        item: "fullyStretched",
        name: "Puedo colgarme de la barra con el cuerpo totalmente estirado.",
        value: "fullyStretched",
      },
      {
        type: "checkbox",
        item: "spaceAbove",
        name: "Tengo suficiente espacio por encima de la barra para realizar MuscleUps.",
        value: "spaceAbove",
      },
      {
        type: "checkbox",
        item: "swayFromSideToSide",
        name: "Tengo suficiente espacio alrededor para balancearme de lado a lado.",
        value: "swayFromSideToSide",
      },
      {
        type: "checkbox",
        item: "jumpsAndSwings",
        name: "Mi barra es lo suficientemente segura para realizar saltos y balanceos.",
        value: "jumpsAndSwings",
      },
    ]
  },
  {
    item: "Cajon de Salto",
    name: "Cajon de Salto",
    value: ValidateEquipment.JumpBox,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/xp9mzz56639gn2pfzjl2.webp"
  },
  {
    item: "Pared",
    name: "Pared",
    value: ValidateEquipment.Wall,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/wen3l3phy5dlwkr8dgbi.jpg"
  },
  {
    item: "Cuerda de Saltar",
    name: "Cuerda de Saltar",
    value: ValidateEquipment.JumpRope,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/aatgdlhld1uv8szpvzfe.webp"
  },
  {
    item: "Rolo",
    name: "Rolo",
    value: ValidateEquipment.Roller,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/e4krs9odmldkiwxsluq6.webp"
  },
  {
    item: "Barra",
    name: "Barra",
    value: ValidateEquipment.Bar,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/ujcndyr9kgrjex7cma16.webp",
    withOptions: true,
    options: [
      {
        type: "number",
        item: "weightBar",
        name: "Peso",
        value: "weightBar",
      }
    ]
  },
  {
    item: "Rueda Abdominal",
    name: "Rueda Abdominal",
    value: ValidateEquipment.AbdominalWheel,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/ecp2icb0k0p5fwjzszlk.webp"
  },
  // {
  //   item: "Gym",
  //   name: "Gym",
  //   value: ValidateEquipment.Gym,
  //   img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1715117567/fitapp/equipment/brdgg9marya3nhpdsiti.webp"
  // },
  {
    item: "Otro",
    name: "Otro",
    value: ValidateEquipment.Other,
    img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/pxb0a70j7iif5q26pidi.webp",
    withOptions: true,
    options: [
      {
        type: "string",
        item: "otherDetails",
        name: "Otro",
        value: "otherDetails",
      }
    ]
  }
];

export const optionsEquipment = {
  withDumbbells: false,
  withKettlebell: false,
  withBank: false,
  withDisk: false,
  withRack: false,
  withResistanceBand: false,
  withChinUpBar: false,
  withJumpBox: false,
  withWall: false,
  withJumpRope: false,
  withRoller: false,
  withBar: false,
  withAbdominalWheel: false,
  withOther: false,
  // inGym: false
}

export const filterOptionsEquipment = (optionsEquipment, allOptions) => {
  const filteredOptions = allOptions.filter(option => optionsEquipment[option.value]);
  return filteredOptions;
};

// export const updateOptionsEquipment = (optionsEquipment, equipmentArray) => { //este segun el array del usuario, genera un optionsEquipment con los bolleans correspondientes
//   const updatedOptionsEquipment = { ...optionsEquipment };
//   equipmentArray.forEach((equipment) => {
//     if (equipment.value in updatedOptionsEquipment) {
//       updatedOptionsEquipment[equipment.value] = true;
//     }
//   });
//   return updatedOptionsEquipment;
// };



export const getTextsEquipment = (reponseEquipmentAPI) => {
  let texts = [];

  if (reponseEquipmentAPI?.dumbbells) {
    let texto = reponseEquipmentAPI.dumbbellOptions.withPair ? `(2 x ${reponseEquipmentAPI.dumbbellOptions.weight}Kg. c/u)` : `(1 x ${reponseEquipmentAPI.dumbbellOptions.weight}Kg.)`;
    texts.push({ value: 'withDumbbells', label: `Mancuerna ${texto}`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/es4rrkomchosyqzhxgqn.webp" });
  }

  if (reponseEquipmentAPI?.kettlebell) {
    texts.push({ value: 'withKettlebell', label: `Pesa Rusa (${reponseEquipmentAPI.kettlebellOptions.weight}Kg.)`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/mrtfhrrxwzjmi2goal4g.webp" });
  }

  if (reponseEquipmentAPI?.disk) {
    texts.push({ value: 'withDisk', label: `Disco (${reponseEquipmentAPI.diskOptions.weight}Kg.)`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/xoggyen1sq3qd3kjw3aa.webp" });
  }

  if (reponseEquipmentAPI?.bar) {
    texts.push({ value: 'withBar', label: `Barra (${reponseEquipmentAPI.barOptions.weight}Kg.)`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/ujcndyr9kgrjex7cma16.webp" });
  }

  if (reponseEquipmentAPI?.chinUpBar) {
    let opciones = '';
    if (reponseEquipmentAPI.chinUpBarOptions.fullyStretched) opciones += 'Colgarse de la barra completamente. ';
    if (reponseEquipmentAPI.chinUpBarOptions.spaceAbove) opciones += 'Espacio superior para realizar MuscleUps. ';
    if (reponseEquipmentAPI.chinUpBarOptions.swayFromSideToSide) opciones += 'Balancearme de lado a lado. ';
    if (reponseEquipmentAPI.chinUpBarOptions.jumpsAndSwings) opciones += 'Realizar saltos y balanceos. ';

    let label = 'Barra de Dominadas';
    if (opciones) label += ', permite: ' + opciones;

    texts.push({ value: 'withChinUpBar', label: label, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/rxagjwedse7eqtqmnsba.webp" });
  }

  if (reponseEquipmentAPI?.bank) {
    texts.push({ value: 'withBank', label: `Banco`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/fkh3viyepwfq5usnyqak.webp" });
  }

  if (reponseEquipmentAPI?.rack) {
    texts.push({ value: 'withRack', label: `Rack`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/yz1pzgavvzgnm2n6yous.webp" });
  }

  if (reponseEquipmentAPI?.wall) {
    texts.push({ value: 'withWall', label: `Pared`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/wen3l3phy5dlwkr8dgbi.jpg" });
  }

  if (reponseEquipmentAPI?.resistanceBand) {
    texts.push({ value: 'withResistanceBand', label: `Banda de Resistencia`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/qu8rpsjyqshzqdonhwha.webp" });
  }

  if (reponseEquipmentAPI?.jumpBox) {
    texts.push({ value: 'withJumpBox', label: `Cajon de Salto`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/xp9mzz56639gn2pfzjl2.webp" });
  }


  if (reponseEquipmentAPI?.jumpRope) {
    texts.push({ value: 'withJumpRope', label: `Cuerda de Saltar`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/aatgdlhld1uv8szpvzfe.webp" });
  }

  if (reponseEquipmentAPI?.roller) {
    texts.push({ value: 'withRoller', label: `Rolo`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884753/fitapp/equipment/e4krs9odmldkiwxsluq6.webp" });
  }

  if (reponseEquipmentAPI?.abdominalWheel) {
    texts.push({ value: 'withAbdominalWheel', label: `Rueda Abdominal`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884751/fitapp/equipment/ecp2icb0k0p5fwjzszlk.webp" });
  }

  if (reponseEquipmentAPI?.other) {
    texts.push({ value: 'withOther', label: `Otro: ${reponseEquipmentAPI.otherOptions.details}`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1714884752/fitapp/equipment/pxb0a70j7iif5q26pidi.webp" });
  }

  // if (reponseEquipmentAPI?.inGym) {
  //   texts.push({ value: 'inGym', label: `Entreno en un Gym`, img: "https://res.cloudinary.com/dtwdbcfu7/image/upload/v1715117567/fitapp/equipment/brdgg9marya3nhpdsiti.webp" });
  // }



  return texts;
}


export const getTextOneEquipment = (key, reponseEquipmentAPI) => {
  let text = '';

  // eslint-disable-next-line default-case
  switch (key) {
    case 'dumbbells':
      if (reponseEquipmentAPI?.dumbbells) {
        text = reponseEquipmentAPI.dumbbellOptions.withPair ? `(2x${reponseEquipmentAPI.dumbbellOptions.weight}Kg. c/u)` : `(1x${reponseEquipmentAPI.dumbbellOptions.weight}Kg.)`;
        text = `${text}`;
      }
      break;
    case 'kettlebell':
      if (reponseEquipmentAPI?.kettlebell) {
        text = `(${reponseEquipmentAPI.kettlebellOptions.weight}Kg.)`;
      }
      break;
    case 'disk':
      if (reponseEquipmentAPI?.disk) {
        text = `(${reponseEquipmentAPI.diskOptions.weight}Kg.)`;
      }
      break;
    case 'bar':
      if (reponseEquipmentAPI?.bar) {
        text = `(${reponseEquipmentAPI.barOptions.weight}Kg.)`;
      }
      break;
    case 'chinUpBar':
      if (reponseEquipmentAPI?.chinUpBar) {
        let opciones = reponseEquipmentAPI.chinUpBarOptions.fullyStretched ? 'Colgarse de la barra completamente.' : '';
        opciones += reponseEquipmentAPI.chinUpBarOptions.spaceAbove ? ' Espacio superior para realizar MuscleUps.' : '';
        opciones += reponseEquipmentAPI.chinUpBarOptions.swayFromSideToSide ? ' Balancearme de lado a lado.' : '';
        opciones += reponseEquipmentAPI.chinUpBarOptions.jumpsAndSwings ? ' Realizar saltos y balanceos.' : '';
        text = 'Barra de Dominadas, permite: ' + opciones;
      }
      break;
    case 'other':
      if (reponseEquipmentAPI?.other) {
        text = `Otro: ${reponseEquipmentAPI.otherOptions.details}`;
      }
      break;
  }

  return text;
}
import React, { useContext, useState, useEffect } from "react";
import { Badge, Button, Card } from "react-bootstrap";
import { AlertSinLink, ModalCreateExercise } from "../components";
import { LanguageContext } from "../context/LanguageContext";
import { InfoIcon } from "../assets/svg/svgs";
import { categoriesExercises } from "../assets/data/categoriesExcercise";
import { useFilteredMyExercises, useGetMyexercises, usePath } from "../hooks";
import { Loading } from "../components/Loading/Loading";
import { useSlicesActions, useSlicesState } from "../redux/slices/hook";
import { optionsEquipment } from "../assets/data/validateEquipment";

export const MyExercises = () => {
  const { lang } = useContext(LanguageContext);
  const { path } = usePath(1)

  const { exerciseSelected } = useSlicesState()

  const {
    myExercises,
    msgCode,
    isLoading,
  } = useGetMyexercises()

  const { dispatch, setExerciseSelected, setModalCreateExercise, setSelectUser } = useSlicesActions()

  const [input, setInput] = useState("");
  const [isCreateExercise, setIsCreateExercise] = useState(false);

  const {
    dataFilter,
    filterExercise,
    filterSelected
  } = useFilteredMyExercises(myExercises, input)


  const openModalCreateExercise = () => {
    setIsCreateExercise(true);
    dispatch(setModalCreateExercise(true));
  };

  const openModalViewexercise = (exercise) => {
    dispatch(setExerciseSelected({
      ...exercise,
      optionsEquipment: optionsEquipment,
      options: {
        ...exercise.options,
        isUsingMinutes: exercise.options.isUsingMinutes ? exercise.options.isUsingMinutes : false,
        kmInfo: exercise.options.kmInfo === 0 ? 1 : exercise.options.kmInfo,
        hearRateInfo: exercise.options.hearRateInfo === 0 ? 30 : exercise.options.hearRateInfo,
        cadenceInfo: exercise.options.cadenceInfo === 0 ? 1 : exercise.options.cadenceInfo,
        metersInfo: exercise.options.metersInfo === 0 ? 1 : exercise.options.metersInfo,
        powerInfo: exercise.options.powerInfo === 0 ? 1 : exercise.options.powerInfo,
        series: exercise.options.series === 0 ? 1 : exercise.options.series,
        breakInfo: exercise.options.breakInfo === 0 ? 1 : exercise.options.breakInfo,
        rhythmInfo: exercise.options.rhythmInfo === 0 ? 1 : exercise.options.rhythmInfo,
        toTheRuling: exercise.options.toTheRuling ? exercise.options.toTheRuling : false,
      }
    }));
    setIsCreateExercise(false);
    dispatch(setModalCreateExercise(true));
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value.toLowerCase());
  };

  useEffect(() => {
    if (path === "exercises") dispatch(setSelectUser(null))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (<Loading show={isLoading} />)
  }


  return (
    <Card className="col-12 col-md-10 col-xl-7 m-auto">
      <Card.Header>
        <Card.Title>{lang.generic.myExercises}</Card.Title>
        {/* Input Busqueda de User */}
        <div className="col-6 col-md-4 m-auto">
          <input
            type="text"
            className="form-control border-2 border-warning"
            placeholder={lang.placeHolders.searchExercise}
            onChange={handleChange}
            value={input}
          />
        </div>

        {/* Input Busqueda de User */}
        <div className="float-right">
          <button
            type="submit"
            className="btn btn-outline-info btn-block mt-2"
            onClick={() => openModalCreateExercise()}
          >
            <i className="fa fa-plus mr-2" />
            {lang.buttons.createExercise}
          </button>

          <ModalCreateExercise
            isCreateExercise={isCreateExercise}
            setIsCreateExercise={setIsCreateExercise}
            exercise={exerciseSelected}
            setIInputSearchExercise={setInput}
          />
        </div>
      </Card.Header>

      <div className="text-primary">
        {lang.generic.filter}
        {
          categoriesExercises.map(({ value, label }, index) => (
            <Badge
              key={index}
              variant={filterSelected === value ? 'success' : 'dark'}
              className="badge-xs  ml-1"
              onClick={() => filterExercise(value)}
            >
              {label}
            </Badge>
          ))

        }


      </div>

      <Card.Body>
        <p className="m-0 p-0">{lang.myExercises.description}</p>
        <p className="m-0 p-0 text-info" style={{ fontSize: '0.8rem' }}>{lang.myExercises.descriptionTwo}</p>

        {msgCode === 'SE0026' && (
          <AlertSinLink
            variant={"info"}
            Icon={InfoIcon}
            msg={lang.alerts.info}
            text={lang.myExercises.infoAlert}
          />
        )}

        {dataFilter?.length < 1 ? (
          <AlertSinLink
            variant={"secondary"}
            Icon={InfoIcon}
            msg={"Info!"}
            text={lang.search.noSearchResults}
          />
        ) : (
          <div
            className="row p-2 scrollbar-color"
            style={{ height: 'auto', maxHeight: '55vh', backgroundColor: '#f9f9f9', borderRadius: '1rem', overflow: 'auto' }}
          >
            {dataFilter?.map((exercise) => (
              <div key={exercise._id}>
                <Button
                  key={exercise._id}
                  className="mr-2 mt-2 text-capitalize"
                  variant={`${exercise?.itsFromFitool ? "secondary" : "info"}`}
                  onClick={() => openModalViewexercise(exercise)}
                >
                  {exercise.name}
                  <span className="btn-icon-right">
                    <i className="fa fa-eye" />
                  </span>
                </Button>
              </div>
            ))}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
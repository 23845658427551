import Swal from "sweetalert2";
import { API } from "../api/api";

const customHeader = process.env.REACT_APP_CUSTOM_HEADER

//exportar para usar en las funciones de login?
export const setTokenLocalStorage = (token) => {
  localStorage.setItem("token", token);
  localStorage.setItem("token-init-date", new Date().getTime()); //?
};

//leer token de local Storage
export const getTokenLocalStorage = () => {
  return localStorage.getItem("token");
};

//eliminar token de localstorage
export const deleteTokenLocalStorage = () => {
  localStorage.removeItem("token");
};

//interceptar llamadas que salen de la app para ver si tienen token
export const initAxiosIntercerptors = () => {
  API.interceptors.request.use(function (config) {
    const token = getTokenLocalStorage();
    if (token) {
      //si token existe
      config.headers = { "x-token": token, "X-Custom-Header": customHeader };
    }
    return config;
  });

  //interceptor para respuestas
  API.interceptors.response.use( //FIXME: NO se esta usandoa actuamente
    function (response) {
      //si la llamada es exitosa que pase
      return response;
    },
    function (error) {
      const token = getTokenLocalStorage();
      if (token && error.response.data.code === "MSG[GEN.T02]") {
        //error de JWT, token vencido
        deleteTokenLocalStorage();
        return Swal.fire(
          "Oops 😥",
          "Tu sesión ha expirado, vuelve a iniciar sesión.",
          "info"
        );
      }

      return Promise.reject(error);
    }
  );
};

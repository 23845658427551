import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { CardItemEquipment } from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { useEquipmentAssociation } from "../../hooks";

export const CardSelectEquipment = ({ equipment }) => {
  const { lang } = useContext(LanguageContext)

  const {
    equipment: equipmentData,
    setEquipment,
    dynamicFormValues,
    handleDynamicFormChange,
    originalEquipment,

    handleCreateEquipmentAssociation,
    isDisabled,
  } = useEquipmentAssociation()


  return (
    <>
      <div>
        <div className="card overflow-hidden ">
          <div className="card-body">
            <div className="profile-skills ">
              <h4 className="text-primary text-capitalize">
                {lang.generic.equipment}
              </h4>
              <p className="text-muted">
                {lang.equipment.descriptionCard}
              </p>

              <Card.Body
                className="tab-content scroll_test_responsive scrollbar-color p-2"
                style={{ height: 'auto', maxHeight: '50vh', backgroundColor: '#f9f9f9', borderRadius: '1rem' }}
              >

                {equipment?.map((item, i) => (
                  <CardItemEquipment
                    key={i}
                    item={item}
                    value={item.value}
                    equipment={equipmentData}
                    setEquipment={setEquipment}
                    dynamicFormValues={dynamicFormValues}
                    handleDynamicFormChange={handleDynamicFormChange}
                    originalEquipment={originalEquipment}
                  />
                ))}

              </Card.Body>
              <Card.Footer style={{border: 'none'}}>
                {equipment.length > 0 && (
                  <Button
                    className="float-right btn-sm"
                    variant="primary xs"
                    onClick={() => handleCreateEquipmentAssociation()}
                    disabled={isDisabled}
                  >
                    {lang.buttons.save}
                  </Button>
                )}
              </Card.Footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useContext, useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { categoriesExercises } from "../../assets/data/categoriesExcercise";
import { InfoIcon } from "../../assets/svg/svgs";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { AlertConLink } from "../alerts/AlertConLink";
import { AlertSinLink } from "../alerts/AlertSinLink";
import { useFilteredMyExercises, useGetMyexercises } from "../../hooks";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { optionsEquipment } from "../../assets/data/validateEquipment";
import { v4 as uuidv4 } from 'uuid';

export const ModalListMyExercises = () => {
  const { lang } = useContext(LanguageContext);

  const { exercisesSelected, modalListMyExercises, blockIdSelected } = useSlicesState()
  const { dispatch, setImportedExercises, setExercisesSelected, setModalListMyExercises, setBlockIdSelected } = useSlicesActions()
  const { myExercises: exercises, msgCode } = useGetMyexercises()
  const [input, setInput] = useState("");

  const {
    dataFilter,
    filterExercise,
    filterSelected
  } = useFilteredMyExercises(exercises, input)

  const isDisabled = exercisesSelected.length === 0;

  const handleSelect = (exercise) => {
    if (
      !exercisesSelected?.map((exercise) => exercise._id).includes(exercise._id)
    ) {
      dispatch(setExercisesSelected([...exercisesSelected, {
        ...exercise,
        idTemporalClonar: uuidv4(),
        options: {
          ...exercise.options,
          isUsingMinutes: exercise.options.isUsingMinutes ? exercise.options.isUsingMinutes : false,
          hearRateInfo: exercise.options.hearRateInfo === 0 ? 30 : exercise.options.hearRateInfo,
          kmInfo: exercise.options.kmInfo === 0 ? 1 : exercise.options.kmInfo,
          cadenceInfo: exercise.options.cadenceInfo === 0 ? 1 : exercise.options.cadenceInfo,
          metersInfo: exercise.options.metersInfo === 0 ? 1 : exercise.options.metersInfo,
          powerInfo: exercise.options.powerInfo === 0 ? 1 : exercise.options.powerInfo,
          series: exercise.options.series === 0 ? 1 : exercise.options.series,
          breakInfo: exercise.options.breakInfo === 0 ? 1 : exercise.options.breakInfo,
          rhythmInfo: exercise.options.rhythmInfo === 0 ? 1 : exercise.options.rhythmInfo,
          toTheRuling: exercise.options.toTheRuling ? exercise.options.toTheRuling : false,
        },
        optionsEquipment: { ...optionsEquipment },
      }
      ]))
    } else {
      dispatch(setExercisesSelected(
        exercisesSelected?.filter((exer) => exer._id !== exercise._id)
      ))
    }
  };

  const handleImportMyexercises = () => {

    const exerciseWithIdTemporal = exercisesSelected.map(exercise => ({
      ...exercise,
      // idTemporalClonar: uuidv4(), 
      blockIdTemp: blockIdSelected,
      options: {
        ...exercise.options,
        isUsingMinutes: exercise.options.isUsingMinutes ? exercise.options.isUsingMinutes : false,
        hearRateInfo: exercise.options.hearRateInfo === 0 ? 30 : exercise.options.hearRateInfo,
        kmInfo: exercise.options.kmInfo === 0 ? 1 : exercise.options.kmInfo,
        cadenceInfo: exercise.options.cadenceInfo === 0 ? 1 : exercise.options.cadenceInfo,
        metersInfo: exercise.options.metersInfo === 0 ? 1 : exercise.options.metersInfo,
        powerInfo: exercise.options.powerInfo === 0 ? 1 : exercise.options.powerInfo,
        series: exercise.options.series === 0 ? 1 : exercise.options.series,
        breakInfo: exercise.options.breakInfo === 0 ? 1 : exercise.options.breakInfo,
        rhythmInfo: exercise.options.rhythmInfo === 0 ? 1 : exercise.options.rhythmInfo,
        toTheRuling: exercise.options.toTheRuling ? exercise.options.toTheRuling : false,
      }
    }))

    dispatch(setImportedExercises(exerciseWithIdTemporal));
    dispatch(setModalListMyExercises(false));
    dispatch(setExercisesSelected([]));
  };

  const closeModalListTraining = () => {
    dispatch(setModalListMyExercises(false));
    dispatch(setBlockIdSelected(null));
  }

  const handleChange = (e) => {
    e.preventDefault();
    setInput(e.target.value.toLowerCase());
  };

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg modal-dialog-scrollable "
        show={modalListMyExercises}
        size="lg"
        backdropClassName="modalEditCreateExercise"
      >
        <Modal.Header className="d-block">
          <Modal.Title className="col-6 col-md-4">
            {lang.buttons.yourExercises}
          </Modal.Title>

          <div className="col-6 col-md-4 m-auto">
            <input
              type="text"
              className="form-control border-2 border-warning"
              placeholder={lang.placeHolders.searchExercise}
              onChange={handleChange}
              value={input}
            />
          </div>

          <Button
            variant=""
            className="close"
            onClick={() => closeModalListTraining()}
          >
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="text-primary mt-0 mb-2">
            {lang.generic.filter}
            {
              categoriesExercises?.map(({ value, label }, index) => (
                <Badge
                  key={index}
                  variant={filterSelected === value ? 'success' : 'dark'}
                  className="badge-xs light ml-1"
                  onClick={() => filterExercise(value)}
                >
                  {label}
                </Badge>
              ))

            }


          </div>
          <p>{lang.createTraining.descriptionYourExercises}</p>

          {msgCode === "SE0026" && (
            <AlertConLink
              variant={"secondary"}
              Icon={InfoIcon}
              msg={lang.alerts.info}
              text={lang.myExercises.infoAlert}
              path={Routes.myExercises}
              textButton="Crear Ejercicios"
            />
          )}

          {dataFilter?.length < 1 ? (
            <AlertSinLink
              variant={"secondary"}
              Icon={InfoIcon}
              msg={"Info!"}
              text={lang.search.noSearchResults}
            />
          ) : (
            <div
              className="row p-2 scrollbar-color"
              style={{ height: 'auto', maxHeight: '55dvh', backgroundColor: '#f9f9f9', borderRadius: '1rem', overflow: 'auto' }}
            >
              {dataFilter?.map((exercise, i) => (
                <div key={exercise._id}>
                  <Button
                    className="mr-2 mt-2 text-capitalize"
                    variant={`${exercisesSelected
                      ?.map((exercise) => exercise._id)
                      .includes(exercise._id)
                      ? "success"
                      : "dark"
                      }`}
                    onClick={() => handleSelect(exercise)}
                  >
                    {exercise.name}
                    <span className="btn-icon-right">
                      <i
                        className={`fa ${exercisesSelected
                          ?.map((exercise) => exercise._id)
                          .includes(exercise._id)
                          ? "fa-check"
                          : "fa-times"
                          }`}
                      />
                    </span>
                  </Button>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger light" onClick={() => closeModalListTraining()}>
            {lang.buttons.close}
          </Button>
          <Button
            variant=""
            type="button"
            className={`btn ${!isDisabled ? "btn-success" : "btn-dark pointer_none"
              }`}
            disabled={isDisabled}
            onClick={() => handleImportMyexercises()}
          >
            {lang.buttons.import}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import { AccordionComponent } from "../accordion/AccordionEquipment";
import { ValidateEquipment, getTextOneEquipment } from "../../assets/data/validateEquipment";

export const CardItemEquipment = ({
  item,
  value,
  equipment,
  setEquipment,
  dynamicFormValues,
  handleDynamicFormChange,
  originalEquipment
}) => {
  const [checked, setChecked] = useState(equipment.includes(value));

  const handleSport = (e) => {
    if (e.target.checked) {
      setEquipment((prev) => [...prev, e.target.id]);
      setChecked(true);
    } else {
      const newData = equipment.filter((s) => s !== e.target.id);
      setEquipment(newData);
    }
  };

  return (
    <>
      <div className="d-flex flex-column rounded p-2 bg-white mb-2">

        <div className="d-flex flex-row justify-content-between align-items-center rounded p-2 bg-white mb-2">
          <div onClick={() => setChecked(!checked)}>
            <img
              src={item.img}
              className="img-fluid"
              alt={item.name}
              style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '5px' }}
              loading="lazy"
            />
          </div>
          <div onClick={() => setChecked(!checked)}>
            <p className="font-weight-bold text-center cursor-pointer">{item.item}
              {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.Dumbbells) && <span style={{ fontSize: '12px' }}> {getTextOneEquipment(ValidateEquipment.Dumbbells, originalEquipment)}</span>}
              {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.Kettlebell) && <span style={{ fontSize: '12px' }}> {getTextOneEquipment(ValidateEquipment.Kettlebell, originalEquipment)}</span>}
              {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.Disk) && <span style={{ fontSize: '12px' }}> {getTextOneEquipment(ValidateEquipment.Disk, originalEquipment)}</span>}
              {/* {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.ChinUpBar ) && <span style={{fontSize: '12px'}}> {getTextOneEquipment(ValidateEquipment.ChinUpBar, originalEquipment)}</span>} */}
              {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.Bar) && <span style={{ fontSize: '12px' }}> {getTextOneEquipment(ValidateEquipment.Bar, originalEquipment)}</span>}
              {/* {(equipment.includes(value) && item.withOptions && item.value === ValidateEquipment.Other ) && <span style={{fontSize: '12px'}}> {getTextOneEquipment(ValidateEquipment.Other, originalEquipment)}</span>} */}            </p>
          </div>
          <div className={`custom-control custom-checkbox mb-3 checkbox-primary check-xl mr-2`}>
            <input
              type="checkbox"
              className="custom-control-input cursor-pointer"
              id={item.value}
              checked={checked}
              onChange={() => setChecked(!checked)}
              onClick={(e) => handleSport(e)}
              value={checked}
            />
            <label className="custom-control-label" htmlFor={item.value}></label>
          </div>

        </div>

        {(equipment.includes(value) && item.withOptions) &&
          <AccordionComponent
            item={item}
            dynamicFormValues={dynamicFormValues}
            handleDynamicFormChange={handleDynamicFormChange}
          />
        }
      </div>
    </>
  );
};



import { Button, Dropdown } from "react-bootstrap";
import { InputRange } from "../range/InputRange";

export const RangeInputWithProgressionForBlocks = ({
    name,
    block,
    getOptionValue,
    handleInputChangeForBlocks,
    multipleTrainingDate,
    activateTrainingProgressionsTemp,
    min,
    max,
    text,
    step,
    inputType,
    value,
    index
}) => {
    const renderValue = typeof value === 'object' ? Object.values(value).join(', ') : value;
    return (
        <>
            {multipleTrainingDate?.length > 1 ? (
                <Dropdown drop='dwon'> {/* up | dwon | right | left */}
                    <Dropdown.Toggle
                        as='text'
                        id='tp-btn'
                        className="custom-dropdown-toggle"
                    >
                        <Button
                            variant="outline-info"
                            className="btn-xs"
                        >
                            <i className="fa fa-sliders"></i>
                        </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{
                        padding: '10px',
                        width: 'max-content',
                        border: '1px solid #ccc',
                        boxShadow: '5px 6px 8px -2px rgba(8,32,115,0.59)'
                    }}>
                        <div className="bg-white m-0 p-1 rounded w-80">
                            {
                                multipleTrainingDate.map((date, dateIndex) => (
                                    <div key={dateIndex}>
                                        {multipleTrainingDate.length > 1 && <label style={{ fontSize: '0.8rem', marginBottom: '0', color: '#3d4465' }}>{`Fecha: ${date}`}</label>}
                                        <InputRange
                                            name={name}
                                            value={getOptionValue(block, name, date, activateTrainingProgressionsTemp, inputType)}
                                            min={min}
                                            max={max}
                                            text={text}
                                            step={step}
                                            fun={(e) => handleInputChangeForBlocks(e, index, date)}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <InputRange
                    name={name}
                    value={renderValue}
                    min={min}
                    max={max}
                    text={text}
                    step={step}
                    fun={(e) => handleInputChangeForBlocks(e, index)}
                />
            )}
        </>
    );
};


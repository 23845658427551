import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AboutMe, Account } from "..";
import { LanguageContext } from "../../context/LanguageContext";

export const CardAboutMeOptions = () => {
  const { lang } = useContext(LanguageContext);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const aboutMeRef = useRef()

  return (
    <>
      <div>
        <div className="card">
          <div className="card-body">
            <div className="profile-tab">
              <div className="custom-tab-1">
                <ul className="nav nav-tabs">
                  <li
                    className="nav-item"
                    onClick={() => setActiveToggle("aboutMe")}
                  >
                    <Link
                      to="#about-me"
                      data-toggle="tab"
                      className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                    >
                      {lang.profile.aboutMe}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#account"
                      data-toggle="tab"
                      onClick={() => setActiveToggle("account")}
                      className={`nav-link ${activeToggle === "account" ? "active show" : ""
                        }`}
                    >
                      {lang.generic.account}
                    </Link>
                  </li>
                </ul>
                <div className="tab-content">

                  <AboutMe
                    aboutMeRef={aboutMeRef}
                    activeToggle={activeToggle}
                  />

                  <Account
                    activeToggle={activeToggle}
                  />           

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../routers/Routes";
import { useSlicesActions } from "../../redux/slices";
import { Avatar } from "../Avatar/Avatar";

export const Items = (props) => {

  const history = useHistory();
  const {dispatch, setOtherUserId} = useSlicesActions()

  const handleOtherProfile = () => {
    dispatch(setOtherUserId(props._id));
    history.push(Routes.searchDetails);
  };

  return (
    <>
      <Link
        className="items"
        to="#"
        onClick={props.type === "user" ? () => handleOtherProfile() : null}
      >
        <div className="card text-center">
          <div className="card-body">
            <Avatar 
            name={props.name ? props.name : ''} 
            img={props.img} 
            size="113px"
            marginBottom="15px"
            fontSize="1.5rem"
            />
            <h5 className="fs-16 font-w500 mb-1 text-capitalize">
              {props.name}
            </h5>
            {/* {props.type === "user" && <p className="fs-14">{props.fullProfile?.yearsOfExperience} {lang.dashboard.yearsTraining}</p>} */}
            {props.type === "gym" && (
              <p className="fs-14">{props.description}</p>
            )}
            {props.type === "team" && (
              <p className="fs-14">{props.description}</p>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

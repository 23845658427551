import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useReqEquipmentData } from "../../hooks";
import { useSlicesActions } from "../../redux/slices";
import { statusSubscription } from "../../domain/statusSubscription";
import { Button } from "react-bootstrap";
import { ModalViewEquipment } from "../modals/ModalViewEquipment";

export const EquipmentView = ({ equipmentData, userId, athleteName, suscriptionStatus }) => {

  const { lang } = useContext(LanguageContext);
  const { handleReqEquipmentData } = useReqEquipmentData()
  const { dispatch, setModalViewEquipment } = useSlicesActions()

  const isDisabledButton = suscriptionStatus === statusSubscription.finished || suscriptionStatus === statusSubscription.new

  return (
    <>

      {equipmentData?.length > 0 ? (
        <>

          <Button
            variant="outline-success"
            disabled={isDisabledButton}
            onClick={() => dispatch(setModalViewEquipment(userId))}
          >
            {lang.buttons.seeEquipment}
          </Button>
        </>
      )
        :
        (
          <Button
            variant={`${isDisabledButton ? "outline-dark" : "outline-info"}`}
            disabled={isDisabledButton}
            onClick={() => handleReqEquipmentData(userId)}
          >
            {lang.buttons.requestInfo}
          </Button>
        )
      }
      <ModalViewEquipment idModal={userId} equipamentData={equipmentData} athleteName={athleteName} />
    </>
  );
};
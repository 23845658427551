import React from "react";
import ReactApexChart from "react-apexcharts";

export const EvolutionWorkouts = ({ data }) => {
  const concatData = {
    series: [{ name: "Entrenamientos", data }],
  };

  const options = {
    chart: {
      height: 200,
      type: "area",
      group: "social",
      fontFamily: "Helvetica, Arial, sans-serif",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      //linea
      width: [5],
      colors: ["#0B2A97"],
      curve: "smooth",
    },
    markers: {
      //circulo
      strokeWidth: [5],
      strokeColors: ["#0B2A97"],
      border: 0,
      colors: ["#fff"],
      hover: {
        size: 8,
      },
    },
    xaxis: {
      categories: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      labels: {
        style: {
          colors: "#3E4954",
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 100,
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: -16,
        style: {
          colors: "#3E4954",
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 100,
        },
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    fill: {
      colors: ["#0B2A97"],
      type: "solid",
      opacity: 0,
    },
    colors: ["#0B2A97"],
    grid: {
      borderColor: "#f1f1f1",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 1601,
        options: {
          chart: {
            height: 400,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
          },
          markers: {
            strokeWidth: [4],
            strokeColors: ["#0B2A97"],
            border: 0,
            colors: ["#fff"],
            hover: {
              size: 6,
            },
          },
          stroke: {
            width: [6],
            colors: ["#0B2A97"],
            curve: "smooth",
          },
        },
      },
    ],
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={concatData.series}
          type="area"
          height={200}
        />
      </div>
    </>
  );
};

import * as React from "react";
import { getInitialsString } from "../../helpers/getInitialsString";
import { generateRadomColor } from "../../helpers/generateRadomColor";

export const Avatar = ({
    name,
    size = '39px',
    fontSize = '1rem',
    marginBottom = '0px',
    img, 
    className
}) => {

    return (
        <>
            {img
                ?
                (
                    <img
                        src={img}
                        width={size}
                        alt={name}
                        className={`rounded-circle ${className}`}
                        loading="lazy"
                    />
                )
                :
                (
                    <>
                        <div
                            className={`img-fluid rounded-circle cursor-pointer ${className}`}
                            style={{
                                color: '#fff',
                                backgroundColor: generateRadomColor(name),
                                width: size,
                                height: size,
                                lineHeight: size,
                                fontSize: fontSize,
                                textAlign: 'center',
                                borderRadius: '100%',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                marginBottom: marginBottom
                            }}
                        >
                            {getInitialsString(name)}
                        </div>
                    </>
                )
            }
        </>
    );
};
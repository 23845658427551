import React, { useContext } from 'react'
import { Accordion, Button, Card } from 'react-bootstrap'
import { LanguageContext } from '../../context/LanguageContext';
import { useGetMyProfile } from '../../hooks';

export const AccordionBlockNote = ({
    activeBlockNote,
    blockId,
    setActiveBlockNote,
    setIsEditBlocknote,
    blockNote,
    training
}) => {

    const { lang } = useContext(LanguageContext);
    const { isAthlete } = useGetMyProfile()

    return (
        <Accordion
            className={`accordion accordion-rounded-stylish accordion-bordered ${training?.isSimpleMode && "mt-4"}`}
            defaultActiveKey="0"
        >
            <div className="accordion__item col-md-12 p-0">
                <Card.Header className="border-info"
                    style={{
                        border: "2px solid",
                        borderRadius: '1rem',
                        paddingTop: '3px',
                        paddingBottom: '3px',
                        borderBottomLeftRadius: activeBlockNote === blockId ? '0px' : '1rem',
                        borderBottomRightRadius: activeBlockNote === blockId ? '0px' : '1rem'
                    }}
                >
                    <Accordion.Toggle
                        as={Card.Text}
                        eventKey={`${blockId}`}
                        className="border-info accordion__header--primary"
                        onClick={() => setActiveBlockNote(blockId)}
                    >
                        <span className="accordion__header--icon"></span>
                        <span className="accordion__header--text">
                            {isAthlete() ? lang.blockNotes.descriptionForAthlete : lang.blockNotes.descriptionForProfesional}
                        </span>

                        <span className="accordion__header--indicator h4 cursor-pointer">{activeBlockNote === blockId
                            ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                            : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                        }</span>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse
                    eventKey={`${blockId}`}
                    className="accordion__body border-2 border-info"
                >
                    <div className="accordion__body--text">
                        <div className="mb-2 mt-2">
                            <p>{blockNote}</p>
                        </div>

                        {isAthlete() &&
                            <>
                                <hr />
                                <div className="row flex-row-reverse">
                                    {/* <div className="mr-2">
                                <Button
                                    variant="outline-danger btn-xs"
                                // onClick={() =>
                                //     handleRemoveUserCard(card._id)
                                // }
                                >
                                    <i className="fa fa-remove" />
                                </Button>
                            </div> */}

                                    <div className="mr-2">
                                        <Button
                                            variant="outline-info btn-xs"
                                            onClick={() => setIsEditBlocknote(true)}
                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Accordion.Collapse>

            </div>
        </Accordion>
    )
}

import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import logoText from "../../assets/img/logo-text.png";
import { useSlicesActions, useSlicesState } from "../../redux/slices";

export const NavHader = () => {
  const {openMenuSidebar} = useSlicesState()
  const {dispatch, setOpenMenuSidebarSwitch} = useSlicesActions()
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img className="logo-abbr" src={logo} alt="logo icono fitool" />
        <img className="logo-compact" src={logoText} alt="logo text fitool" />
        <img className="brand-title" src={logoText} alt="logo text fitool" />
      </Link>

      <div className="nav-control " onClick={() => dispatch(setOpenMenuSidebarSwitch())} >
        <div className={`hamburger ${openMenuSidebar ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

import { useContext, useEffect } from "react";
import { analyticsAPI, useCreateSubscriptionMutation, userProfileAPI } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../../routers/Routes";
import { useDispatch } from "react-redux";
import { LanguageContext } from "../../context/LanguageContext";

export function useCreateSubscription() {
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [createSubscription, { isError, isSuccess, error, isLoading, data }] = useCreateSubscriptionMutation();

  const handleCreateSubscription = async (userId) => {
    await createSubscription(userId).unwrap().then(() => {
      dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
      dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
    })
    Swal.close();
  };

  const handreSubcriberAndRedirect = (userId) => {
    handleCreateSubscription(userId);
    history.push(Routes.subscriptions);
  }

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if (isLoading) loadingAlert(lang.alerts.creatingConnectionWithThisUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    handreSubcriberAndRedirect,
  };
}


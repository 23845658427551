import { useContext, useEffect } from "react";
import { analyticsAPI, useFinishedSubscriptionMutation } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useDispatch } from "react-redux";
import { LanguageContext } from "../../context/LanguageContext";

export function useFinishedSubscriptions() {
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [finishedSubscriptions, { isError, isSuccess, error, isLoading ,data }] = useFinishedSubscriptionMutation()

  const handleFinishedSusbcriptions = async (subscriptionsIds, action) => {

    const idsArray = Array.isArray(subscriptionsIds)
      ? subscriptionsIds?.map(item => item._id)
      : [subscriptionsIds];
    const body = {
      "status": action,
      "listIdSubscriptions": idsArray
    }

    await finishedSubscriptions(body).unwrap().then(() => {
      dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
    })
    Swal.close();
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) data?.msgCode === 'SE0008' && showAlert(data?.msgCode, data?.ok)
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if (isLoading) loadingAlert(lang.alerts.endingConnectionWithThisUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    handleFinishedSusbcriptions,
  };
}


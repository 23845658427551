import React from "react";

export const ListSVGIcons = ({ SVG, color, text, widthProp = "20px", heightProp = "20px" }) => {
  return (
    <li className="list-group-item">
      <span className="mr-2">
        {<SVG widthProp={widthProp} heightProp={heightProp} color={color} />}
      </span>
      {text}
    </li>
  );
};

import { analyticsAPI, useTerminateTrainingMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useTerminateTraining(training) {

  const { lang } = useContext(LanguageContext);
  const { optionsSeriesTraining } = training;
  const series = optionsSeriesTraining?.series
  const seriesCompleted = optionsSeriesTraining?.seriesCompleted

  const [terminateTraining, { isError, error }] = useTerminateTrainingMutation();

  const { query } = useSlicesState()
  const { dispatch, setModalFinishTrainingRPE } = useSlicesActions()

  const terminateExerciseTrigger = ({ idTraining, idProfessional }) => {
    terminateTraining({ idTraining, idProfessional, series, seriesCompleted, query })
  }

  const handleScheduledTraining = (idTraining, idProfessional) => {
    Swal.fire({
      title: lang.createTraining.completedTraining,
      icon: "success",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.viewExercise.alertCompleteExercise.textButtonTwo,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        terminateExerciseTrigger({ idTraining, idProfessional })
        dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
        dispatch(setModalFinishTrainingRPE(idTraining))
      }
    });
  };

  const handleSeriesTraining = (idTraining, idProfessional) => {
    Swal.fire({
      title: `Completar Serie ${seriesCompleted + 1} de ${series}`,
      icon: "success",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.viewExercise.alertCompleteExercise.textButtonTwo,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        terminateExerciseTrigger({ idTraining, idProfessional })
        if (seriesCompleted + 1 === series) {
          dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
          dispatch(setModalFinishTrainingRPE(idTraining))
        }

      }
    });
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, isError]);


  return {
    handleScheduledTraining,
    handleSeriesTraining
  };
}


import React, { useContext } from 'react'
import { useSlicesActions } from "../../redux/slices";
import { Button } from "react-bootstrap";
import { ModalVideo } from "../modals/ModalVideo";
import { LanguageContext } from '../../context/LanguageContext';

export const NewExerciseSimpleMode = ({
    exercise,
    index,
    handleInputChangeForExercises,
    handleRemoveExercise
}) => {

    const { lang } = useContext(LanguageContext);
    const { dispatch, setModalViewVideo } = useSlicesActions()

    const name = exercise?.name
    const video = exercise?.video

    return (
        <>
            <div className="input-group mt-2">
                <input
                    type="text"
                    name="name"
                    className="form-control"
                    style={{ height: '2.5rem' }}
                    required={name?.length < 1}
                    placeholder={lang.placeHolders.nameExercise}
                    value={name}
                    onChange={(e) => handleInputChangeForExercises(e, exercise?.idTemporalClonar)}
                />
                <div className="input-group-append" id="button-addon4">
                    <button
                        className="btn btn-xs btn-outline-secondary btn-block"
                        onClick={() => dispatch(setModalViewVideo(exercise?._id))}
                    >
                        <i className="fa fa-play mr-2" />
                        {lang.buttons.viewVideo}
                    </button>
                    <Button
                        variant="outline-danger btn-xs input-icon"
                        onClick={() => handleRemoveExercise(exercise?.idTemporalClonar)}
                    >
                        <i className="fa fa-trash" />
                    </Button>
                </div>
            </div>
            <ModalVideo
                video={video}
                nombreEjercicio={name}
                idModal={exercise?._id}
            />
        </>
    );
};


import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: getBaseQueryConfig(),
    endpoints: (builder) => ({
        updateUser: builder.mutation({
            query: (newData) => ({
                url: "/users/update",
                method: 'PUT',
                body: newData
            }),
            extraOptions: { maxRetries: 0 },
        }),
        searchUsers: builder.query({
            query: (termSearch) => `/users/search?name=${termSearch}`,
            extraOptions: { maxRetries: 0 },
        }),
        getOneProfile: builder.query({
            query: (userId) => `/users/find-one/${userId}`,
            extraOptions: { maxRetries: 0 },
        }),
    }),
});

export const { useUpdateUserMutation, useLazySearchUsersQuery, useGetOneProfileQuery } = userAPI;
import React, { useContext } from "react";
import dayjs from "dayjs";
import { LanguageContext } from "../../context/LanguageContext";
import { roles } from "../../domain/roles";
import { useDeletePhysiologicalData, useGetMyProfile } from "../../hooks";
export const TrMeasures = ({
  fecha,
  peso,
  brazo,
  pecho,
  cintura,
  abdomen,
  gluteos,
  cadera,
  pierna,
  id,
}) => {
  const { lang } = useContext(LanguageContext);

  const {
    isAthlete,
  } = useGetMyProfile()

  const { handleDeleteMeasureData } = useDeletePhysiologicalData()



  return (
    <>
      <tr>
        <td>{dayjs(fecha).format("MM/YY")}</td>
        <td>
          {peso} {lang.generic.kg}
        </td>
        <td>
          {brazo} {lang.generic.cm}
        </td>
        <td>
          {pecho} {lang.generic.cm}
        </td>
        <td>
          {cintura} {lang.generic.cm}
        </td>
        <td>
          {abdomen} {lang.generic.cm}
        </td>
        <td>
          {gluteos} {lang.generic.cm}
        </td>
        <td>
          {cadera} {lang.generic.cm}
        </td>
        <td>
          {pierna} {lang.generic.cm}
        </td>
        {isAthlete(roles.athlete) && (
          <td>
            <span>
              <button
                className="btn btn-danger btn-block btn-sm"
                onClick={() => handleDeleteMeasureData(id)}
              >
                {lang.buttons.delete}
              </button>
            </span>
          </td>
        )}
      </tr>
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit'


export const blocksSlice = createSlice({
    name: 'blocks',
    initialState: {
        blockIdSelected: null,
        blockIsBeingEdited: false,
    },
    reducers: {
        setBlockIdSelected: (state, action) => {
            state.blockIdSelected = action.payload
        },
        setBlockIsBeingEditedTrue: (state) => {
            state.blockIsBeingEdited = true
        },
        setBlockIsBeingEditedFalse: (state) => {
            state.blockIsBeingEdited = false
        },
        setInitialStateBlocks: (state) => {
            Object.assign(state, blocksSlice.getInitialState())
        },
    },
})



export const {
    setBlockIdSelected,
    setInitialStateBlocks,
    setBlockIsBeingEditedTrue,
    setBlockIsBeingEditedFalse,
} = blocksSlice.actions
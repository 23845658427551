import React from "react";
import { Link } from "react-router-dom";

export const ActivityCard = ({ Icon, color, title, value, text, route }) => {
  return (
    <Link to={route}>
      <div className="card avtivity-card">
        <div className="card-body">
          <div className="media align-items-center">
            <span className={`activity-icon bgl-${color} mr-md-4 mr-3`}>
              <Icon />
            </span>

            <div className="media-body">
              <p className="fs-14 mb-2">{title}</p>
              <span className=" title text-black font-w500">
                {value} {text}
              </span>
            </div>
          </div>
          <div className="progress" style={{ height: 5 }}>
            <div
              className={`progress-bar bg-${color}`}
              style={{ width: "100%", height: 5 }}
              role="progressbar"
            ></div>
          </div>
        </div>
        <div className={`effect bg-${color}`} />
      </div>
      </Link>
  );
};

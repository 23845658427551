import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { filterIdsAndReturnStatusSuscriber } from "../../helpers/filterIDs";
import { useCreateSubscription, useGetMyProfile, useGetMySubscriptions, useGetOneProfile } from "../../hooks";
import { Loading } from "../Loading/Loading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSlicesActions } from "../../redux/slices";
import { Avatar } from "../Avatar/Avatar";

export const CardOtherProfileDetail = (userProps) => {
  const history = useHistory();
  const { lang } = useContext(LanguageContext);
  const { dispatch, setOtherCompleteUser } = useSlicesActions()

  const {
    otherUserIsAthlete,
    otherUserIsPersonalTrainer,
    otherUserIsYogaStretching,
  } = useGetOneProfile()

  const { isAthlete } = useGetMyProfile()

  const { handreSubcriberAndRedirect } = useCreateSubscription()

  const {
    subscriptions,
    isLoading,
    isSuscriptor
  } = useGetMySubscriptions()

  const setUserAndRedirect = () => {
    dispatch(setOtherCompleteUser(userProps))
    history.push(Routes.measureDetailsOtherUser)
  }

  if (isLoading) return <Loading show={isLoading} />


  return (
    <>
      <div className="col-xl-4 h-100">
        <div className="card overflow-hidden">
          <div className="card-body">
            <div className="text-center">
              <div className="profile-photo">
                <Avatar
                  name={userProps.name ? userProps.name : ''}
                  img={userProps.img}
                  size="113px"
                  marginBottom="15px"
                  fontSize="1.5rem"
                />
              </div>

              <h3 className="mt-4 mb-1 text-capitalize">{userProps?.name}</h3>

              {otherUserIsAthlete() && (
                <p className="text-muted">{lang.roles.athlete}</p>
              )}

              {otherUserIsPersonalTrainer() && (
                <p className="text-muted">{lang.roles.personalTrainer}</p>
              )}

              {otherUserIsYogaStretching() && (
                <p className="text-muted">{lang.roles.yogaStretching}</p>
              )}

              {!filterIdsAndReturnStatusSuscriber(subscriptions, userProps?._id) ? (

                !isAthlete() && otherUserIsAthlete() ? (
                  <Button
                    variant="outline-success btn-xs"
                    onClick={() => handreSubcriberAndRedirect(userProps?._id)}
                  >
                    {lang.buttons.subscribe}
                  </Button>
                ) : isAthlete() && !otherUserIsAthlete() ? (
                  <Button
                    variant="outline-success btn-xs"
                    onClick={() => handreSubcriberAndRedirect(userProps?._id)}
                  >
                    {lang.buttons.subscribe}
                  </Button>
                ) : (null)
              ) : (
                <>
                  <Button
                    variant="outline-warning btn-xs"
                    className="mt-2"
                    onClick={() => history.push(Routes.subscriptions)}
                  >
                    {lang.buttons.administrated}
                  </Button>

                  {otherUserIsAthlete() && isSuscriptor(userProps?._id) && (
                    <Button
                      variant="outline-info btn-xs"
                      className="ml-2 mt-2"
                      onClick={() => setUserAndRedirect()}
                    >
                      {lang.generic.physiologicalData}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="card-footer pt-0 pb-0 text-center">
            <div className="row">
              <div className="col-4 pt-3 pb-3 border-right">
                <h3 className="mb-1">{userProps?.yearsOfExperience}</h3>
                {(otherUserIsPersonalTrainer() ||
                  otherUserIsYogaStretching()) && (
                    <span className="textSmallProfile">
                      {userProps.yearsOfExperience > 1
                        ? lang.profile.yearsOfExperience
                        : lang.profile.oneYearOfExperience}
                    </span>
                  )}
                {otherUserIsAthlete() && (
                  <span className="textSmallProfile">
                    {userProps?.yearsOfExperience > 1
                      ? lang.profile.yearsOfTraining
                      : lang.profile.yeaOfTraining}
                  </span>
                )}
              </div>
              {(otherUserIsPersonalTrainer() ||
                otherUserIsYogaStretching()) && (
                  <div className="col-4 pt-3 pb-3 border-right">
                    <h3 className="mb-1">{userProps?.numberOfSubscriptions}</h3>
                    <span>
                      {userProps.numberOfSubscriptions > 1
                        ? lang.generic.athletes
                        : lang.roles.athlete}
                    </span>
                  </div>
                )}
              {otherUserIsAthlete() && (
                <div className="col-4 pt-3 pb-3 border-right">
                  <h3 className="mb-1">{userProps?.numberOfSubscriptions}</h3>
                  <span className="textSmallProfile">
                    {userProps?.numberOfSubscriptions > 1
                      ? lang.generic.trainers
                      : lang.generic.coach}
                  </span>
                </div>
              )}
              {(otherUserIsPersonalTrainer() ||
                otherUserIsYogaStretching()) && (
                  <div className="col-4 pt-3 pb-3">
                    <h3 className="mb-1">{userProps?.trainersContainerCreated}</h3>
                    <span className="textSmallProfile">
                      {userProps?.trainersContainerCreated > 1
                        ? lang.profile.personalTrainer.workoutsCreated
                        : lang.profile.personalTrainer.workoutsCreated}
                    </span>
                  </div>
                )}
              {otherUserIsAthlete() && (
                <div className="col-4 pt-3 pb-3">
                  <h3 className="mb-1">{userProps?.trainingContainersComplete}</h3>
                  <span className="textSmallProfile">
                    {userProps?.trainingContainersComplete > 1
                      ? lang.profile.athlete.completedWorkouts
                      : lang.profile.athlete.trainingcompleted}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { analyticsAPI, useTerminateExerciseInBlockMutation, useTerminateExerciseMutation } from "../../api";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useEffect } from "react";
import { showAlert } from "../../helpers/Alerts";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useTerminateExercise() {
  const { lang } = useContext(LanguageContext);
  const [terminateExercise, { isError, error }] = useTerminateExerciseMutation();
  const [terminateExerciseInBlock, { isError: isErrorInBlock, error: errorInBlock }] = useTerminateExerciseInBlockMutation();

  const { trainingIdSelected, query } = useSlicesState()
  const { dispatch } = useSlicesActions()

  const handleTerminateExercise = ({ exerciseId, idProfessional }) => {
    terminateExercise({ idExercise: exerciseId, idTraining: trainingIdSelected, idProfessional, query })
    dispatch(analyticsAPI.util.invalidateTags(["analyticsAPI"]))
  }

  const handleTerminateExerciseInTraining = (exerciseId, idProfessional) => {
    Swal.fire({
      title: lang.viewExercise.alertCompleteExercise.title,
      icon: "success",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.generic.no,
      confirmButtonText: lang.viewExercise.alertCompleteExercise.textButtonTwo,
      confirmButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleTerminateExercise({ exerciseId, idProfessional })
      }
    });
  };

  const handleTerminateExerciseInBlock = (exerciseId, idProfessional, startDate) => {
    terminateExerciseInBlock({ idExercise: exerciseId, idTraining: trainingIdSelected, startDate, idProfessional, query })
  }

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorInBlock) showAlert(errorInBlock?.data?.msgCode, errorInBlock?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, errorInBlock?.data?.msgCode, errorInBlock?.data?.ok, isError, isErrorInBlock]);


  return {
    handleTerminateExerciseInTraining,
    handleTerminateExerciseInBlock
  };
}


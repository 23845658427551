import {
    BreakTwoSVG,
    CadenceSVG,
    DistanceSVG,
    FireSVG,
    HeartSVG,
    InfoSVG,
    PowerSVG,
    RepetitionsSVG,
    RhythmSVG,
    RunSVG,
    SwimmSVG,
    TairedSVG,
    TimeSVG,
    WeithSVG
} from "../svg/svgs";

export const iconMapping = {
    heating: { SVG: FireSVG, color: "#ff8700", key: "heating" },
    activeRecovery: { SVG: RunSVG, key: "activeRecovery" },
    series: { SVG: InfoSVG, color: "#2367de", key: "series", textPrefix: "series" },
    repetitions: { SVG: RepetitionsSVG, color: "#c28600", key: "repetitions", textPrefix: "repetitions" },
    weightInfo: { SVG: WeithSVG, color: "#808080", key: "weightInfo", textPrefix: "suggestedWeight" },
    kmInfo: { SVG: DistanceSVG, color: "#00b033", key: "kmInfo", textPrefix: "distance" },
    timeInfo: { SVG: TimeSVG, color: "#d85195", key: "timeInfo", textPrefix: "time" },
    hearRateInfo: { SVG: HeartSVG, color: "#d85195", key: "hearRateInfo", textPrefix: "hearRate" },
    cadenceInfo: { SVG: CadenceSVG, color: "#cc232b", key: "cadenceInfo", textPrefix: "cadence" },
    rhythmInfo: { SVG: RhythmSVG, color: "#3498db", key: "rhythmInfo", textPrefix: "rhythm" },
    swimmingStylesInfo: { SVG: SwimmSVG, key: "swimmingStylesInfo", textPrefix: "swimmingStyles" },
    metersInfo: { SVG: DistanceSVG, color: "#00b033", key: "metersInfo", textPrefix: "meters" },
    powerInfo: { SVG: PowerSVG, color: "#ede200", key: "powerInfo", textPrefix: "power" },
    otherInfo: { SVG: InfoSVG, color: "#2367de", key: "otherInfo", textPrefix: "other" },
    breakInfo: { SVG: BreakTwoSVG, color: "orange", key: "breakInfo", textPrefix: "breakRecovery" },
    toTheRuling: { SVG: TairedSVG, color: "#f8216f", key: "toTheRuling" },
};
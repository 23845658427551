import { useEffect } from "react";
import { useReqUserDataEquipmentMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";

export function useReqEquipmentData() {
  const [reqEquipmentData, { isError, isSuccess, error, data }] = useReqUserDataEquipmentMutation();
  

  const handleReqEquipmentData = async (userId) => {
    await reqEquipmentData(userId).unwrap()
  };
  
  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);
    return {
      handleReqEquipmentData
    };
  }


import React, { useContext, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { AlertSinLink } from "../components/alerts/AlertSinLink";
import { LanguageContext } from "../context/LanguageContext";
import { formatDate } from "../helpers/formatDate";
import { InfoIcon } from "../assets/svg/svgs";
import { useHistory } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { useCreateAndUpdateUserCards, useDeleteUserCard, useGetDataForOneUser } from "../hooks";
import { Loading } from "../components/Loading/Loading";

export const UserCard = () => {
  const { lang } = useContext(LanguageContext);
  const history = useHistory();

  const [activeaccordionRoundedStylish, setActiveaccordionRoundedStylish] =
    useState(0);

  const {
    userCards,
    isLoading,
    userSelect,
    msgCode } = useGetDataForOneUser()

  const {
    handleCreateUserCard,
    handleUpdateUserCard,
    handleFormUserCards,
    note,
    isUpdate,
    startUpdated,
    handleCancelEdit
  } = useCreateAndUpdateUserCards()

  const { handleRemoveUserCard } = useDeleteUserCard()

  if (userSelect?._id === undefined) {
    history.push(Routes.subscriptions);
  }

  if (isLoading) {
    return (<Loading show={isLoading} />)
  }

  return (
    <>
      <Card className="col-12 col-md-12 col-xl-8 m-auto ">
        <Card.Header>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <Button
                variant="outline-success btn-xs"
                onClick={() => history.push(Routes.subscriptions)}
              >
                <i className="fa fa-arrow-left" />
              </Button>
            </div>
            <Card.Title className="ml-2">
              {lang.userCard.title} {userSelect.name}
            </Card.Title>
          </div>
        </Card.Header>

        <Card.Body>
          <div className="form-row">
            <div className="form-group col-md-12 border-color-textarea">
              <div className="mb-2">
                <h5 className="text-primary mb-2">
                  {lang.userCard.description}
                </h5>
                <textarea
                  className="form-control h-100 "
                  rows="4"
                  id="comment"
                  name="note"
                  value={note}
                  onChange={handleFormUserCards}
                  placeholder={lang.placeHolders.trainingTargetDescription}
                ></textarea>
              </div>
            </div>
          </div>
        </Card.Body>

        <hr className="mb-2 mt-0" />
        <div className="form-row ">
          <div className="form-group col-md-5 m-auto">
            {!isUpdate ? (
              <button
                className={`btn btn-outline-success btn-block mt-2`}
                type="submit"
                onClick={handleCreateUserCard}
                disabled={note === ""}
              >
                <i className="fa fa-plus mr-2" />
                {lang.userCard.buttonCreate}
              </button>
            ) : (
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className={`btn btn-outline-warning btn-block mt-2`}
                  type="submit"
                  onClick={handleUpdateUserCard}
                >
                  <i className="fa fa-save mr-2" />
                  {lang.userCard.buttonUpdate}
                </button>
                <button
                  className={`btn btn-outline-info btn-block mt-2 ml-2`}
                  type="submit"
                  onClick={handleCancelEdit}
                >
                  {lang.buttons.cancel}
                </button>
              </div>
            )}
          </div>
        </div>

        <Accordion
          className="accordion accordion-rounded-stylish accordion-bordered "
          defaultActiveKey="0"
        >
          <div className="row p-2">
            {msgCode === 'SE0020' ? (
              <div className="m-auto">
                <AlertSinLink
                  variant={"secondary"}
                  Icon={InfoIcon}
                  msg={lang.alerts.info}
                  text={lang.userCard.infoAlert}
                />
              </div>
            )
              :

              userCards?.map((card, i) => (
                <div className="accordion__item col-md-12 " key={card._id}>
                  <Card.Header className={`${activeaccordionRoundedStylish === i
                    ? "border-success"
                    : "border-dark"
                    }`}
                    style={{
                      border: "2px solid",
                      borderRadius: '1rem',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      borderBottomLeftRadius: activeaccordionRoundedStylish === i ? '0px' : '1rem',
                      borderBottomRightRadius: activeaccordionRoundedStylish === i ? '0px' : '1rem'
                    }}
                  >
                    <Accordion.Toggle
                      as={Card.Text}
                      eventKey={`${i}`}
                      className={`${activeaccordionRoundedStylish === i
                        ? "border-success"
                        : "border-dark"
                        } accordion__header--primary `}
                      onClick={() =>
                        setActiveaccordionRoundedStylish(
                          activeaccordionRoundedStylish === i ? -1 : i
                        )
                      }
                    >
                      <span className="accordion__header--icon"></span>
                      <span className="accordion__header--text">
                        {formatDate(card.createdAt)}
                      </span>
                      <span className="accordion__header--indicator h4 cursor-pointer">{activeaccordionRoundedStylish === i
                        ? <i className="fa fa-caret-up" style={{ fontSize: '1rem' }} />
                        : <i className="fa fa-caret-down" style={{ fontSize: '1rem' }} />
                      }</span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse
                    eventKey={`${i}`}
                    className={`accordion__body border-2 ${activeaccordionRoundedStylish === i
                      ? "border-success"
                      : "border-dark"
                      }`}
                  >
                    <div className="accordion__body--text">
                      <div className="mb-2 mt-2">
                        <p>{card.note}</p>
                      </div>

                      <hr />
                      <div className="row flex-row-reverse">
                        <div className="mr-2">
                          <Button
                            variant="outline-danger btn-xs"
                            onClick={() =>
                              handleRemoveUserCard(card._id)
                            }
                          >
                            <i className="fa fa-remove" />
                          </Button>
                        </div>

                        <div className="mr-2">
                          <Button
                            variant="outline-info btn-xs"
                            onClick={() => startUpdated(card)}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </div>
              ))

            }


          </div>
        </Accordion>
      </Card>
    </>
  );
};

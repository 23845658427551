import React from 'react'
import { useSlicesActions, useSlicesState } from '../../redux/slices/hook'
import { usePath } from '../../hooks'
export const RpeMessage = ({ number, title, description, color }) => {

    const { trainingIdSelected } = useSlicesState()
    const { dispatch, setTempSelectRPE, setModalFinishTrainingFeedback, setModalFinishTrainingRPE } = useSlicesActions()

    const { path } = usePath(1)
    const isValidPath = (path === "workout-plan-suscriptor" || path === "workout-plan")
        ? true
        : false;

    const message = `RPE Seleccionado: ${number} | ${title} ${description ? "| " + description : ''}`

    const selectMessage = () => {
        dispatch(setTempSelectRPE(message))
        dispatch(setModalFinishTrainingRPE(null))
        dispatch(setModalFinishTrainingFeedback(trainingIdSelected))
    }

    return (
        <div className="d-flex align-items-center mt-1 rounded" onClick={isValidPath ? () => selectMessage() : null} style={{ backgroundColor: color }}>
            <div className="p-2 text-white h4">{number}</div>
            <div className="p-2 text-white h5">{title} {description && <><br /><span className="h6 text-white">{description}</span></>}</div>
        </div>
    )
}
import React, { useContext, useEffect } from "react";
import { Badge, Card, OverlayTrigger, Popover } from "react-bootstrap";
import {
  DinamicBlocks,
  InputCalendar,
  InputRange,
  ListIconsSportsCreateEditTraining,
  Loading,
  SelectSearchSingleMultiple,
} from "../components";
import { LanguageContext } from "../context/LanguageContext";
import { formatDate } from "../helpers/formatDate";
import { useCreateTraining, useGetMyexercises } from "../hooks";
import { useSlicesActions, useSlicesState } from "../redux/slices";
import Swal from "sweetalert2";
import { getLocalStorageValue, setLocalStorageValue } from "../helpers/localStorage";

export const CreateTraining = () => {
  const { lang } = useContext(LanguageContext);

  const { isCloneTraining, trainingToClone, createTrainingFromCalendar, toggleCreateTrainingSimpleMode } = useSlicesState()

  const {
    setSelectUser,
    setUserSelected,
    setTrainingIdSelected,
    setIsCloneTrainingFalse,
    setTrainingToClone,
    setIsCreateExerciseInTrainingFalse,
    setEreateTrainingFromCalendarFalse,
    setDateTempTraining,
    setToggleCreateTrainingSimpleMode
  } = useSlicesActions()

  const {
    exercises,
    title,
    target,
    startDate,
    trainingProgressionsTemp,
    activateTrainingProgressionsTemp,
    dateTempTraining,
    valueSports,
    athletes,
    widthAndHeight,
    selectUser,
    valuesUsers,
    setValuesUsers,
    sports,
    setvalueSports,
    isDisabled,
    setCleanSelectUser,
    handleAddExercise,
    handleCloneExercise,
    handleRemoveExercise,
    handleCreateTraining,
    handleCreateTrainingContainer,
    dispatch,
    handleASingleTrainingDate,
    handleMultipleTrainingDate,
    blocks,
    handleCloneBlocks,
    handleAddBlocks,
    handleRemoveBlocks,
    handleInputChangeForBlocks,
    handleInputCheckBoxForBlocks,
    handleInputChangeForExercises,
    handleInputCheckboxForExercises,
    handleEditExerciseInTrainingCreation,
    exerciseIdsPerBlock,
    isAlertCalendarMulti,
    activeAccordionDinamicBlocks,
    setActiveAccordionDinamicBlocks,
    multipleTrainingDate,
    asSingleTrainingDate,
    handleImportTraining,
    handleContinueTraining
  } = useCreateTraining()

  const { isLoading } = useGetMyexercises()

  const isThereOngoingTraining = getLocalStorageValue('isOngoingTraining', false)

  if (isCloneTraining && trainingToClone !== null) {
    Swal.fire({
      title: lang.createTraining.importTraining.alertTitle,
      icon: "info",
      text: `${lang.createTraining.importTraining.descriptionText}${trainingToClone.title}?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      cancelButtonText: lang.buttons.noCancel,
      confirmButtonText: lang.buttons.yesImport,
      confirmButtonColor: "#2bc155",
      cancelButtonColor: "#e64942",
      customClass: {
        cancelButton: "order-1",
        confirmButton: "order-2",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (isCloneTraining) {
          handleImportTraining()
        }
      }
      if (result.isDismissed) {
        dispatch(setTrainingToClone(null))
        dispatch(setIsCloneTrainingFalse())
        dispatch(setIsCreateExerciseInTrainingFalse())
      }
    });
  }

  useEffect(() => {
    if (isThereOngoingTraining) {
      Swal.fire({
        title: lang.createTraining.importTraining.alertTitleContinue,
        icon: "info",
        text: lang.createTraining.importTraining.descriptionTextContinue,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        cancelButtonText: lang.buttons.noCancel,
        confirmButtonText: lang.buttons.yesContinue,
        confirmButtonColor: "#1ea7c5",
        cancelButtonColor: "#e64942",
        customClass: {
          cancelButton: "order-1",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (isThereOngoingTraining) {
            handleContinueTraining()
          }
        }
        if (result.isDismissed) {
          localStorage.removeItem('tempTraining')
          localStorage.removeItem('tempExercises')
          localStorage.removeItem('tempBlocks')
          localStorage.removeItem('tempSports')
          setLocalStorageValue('isOngoingTraining', false)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const cleanup = () => {
      dispatch(setSelectUser(null))
      dispatch(setUserSelected(null))
      dispatch(setTrainingIdSelected(null))
      dispatch(setIsCloneTrainingFalse())
      dispatch(setTrainingToClone(null))
      dispatch(setDateTempTraining(null))
      dispatch(setEreateTrainingFromCalendarFalse())
    }
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!createTrainingFromCalendar) {
      dispatch(setCleanSelectUser())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])





  if (isLoading) return <Loading show={isLoading} />

  return (
    <>

      <div className="row">
        <div className="col-12 col-md-12 col-xl-3"
          style={{ height: 'fit-content' }}
        >

          <Card>

            <Card.Header>

              <Card.Title>
                {lang.createTraining.title}{" "}
                {selectUser ? `para ${selectUser.name} ` : ""}{" "}
                {dateTempTraining
                  ? `para el día ${formatDate(dateTempTraining)}`
                  : ""}
              </Card.Title>
              {
                toggleCreateTrainingSimpleMode ?
                  (
                    <button
                      className={`btn btn-xs btn-outline-primary`}
                      onClick={() => dispatch(setToggleCreateTrainingSimpleMode())}
                    >
                      <i className="fa fa-sliders mr-1" />
                      {lang.buttons.advancedMode}
                    </button>
                  )
                  :
                  (
                    <button
                      className={`btn btn-xs btn-outline-secondary`}
                      onClick={() => dispatch(setToggleCreateTrainingSimpleMode())}
                    >
                      <i className="fa fa-file mr-1" />
                      {lang.buttons.simpleMode}
                    </button>
                  )
              }

            </Card.Header>

            <Card.Body>
              <div className="form-row">
                <div className="col-md-6 col-xl-12">
                  <input
                    type="text"
                    className="form_test_entrenamiento"
                    name="title"
                    required={title.length < 1}
                    placeholder={lang.placeHolders.createTraining}
                    value={title}
                    onChange={handleCreateTraining}
                  />
                </div>

                <div className="col-md-6 col-xl-12 mt-xl-3">
                  <SelectSearchSingleMultiple
                    multiple={true}
                    data={sports}
                    search={false}
                    placeholder={lang.createTraining.trainingType}
                    values={valueSports}
                    setValues={setvalueSports}
                    closeOnSelect={false}
                    required={true}
                  />
                  <br />
                  <ListIconsSportsCreateEditTraining valueSports={valueSports} widthAndHeight={widthAndHeight} />
                </div>

                {
                  !toggleCreateTrainingSimpleMode &&
                  <div className="form-group col-md-6 col-xl-12 mt-xl-5">
                    <div className="d-flex">
                      <h6 className="text-primary mb-2">
                        {lang.generic.progressions}
                      </h6>

                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        responsive={true}
                        overlay={
                          <Popover id={`test`}>
                            <Popover.Title as="h3">{lang.generic.moreInfo}</Popover.Title>
                            <Popover.Content>
                              <p>{lang.createTraining.checkProgressionDescriptionOne}</p>
                              <p style={{ fontWeight: 'bold' }}>{lang.createTraining.checkProgressionDescriptionTwo}</p>
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <Badge
                          variant="info cursor-pointer"
                          className="badge-xs light ml-1 text-capitalize"
                        >
                          {lang.generic.moreInfo}
                        </Badge>
                      </OverlayTrigger>
                    </div>

                    {
                      !toggleCreateTrainingSimpleMode &&
                      <div
                        className={`custom-control custom-checkbox checkbox-secondary check-md mt-2`}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="activateTrainingProgressionsTemp"
                          name="activateTrainingProgressionsTemp"
                          value={activateTrainingProgressionsTemp}
                          checked={activateTrainingProgressionsTemp}
                          onChange={handleCreateTraining}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="activateTrainingProgressionsTemp"
                          style={{ fontSize: '0.8rem' }}
                        >
                          {lang.createTraining.activatedProgression}
                        </label>
                      </div>
                    }


                  </div>
                }

                {
                  activateTrainingProgressionsTemp && !toggleCreateTrainingSimpleMode &&
                  <div className="col-md-6 col-xl-12 mt-xl-0">
                    <h6 className="text-primary">{lang.createTraining.numberOfProgressions}</h6>
                    <InputRange
                      name="trainingProgressionsTemp"
                      value={trainingProgressionsTemp}
                      min={2}
                      max={100}
                      fun={handleCreateTraining}
                    />
                  </div>
                }

                {!dateTempTraining && (
                  <div className="form-group col-md-6 col-xl-12 mt-xl-3">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        gap: '1rem',
                        border: '0px',
                        borderBottom: `2px solid ${activateTrainingProgressionsTemp ? (multipleTrainingDate?.length < trainingProgressionsTemp ? "orange" : "#efefef") : "#efefef"}`,
                        width: '90%',
                        fontSize: '1.2rem',
                        marginBottom: '0.5rem',
                        fontWeight: '500',
                        lineHeight: '1.2',
                        color: '#3d4465',
                      }}>
                      <h6 className="text-primary">{lang.placeHolders.dateTraining}</h6>
                      <InputCalendar
                        date={startDate}
                        handleDate={activateTrainingProgressionsTemp ? handleMultipleTrainingDate : handleASingleTrainingDate}
                        multiple={activateTrainingProgressionsTemp}
                        numberOfDates={trainingProgressionsTemp}
                        fullMaximumDates={isAlertCalendarMulti}
                      />
                    </div>

                  </div>
                )}

                {!selectUser && (
                  <div className="form-group col-md-6 col-xl-12">
                    <SelectSearchSingleMultiple
                      multiple={true}
                      data={athletes}
                      search={true}
                      placeholder={lang.placeHolders.selectAthletes}
                      values={valuesUsers}
                      setValues={setValuesUsers}
                      closeOnSelect={false}
                    />
                  </div>
                )}

                {selectUser && (
                  <p
                    className="text-danger cursor-pointer text-center"
                    onClick={() => dispatch(setCleanSelectUser())}
                  >
                    {lang.createTraining.selectOtherAthletes}
                  </p>
                )}



                {

                  !toggleCreateTrainingSimpleMode &&
                  <div className="form-group col-md-12">
                    <h6 className="text-primary mb-2">
                      {lang.createTraining.trainingTarget}
                    </h6>
                    <textarea
                      className="form-control h-100 textarea-required"
                      rows="2"
                      id="target"
                      name="target"
                      value={target}
                      placeholder={lang.placeHolders.trainingTargetDescription}
                      onChange={handleCreateTraining}
                      required={target.length < 1}
                    ></textarea>
                  </div>
                }



              </div>
            </Card.Body>
            <Card.Footer>
              {toggleCreateTrainingSimpleMode
                ?
                (
                  <button
                    type="button"
                    className={`btn btn-block btn-success`}
                    onClick={handleCreateTrainingContainer}
                  >
                    {lang.generic.createWorkout}
                  </button>
                )
                :
                (
                  <button
                    type="button"
                    className={`btn btn-block ${!isDisabled ? "btn-success" : "btn-dark pointer_none"
                      }`}
                    disabled={isDisabled}
                    onClick={handleCreateTrainingContainer}
                  >
                    {lang.generic.createWorkout}
                  </button>
                )
              }
            </Card.Footer>
          </Card>
        </div>

        <div className="col-12 col-md-12 col-xl-9">
          <Card>
            <Card.Header>
              <Card.Title>
                {lang.blocks.trainingBlocks}
              </Card.Title>


              <div
                className="d-flex justify-content-end align-items-center w-50"
                style={{ gap: '1rem' }}
              >
                <button
                  className={`btn btn-xs btn-outline-secondary`}
                  style={{ marginBottom: '-0.6rem' }}
                  onClick={handleAddBlocks}
                >
                  <i className="fa fa-plus mr-2" />
                  {lang.blocks.addBlocks}
                </button>

                {/* <button TODO: Mis Bloques ¿?
                className="btn btn-xs btn-outline-warning "
                style={{ marginBottom: '-0.6rem' }}
                onClick={() => dispatch(setModalListMyExercises(true))}
              >
                <i className="fa fa-briefcase mr-2" />
                {lang.blocks.myBlocks}
              </button> */}
              </div>
            </Card.Header>

            <Card.Body
              className="tab-content scroll_test_responsive scrollbar-color p-2"
              style={{
                height: 'fit-content',
                maxHeight: 'calc(100vh - 15rem)',
              }}
            >
              <DinamicBlocks
                blocks={blocks}
                exercises={exercises}
                handleCloneBlocks={handleCloneBlocks}
                handleAddBlocks={handleAddBlocks}
                handleRemoveBlocks={handleRemoveBlocks}
                handleInputChangeForBlocks={handleInputChangeForBlocks}
                handleInputCheckBoxForBlocks={handleInputCheckBoxForBlocks}
                handleAddExercise={handleAddExercise}
                handleInputChangeForExercises={handleInputChangeForExercises}
                handleInputCheckboxForExercises={handleInputCheckboxForExercises}
                handleCloneExercise={handleCloneExercise}
                handleRemoveExercise={handleRemoveExercise}
                handleEditExerciseInTrainingCreation={handleEditExerciseInTrainingCreation}
                exerciseIdsPerBlock={exerciseIdsPerBlock}
                activeAccordionDinamicBlocks={activeAccordionDinamicBlocks}
                setActiveAccordionDinamicBlocks={setActiveAccordionDinamicBlocks}
                multipleTrainingDate={activateTrainingProgressionsTemp ? multipleTrainingDate : [asSingleTrainingDate]}
                activateTrainingProgressionsTemp={activateTrainingProgressionsTemp}
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};




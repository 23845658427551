import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const equipmentAssociationAPI = createApi({
    reducerPath: 'equipmentAssociationAPI',
    baseQuery: getBaseQueryConfig(),
    endpoints: (builder) => ({
        createEquipmentAssociation: builder.mutation({
            query: (equipmentAssociationData) => ({
                url: "/equipment/create",
                method: 'POST',
                body: equipmentAssociationData
            }),
            extraOptions: { maxRetries: 0 }
        }),
        reqUserDataEquipment: builder.mutation({
            query: (userId) => ({
                url: `/equipment/req-user-data/${userId}`,
                method: 'POST',
            }),
            extraOptions: { maxRetries: 0 }
        }),
    }),
});

export const { useCreateEquipmentAssociationMutation, useReqUserDataEquipmentMutation } = equipmentAssociationAPI;
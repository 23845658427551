import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ModalVideo } from '../modals/ModalVideo'
import { useSlicesActions } from '../../redux/slices'
import { useDeleteExercise } from '../../hooks'
import { ModalCreateExercise } from '../modals/ModalCreateExercise'
import { isBlockTraining } from '../../assets/data/typesOfTrainings'

export const ViewExerciseSimpleMode = ({
    exercises,
    training,
    handleUpdateExercise,
    isAthlete,
    setModalViewVideo,
    handleTerminateExerciseInBlock,
    lang,
    block,
    trainingIsSimpleMode
}) => {

    const { dispatch, setBlockIdSelected, setIsEditingExerciseInTrainingTrue } = useSlicesActions()

    const { handleDeleteExercise } = useDeleteExercise(null, true, block?._id)
    const [isCreateExercise, setIsCreateExercise] = useState(false);

    return (
        <div
            className="d-flex flex-column justify-content-between align-items-center"
        >
            {exercises?.map((exercise, index) => (
                <div
                    className="d-flex flex-row justify-content-between align-items-center"
                    style={{ backgroundColor: '#f5f5f5', width: '95%', marginTop: '1rem', padding: '0.5rem', borderRadius: '1.5rem' }}
                    key={exercise._id}
                >

                    <p className="text-primary" style={{ fontSize: '1.4rem', }}>#{index + 1} {exercise?.name}</p>

                    <div className="d-flex flex-row justify-content-between align-items-center"
                        style={{ gap: '1rem' }}
                    >

                        <Button
                            as="a"
                            variant="secondary"
                            className="btn-card btn-xs"
                            onClick={() => dispatch(setModalViewVideo(exercise?._id))}
                        >
                            {lang.buttons.viewVideo}
                            <i className={`fa fa-play-circle ml-2`} />
                        </Button>
                        <ModalVideo
                            video={exercise?.video}
                            nombreEjercicio={exercise?.name}
                            idModal={exercise._id}
                        />
                        {isAthlete() && !training?.isComplete && !block?.isComplete && (
                            <Button
                                as="a"
                                variant={
                                    exercise?.isComplete ? "success disabled" : "warning"
                                }
                                href="#"
                                className="btn-card float-right btn-xs"
                                onClick={() => handleTerminateExerciseInBlock(exercise?._id, training?.idPersonalTrainer?._id, training?.startDate)}
                            >
                                <i className={`fa fa-check`} />
                            </Button>
                        )}

                        {!exercise?.isComplete && !isAthlete() && !training?.isComplete && !block?.isComplete && (
                            <>
                                <Button
                                    as="a"
                                    variant="warning ml-2"
                                    href="#"
                                    className="btn-xs"
                                    onClick={() => {
                                        dispatch(setBlockIdSelected(exercise?.blockId))
                                        dispatch(setIsEditingExerciseInTrainingTrue())
                                        handleUpdateExercise(exercise?._id, exercise)
                                    }}
                                >
                                    <i className={`fa fa-edit`} />
                                </Button>
                            </>
                        )}

                        {!exercise?.isComplete && !isAthlete() && !training?.isComplete && !block?.isComplete && isBlockTraining(training?.typesOfTraining) && (
                            <>
                                <Button
                                    as="a"
                                    variant="danger ml-2"
                                    href="#"
                                    className="btn-xs"
                                    onClick={() => {
                                        dispatch(setBlockIdSelected(exercise?.blockId))
                                        handleDeleteExercise(exercise?._id)
                                    }}
                                >
                                    <i className={`fa fa-trash`} />
                                </Button>

                            </>
                        )}
                        <ModalCreateExercise
                            exercise={exercise}
                            idModal={exercise?._id}
                            listExercises={exercises}
                            isCreateExercise={isCreateExercise}
                            setIsCreateExercise={setIsCreateExercise}
                            trainingIsSimpleMode={trainingIsSimpleMode}
                        />
                    </div>


                </div>



            ))}
        </div>
    )
}

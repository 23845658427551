import React, { Suspense, useEffect } from "react";

/// Components
import { AppRouter } from "./routers/AppRouter";
import { useResizeDetector } from 'react-resize-detector';

/// Style
import "./css/style.css";

import { LanguageProvider } from "./context/LanguageContext";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { initAxiosIntercerptors } from "./helpers/auth-helpers";
import { Loading } from "./components/Loading/Loading";
import { Maintenance } from './views/Maintenance'
import { usePath } from "./hooks";

initAxiosIntercerptors(); //helper de Axios

const App = () => {

  const { path } = usePath(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);


  const { width, ref } = useResizeDetector();

  const body = document.querySelector("body");
  body.setAttribute("data-typography", "poppins");
  body.setAttribute("data-theme-version", "light");
  body.setAttribute("data-layout", "vertical");
  body.setAttribute("data-nav-headerbg", "color_1");
  body.setAttribute("data-headerbg", "color_1");
  body.setAttribute("data-sidebar-style", "compact");
  body.setAttribute("data-sibebarbg", "color_1");
  body.setAttribute("data-primary", "color_1");
  body.setAttribute("data-sidebar-position", "fixed");
  body.setAttribute("data-header-position", "fixed");
  body.setAttribute("data-container", "wide");
  body.setAttribute("direction", "ltr");

  width >= 768 && width < 1300
    ? body.setAttribute("data-sidebar-style", "mini")
    : width <= 768
      ? body.setAttribute("data-sidebar-style", "overlay")
      : body.setAttribute("data-sidebar-style", "full");


  //mantenmiento solo desde front con variable (mantenimiento db)
  const isMaintenanceOnlyFront = process.env.REACT_APP_MAINTENANCE_ONLY_FRONT === 'true';
  if (isMaintenanceOnlyFront) return <Maintenance />

  return (
    <>
      <Suspense fallback={<Loading show={true} />}>
        <div ref={ref}>
          <Provider store={store}>
            <LanguageProvider>
              <AppRouter />
            </LanguageProvider>
          </Provider>
        </div>
      </Suspense>
    </>
  );
};

export default App;


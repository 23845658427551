import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useUpdateUserMutation } from "../../api/userAPI";
import { showAlert } from "../../helpers/Alerts";
import { useForm, useGetMyProfile } from "..";
import { userProfileAPI } from "../../api";
import { LanguageContext } from "../../context/LanguageContext";
import { loadingAlert, Swal } from "../../helpers/Alerts";

export function useUserUpdate() {
  const dispatch = useDispatch();
  const { lang } = useContext(LanguageContext);

  const {
    name,
    email,
  } = useGetMyProfile()

  const [formUpdateUserValues, handleFormUpdateUserValues] = useForm({
    name,
    email,
  });

  const [updateUser, { isError, isSuccess, error, isLoading, data }] = useUpdateUserMutation()

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const nuewDateUser = {
      name: formUpdateUserValues.name,
      email: formUpdateUserValues.email
    }
    await updateUser(nuewDateUser).unwrap().then(() => {
      Swal.close();
      dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
    })
  };

  const isDisableUpdateUser =
    formUpdateUserValues.name === name &&
    formUpdateUserValues.email === email;

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      loadingAlert(lang.alerts.updatingProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    formUpdateUserValues,
    handleFormUpdateUserValues,
    isDisableUpdateUser,
    handleUpdateUser
  };
}

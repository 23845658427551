import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { LanguageContext } from "../context/LanguageContext";
import { usePath } from "../hooks/usePath";
import { useForm } from "../hooks/useForm";
import { LogoWraper } from "../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { resetPassword } from "../services/auth.services.";

export const ResetPassword = () => {
  //Acceso a Idiomas
  const { lang } = useContext(LanguageContext);

  const { token } = usePath(); //leer url y token de url

  const [formNewPassword, handleFormNewPassword] = useForm({
    newPassword: "",
    resetLink: token,
  });

  const { newPassword, resetLink } = formNewPassword;

  const [alert, setAlert] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
    resetPassword(newPassword, resetLink);
  };

  return (
    <div className="mt-5">
      <PerfectScrollbar className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <LogoWraper />
              <div className="card border border-primary">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4 text-black">
                        {lang.resetPassword.title}
                      </h4>
                      <form onSubmit={handleResetPassword}>
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.generic.password}</strong>
                          </label>
                          <input
                            type="password"
                            name="newPassword"
                            className="form-control border border-info"
                            placeholder={lang.placeHolders.enterYourPassword}
                            value={newPassword}
                            onChange={handleFormNewPassword}
                            onFocus={() => setAlert(true)}
                            onBlur={() => setAlert(false)}
                            required
                          />
                          {alert && (
                            <div
                              className="alert alert-warning mt-3 border border-warning"
                              role="alert"
                            >
                              {lang.signup.alertPass}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn bg-primary text-white btn-block"
                          >
                            {lang.buttons.generatePassword}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="text-black">
                          {lang.forgotPassword.foot1}
                          <Link className="text-primary" to={Routes.login}>
                            {lang.forgotPassword.foot2}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export const esVersionMayor = (versionApi, versionLocal) => {

  if (typeof versionApi !== 'string' || typeof versionLocal !== 'string') {
    return false;
}
    const arrVer1 = versionApi.split(".").map(Number);
    const arrVer2 = versionLocal.split(".").map(Number);
  
    for (let i = 0; i < arrVer1.length; i++) {
      if (arrVer1[i] > arrVer2[i]) {
        return true;
      } else if (arrVer1[i] < arrVer2[i]) {
        return false;
      }
    }
  
    return false;
  } //si es versionApi > versionLocal retorna true
export const countries = {
  AFG: { value: "AFG", label: "Afganistán" },
  AGO: { value: "AGO", label: "Angola" },
  ALB: { value: "ALB", label: "Albania" },
  AND: { value: "AND", label: "Andorra" },
  ARE: { value: "ARE", label: "Emiratos Árabes Unidos" },
  ARG: { value: "ARG", label: "Argentina" },
  ARM: { value: "ARM", label: "Armenia" },
  ATG: { value: "ATG", label: "Antigua y Barbuda" },
  AUS: { value: "AUS", label: "Australia" },
  AUT: { value: "AUT", label: "Austria" },
  AZE: { value: "AZE", label: "Azerbaiyán" },
  BDI: { value: "BDI", label: "Burundi" },
  BEL: { value: "BEL", label: "Bélgica" },
  BEN: { value: "BEN", label: "Benín" },
  BFA: { value: "BFA", label: "Burkina Faso" },
  BGD: { value: "BGD", label: "Bangladés" },
  BGR: { value: "BGR", label: "Bulgaria" },
  BHR: { value: "BHR", label: "Baréin" },
  BHS: { value: "BHS", label: "Bahamas" },
  BIH: { value: "BIH", label: "Bosnia y Herzegovina" },
  BLR: { value: "BLR", label: "Bielorrusia" },
  BLZ: { value: "BLZ", label: "Belice" },
  BOL: { value: "BOL", label: "Bolivia" },
  BRA: { value: "BRA", label: "Brasil" },
  BRN: { value: "BRN", label: "Brunéi" },
  BTN: { value: "BTN", label: "Bután" },
  BWA: { value: "BWA", label: "Botsuana" },
  CAF: { value: "CAF", label: "República Centroafricana" },
  CAN: { value: "CAN", label: "Canadá" },
  CHE: { value: "CHE", label: "Suiza" },
  CHL: { value: "CHL", label: "Chile" },
  CHN: { value: "CHN", label: "China" },
  CIV: { value: "CIV", label: "Costa de Marfil" },
  CMR: { value: "CMR", label: "Camerún" },
  COD: { value: "COD", label: "República Democrática del Congo" },
  COK: { value: "COK", label: "Islas Cook" },
  COL: { value: "COL", label: "Colombia" },
  COM: { value: "COM", label: "Comoras" },
  CPV: { value: "CPV", label: "Cabo Verde" },
  CRI: { value: "CRI", label: "Costa Rica" },
  CUB: { value: "CUB", label: "Cuba" },
  CYP: { value: "CYP", label: "Chipre" },
  CZE: { value: "CZE", label: "República Checa" },
  DEU: { value: "DEU", label: "Alemania" },
  DJI: { value: "DJI", label: "Yibuti" },
  DMA: { value: "DMA", label: "Dominica" },
  DNK: { value: "DNK", label: "Dinamarca" },
  DOM: { value: "DOM", label: "República Dominicana" },
  ECU: { value: "ECU", label: "Ecuador" },
  EGY: { value: "EGY", label: "Egipto" },
  ERI: { value: "ERI", label: "Eritrea" },
  ESP: { value: "ESP", label: "España" },
  EST: { value: "EST", label: "Estonia" },
  ETH: { value: "ETH", label: "Etiopía" },
  FIN: { value: "FIN", label: "Finlandia" },
  FJI: { value: "FJI", label: "Fiyi" },
  FLK: { value: "FLK", label: "Islas Malvinas" },
  FRA: { value: "FRA", label: "Francia" },
  FRO: { value: "FRO", label: "Islas Feroe" },
  FSM: { value: "FSM", label: "Micronesia" },
  GAB: { value: "GAB", label: "Gabón" },
  GBR: { value: "GBR", label: "Reino Unido" },
  GEO: { value: "GEO", label: "Georgia" },
  GHA: { value: "GHA", label: "Ghana" },
  GIN: { value: "GIN", label: "Guinea" },
  GMB: { value: "GMB", label: "Gambia" },
  GNB: { value: "GNB", label: "Guinea-Bisáu" },
  GNQ: { value: "GNQ", label: "Guinea Ecuatorial" },
  GRC: { value: "GRC", label: "Grecia" },
  GRD: { value: "GRD", label: "Granada" },
  GTM: { value: "GTM", label: "Guatemala" },
  GUY: { value: "GUY", label: "Guyana" },
  HND: { value: "HND", label: "Honduras" },
  HRV: { value: "HRV", label: "Croacia" },
  HTI: { value: "HTI", label: "Haití" },
  HUN: { value: "HUN", label: "Hungría" },
  IDN: { value: "IDN", label: "Indonesia" },
  IND: { value: "IND", label: "India" },
  IRL: { value: "IRL", label: "Irlanda" },
  IRN: { value: "IRN", label: "Irán" },
  IRQ: { value: "IRQ", label: "Irak" },
  ISL: { value: "ISL", label: "Islandia" },
  ISR: { value: "ISR", label: "Israel" },
  ITA: { value: "ITA", label: "Italia" },
  JAM: { value: "JAM", label: "Jamaica" },
  JOR: { value: "JOR", label: "Jordania" },
  JPN: { value: "JPN", label: "Japón" },
  KAZ: { value: "KAZ", label: "Kazajistán" },
  KEN: { value: "KEN", label: "Kenia" },
  KGZ: { value: "KGZ", label: "Kirguistán" },
  KHM: { value: "KHM", label: "Camboya" },
  KIR: { value: "KIR", label: "Kiribati" },
  KNA: { value: "KNA", label: "San Cristóbal y Nieves" },
  KOR: { value: "KOR", label: "Corea del Sur" },
  KWT: { value: "KWT", label: "Kuwait" },
  LAO: { value: "LAO", label: "Laos" },
  LBN: { value: "LBN", label: "Líbano" },
  LBR: { value: "LBR", label: "Liberia" },
  LBY: { value: "LBY", label: "Libia" },
  LCA: { value: "LCA", label: "Santa Lucía" },
  LIE: { value: "LIE", label: "Liechtenstein" },
  LKA: { value: "LKA", label: "Sri Lanka" },
  LSO: { value: "LSO", label: "Lesoto" },
  LTU: { value: "LTU", label: "Lituania" },
  LUX: { value: "LUX", label: "Luxemburgo" },
  LVA: { value: "LVA", label: "Letonia" },
  MAR: { value: "MAR", label: "Marruecos" },
  MCO: { value: "MCO", label: "Mónaco" },
  MDA: { value: "MDA", label: "Moldavia" },
  MDG: { value: "MDG", label: "Madagascar" },
  MDV: { value: "MDV", label: "Maldivas" },
  MEX: { value: "MEX", label: "México" },
  MHL: { value: "MHL", label: "Islas Marshall" },
  MKD: { value: "MKD", label: "Macedonia del Norte" },
  MLI: { value: "MLI", label: "Malí" },
  MLT: { value: "MLT", label: "Malta" },
  MNE: { value: "MNE", label: "Montenegro" },
  MNG: { value: "MNG", label: "Mongolia" },
  MOZ: { value: "MOZ", label: "Mozambique" },
  MRT: { value: "MRT", label: "Mauritania" },
  MUS: { value: "MUS", label: "Mauricio" },
  MWI: { value: "MWI", label: "Malaui" },
  MYS: { value: "MYS", label: "Malasia" },
  NAM: { value: "NAM", label: "Namibia" },
  NER: { value: "NER", label: "Níger" },
  NGA: { value: "NGA", label: "Nigeria" },
  NIC: { value: "NIC", label: "Nicaragua" },
  NLD: { value: "NLD", label: "Países Bajos" },
  NOR: { value: "NOR", label: "Noruega" },
  NPL: { value: "NPL", label: "Nepal" },
  NRU: { value: "NRU", label: "Nauru" },
  NZL: { value: "NZL", label: "Nueva Zelanda" },
  OMN: { value: "OMN", label: "Omán" },
  PAK: { value: "PAK", label: "Pakistán" },
  PAN: { value: "PAN", label: "Panamá" },
  PER: { value: "PER", label: "Perú" },
  PHL: { value: "PHL", label: "Filipinas" },
  PLW: { value: "PLW", label: "Palaos" },
  PNG: { value: "PNG", label: "Papúa Nueva Guinea" },
  POL: { value: "POL", label: "Polonia" },
  PRK: { value: "PRK", label: "Corea del Norte" },
  PRT: { value: "PRT", label: "Portugal" },
  PRY: { value: "PRY", label: "Paraguay" },
  PSE: { value: "PSE", label: "Palestina" },
  QAT: { value: "QAT", label: "Catar" },
  ROU: { value: "ROU", label: "Rumanía" },
  RUS: { value: "RUS", label: "Rusia" },
  RWA: { value: "RWA", label: "Ruanda" },
  SDN: { value: "SDN", label: "Sudán" },
  SEN: { value: "SEN", label: "Senegal" },
  SGP: { value: "SGP", label: "Singapur" },
  SLB: { value: "SLB", label: "Islas Salomón" },
  SLE: { value: "SLE", label: "Sierra Leona" },
  SLV: { value: "SLV", label: "El Salvador" },
  SMR: { value: "SMR", label: "San Marino" },
  SOM: { value: "SOM", label: "Somalia" },
  SRB: { value: "SRB", label: "Serbia" },
  SSD: { value: "SSD", label: "Sudán del Sur" },
  STP: { value: "STP", label: "Santo Tomé y Príncipe" },
  SUR: { value: "SUR", label: "Surinam" },
  SVK: { value: "SVK", label: "Eslovaquia" },
  SVN: { value: "SVN", label: "Eslovenia" },
  SWE: { value: "SWE", label: "Suecia" },
  SWZ: { value: "SWZ", label: "Suazilandia" },
  SYC: { value: "SYC", label: "Seychelles" },
  SYR: { value: "SYR", label: "Siria" },
  TCD: { value: "TCD", label: "Chad" },
  TGO: { value: "TGO", label: "Togo" },
  THA: { value: "THA", label: "Tailandia" },
  TJK: { value: "TJK", label: "Tayikistán" },
  TKM: { value: "TKM", label: "Turkmenistán" },
  TLS: { value: "TLS", label: "Timor Oriental" },
  TON: { value: "TON", label: "Tonga" },
  TTO: { value: "TTO", label: "Trinidad y Tobago" },
  TUN: { value: "TUN", label: "Túnez" },
  TUR: { value: "TUR", label: "Turquía" },
  TUV: { value: "TUV", label: "Tuvalu" },
  TZA: { value: "TZA", label: "Tanzania" },
  UGA: { value: "UGA", label: "Uganda" },
  UKR: { value: "UKR", label: "Ucrania" },
  URY: { value: "URY", label: "Uruguay" },
  USA: { value: "USA", label: "Estados Unidos" },
  UZB: { value: "UZB", label: "Uzbekistán" },
  VAT: { value: "VAT", label: "Ciudad del Vaticano" },
  VCT: { value: "VCT", label: "San Vicente y las Granadinas" },
  VEN: { value: "VEN", label: "Venezuela" },
  VNM: { value: "VNM", label: "Vietnam" },
  VUT: { value: "VUT", label: "Vanuatu" },
  WSM: { value: "WSM", label: "Samoa" },
  XKX: { value: "XKX", label: "Kosovo" },
  YEM: { value: "YEM", label: "Yemen" },
  ZAF: { value: "ZAF", label: "Sudáfrica" },
  ZMB: { value: "ZMB", label: "Zambia" },
  ZWE: { value: "ZWE", label: "Zimbabue" },
};
  

import React from "react";
import { ListSearchAthletes, ListSearchProfessional } from "..";
import "../../css/pruebaBusqueda.css";
import { roles } from "../../domain/roles";

export const AutocompleteItem = (item) => {
  const { name, role, sportsAssociation, fullProfile } = item;


  let yearsOfExperience = !fullProfile?.yearsOfExperience ? 0 : fullProfile?.yearsOfExperience;
  let sports = !sportsAssociation ? [] : sportsAssociation.sports;

  return (
    <>
      {role?.includes(roles.athlete) && (
        <ListSearchAthletes
          name={name}
          yearsOfExperience={yearsOfExperience}
          imgProfile={fullProfile?.urlImage}
          {...item}
        />
      )}

      {!role?.includes(roles.athlete) && (
        <ListSearchProfessional
          name={name}
          yearsOfExperience={yearsOfExperience}
          sports={sports}
          // gym="gym"
          // team="team"
          imgProfile={fullProfile?.urlImage}
          {...item}
        />
      )}

      {/* type gym | team */}
    </>
  );
};

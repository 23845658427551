
import React from 'react'
import { isMobile } from "react-device-detect";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import InputIcon from "react-multi-date-picker/components/input_icon"
import transition from "react-element-popper/animations/transition"
import "react-multi-date-picker/styles/layouts/mobile.css"
import dayjs from 'dayjs';


const spanish = {
    name: "spanish",
    months: [
        ["enero", "ene"],
        ["febrero", "feb"],
        ["marzo", "mar"],
        ["abril", "abr"],
        ["mayo", "may"],
        ["junio", "jun"],
        ["julio", "jul"],
        ["agosto", "ago"],
        ["septiembre", "sep"],
        ["octubre", "oct"],
        ["noviembre", "nov"],
        ["diciembre", "dic"],
    ],
    weekDays: [
        ["sábado", "sáb"],
        ["domingo", "dom"],
        ["lunes", "lun"],
        ["martes", "mar"],
        ["miércoles", "mié"],
        ["jueves", "jue"],
        ["viernes", "vie"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"], // Dígitos del 0 al 9
    meridiems: [
        ["AM", "am"],
        ["PM", "pm"],
    ],
};

export const InputCalendar = ({ date, handleDate, multiple, numberOfDates, fullMaximumDates }) => {

    return (
        <>
            <DatePicker
                // className={`${isMobile && "rmdp-mobile"}`}
                value={date}
                onChange={handleDate}
                format="DD-MM-YYYY"
                showOtherDays
                numberOfMonths={isMobile ? 1 : multiple ? 2 : 1}
                calendarPosition={isMobile ? "bottom-center" : "bottom-start"}
                multiple={multiple}
                minDate={dayjs().format('DD-MM-YYYY')}
                plugins={[
                    <DatePanel
                        key="1"
                        header="Fechas Seleccionadas:"
                        style={{ width: 'content', paddingLeft: '1.5rem', paddingRight: '1.5rem' }}
                        disabled={!multiple}

                    />,
                ]}
                render={<InputIcon />}
                animations={[transition()]}
                mapDays={({ date }) => {
                    let isWeekend = [0, 6].includes(date.weekDay.index)
                    if (isWeekend) return {
                        disabled: false,
                        style: { color: "#979797" },
                    }
                }}
                // mobileLabels={{
                //     OK: "Accept",
                //     CANCEL: "Close",
                // }}


                locale={spanish}
            >
                {multiple && <p style={{ fontSize: '0.8rem', marginBottom: '0.2rem', paddingBottom: `${!fullMaximumDates && "0.5rem"}` }}>Seleccione sus {numberOfDates} fechas en la que se repetirá su entrenamiento.</p>}
                {multiple && <p style={{
                    fontSize: '0.8rem',
                    color: `${fullMaximumDates ? "red" : date.length < numberOfDates ? "orange" : "green"}`,
                    marginTop: '0.2rem',
                    paddingBottom: '0.5rem',
                }}>{date.length} de {numberOfDates} fechas seleccionadas.</p>}
            </DatePicker>

        </>
    )
}
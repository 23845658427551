import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AlertSinLink,
  SelectSearchSingleMultiple,
  InputRange,
  ListIconsSports,
  ListIconsSportsCreateEditTraining,
  InputCalendar,
  ExerciseList,
} from "..";
import { LanguageContext } from "../../context/LanguageContext";
import { useDeleteTraining, useGetMyProfile, useTerminateExercise, useUpdateTraining } from "../../hooks";
import { isSeriesTraining } from "../../assets/data/typesOfTrainings";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";
import { filterOptionsEquipment, getTextsEquipment, optionsEquipment } from "../../assets/data/validateEquipment";
import { InfoIcon } from "../../assets/svg/svgs";
import { scrollToTop } from "../../helpers/scrollToTop";

export const ModalViewTraining = ({ training, countTraining, idModal }) => {
  const { query, modalViewExercise, selectUser, uid } = useSlicesState()

  const { lang } = useContext(LanguageContext);
  const { isAthlete, myIdUSer, equipment } = useGetMyProfile()
  const { dispatch,
    setExerciseSelected,
    setIsEditingExerciseInTrainingTrue,
    setModalViewExercise,
    setModalViewVideo,
    setModalCreateExercise,
    setTrainingIdSelected,
  } = useSlicesActions()

  const userEquipment = getTextsEquipment((uid === myIdUSer && isAthlete()) ? equipment : selectUser?.equipment);

  const [isEdit, setIsEdit] = useState(false);

  const handleIsEdit = () => {
    setIsEdit(true);
  };

  const closeModal = () => {
    dispatch(setModalViewExercise(null));
    dispatch(setTrainingIdSelected(null));
    setIsEdit(false);
    scrollToTop()
  };


  const {
    title,
    handleUpdateTraining,
    sports,
    valueSports,
    setvalueSports,
    target,
    seriesTemp,
    handleUpdateTrainingContainer,
    aSingleTrainingDate,
    handleASingleTrainingDate,
    isSaveButtonDisabled,
  } = useUpdateTraining(training, closeModal)

  const { handleDeleteTraining } = useDeleteTraining()

  const exercises = training.exercisesId

  const checkExercise = (isComplete) => isComplete === false;

  const widthAndHeight = "20px";

  const { handleTerminateExerciseInTraining } = useTerminateExercise()

  const handleUpdateExercise = (idExercise, exercise) => {
    dispatch(setIsEditingExerciseInTrainingTrue())
    dispatch(setExerciseSelected({
      ...exercise,
      optionsEquipment: exercise?.optionsEquipment ? exercise?.optionsEquipment : optionsEquipment,
      options: {
        ...exercise.options,
        isUsingMinutes: exercise.options.isUsingMinutes ? exercise.options.isUsingMinutes : false,
        hearRateInfo: exercise.options.hearRateInfo === 0 ? 30 : exercise.options.hearRateInfo,
        kmInfo: exercise.options.kmInfo === 0 ? 1 : exercise.options.kmInfo,
        cadenceInfo: exercise.options.cadenceInfo === 0 ? 1 : exercise.options.cadenceInfo,
        metersInfo: exercise.options.metersInfo === 0 ? 1 : exercise.options.metersInfo,
        powerInfo: exercise.options.powerInfo === 0 ? 1 : exercise.options.powerInfo,
        series: exercise.options.series === 0 ? 1 : exercise.options.series,
        breakInfo: exercise.options.breakInfo === 0 ? 1 : exercise.options.breakInfo,
        rhythmInfo: exercise.options.rhythmInfo === 0 ? 1 : exercise.options.rhythmInfo,
        toTheRuling: exercise.options.toTheRuling ? exercise.options.toTheRuling : false,

      }
    }))
    dispatch(setModalCreateExercise(idExercise));

  };

  return (
    <>
      <Modal
        className="fade bd-example-modal-lg modal-dialog-scrollable"
        show={modalViewExercise === idModal}
        size="xl"
        backdropClassName="modalEditCreateExercise"

      >
        <Modal.Header>
          {!isEdit ? (
            <Modal.Title className="col-11">
              {training.title}{" "}
              {
                isSeriesTraining(training.typesOfTraining) &&
                <span className="font-weight-bold"> x {training?.optionsSeriesTraining?.series}</span>
              }

              {training.isComplete && (
                <span className="text-success">{` ${lang.mySubscriptions.status.finished}`}</span>
              )}

              {(!training.isComplete && !isSeriesTraining(training.typesOfTraining)) &&
                training?.exercisesId?.map((exercise) => exercise.isComplete).includes(true) && (
                  <span className="text-secondary">{` ${lang.generic.inProgress}`}</span>
                )}

              {(!training.isComplete && !isSeriesTraining(training.typesOfTraining)) && //pendiente sin series
                training.exercisesId
                  ?.map((exercise) => exercise.isComplete)
                  .every(checkExercise) && (
                  <span className="text-warning">{` ${lang.generic.pending}`}</span>
                )}

              {(isSeriesTraining(training.typesOfTraining) && training?.optionsSeriesTraining?.seriesCompleted > 0 && !training.isComplete) && ( //en progreso con series
                <span className="text-secondary">{` ${lang.generic.inProgress}`}</span>
              )}

              {(isSeriesTraining(training.typesOfTraining) && training?.optionsSeriesTraining?.seriesCompleted === 0) && //pendiente con series
                (
                  <span className="text-warning ">{` ${lang.generic.pending}`}</span>
                )}

              {!isAthlete() && !isEdit && !training.isComplete && (
                <Button
                  as="a"
                  variant="warning ml-3"
                  href="#"
                  className="btn-xs"
                  onClick={() => handleIsEdit()}
                >
                  {/* {lang.buttons.edit} */}
                  <i className={`fa fa-edit`} />{" "}
                </Button>
              )}
              {!isAthlete() && !training.isComplete && (
                <Button
                  as="a"
                  variant="danger ml-2"
                  href="#"
                  className="btn-xs"
                  onClick={() => handleDeleteTraining(training._id)}
                >
                  <i className={`fa fa-trash`} />{" "}
                  {/* si esta editando guardar estado edtando y reemplaar title por inputs */}
                </Button>
              )}

              <br />
              <ListIconsSports trainingType={training.sports} className="ml-2 mr-2" widthAndHeight="24px" />
            </Modal.Title>
          ) : (
            <>
              <h4>{lang.viewExercise.editTraining}</h4>
            </>
          )}

          <Button variant="" className="close" onClick={() => closeModal()}>
            <span>x</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {isAthlete() && (
            <p className="text-primary text-capitalize">
              {lang.roles.personalTrainer}: {training.idPersonalTrainer?.name}
            </p>
          )}

          {training.target && !isEdit && (
            <>
              {(isSeriesTraining(training.typesOfTraining) && !training.isComplete) &&
                <p className="text-secondary text-center">
                  {/* {`${countTraining + lang.viewExercise.roundsOf + data.series + " Completadas"}`} */}
                  {training?.optionsSeriesTraining?.seriesCompleted > 0
                    ? training?.optionsSeriesTraining?.seriesCompleted + lang.viewExercise.roundsOf + training?.optionsSeriesTraining?.series + " Completadas"
                    : countTraining + lang.viewExercise.roundsOf + training?.optionsSeriesTraining?.series + " Completadas"
                  }
                </p>
              }
              <AlertSinLink
                variant={"info"}
                Icon={InfoIcon}
                msg={`${lang.viewExercise.target}:`}
                text={training.target}
              />
            </>
          )}

          {isEdit && (
            <>
              <div className="row">
                <div className="form-group col-md-6 mt-2">
                  <div className="mb-2">
                    <input
                      type="text"
                      className="form_test_entrenamiento"
                      name="title"
                      required={title.length < 1}
                      placeholder={lang.placeHolders.createTraining}
                      value={title}
                      onChange={handleUpdateTraining}
                    />
                  </div>
                </div>

                <div className="form-group col-md-6 mt-2">
                  <div className="form-group">
                    <SelectSearchSingleMultiple
                      multiple={true}
                      data={sports}
                      search={false}
                      placeholder={lang.createTraining.trainingType}
                      values={valueSports}
                      setValues={setvalueSports}
                      closeOnSelect={false}
                    />
                    <br />
                    <ListIconsSportsCreateEditTraining valueSports={valueSports} widthAndHeight={widthAndHeight} />
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <div className="mb-2">
                    <h5 className="text-primary mb-2">
                      {lang.createTraining.trainingTarget}
                    </h5>
                    <textarea
                      className="form-control h-100 textarea-required"
                      rows="4"
                      id="target"
                      name="target"
                      value={target}
                      placeholder={lang.placeHolders.trainingTargetDescription}
                      onChange={handleUpdateTraining}
                      required={target?.length < 1}
                    ></textarea>
                  </div>
                </div>

                {
                  isSeriesTraining(training.typesOfTraining) &&
                  <>
                    <div className="form-group col-md-6">
                      <div className="mb-2">
                        <h5 className="text-primary mb-2">{lang.generic.series}</h5>

                        <InputRange
                          name="seriesTemp"
                          value={seriesTemp ? seriesTemp : 0}
                          min={0}
                          max={50}
                          fun={handleUpdateTraining}
                        />
                      </div>
                    </div>

                  </>

                }



                <div className="form-group col-md-6">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      gap: '1rem',
                      border: '0px',
                      borderBottom: `2px solid #efefef`,
                      width: '90%',
                      fontSize: '1.2rem',
                      marginBottom: '0.5rem',
                      fontWeight: '500',
                      lineHeight: '1.2',
                      color: '#3d4465',
                    }}>
                    <h6 className="text-primary">{lang.placeHolders.dateTraining}</h6>
                    <InputCalendar
                      date={aSingleTrainingDate}
                      handleDate={handleASingleTrainingDate}
                      multiple={false}
                    />
                  </div>

                </div>
              </div>
            </>
          )}

          <h4>{lang.viewExercise.exercisesOfTraining}</h4>

          <ExerciseList
            exercises={exercises}
            training={training}
            handleUpdateExercise={handleUpdateExercise}
            isAthlete={isAthlete}
            dispatch={dispatch}
            setModalViewVideo={setModalViewVideo}
            handleTerminateExerciseInTraining={handleTerminateExerciseInTraining}
            userEquipment={userEquipment}
            lang={lang}
            filterOptionsEquipment={filterOptionsEquipment}
          />

        </Modal.Body>
        <Modal.Footer>
          {isEdit && (
            <>
              <Button variant="warning light" onClick={() => setIsEdit(false)}>
                {lang.buttons.cancel}
              </Button>
              <Button
                variant={`${isSaveButtonDisabled ? "dark" : "success light"}`}
                onClick={() => handleUpdateTrainingContainer(query)}
                disabled={isSaveButtonDisabled}
              >
                {lang.buttons.saveChanges}
              </Button>
            </>
          )}
          <Button variant="danger light" onClick={() => closeModal()}>
            {lang.buttons.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

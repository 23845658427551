import React from 'react';
import { iconMapping } from '../../assets/data/iconMapping';
import { ListSVGIcons } from '../list/ListSVGIcons';


export const DynamicSVGIconList = ({ exercise, lang }) => {
    return (
        <ul className="list-group list-group-flush mt-2">
            {Object.entries(iconMapping).map(([key, { SVG, color, textPrefix }]) => {
                const value = exercise?.options?.[key];

                let shouldDisplay = false;

                switch (key) {
                    case 'weightInfo':
                    case 'kmInfo':
                    case 'hearRateInfo':
                    case 'cadenceInfo':
                    case 'metersInfo':
                    case 'powerInfo':
                        shouldDisplay = value >= 1 && exercise?.options?.[`${key.replace('Info', '')}`];
                        break;
                    case 'series':
                        shouldDisplay = value > 1;
                        break;
                    case 'repetitions':
                        shouldDisplay = value > 0;
                        break;
                    case 'breakInfo':
                        shouldDisplay = exercise?.options?.breakRecovery && value >= 1;
                        break;
                    case 'rhythmInfo':
                        shouldDisplay = exercise?.options?.rhythm && value >= 1;
                        break;
                    case 'heating':
                    case 'activeRecovery':
                    case 'toTheRuling':
                        shouldDisplay = value;
                        break;
                    case 'timeInfo':
                        shouldDisplay = exercise?.options?.time && exercise?.options?.timeInfo !== "";
                        break;
                    case 'swimmingStylesInfo':
                        shouldDisplay = exercise?.options?.swimmingStyles && exercise?.options?.swimmingStylesInfo !== "";
                        break;
                    case 'otherInfo':
                        shouldDisplay = exercise?.options?.other && exercise?.options?.otherInfo !== "";
                        break;
                    default:
                        break;
                }

                if (shouldDisplay) {
                    let text;
                    if (textPrefix) {
                        text = `${lang.generic[textPrefix]}: ${value}`;
                        if (key === 'weightInfo') text += ' Kg';
                        else if (key === 'breakInfo') text += ` ${exercise?.options?.isUsingMinutes ? lang.generic.minutes : lang.generic.seconds}`;
                        else if (key === 'kmInfo') text += ` Km's`;
                        else if (key === 'hearRateInfo') text += ` lpm`;
                        else if (key === 'cadenceInfo') text += ` CAD`;
                        else if (key === 'rhythmInfo') text += ` Km/h`;
                        else if (key === 'metersInfo') text += ` m's`;
                        else if (key === 'powerInfo') text += ` P`;
                    } else {
                        text = lang.generic[key];
                    }

                    return (
                        <ListSVGIcons
                            key={key}
                            SVG={SVG}
                            color={color}
                            text={text}
                        />
                    );
                }

                return null;
            })}
        </ul>
    );
};

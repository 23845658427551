import { useEffect } from "react";
import { useGetMyProfileQuery } from "../../api";
import { roles } from "../../domain/roles";
import { useStateRedux } from "../useStateAPI";
import { getParametersTraining } from "../../helpers/getParametersTraining";
import { useSlicesActions } from "../../redux/slices";

export const useGetMyProfile = () => {

  const { isLoading: isLoadingData, isFetching } = useGetMyProfileQuery(undefined, {
    refetchOnMountOrArgChange: 3600,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  });

  const { dataUserProfile, dataDashboardData } = useStateRedux()
  const { dispatch, setTrainingParameters } = useSlicesActions()

  const myIdUSer = dataUserProfile?.myProfile?._id;
  const name = dataUserProfile?.myProfile?.name;
  const email = dataUserProfile?.myProfile?.email;
  const img = dataUserProfile?.myProfile?.fullProfile?.urlImage
  const yearsOfExperience = dataUserProfile?.myProfile?.fullProfile?.yearsOfExperience
  const birthdate = dataUserProfile?.myProfile?.fullProfile?.birthdate
  const aboutMe = dataUserProfile?.myProfile?.fullProfile?.aboutMe
  const country = dataUserProfile?.myProfile?.fullProfile?.country
  const numberOfSubscriptions = dataUserProfile?.myProfile?.fullProfile?.numberOfSubscriptions
  const role = dataUserProfile?.myProfile?.role
  const acountIsFreeForEver = dataUserProfile?.myProfile?.fullProfile?.acountIsFreeForEver
  const profileUpdated = dataUserProfile?.myProfile?.fullProfile?.profileUpdated
  const physiologicalData = dataUserProfile?.myProfile?.physiologicalData
  const sports = dataUserProfile?.myProfile?.sportsAssociation?.sports
  const trainersContainerCreated = dataDashboardData?.trainingContainers
  const trainingContainersComplete = trainersContainerCreated - dataDashboardData?.trainingContainersIncomplete
  const telegramChatIdInProfile = dataUserProfile?.myProfile?.fullProfile?.telegramChatId
  const phoneWhatsApp = dataUserProfile?.myProfile?.fullProfile?.phoneWhatsApp
  const equipment = dataUserProfile?.myProfile?.equipment

  const isPersonalTrainer = () => dataUserProfile?.myProfile?.role?.includes(roles.personalTrainer)
  const isAthlete = () => dataUserProfile?.myProfile?.role?.includes(roles.athlete)
  const isYogaStretching = () => dataUserProfile?.myProfile?.role?.includes(roles.yogaStretching)

  useEffect(() => {
    const trainingParameters = getParametersTraining(sports)
    dispatch(setTrainingParameters(trainingParameters))
  }, [dispatch, setTrainingParameters, sports])


  const isLoading = isLoadingData || isFetching
  return {
    myIdUSer,
    dataUserProfile,
    name,
    role,
    profileUpdated,
    physiologicalData,
    email,
    img,
    sports,
    yearsOfExperience,
    birthdate,
    aboutMe,
    country,
    numberOfSubscriptions,
    isLoading,
    trainersContainerCreated,
    trainingContainersComplete,
    isPersonalTrainer,
    isAthlete,
    isYogaStretching,
    acountIsFreeForEver,
    telegramChatIdInProfile,
    equipment,
    phoneWhatsApp
  };
};



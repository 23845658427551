import React, { useState } from "react";
import { IconSport } from "..";

export const CardSportItemSelect = ({
  color,
  title,
  value,
  setSports,
  sports,
}) => {
  const [checked, setChecked] = useState(sports.includes(value));

  const handleSport = (e) => {
    if (e.target.checked) {
      setSports((prev) => [...prev, e.target.id]);
      setChecked(true);
    } else {
      const data = sports.filter((s) => s !== e.target.id);
      setSports(data);
    }
  };

  return (
    <>
      <div className="d-flex flex-column rounded p-2 bg-white mb-2">

        <div className="d-flex flex-row justify-content-between align-items-center rounded p-2 bg-white mb-2">

          <div onClick={() => setChecked(!checked)}>
            <div className={`media align-items-center w-50 `}>
              <span className={`activity-icon bgl-${color} mr-0"`}>
                {IconSport(value)}
              </span>
            </div>
          </div>


          <div onClick={() => setChecked(!checked)}>
            <p className="font-weight-bold text-center cursor-pointer">{title}</p>
          </div>

          <div
            className={`custom-control custom-checkbox mb-3 checkbox-${color} check-xl mr-2`}
          >
            <input
              type="checkbox"
              className="custom-control-input cursor-pointer"
              id={value}
              checked={checked}
              onChange={() => setChecked(!checked)}
              onClick={(e) => handleSport(e)}
              value={checked}
            />
            <label className="custom-control-label" htmlFor={value}></label>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import { useDeleteAcount, useUserUpdate } from '../../hooks'

export const Account = ({ activeToggle }) => {

    const { lang } = useContext(LanguageContext);

    const {
        formUpdateUserValues,
        handleFormUpdateUserValues,
        isDisableUpdateUser,
        handleUpdateUser
    } = useUserUpdate()

    const { handleRemoveAcount } = useDeleteAcount()


    return (
        <div
            id="account"
            className={`tab-pane fade ${activeToggle === "account" ? "active show" : ""
                }`}
        >
            <div className="pt-3">
                <div className="settings-form">
                    <h4 className="text-primary">{lang.profile.personalInformation}</h4>

                    <form autoComplete="off">
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>{lang.signup.fullname}</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control text-capitalize"
                                    placeholder={lang.placeHolders.enterYourName}
                                    value={formUpdateUserValues.name}
                                    onChange={handleFormUpdateUserValues}
                                />
                            </div>

                            <div className="form-group col-md-6">
                                <label>{lang.generic.email}</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={lang.placeHolders.enterYourEmail}
                                    value={formUpdateUserValues.email}
                                    className="form-control"
                                    onChange={handleFormUpdateUserValues}
                                />
                            </div>
                        </div>
                        <button
                            className="btn btn-primary"
                            disabled={isDisableUpdateUser}
                            onClick={(e) => handleUpdateUser(e)}
                        >
                            {lang.buttons.update}
                        </button>
                    </form>
                    <hr />
                    <button
                        className='btn btn-block btn-danger'
                        onClick={() => {
                            handleRemoveAcount()
                        }}
                    >{lang.buttons.deleteAccount}</button>
                </div>
            </div>
        </div>
    )
}

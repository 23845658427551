import { API } from "../../api";
import { showAlert } from "../../helpers/Alerts";
import { setTokenLocalStorage } from "../../helpers/auth-helpers";
import { setCheckAuthAndToken, setStartLogin, setStartSigNup, setToken, setUid } from "../slices";


export const signup = (name, email, password, role) => {
    return async (dispatch) => {
        try {
            dispatch(setStartSigNup(true))

            const resp = await API.post("/auth/signup", {
                name,
                email,
                password,
                role,
            });

            const { token, uid } = resp.data;

            if (resp.data) {
                setTokenLocalStorage(token);
                dispatch(setUid(uid))
                dispatch(setToken(token))
            }
        } catch (error) {
            dispatch(setStartSigNup(false))
            showAlert(error?.response?.data?.msgCode, error?.response?.data?.ok);
        } finally {
            dispatch(setStartSigNup(false))
        }
    };
};


export const login = (email, password) => {
    return async (dispatch) => {
        try {
            dispatch(setStartLogin(true));

            const resp = await API.post("/auth/login", { email, password });

            const { token, uid } = resp.data;

            if (resp.data) {
                setTokenLocalStorage(token);
                dispatch(setUid(uid))
                dispatch(setToken(token))
            }
        } catch (error) {
            dispatch(setStartLogin(false));
            showAlert(error?.response?.data?.msgCode, error?.response?.data?.ok);
        } finally {
            dispatch(setStartLogin(false));
        }
    };
};

export const checkingAuthAndToken = () => {
    return async (dispatch) => {
        try {
            const resp = await API.get("/auth/check-status");

            const { token, uid } = resp.data;

            if (resp.data) {
                setTokenLocalStorage(token);
                dispatch(setUid(uid))
                dispatch(setToken(token))
            } else {
                dispatch(setCheckAuthAndToken(false));
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setCheckAuthAndToken(false));
        }
    };
};
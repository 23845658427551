import React from "react";

const width = "35px";
const height = "35px";

//DEPORTES
//Run
export const RunSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 40 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.64826 26.5285C0.547125 26.7394 -0.174308 27.8026 0.0366371 28.9038C0.222269 29.8741 1.07449 30.5491 2.02796 30.5491C2.15453 30.5491 2.28531 30.5364 2.41188 30.5112L10.7653 28.908C11.242 28.8152 11.6682 28.5578 11.9719 28.1781L15.558 23.6554L14.3599 23.0437C13.4739 22.5965 12.8579 21.7865 12.6469 20.8035L9.26338 25.0688L1.64826 26.5285Z"
          fill="#A02CFA"
          className="text-primary"
        />
        <path
          d="M31.3999 8.89345C33.8558 8.89345 35.8467 6.90258 35.8467 4.44673C35.8467 1.99087 33.8558 0 31.3999 0C28.9441 0 26.9532 1.99087 26.9532 4.44673C26.9532 6.90258 28.9441 8.89345 31.3999 8.89345Z"
          fill="#A02CFA"
        />
        <path
          d="M21.6965 3.33297C21.2282 2.85202 20.7937 2.66217 20.3169 2.66217C20.1439 2.66217 19.971 2.68748 19.7853 2.72967L12.1534 4.53958C11.0986 4.78849 10.4489 5.84744 10.6979 6.89795C10.913 7.80079 11.7146 8.40831 12.6048 8.40831C12.7567 8.40831 12.9086 8.39144 13.0605 8.35347L19.5618 6.81357C19.9837 7.28187 22.0974 9.57273 22.4813 9.97775C19.7938 12.855 17.1064 15.7281 14.4189 18.6054C14.3767 18.6519 14.3388 18.6982 14.3008 18.7446C13.5161 19.7445 13.7566 21.3139 14.9379 21.9088L23.1774 26.1151L18.8994 33.0467C18.313 34.0002 18.6083 35.249 19.5618 35.8396C19.8951 36.0464 20.2621 36.1434 20.6249 36.1434C21.3042 36.1434 21.9707 35.8017 22.3547 35.1815L27.7886 26.3766C28.0882 25.8915 28.1683 25.305 28.0122 24.7608C27.8561 24.2123 27.4806 23.7567 26.9702 23.4993L21.3885 20.66L27.2571 14.3823L31.6869 18.1371C32.0539 18.4493 32.5054 18.6012 32.9526 18.6012C33.4335 18.6012 33.9145 18.424 34.2899 18.078L39.3737 13.3402C40.1669 12.6019 40.2133 11.3615 39.475 10.5684C39.0868 10.1549 38.5637 9.944 38.0406 9.944C37.5638 9.944 37.0829 10.117 36.7074 10.4671L32.9019 14.0068C32.8977 14.011 23.363 5.04163 21.6965 3.33297Z"
          fill="#A02CFA"
        />
      </svg>
    </>
  );
};

//RunTrail
export const TrailRunningSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 920.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M5020 4531 c-61 -12 -127 -50 -170 -97 -80 -87 -105 -188 -76 -297
59 -217 320 -306 496 -171 233 180 125 539 -170 568 -25 3 -61 1 -80 -3z"/>
          <path d="M5896 4080 c-53 -16 -93 -70 -238 -316 -78 -133 -145 -241 -148 -239
-3 1 -144 85 -315 186 -286 169 -314 183 -365 187 -77 5 -828 -187 -877 -224
-26 -20 -75 -110 -234 -427 -217 -433 -224 -451 -186 -524 28 -53 73 -78 142
-78 102 0 101 -2 304 404 l180 358 133 35 c73 19 134 34 136 33 1 -1 -161
-323 -360 -715 l-363 -713 -320 -82 c-389 -99 -359 -90 -406 -132 -96 -86 -81
-240 29 -317 72 -51 108 -46 562 72 402 105 492 136 541 189 15 15 93 156 174
313 82 157 149 286 150 287 2 3 578 -326 586 -335 4 -4 9 -197 11 -430 4 -382
3 -422 -11 -417 -68 27 -195 55 -268 61 -108 8 -173 -3 -362 -62 -251 -77
-373 -77 -611 2 -135 44 -145 46 -233 42 -124 -5 -168 -27 -343 -170 -153
-124 -206 -153 -310 -167 -87 -13 -126 -5 -314 65 -86 32 -178 59 -208 62
-125 11 -202 -43 -281 -198 -28 -56 -51 -114 -51 -131 0 -42 28 -86 64 -101
25 -11 486 -13 2401 -13 l2370 0 45 21 c56 26 118 86 137 132 16 39 88 389 98
479 9 77 -10 218 -39 297 -37 97 -254 498 -287 529 -21 19 -40 27 -65 27 -47
0 -74 -29 -138 -149 -57 -106 -120 -170 -204 -209 -51 -23 -73 -27 -222 -33
-263 -11 -336 -46 -580 -274 l-165 -155 -5 157 c-3 87 -6 313 -8 503 -1 190
-5 368 -9 395 -7 63 -38 129 -72 158 -15 12 -144 76 -287 141 -142 65 -263
122 -267 126 -8 7 302 640 314 640 4 0 92 -50 195 -112 169 -100 195 -112 244
-116 65 -4 110 16 152 69 56 70 388 650 394 688 12 75 -39 154 -115 180 -39
13 -54 13 -95 1z"/>
        </g>
      </svg>

    </>
  );
};

//Bike
export const BikeSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 40 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0977 7.90402L9.78535 16.7845C9.17929 17.6683 9.40656 18.872 10.2862 19.4738L18.6574 25.2104V30.787C18.6574 31.8476 19.4992 32.7357 20.5598 32.7568C21.6456 32.7735 22.5295 31.9023 22.5295 30.8207V24.1961C22.5295 23.5564 22.2138 22.9588 21.6877 22.601L16.3174 18.9184L20.8376 14.1246L23.1524 19.3982C23.4596 20.101 24.1582 20.5556 24.9243 20.5556H31.974C33.0346 20.5556 33.9226 19.7139 33.9437 18.6532C33.9605 17.5674 33.0893 16.6835 32.0076 16.6835H26.1953C25.4293 14.9411 24.6128 13.2155 23.9015 11.4478C23.5395 10.5556 23.3376 10.1684 22.6726 9.55389C22.5379 9.42763 21.5993 8.56904 20.7618 7.80305C19.9916 7.10435 18.8047 7.15065 18.0977 7.90402Z"
          fill="#FF3282"
        />
        <path
          d="M26.0269 8.87206C28.4769 8.87206 30.463 6.88598 30.463 4.43603C30.463 1.98608 28.4769 0 26.0269 0C23.577 0 21.5909 1.98608 21.5909 4.43603C21.5909 6.88598 23.577 8.87206 26.0269 8.87206Z"
          fill="#FF3282"
        />
        <path
          d="M8.16498 38.388C12.6744 38.388 16.33 34.7325 16.33 30.2231C16.33 25.7137 12.6744 22.0581 8.16498 22.0581C3.65559 22.0581 0 25.7137 0 30.2231C0 34.7325 3.65559 38.388 8.16498 38.388Z"
          fill="#FF3282"
        />
        <path
          d="M31.835 38.388C36.3444 38.388 40 34.7325 40 30.2231C40 25.7137 36.3444 22.0581 31.835 22.0581C27.3256 22.0581 23.67 25.7137 23.67 30.2231C23.67 34.7325 27.3256 38.388 31.835 38.388Z"
          fill="#FF3282"
        />
      </svg>
    </>
  );
};

//Natación
export const SwimmSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 491.181 491.181"
      >
        <path
          id="XMLID_35_"
          d="M379.267,196.454c16.091-15.368,16.685-40.885,1.308-56.974c-15.367-16.107-40.876-16.693-56.967-1.332
		c-16.106,15.368-16.692,40.876-1.331,56.967C337.653,211.22,363.16,211.815,379.267,196.454z"
          fill="#4b7cad"
        />
        <path
          d="M163.19,222.82l57.794-28.523l29.036,11.35l-95.325,91.009l0.521,0.537
		c28.62-17.855,65.824-15.376,91.315,7.396c11.398-10.186,25.717-15.994,40.565-17.895l29.036-27.713l16.075,21.353
		c3.225,4.283,8.254,6.753,13.549,6.753c1.5,0,80.092-9.048,81.72-9.232c9.305-1.06,15.97-9.466,14.92-18.771
		c-1.059-9.313-9.433-15.994-18.77-14.903l-70.162,8.013l-29.269-38.895c-2.93-3.898-2.351-3.281-5.311-6.698l-9.344-9.786
		c-1.973-2.07-8.879-7.78-12.04-9.023l-71.133-27.802c-4.42-1.7-9.402-1.499-13.676,0.593l-64.499,31.805
		c-8.398,4.155-11.848,14.317-7.708,22.716C144.598,223.437,154.695,226.975,163.19,222.82z"
          fill="#4b7cad"
        />
        <path
          d="M486.448,324.699c-6.32-6.314-16.54-6.329-22.829,0.015c-7.444,7.461-20.461,7.452-27.938-0.008
		c-19.677-19.667-53.983-19.667-73.627,0.017c-7.46,7.46-20.447,7.477-27.946-0.032c-19.661-19.652-53.913-19.652-73.58,0
		l-0.306,0.288c-7.7,7.42-20.028,7.356-27.666-0.28c-20.317-20.295-53.332-20.278-74.269,0.649
		c-7.05,7.051-19.579,7.083-26.67-0.031c-19.33-19.332-53.037-19.341-72.399,0.006c-8.439,8.455-23.214,8.455-31.653,0
		c-6.305-6.304-16.532-6.296-22.836-0.006c-6.305,6.303-6.305,16.531-0.009,22.836c10.332,10.331,24.065,16.026,38.671,16.026
		c14.616,0,28.356-5.695,38.67-16.018c7.148-7.14,19.58-7.14,26.719-0.017c19.244,19.26,52.555,19.853,73.001-0.601
		c7.717-7.725,20.253-7.701,27.978,0.023c20.262,20.27,53.246,20.262,73.557,0.008l0.28-0.288
		c7.395-7.131,20.421-7.011,27.673,0.232c19.669,19.7,53.968,19.725,73.637,0.024c7.467-7.476,20.462-7.476,27.938,0
		c19.675,19.675,53.966,19.644,73.618-0.016C492.76,341.214,492.752,330.995,486.448,324.699z"
          fill="#4b7cad"
        />
      </svg>
    </>
  );
};

//Funcional
export const FuncionalSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 412.336 412.336"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          id="XMLID_139_"
          d="M48.66,281.414c3.451-2.76,4.013-7.794,1.254-11.244c-22.099-27.643-34.093-62.224-33.912-97.636
	c46.001,9.121,99.416,13.925,155.173,13.925c36.097,0,71.221-2.015,103.986-5.911c0.064,2.444,0.071,4.894,0.02,7.352
	c-0.144,6.75-0.724,13.528-1.727,20.147c-0.661,4.368,2.344,8.445,6.712,9.107c4.37,0.664,8.446-2.344,9.108-6.712
	c1.104-7.298,1.745-14.768,1.902-22.204c0.069-3.264,0.044-6.521-0.072-9.761c12.147-1.715,23.921-3.698,35.246-5.943
	c0.007,1.355-0.005,2.714-0.033,4.076c-0.144,6.757-0.724,13.535-1.727,20.146c-0.662,4.369,2.343,8.446,6.711,9.108
	c4.38,0.674,8.447-2.343,9.109-6.71c1.104-7.29,1.745-14.761,1.902-22.205C344.304,82.583,269.153,4.191,174.789,2.2
	C80.469,0.172,2.029,75.359,0.038,169.725c-0.844,40.011,12.431,79.23,37.378,110.436c1.58,1.977,3.906,3.005,6.254,3.005
	C45.422,283.165,47.185,282.593,48.66,281.414z M299.227,85.785c14.104,20.568,23.378,44.671,26.209,70.616
	c-11.43,2.313-23.341,4.351-35.657,6.106c-3.378-25.897-12.632-50.542-26.854-72.116C275.375,89.114,287.515,87.575,299.227,85.785z
	 M174.451,18.196c45.331,0.957,85.771,21.363,113.507,53.065c-11.689,1.629-23.775,3.009-36.138,4.13
	c-9.119-11.008-19.68-20.953-31.528-29.528c-3.582-2.593-8.582-1.788-11.171,1.79c-2.591,3.579-1.789,8.581,1.79,11.171
	c7.553,5.467,14.528,11.547,20.878,18.144c-19.818,1.325-40.17,2.004-60.614,2.004c-40.444,0-80.509-2.655-116.787-7.71
	C83.586,37.909,126.787,17.198,174.451,18.196z M43.112,85.784c39.349,6.016,83.503,9.188,128.063,9.188
	c24.839,0,49.564-0.987,73.4-2.903c15.73,21.179,25.899,46.133,29.34,72.519c-32.312,3.871-67.021,5.873-102.74,5.873
	c-55.627,0-108.783-4.853-154.255-14.059C19.759,130.363,29.043,106.294,43.112,85.784z M384.02,287.343h-1.829v-40.353
	c0-14.254-11.597-25.851-25.852-25.851c-6.916,0-13.206,2.729-17.85,7.169c-4.644-4.439-10.935-7.169-17.852-7.169
	c-14.255,0-25.852,11.597-25.852,25.851v40.353H158.461v-40.353c0-14.254-11.597-25.851-25.851-25.851
	c-6.917,0-13.208,2.73-17.853,7.17c-4.644-4.439-10.935-7.17-17.851-7.17c-14.254,0-25.85,11.597-25.85,25.851v40.353h-1.832
	c-15.613,0-28.316,12.702-28.316,28.315s12.703,28.316,28.316,28.316h1.832v40.351c0,14.254,11.596,25.851,25.85,25.851
	c6.916,0,13.207-2.73,17.851-7.17c4.645,4.439,10.936,7.17,17.853,7.17c14.254,0,25.851-11.597,25.851-25.851v-40.351h136.326
	v40.351c0,14.254,11.597,25.851,25.852,25.851c6.917,0,13.208-2.729,17.852-7.169c4.644,4.439,10.934,7.169,17.85,7.169
	c14.255,0,25.852-11.597,25.852-25.851v-40.351h1.829c15.613,0,28.315-12.703,28.315-28.316S399.634,287.343,384.02,287.343z
	 M71.057,327.975h-1.832c-6.791,0-12.316-5.525-12.316-12.316s5.525-12.315,12.316-12.315h1.832V327.975z M106.758,384.326
	c0,5.432-4.419,9.851-9.851,9.851c-5.431,0-9.85-4.419-9.85-9.851v-48.351v-88.984c0-5.432,4.419-9.851,9.85-9.851
	c5.432,0,9.851,4.419,9.851,9.851V384.326z M142.461,384.326c0,5.432-4.419,9.851-9.851,9.851c-5.433,0-9.853-4.419-9.853-9.851
	V246.991c0-5.432,4.42-9.851,9.853-9.851c5.432,0,9.851,4.419,9.851,9.851V384.326z M294.787,327.975H158.461v-24.632h136.326
	V327.975z M330.491,384.326c0,5.432-4.42,9.851-9.853,9.851s-9.852-4.419-9.852-9.851v-48.351v-88.984
	c0-5.432,4.419-9.851,9.852-9.851s9.853,4.419,9.853,9.851V384.326z M366.191,384.326c0,5.432-4.419,9.851-9.852,9.851
	c-5.431,0-9.849-4.419-9.849-9.851V246.991c0-5.432,4.418-9.851,9.849-9.851c5.433,0,9.852,4.419,9.852,9.851V384.326z
	 M384.02,327.975h-1.829v-24.632h1.829c6.791,0,12.315,5.524,12.315,12.315S390.811,327.975,384.02,327.975z"
          fill="#0B2A97"
        />
      </svg>
    </>
  );
};

//Fuerza
export const StrongSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6406 24.384C14.4639 24.1871 14.421 23.904 14.5305 23.6633C15.9635 20.513 14.4092 18.7501 14.564 11.6323C14.5713 11.2944 14.8346 10.9721 15.2564 10.9801C15.6201 10.987 15.905 11.2962 15.8971 11.6598C15.8902 11.9762 15.8871 12.2939 15.8875 12.6123C15.888 12.9813 16.1893 13.2826 16.5583 13.2776C17.6426 13.2628 19.752 12.9057 20.5684 10.4567L20.9744 9.23876C21.7257 6.9847 20.4421 4.55115 18.1335 3.91572L13.9816 2.77294C12.3274 2.31768 10.5363 2.94145 9.52387 4.32498C4.66826 10.9599 1.44452 18.5903 0.0754914 26.6727C-0.300767 28.8937 0.754757 31.1346 2.70222 32.2488C13.6368 38.5051 26.6023 39.1113 38.35 33.6379C39.3524 33.1709 40.0002 32.1534 40.0002 31.0457V19.1321C40.0002 18.182 39.5322 17.2976 38.7484 16.7664C34.5339 13.91 29.1672 14.2521 25.5723 18.0448C25.2519 18.3828 25.3733 18.937 25.8031 19.1166C27.4271 19.7957 28.9625 20.7823 30.2439 21.9475C30.5225 22.2008 30.542 22.6396 30.2654 22.9155C30.0143 23.1658 29.6117 23.1752 29.3485 22.9376C25.9907 19.9053 21.4511 18.5257 16.935 19.9686C16.658 20.0571 16.4725 20.3193 16.477 20.61C16.496 21.8194 16.294 22.9905 15.7421 24.2172C15.5453 24.6544 14.9607 24.7409 14.6406 24.384Z"
          fill="#27BC48"
        />
      </svg>
    </>
  );
};

//Yoga
export const YogaSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.998 511.998"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M348.326,75.21L292.531,6.286c-5.084-6.281-13.719-7.97-20.674-4.549c-6.955-3.422-15.59-1.731-20.674,4.549
			l-55.795,68.923c-3.693,4.561-4.767,10.705-2.843,16.248l30.407,87.627v113.669l-57.151,61.451
			c-5.114,5.499-7.082,13.22-5.225,20.497c1.858,7.276,7.287,13.108,14.411,15.483c105.849,35.277,98.421,33.004,101.66,33.405
			v66.332c0,12.193,9.884,22.076,22.076,22.076c12.193,0,22.076-9.884,22.076-22.076c0-294.794-0.4-122.087-0.4-309.798
			l30.769-88.665C353.093,85.915,352.018,79.771,348.326,75.21z M276.648,377.525l-55.143-18.378
			c43.381-46.646,40.469-43.314,42.019-45.688h13.125V377.525z M292.989,155.548h-42.261l-11.061-31.877
			c6.761,10.657,18.656,17.736,32.211,17.736c13.498,0,25.35-7.021,32.126-17.603L292.989,155.548z M309.819,107.039
			c0.121-1.238,0.186-2.492,0.186-3.762c0-21.058-17.071-38.13-38.13-38.13c-21.058,0-38.13,17.071-38.13,38.13
			c0,1.319,0.067,2.623,0.198,3.907l-6.227-17.943l44.14-54.524l44.138,54.524L309.819,107.039z"
          fill="#FFBC11"
        />
      </svg>
    </>
  );
};

//Stretching
export const StretchingSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M361.816,157.046c-17.621-25.124-52.273-31.207-77.397-13.586c-26.702,18.728-31.757,56.527-10.283,81.648l77.586,6.655
			C373.872,213.486,378.586,180.958,361.816,157.046z"
          fill="#ff9900"
        />

        <path
          d="M477.518,308.999c-0.018,0-0.037,0-0.054,0l-16.253,0.024c9.071-4.976,15.14-14.742,14.792-25.897
			c-0.483-15.498-13.706-27.576-29.21-27.576h-99.366l-158.639-36.235l54.842,3.176c-29.481-33.207-79.81-39.77-117.054-13.681
			L23.963,279.913C4.406,293.462-4.706,318.142,2.4,340.848c7.033,22.472,27.85,37.77,51.398,37.77c0.018,0,0.037,0,0.054,0
			l422.886-0.65c19.081-0.029,35.401-15.661,35.261-34.741C511.861,324.297,496.461,308.999,477.518,308.999z M214.789,309.403
			l21.17-11.295l-60.461-26.294l151.5,37.416C306.005,309.262,235.668,309.371,214.789,309.403z"
          fill="#ff9900"
        />
      </svg>
    </>
  );
};

//HIIt
export const HiitSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#FF6F61"
          d="M256 24c-67.8 0-111.5 43.46-132.4 97.8-20 52-20.8 114.2-6.1 163.5l14.2-16.1c-10.1-43.5-8.1-97.2 8.7-141C159.5 78.54 195.7 42 256 42c60.2 0 96.5 36.54 115.6 86.2 16.8 43.8 18.8 97.5 8.7 141l14.2 16.1c14.7-49.3 13.9-111.5-6.1-163.5C367.5 67.46 323.8 24 256 24zm0 92.8c-18.2 0-34.2 17.8-34.2 41.6s16 41.6 34.2 41.6c18.2 0 34.2-17.8 34.2-41.6s-16-41.6-34.2-41.6zM231.3 211c-17.3 3.8-31.3 10.2-33.9 11.4l-74.6 84 8.3 16.6 85.9-68.7V417h30v-64h18v135h30V254.3l85.9 68.7 8.3-16.6-74.6-84c-2.6-1.2-16.6-7.6-33.9-11.4-7.3 4.4-15.7 7-24.7 7s-17.4-2.6-24.7-7z"
        />
      </svg>
    </>
  );
};

//Crossfit
export const CrossfitSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g>
          <g>
            <path fill="#1E90FF" d="M217.234,97.98c-19.017-3.064-36.915,9.87-39.978,28.88c-3.078,19.102,10.027,37.373,29.68,40.075l21.905-64.255v-0.64
			C225.339,100.063,221.436,98.658,217.234,97.98z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M298.533,255.089c-5.753-3.74-12.697-4.523-20.697-0.78v56.021c10.253,6.419,11.166,15.246,13.627,25.558l13.802,57.796
			l2.403-121.351C307.805,265.392,304.351,258.873,298.533,255.089z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M241.089,461.296v38.455c0,6.764,5.484,12.249,12.249,12.249s12.249-5.484,12.249-12.249v-14.68
			C254.492,481.386,245.276,472.815,241.089,461.296z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M255.293,197.938c-2.963,8.688-10.732,10.272-14.204,11.324v99.791c22.356-2.673,21.551-2.697,24.499-2.559V167.741
			C261.715,179.102,259.315,186.142,255.293,197.938z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M297.449,443.196l-24.567-102.872c-1.806-7.565-7.815-13.407-15.428-15l-55.429-11.601l26.816-3.206
			c0-19.787,0-79.38,0-97.55l-37.143,11.235l42.539-28.242c2.537-1.685,4.505-4.099,5.644-6.924l31.763-78.852
			c3.109-7.721-0.628-16.5-8.347-19.611c-7.725-3.111-16.5,0.628-19.611,8.347l-30.017,74.516l-34.453,22.875l17.109-16.664
			c-8.559,0.495-16.841,3.601-23.669,9.025c-9.231,7.334-14.95,18.215-15.754,29.978c-0.019,0.284-3.175,50.283-5.749,90.739
			c-1.242,19.516,11.98,37.036,31.1,41.133c15.051,3.225,33.15,7.06,54.497,11.529l21.608,90.481
			c2.578,10.791,13.416,17.452,24.212,14.875C293.367,464.829,300.027,453.99,297.449,443.196z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M314.788,145.419l-30.223-33.533c-0.361,1.32,0.512-1.319-11.089,32.714l4.36,4.837c0,22.308,0,16.464,0,41.785
			c40.091-27.233,33.481-22.734,34.427-23.402C319.777,162.532,320.714,151.994,314.788,145.419z"/>
          </g>
        </g>
        <g>
          <g>
            <path fill="#1E90FF" d="M501.44,0C436.112,0,75.734,0,10.561,0C5.02,0,0.528,4.491,0.528,10.032v21.092c0,5.541,4.491,10.032,10.033,10.032
			c44.41,0,217.813,0,230.529,0v25.719c-4.043-1.234-8.514-0.747-12.248,1.534c-8.226,5.025-9.54,16.089-3.355,22.95
			c3.651,4.051,2.728,3.026,5.235,5.808c6.199-18.185,22.362-23.062,34.867-19.346V41.155c8.239,0,188.075,0,235.852,0
			c5.54,0,10.033-4.491,10.033-10.032V10.033C511.472,4.491,506.981,0,501.44,0z"/>
          </g>
        </g>
      </svg>
    </>
  );
};

//Calistenia
export const CalisteniaSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 197.039 197.039"
      >
        <g>
          <g>
            <ellipse fill="#000" cx="100.063" cy="35.062" rx="14.965" ry="14.767" />
            <path fill="#000" d="M132.391,5.637c-0.16-1-0.273-1.491-0.314-1.646c-0.736-2.854-3.654-4.398-6.502-3.662c-2.396,0.619-3.99,2.308-4,5.308
			H78.651c-0.012-3-1.605-4.856-4.001-5.477c-2.849-0.735-5.765,1.146-6.5,4.002c-0.041,0.155-0.154,0.475-0.315,1.475H38.874
			c-7.928,0-14.354,6.426-14.354,14.354v171.92c0,2.832,2.173,5.122,5.004,5.122l0,0c2.833,0,4.996-2.29,4.996-5.122V18.692
			c0-2.791,2.263-5.055,5.055-5.055h26.762c-2.025,14-2.933,40.004,15.048,50.263c0.292,0.166,0.92,0.351,0.92,0.351l-0.138,124.754
			c0,4.42,3.755,7.974,8.175,7.974c4.422,0,8.178-3.612,8.178-8.031v-60.311h3v60.31c0,4.419,3.83,8.003,8.25,8.003
			s8.137-3.584,8.137-8.003l-0.041-124.813c0,0,0.656-0.066,0.947-0.233c17.979-10.26,17.102-36.263,15.077-50.263h21.575
			c2.791,0,5.055,2.264,5.055,5.055v172.706c0,3.115,2.883,5.642,6,5.642c3.115,0,6-2.525,6-5.642V19.991
			c0-7.928-6.426-14.354-14.354-14.354H132.391z M116.756,51.637H83.47c-8.87-8-8.252-27-6.374-38h46.033
			C125.008,24.637,125.627,43.637,116.756,51.637z"/>
          </g>
        </g>
      </svg>
    </>
  );
};

//Tabata
export const TabataIcon = ({ widthProp, heightProp }) => (
  <svg
    width={widthProp || width}
    height={heightProp || height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="style=linear">
      <g id="chronometer">
        <path fill="#4B0082" id="line" d="M19 7L20.5 5.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
        <path fill="#ccc" id="line_2" d="M22 6L20 4" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" />
        <path fill="#ccc" id="vector" d="M21 13.25C21 18.218 16.968 22.25 12 22.25C7.032 22.25 3 18.218 3 13.25C3 8.282 7.032 4.25 12 4.25C16.968 4.25 21 8.282 21 13.25Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="#ccc" id="Vector" d="M12 8.24997V13.25" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path fill="#4B0082" id="line_3" d="M9 1.75L15 1.75" stroke="#000000" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </g>
  </svg>
);

//Amrap
export const AmrapIcon = ({ widthProp, heightProp }) => (
  <svg
    width={widthProp || width}
    height={heightProp || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 84.285 84.285"
  >
    <g>
      <path
        fill="#2E8B57"
        d="M77.386,24.214H50.558h-0.176H33.904h-0.176H6.899c-1.654,0-3,1.346-3,3v2.25c0,1.654,1.346,3,3,3h23.828v21.088
		l-7.202,1.069c-1.915,0.285-3.432,2.024-3.453,3.96l-0.237,22.094c-0.01,0.961,0.357,1.866,1.033,2.55
		c0.677,0.684,1.578,1.06,2.539,1.06h2.41c1.985,0,3.6-1.615,3.6-3.6V66.417c0-0.949,0.816-1.944,1.746-2.131l10.979-2.202
		l10.979,2.202c0.93,0.187,1.746,1.182,1.746,2.131v14.268c0,1.985,1.615,3.6,3.6,3.6h2.41c0.961,0,1.862-0.376,2.539-1.061
		c0.676-0.684,1.043-1.589,1.033-2.55l-0.237-22.094c-0.021-1.937-1.538-3.676-3.453-3.96l-7.201-1.069V32.464h23.828
		c1.654,0,3-1.346,3-3v-2.25C80.386,25.56,79.04,24.214,77.386,24.214z"/>
      <path
        fill="#2E8B57"
        d="M42.142,21.672c5.975,0,10.836-4.861,10.836-10.836S48.117,0,42.142,0c-5.975,0-10.835,4.861-10.835,10.836
		S36.167,21.672,42.142,21.672z"/>
    </g>
  </svg>
);

//Emom
export const EmomIcon = ({ widthProp, heightProp }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthProp || width}
    height={heightProp || height}
    viewBox="0 0 273.958 273.958"
  >
    <g>
      <g>
        <path
          fill="#800080"
          d="M10.653,103.717H24v21.443c0,6.863,5.568,12.426,12.431,12.426c2.156,0,4.145-0.597,5.906-1.559v6.025
			c0,6.86,5.568,12.428,12.436,12.428c6.858,0,12.426-5.567,12.426-12.428v-38.335H81.55L94.219,90.32v-7.908H67.199V44.079
			c0-6.863-5.567-12.431-12.426-12.431c-6.868,0-12.436,5.568-12.436,12.431v6.021c-1.762-0.966-3.75-1.557-5.906-1.557
			C29.568,48.542,24,54.103,24,60.966v21.445H10.653C4.77,82.412,0,87.181,0,93.064S4.765,103.717,10.653,103.717z"/>
        <path
          fill="#800080"
          d="M263.307,82.412h-16.484V60.966c0-6.863-5.562-12.424-12.433-12.424c-2.146,0-4.145,0.597-5.908,1.561v-6.025
			c0-6.863-5.573-12.431-12.434-12.431c-6.87,0-12.433,5.568-12.433,12.431v38.333h-4.457v21.305h4.466v38.335
			c0,6.86,5.563,12.428,12.429,12.428s12.429-5.567,12.429-12.428v-6.03c1.764,0.966,3.762,1.563,5.913,1.563
			c6.865,0,12.428-5.563,12.428-12.426v-21.443h16.484c5.89,0,10.65-4.77,10.65-10.653S269.188,82.412,263.307,82.412z"/>
        <path
          fill="#800080"
          d="M113.312,41.588c-5.276,0-9.558,4.282-9.558,9.558v38.821l-15.327,16.204c-7.068,7.47-7.817,19.8-1.706,28.068
			l11.638,15.765c4.044,5.47,7.584,14.837,7.584,20.046c0,8.891,8.573,16.129,19.116,16.129h37.636
			c10.543,0,19.116-8.573,19.116-19.116v-4.182c0-7.033,4.373-18.188,6.986-24.857c1.274-3.244,2.096-6.49,2.45-9.633
			c0.42-3.687-0.299-8.121-1.745-12.503c0.022-0.268,0.079-0.525,0.079-0.803V68.198c0-5.276-4.279-9.558-9.559-9.558
			c-5.278,0-9.558,4.282-9.558,9.558V94.53c-3.449-1.265-7.179-2.017-11.206-2.136c-7.859-0.224-15.653,1.538-23.009,5.295
			c-4.132,2.112-6.86,5.745-7.693,10.224c-0.985,5.336,0.847,11.21,4.894,15.714c0.602,0.667,1.199,1.351,1.778,2.04
			c-0.065-0.038-0.133-0.082-0.205-0.119c-6.251-3.785-13.691-9.521-17.894-16.962l12.384-15.999c1.615-2.088,5.066-3.78,7.703-3.78
			h21.898c5.278,0,9.559-4.282,9.559-9.558c0-3.188-1.578-5.997-3.981-7.733v-14.37c0-5.276-4.284-9.559-9.558-9.559
			c-5.274,0-9.559,4.282-9.559,9.559v12.545h-1.652V57.144c0-5.276-4.282-9.559-9.558-9.559s-9.558,4.282-9.558,9.559v13.07
			c-0.67,0.215-1.295,0.502-1.904,0.856c-0.009,0.005-0.023,0.005-0.033,0.009V51.146C122.87,45.866,118.588,41.588,113.312,41.588z
			"/>
        <path
          fill="#800080"
          d="M184.196,227.973v-19.116c0-7.92-6.417-14.338-14.337-14.338h-56.155c-7.92,0-14.337,6.418-14.337,14.338v19.116
			c0,7.915,6.417,14.337,14.337,14.337h56.155C177.779,242.31,184.196,235.888,184.196,227.973z"/>
      </g>
    </g>
  </svg>
);
//Emom
export const OtmIcon = ({ widthProp, heightProp }) => (
  <svg fill="#f94687"
    width={widthProp || width}
    height={heightProp || height}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 494.138 494.138"
  >
    <g id="XMLID_8_">
      <path id="XMLID_9_" d="M247.069,163.687c24.872,0,45.02-20.146,45.02-45.021c0-24.858-20.147-45.014-45.02-45.014
 c-24.874,0-45.007,20.156-45.007,45.014C202.063,143.54,222.195,163.687,247.069,163.687z"/>
      <path id="XMLID_10_" d="M462.838,17.042c-6.31,0-11.647,3.282-15.1,8.033v-6.082C447.738,8.5,439.221,0,428.734,0
 c-10.484,0-19.002,8.5-19.002,18.994v28.1H84.404v-28.1C84.404,8.5,75.889,0,65.402,0C54.917,0,46.4,8.5,46.4,18.994v6.082
 c-3.453-4.751-8.792-8.033-15.099-8.033c-10.485,0-19.002,8.5-19.002,18.994V82.92c0,10.493,8.517,18.994,19.002,18.994
 c6.307,0,11.646-3.282,15.099-8.025v6.082c0,10.493,8.517,18.994,19.002,18.994c10.486,0,19.002-8.501,19.002-18.994v-28.1h12.002
 c0.322,1.751,37.697,75.509,37.697,75.509c1,1.952,2.339,3.711,3.951,5.21l54.459,50.651v134.08l-44.166,42.424
 c-4.388,4.202-6.855,10.01-6.855,16.084v76.008c0,12.316,9.984,22.301,22.293,22.301c12.309,0,22.293-9.985,22.293-22.301V405.33
 l32.115-30.85h57.749l32.134,30.85v66.507c0,12.316,9.984,22.301,22.292,22.301c12.308,0,22.293-9.985,22.293-22.301v-76.008
 c0-6.074-2.468-11.881-6.855-16.084l-44.149-42.409V203.225l54.426-50.643c1.612-1.491,2.951-3.251,3.951-5.202
 c0,0,37.375-73.758,37.699-75.509h12v28.1c0,10.493,8.518,18.994,19.002,18.994c10.486,0,19.004-8.501,19.004-18.994v-6.09
 c3.452,4.751,8.789,8.033,15.1,8.033c10.483,0,19-8.501,19-18.994V36.036C481.838,25.542,473.321,17.042,462.838,17.042z
  M327.771,127.03l-48.699,45.32h-63.975l-48.732-45.32l-28.422-55.16h218.251L327.771,127.03z"/>
    </g>
  </svg>


);
//FreeTraining
export const FreetrainingIcon = ({ widthProp, heightProp }) => (
  <svg
    width={widthProp || width}
    height={heightProp || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 721.285 721.285"
  >
    <g>
      <g>
        <path d="M235.647,58.741l-46.446-45.08L202.861,0l46.446,45.08L235.647,58.741z M60.79,148.901
			l46.446,45.081l13.661-13.661L74.451,135.24L60.79,148.901z M530.718,13.661L517.058,0L470.61,45.08l13.662,13.661L530.718,13.661
			z M645.468,133.875l-45.08,46.446l13.66,13.661l46.447-45.081L645.468,133.875z M629.075,117.482l-17.758,16.393l-21.857-21.857
			c4.098-9.563,2.732-20.491-5.465-28.688s-19.125-9.563-28.688-5.464l-27.322-28.688l19.125-19.125l-13.66-13.661l-46.445,45.081
			l13.66,13.661l19.125-19.125l27.322,27.321l-92.895,94.259H265.701l-92.893-92.893l27.321-27.321L217.888,76.5l13.661-13.661
			l-45.081-45.08L172.807,31.42l17.759,19.125l-27.321,27.321c-9.563-4.098-20.491-2.732-28.688,5.464
			c-5.464,5.464-8.196,13.661-6.83,21.857c0,2.732,1.366,5.464,2.732,6.83l-21.857,21.857l-17.759-16.393l-13.661,13.661
			l46.446,45.081l13.662-13.66l-20.491-20.491l20.491-20.492l144.803,148.902v416.652c0,19.125,15.027,34.151,34.152,34.151
			s34.152-15.026,34.152-34.151V408.455h19.125v278.679c0,19.125,15.025,34.151,34.15,34.151s34.152-15.026,34.152-34.151V270.482
			l146.17-148.902l20.49,20.491l-20.49,20.491l13.66,13.661l46.447-45.081L629.075,117.482z M359.959,159.83
			c34.151,0,62.838-28.688,62.838-62.839s-28.688-62.839-62.838-62.839c-34.152,0-62.839,28.688-62.839,62.839
			S325.808,159.83,359.959,159.83z"
          fill="#FFA500"
        />
      </g>
    </g>
  </svg>
);


// FIN DEPORTES

//Atletas
export const AthleteLogo = ({ color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 511.998 511.998"
        width={40}
        height={40}
      >
        <circle
          cx="264.358"
          cy="55.54"
          r="42.502"
          fill={color ? color : "#807e7e"}
        />

        <path
          d="M418.946,203.093c-9.346-5.34-21.253-2.092-26.593,7.255c-3.145,5.504-3.291,11.889-0.987,17.305l-3.069,5.372
			l-29.226-30.973l-24.156-74.089c-2.414-7.404-8.961-12.298-16.251-12.975v-0.267h-94.239c-0.222-0.096-0.431-0.208-0.659-0.297
			L166.9,92.138l-4.832-55.134l10.35,0.042c3.541,4.701,9.143,7.764,15.484,7.791c10.765,0.043,19.527-8.647,19.57-19.411
			c0.043-10.765-8.647-19.527-19.412-19.57c-6.339-0.026-11.968,2.992-15.546,7.664l-12.904-0.052
			c-2.524-8.414-10.677-14.185-19.774-13.396c-7.886,0.692-14.208,6.129-16.418,13.25l-5.138-0.021
			c-3.541-4.701-9.143-7.765-15.484-7.791c-10.763-0.045-19.526,8.646-19.57,19.41c-0.044,10.765,8.647,19.527,19.412,19.57
			c6.339,0.025,11.968-2.992,15.546-7.664l5.879,0.023l6.159,70.278c0.628,7.159,5.256,13.345,11.947,15.966l67.787,26.565
			c0.032,0.013,0.065,0.021,0.099,0.033v132.598l-46.906,199.465c-3.11,13.228,5.091,26.474,18.319,29.585
			c13.235,3.11,26.475-5.095,29.585-18.319l47.575-202.305h11.464l47.575,202.305c3.11,13.226,16.352,21.429,29.585,18.319
			c13.229-3.111,21.43-16.357,18.319-29.585L318.664,282.29v-82.082l5.74,17.608c0.866,2.657,2.31,5.089,4.228,7.121l40.04,42.435
			l-4.21,7.369c-5.836,0.765-11.261,4.133-14.406,9.638c-5.34,9.346-2.092,21.253,7.255,26.593
			c9.346,5.34,21.253,2.092,26.593-7.255c3.145-5.504,3.291-11.889,0.987-17.305l2.415-4.227c6.139,1.285,12.386-0.51,16.88-4.749
			c7.523-7.1,7.929-18.9,0.98-26.51l6.629-11.603c5.836-0.765,11.261-4.134,14.406-9.638
			C431.541,220.339,428.292,208.434,418.946,203.093z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//Personal Trainner
export const PersonalTrainer = ({ color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 925.1 925.099"
        width={40}
        height={40}
      >
        <path
          d="M685.301,508.9c0,13.7,11.1,24.8,24.699,24.8v4.2c-29.4,3.8-57.199,14.4-81.6,30.9l-11.5-11.5c-2.301-2.3-5.4-3.5-8.5-3.5
			s-6.1,1.2-8.5,3.5L563.6,593.6c-4.699,4.7-4.699,12.2,0,16.9l11.801,11.8c-20.201,30.7-31,66.6-31,104.2
			c0,50.8,19.799,98.6,55.699,134.5C636,896.9,683.801,916.7,734.6,916.7c50.801,0,98.9-19.6,134.801-55.6
			c35.9-35.9,55.699-83.7,55.699-134.5S905.301,628,869.4,592.1c-30-30-68.301-48.8-109.701-54.1v-4.2
			C773.4,533.7,784.4,522.6,784.4,509c0-13.7-11.1-24.801-24.801-24.801h-49.4C696.5,484.1,685.301,495.2,685.301,508.9z
			 M734.9,577.5c82.4,0,149.1,66.8,149.1,149.1c0,82.4-66.801,149.1-149.1,149.1c-82.301,0-149.1-66.8-149.1-149.1
			S652.5,577.5,734.9,577.5z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M745.4,856.9c66.4-6.3,118.4-62.2,118.4-130.2s-52-124-118.4-130.199c-4.9-0.5-9.301,2.199-11.301,6.3
			c-0.699,1.5-1.1,3.1-1.1,4.899v237.9c0,1.7,0.4,3.4,1.1,4.9C736,854.6,740.5,857.3,745.4,856.9z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M59.8,652.3h179.4v199.3c0,33,26.8,59.8,59.8,59.8c33,0,59.8-26.8,59.8-59.8V592.5c0-33-26.8-59.801-59.8-59.801H122.1
			c7.3-85.899,43.6-188.6,110.9-246.1l50.2,104.5l0.101-0.1c6.6,13.1,19.899,22.1,35.5,22.1H518.1c22,0,39.9-17.9,39.9-39.9
			S540.1,333.3,518.1,333.3H362.4C344.9,297.1,327.5,260.8,310,224.6c-7.9-16.4-14.301-36.8-26.5-50.6
			c-11.2-12.7-27.5-20.1-44.4-20.1c-9.6,0-18.6,2.5-26.6,6.5l-0.1-0.2C75.4,228.7,0,382.2,0,592.3C0,625.5,26.8,652.3,59.8,652.3z"
          fill={color ? color : "#807e7e"}
        />
        <circle cx="372.1" cy="96" r="87.6" fill={color ? color : "#807e7e"} />
        <path
          d="M654.699,321.7L603.9,455H449.5c-13.801,0-25,11.199-25,25c0,13.8,11.199,25,25,25h171.699c10.4,0,19.701-6.4,23.4-16.101
			l56.9-149.4c4.9-12.9-1.6-27.3-14.5-32.3C674.1,302.3,659.6,308.8,654.699,321.7z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//Yoga/Stretching
export const YogaStretching = ({ color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={40}
        height={40}
      >
        <path
          d="M232.003,409.493l-30.934,12.568v57.404c0,13.7,11.106,24.807,24.807,24.807s24.807-11.106,24.807-24.807v-69.972H232.003
			z"
          fill={color ? color : "#807e7e"}
        />

        <path
          d="M280.219,409.493h-18.827v69.972c0,13.7,11.106,24.807,24.807,24.807c13.701,0,24.807-11.106,24.807-24.807v-57.464
			L280.219,409.493z"
          fill={color ? color : "#807e7e"}
        />

        <path
          d="M361.043,169.902l-35.849-53.829c-5.467-8.21-14.677-13.141-24.539-13.141h-89.119c-9.842,0-19.034,4.911-24.507,13.092
			l-36.038,53.879l-32.854,30.436h56.146l26.774-36.709v0.15c0.023-0.088,0.05-0.173,0.074-0.261
			c6.636-24.215,28.849-41.925,54.93-41.925c26.06,0,48.417,17.723,54.999,42.163l26.69,36.581h56.146L361.043,169.902z"
          fill={color ? color : "#807e7e"}
        />

        <circle
          cx="256.034"
          cy="42.844"
          r="42.844"
          fill={color ? color : "#807e7e"}
        />

        <path
          d="M472.844,215.028H299.771c-10.424,12.469-26.143,20.484-43.708,20.484c-17.56,0-33.28-8.011-43.708-20.484H39.153
			c-10.074,0-18.24,8.166-18.24,18.24c0,10.073,8.166,18.24,18.24,18.24h40.345c0.835,2.475,2.179,4.822,4.069,6.863
			c7.162,7.73,19.232,8.191,26.961,1.03l8.52-7.893h84.458c0,11.146,0,88.725,0,101.096l-66.329,26.949
			c-7.677,3.119-13.157,10.018-14.478,18.187c-0.011,0.07-0.028,0.138-0.039,0.209c-1.583,10.376-11.672,76.518-13.234,86.755
			c-2.185,14.321,8.884,27.296,23.475,27.296c11.516,0,21.626-8.406,23.416-20.143l11.202-73.432l61.359-24.93h54.469
			c6.889,2.799,50.448,20.497,61.359,24.93c1.148,7.525,9.497,62.252,11.203,73.432c1.963,12.873,14.02,21.853,27.022,19.869
			c12.949-1.976,21.844-14.073,19.869-27.022l-13.234-86.755c-1.262-8.271-6.783-15.253-14.518-18.396
			c-7.607-3.091-59.489-24.171-66.854-27.163c0-12.353,0-89.749,0-100.882h84.798l8.52,7.893c7.727,7.16,19.798,6.7,26.961-1.03
			c1.89-2.041,3.234-4.387,4.069-6.863h40.306c10.074,0,18.24-8.166,18.24-18.24S482.919,215.028,472.844,215.028z"
          fill={color ? color : "#807e7e"}
        />

        <circle
          cx="256.067"
          cy="178.555"
          r="40.961"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//GYM
export const GymLogo = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 454.832 454.832"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M441.416,318.81V158.125h-49.93l-3.366-2.829c-24.014-20.176-50.669-35.72-79.227-46.2
		c-27.899-10.239-56.992-15.43-86.472-15.43c-29.478,0-58.573,5.192-86.478,15.433c-28.561,10.481-55.218,26.024-79.231,46.199
		l-3.366,2.828H13.416V318.81H0v42.356h454.832V318.81H441.416z M222.422,111.616c53.165,0,103.789,17.811,146.98,51.608
		c-8.217,9.251-16.436,18.501-24.653,27.751c-36.389-27.81-78.51-42.461-122.34-42.461c-43.829,0-85.944,14.65-122.327,42.46
		l-24.651-27.749C118.628,129.427,169.256,111.616,222.422,111.616z M108.697,318.81H71.469l-3.188-53.001h43.604L108.697,318.81z
		 M283.44,318.81H171.392V174.005c16.522-4.985,33.63-7.539,51.018-7.539c20.927,0,41.446,3.698,61.031,10.885V318.81z
		 M383.363,318.81h-37.229l-3.188-53.001h43.604L383.363,318.81z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M200.805,205.313c-8.002,0-11.844,4.477-11.844,15.171v1.585c0,10.695,3.842,15.171,12.24,15.171
		c5.387,0,8.517-1.703,10.813-3.604V220.01h-10.497v5.545h4.08v4.952c-0.95,0.673-2.377,1.189-4.198,1.189h-0.356
		c-4.517,0-5.546-3.446-5.546-9.626v-1.585c0-6.179,1.069-9.625,5.308-9.625h0.356c3.565,0,4.477,2.456,4.477,6.694h6.417v-1.426
		C212.055,208.403,208.173,205.313,200.805,205.313z"
          fill={color ? color : "#807e7e"}
        />
        <polygon
          points="212.129,205.828 221.002,227.259 221.002,236.725 227.42,236.725 227.42,227.259 236.253,205.828 229.242,205.828 
		224.211,219.336 219.141,205.828"
          fill={color ? color : "#807e7e"}
        />
        <polygon
          points="243.418,223.138 243.22,216.959 248.726,236.725 255.262,236.725 260.768,216.959 260.609,223.138 
		260.609,236.725 267.026,236.725 267.026,205.828 257.52,205.828 252.014,225.594 246.468,205.828 237,205.828 237,236.725 
		243.418,236.725"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//TEAM
export const TeamLogo = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 42.152 42.152"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M21.077,4.86c2.412,0,4.367-1.417,4.367-3.165c0-0.107-0.018-0.209-0.031-0.311h-8.672
				c-0.015,0.102-0.031,0.204-0.031,0.311C16.71,3.444,18.666,4.86,21.077,4.86z"
          fill={color ? color : "#807e7e"}
        />
        <polygon
          points="10.793,17.153 20.44,24.943 27.128,24.943 10.793,12.416"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M22.753,9.227c-0.098-0.215-0.23-0.376-0.398-0.484c-0.169-0.11-0.385-0.163-0.65-0.163
				c-0.261,0-0.477,0.053-0.648,0.163c-0.172,0.108-0.308,0.273-0.408,0.493c-0.094,0.206-0.159,0.475-0.194,0.806
				c-0.035,0.332-0.052,0.694-0.052,1.09c0,0.435,0.016,0.797,0.046,1.09c0.031,0.292,0.096,0.555,0.196,0.786
				c0.092,0.217,0.222,0.383,0.391,0.497s0.392,0.17,0.67,0.17c0.263,0,0.48-0.054,0.653-0.163c0.172-0.108,0.307-0.272,0.4-0.492
				c0.098-0.226,0.164-0.492,0.199-0.796c0.034-0.305,0.051-0.672,0.051-1.101c0-0.423-0.018-0.791-0.051-1.103
				C22.92,9.708,22.854,9.444,22.753,9.227z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M26.616,10.325c0.104,0.049,0.251,0.115,0.445,0.196c0.193,0.082,0.382,0.148,0.566,0.201
				c0.274-0.184,0.465-0.375,0.571-0.572c0.106-0.198,0.159-0.426,0.159-0.685c0-0.298-0.113-0.536-0.346-0.713
				c-0.229-0.177-0.521-0.265-0.877-0.265c-0.353,0-0.639,0.083-0.863,0.25s-0.335,0.393-0.335,0.677
				c0,0.201,0.056,0.375,0.169,0.52C26.217,10.08,26.389,10.211,26.616,10.325z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M31.262,0.012V0H25.24c0.437,0.531,0.706,1.143,0.706,1.806c0,1.95-2.181,3.531-4.869,3.531
				s-4.869-1.581-4.869-3.531c0-0.663,0.269-1.275,0.706-1.806h-6.12v0.11l-6.77,6.767l4.459,4.458l2.31-2.311l0.025,2.32
				l17.407,13.598h3.037V8.93l2.406,2.407l4.459-4.458L31.262,0.012z M23.331,13.581c-0.349,0.522-0.892,0.783-1.626,0.783
				c-0.746,0-1.291-0.265-1.635-0.794c-0.344-0.529-0.516-1.342-0.516-2.439c0-1.105,0.174-1.923,0.522-2.45
				s0.892-0.792,1.628-0.792c0.747,0,1.292,0.268,1.635,0.804c0.345,0.536,0.517,1.345,0.517,2.429
				C23.856,12.239,23.681,13.059,23.331,13.581z M28.719,13.841c-0.42,0.357-0.946,0.535-1.582,0.535
				c-0.674,0-1.209-0.174-1.609-0.521c-0.398-0.349-0.6-0.794-0.6-1.337c0-0.345,0.101-0.657,0.301-0.938
				c0.199-0.28,0.482-0.501,0.848-0.667v-0.025c-0.334-0.178-0.582-0.374-0.742-0.584c-0.16-0.212-0.24-0.476-0.24-0.793
				c0-0.468,0.193-0.858,0.576-1.169c0.385-0.312,0.873-0.469,1.467-0.469c0.621,0,1.117,0.149,1.486,0.447
				c0.371,0.298,0.555,0.677,0.555,1.136c0,0.281-0.086,0.558-0.262,0.83c-0.176,0.271-0.434,0.484-0.772,0.636v0.025
				c0.39,0.167,0.688,0.374,0.894,0.619c0.207,0.245,0.311,0.556,0.311,0.935C29.348,13.039,29.139,13.485,28.719,13.841z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M27.656,11.499c-0.13-0.062-0.274-0.118-0.43-0.171c-0.156-0.053-0.363-0.127-0.622-0.221
				c-0.251,0.136-0.452,0.322-0.604,0.555c-0.151,0.234-0.229,0.498-0.229,0.793c0,0.377,0.13,0.688,0.389,0.932
				c0.258,0.245,0.588,0.368,0.985,0.368c0.407,0,0.731-0.104,0.976-0.312c0.243-0.21,0.365-0.496,0.365-0.862
				c0-0.256-0.057-0.461-0.17-0.615C28.206,11.81,27.984,11.655,27.656,11.499z"
          fill={color ? color : "#807e7e"}
        />
        <polygon
          points="10.793,24.943 19.344,24.943 10.793,18.249"
          fill={color ? color : "#807e7e"}
        />
        <rect
          x="10.807"
          y="25.5"
          width="20.539"
          height="2.962"
          fill={color ? color : "#807e7e"}
        />
        <polygon
          points="21.077,28.98 20.915,28.98 10.807,28.98 9.095,42.152 19.364,42.152 20.999,37.331 
				22.788,42.152 33.057,42.152 31.346,28.98"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//SEARCH
export const SearchIcon = ({ color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 487.95 487.95"
      >
        width={40}
        height={40}
        <path
          d="M481.8,453l-140-140.1c27.6-33.1,44.2-75.4,44.2-121.6C386,85.9,299.5,0.2,193.1,0.2S0,86,0,191.4s86.5,191.1,192.9,191.1
			c45.2,0,86.8-15.5,119.8-41.4l140.5,140.5c8.2,8.2,20.4,8.2,28.6,0C490,473.4,490,461.2,481.8,453z M41,191.4
			c0-82.8,68.2-150.1,151.9-150.1s151.9,67.3,151.9,150.1s-68.2,150.1-151.9,150.1S41,274.1,41,191.4z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

//info
export const InfoIcon = () => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        width="24"
        height="24"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="mr-2"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
      </svg>
    </>
  );
};

export const TresPuntos = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

export const Filter = (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 472.615 472.615"
    width="15px"
    height="15px"
  >
    <g>
      <g>
        <polygon points="472.615,12.908 0,12.908 180.081,202.629 180.066,459.708 292.55,401.525 292.534,202.629 " />
      </g>
    </g>
  </svg>
);

export const IconDown = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M17.2929993,29.7070007c-0.3906994-0.3906002-0.3906994-1.0234013,0-1.4140015
		c0.3906002-0.3906002,1.0234013-0.3906002,1.4140015,0L32,41.5858994l13.2929993-13.2929001
		c0.3905983-0.3906002,1.0233994-0.3906002,1.4140015,0C46.902298,28.4883003,47,28.7441998,47,29
		c0,0.2558994-0.097702,0.5116997-0.2929993,0.7070007l-14,14c-0.3906021,0.3906975-1.0234013,0.3906975-1.4140015,0
		L17.2929993,29.7070007z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M32,64c17.6730957,0,32-14.3269005,32-32S49.6730957,0,32,0C14.3268003,0,0,14.3268995,0,32S14.3268003,64,32,64z M32,62
		C15.4579,62,2,48.542099,2,32C2,15.4580002,15.4579,2,32,2s30,13.4580002,30,30C62,48.542099,48.542099,62,32,62z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const IconTop = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M46.7070007,34.2929993c0.3906975,0.3906021,0.3906975,1.0233994,0,1.4140015
		c-0.3906021,0.3906975-1.0233955,0.3906975-1.4140015,0L32,22.4141006L18.7070007,35.7070007
		c-0.3906002,0.3906975-1.0233955,0.3906975-1.4139996,0C17.0977001,35.5116997,17,35.2559013,17,35
		c0-0.2557983,0.0977001-0.5116997,0.2930012-0.7070007l13.9999981-14c0.3906059-0.3906002,1.0233994-0.3906002,1.4140015,0
		L46.7070007,34.2929993z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M32,0c-17.6730995,0-32,14.3268995-32,32s14.3268995,32,32,32c17.6731987,0,32-14.3269005,32-32S49.6731987,0,32,0z M32,2
		c16.542099,0,30,13.4580002,30,30c0,16.542099-13.457901,30-30,30s-30-13.457901-30-30C2.0000007,15.4580002,15.4579,2,32,2z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const People = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M17 13a3 3 0 1 0-3-3 3 3 0 0 0 3 3z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M21 20a1 1 0 0 0 1-1 5 5 0 0 0-8.06-3.95A7 7 0 0 0 2 20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const ThreePoints = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 6 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 3C6 4.65685 4.65685 6 3 6C1.34315 6 0 4.65685 0 3C0 1.34315 1.34315 0 3 0C4.65685 0 6 1.34315 6 3Z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M6 13C6 14.6569 4.65685 16 3 16C1.34315 16 0 14.6569 0 13C0 11.3431 1.34315 10 3 10C4.65685 10 6 11.3431 6 13Z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M6 23C6 24.6569 4.65685 26 3 26C1.34315 26 0 24.6569 0 23C0 21.3431 1.34315 20 3 20C4.65685 20 6 21.3431 6 23Z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const FireSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 472.615 472.615"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M398.239,235.891l-0.278-1.111C357.041,106.465,235.114,15.368,229.929,11.572L214.191,0l1.019,19.441
			c0,0.834,2.869,76.193-70.176,138.868c-79.34,68.138-78.878,124.334-78.692,154.515l0.094,4.166
			c0,82.302,60.399,143.839,146.811,153.984c-4.704-0.739-9.509-1.698-14.331-3.172c-27.867-8.61-44.16-28.144-47.308-56.289
			l-0.74-5.554c-2.5-21.756-5.74-48.697,41.845-99.801c17.775-19.071,33.236-39.624,46.012-61.01l6.388-10.738l8.424,9.257
			c25.182,27.495,44.438,55.269,57.399,82.302c11.942,23.886,12.684,61.659,11.757,69.713
			c-4.536,41.105-34.254,71.749-74.063,76.192c-2.595,0.296-6.479,0.583-11.234,0.689c0.418,0.003,0.816,0.051,1.236,0.051
			c94.06,0,167.66-75.637,167.66-172.197C406.293,277.274,398.516,237.557,398.239,235.891z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const InfoSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 460 460"
      >
        <path
          d="M230,0C102.975,0,0,102.975,0,230s102.975,230,230,230s230-102.974,230-230S357.025,0,230,0z M268.333,377.36
			c0,8.676-7.034,15.71-15.71,15.71h-43.101c-8.676,0-15.71-7.034-15.71-15.71V202.477c0-8.676,7.033-15.71,15.71-15.71h43.101
			c8.676,0,15.71,7.033,15.71,15.71V377.36z M230,157c-21.539,0-39-17.461-39-39s17.461-39,39-39s39,17.461,39,39
			S251.539,157,230,157z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const RepetitionsSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M69.816,256H0l93.096,93.096L186.2,256h-69.816c0.224-77.016,62.6-139.4,139.616-139.632
	c22.672,0.432,44.952,6,65.16,16.296l34.896-34.896C325.6,80.144,291.176,70.528,256,69.832
	C153.296,70.112,70.104,153.296,69.816,256z M395.616,256c-0.224,77.016-62.6,139.4-139.616,139.632
	c-22.672-0.432-44.952-6-65.16-16.296l-34.896,34.896c30.456,17.624,64.88,27.24,100.056,27.936
	C358.696,441.872,441.88,358.696,442.184,256H512l-93.096-93.096L325.8,256H395.616z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};
//

export const WeithSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 163.676 163.676"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M137.146,65.553c3.094-5.931,4.707-12.952,4.707-21.127C141.853,19.927,114.938,0,81.838,0
		C48.734,0,21.823,19.927,21.823,44.426c0,8.741,1.726,15.713,4.563,21.355c-5.836,9.715-9.24,21.044-9.24,33.204
		c0,35.731,28.963,64.691,64.691,64.691c35.731,0,64.691-28.96,64.691-64.691C146.529,86.733,143.059,75.322,137.146,65.553z
		 M78.321,123.743h-6.622l-14.647-21.33l-4.908,5.048v16.295h-5.063h-5.069V75.259h5.051h5.082v20.079l18.575-20.079h6.467h6.461
		L64.292,95.051l20.651,28.705h-6.622V123.743z M122.282,121.825c0,5.688-1.4,9.864-4.214,12.556
		c-2.813,2.686-7.167,4.05-13.122,4.05c-4.871,0-8.756-0.956-11.637-2.856c-2.88-1.905-4.421-4.536-4.615-7.909h10.272
		c0.115,1.224,0.663,2.131,1.698,2.697c1.035,0.554,2.644,0.828,4.823,0.828c2.667,0,4.579-0.64,5.742-1.931
		c1.175-1.278,1.76-3.397,1.76-6.375v-4.421l-0.773,1.151c-2.076,2.886-5.085,4.323-9.006,4.323c-4.652,0-8.428-1.681-11.289-5.03
		c-2.887-3.354-4.317-7.781-4.317-13.273c0-5.633,1.406-10.157,4.207-13.604c2.789-3.435,6.455-5.14,11.01-5.14
		c4.031,0,7.16,1.456,9.377,4.396c0.402,0.536,0.804,1.146,1.169,1.803v-5.243h8.902v33.978H122.282z M128.311,54.041
		c-11.765-12.16-28.218-19.747-46.473-19.747c-18.283,0-34.751,7.605-46.515,19.79c-0.661-2.905-1.029-6.098-1.029-9.658
		c0-17.622,21.33-31.956,47.544-31.956s47.544,14.334,47.544,31.956C129.382,47.593,129.102,50.862,128.311,54.041z M111.005,97.864
		c1.413,1.863,2.106,4.439,2.106,7.745c0,3.276-0.693,5.858-2.106,7.746c-1.395,1.863-3.337,2.795-5.827,2.795
		c-2.43,0-4.354-0.92-5.73-2.746c-1.376-1.839-2.076-4.396-2.076-7.673c0-3.343,0.7-5.955,2.076-7.837
		c1.377-1.881,3.312-2.825,5.761-2.825C107.692,95.076,109.61,96.001,111.005,97.864z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const DistanceSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 480.543 480.543"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M225.278,278.426c0-48.641-39.572-88.213-88.214-88.213c-48.641,0-88.212,39.572-88.212,88.213
			c0,14.254,3.401,27.727,9.431,39.654l78.781,162.463l78.795-162.488C221.88,306.133,225.278,292.669,225.278,278.426z
			 M137.064,327.243c-26.917,0-48.817-21.9-48.817-48.817s21.9-48.817,48.817-48.817c26.918,0,48.817,21.9,48.817,48.817
			S163.982,327.243,137.064,327.243z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M387.021,0c-24.63,0-44.669,20.038-44.669,44.668c0,7.218,1.723,14.04,4.776,20.081l39.893,82.267l39.899-82.28
			c3.049-6.037,4.77-12.855,4.77-20.067C431.69,20.038,411.65,0,387.021,0z M387.021,69.388c-13.631,0-24.72-11.089-24.72-24.72
			c0-13.63,11.089-24.72,24.72-24.72c13.629,0,24.719,11.09,24.719,24.72C411.74,58.299,400.65,69.388,387.021,69.388z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M348.116,289.787c-1.812-8.523-4.166-16.875-7.131-25.075c-3.974-10.987-8.74-21.724-12.391-32.836
			c-6.823-20.771,4.948-38.105,7.16-41.256c3.876-5.517,8.408-10.58,13.19-15.322c9.207-9.128,19.361-17.288,29.984-24.803
			c-1.316-2.039-2.591-4.1-3.805-6.199c-15.279,5.99-93.854,33.452-91.938,94.121c0.384,12.162,5.083,23.737,7.979,35.526
			c1.395,5.681,2.605,11.412,3.345,17.218c0.319,2.614,0.515,5.243,0.729,7.87c0.006-0.02,0.004-0.034-0.008-0.045
			c0.007,0.076,0.202,8.326-0.164,11.9c-1.147,11.189-2.799,22.494-7.516,32.758c-4.387,9.543-11.006,17.842-19.193,24.402
			c-18.716,14.994-43.514,20.988-66.913,23.398c-1.114,0.117-2.228,0.217-3.342,0.314l-41.779,86.152
			c20.614-1.281,41.175-4.102,61.235-9.039c23.607-5.811,46.613-14.588,67.227-27.598c20.596-12.996,38.308-30.578,50.099-51.984
			c12.335-22.391,17.277-48.038,16.225-73.452C350.752,307.156,349.928,298.307,348.116,289.787z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const TimeSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 489.42 489.42"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M46.1,138.368c4.2,2.1,16.1,8.4,29.1-6.2c5.2-7.3,10.4-14.6,16.6-21.8c7.3-8.3,6.2-21.8-2.1-29.1
			c-8.3-7.3-21.8-6.2-29.1,2.1s-14.6,17.7-20.8,27C33.7,119.668,36.8,132.068,46.1,138.368z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M249,40.568c19.8,0,39.5,3.1,58.3,9.4c12.6,3.7,21.8-4.2,26-12.5c3.1-11.4-3.1-22.9-13.5-26
			c-22.9-7.3-45.8-11.4-69.7-11.4c-11.4,0-20.8,8.3-20.8,19.8S237.6,40.568,249,40.568z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M434.2,167.468c7.3,17.7,11.4,36.4,13.5,55.1c0,0,1.2,23.2,22.9,19.8c21.5-2.8,18.7-23.9,18.7-23.9
			c-2.1-22.9-8.3-45.8-16.6-66.6c-5.2-10.4-16.6-15.6-27-11.4C435.2,145.668,430,157.068,434.2,167.468z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M359.3,75.968c16.6,11.4,31.2,25,43.7,40.6c9.3,11.6,25,6.8,28.1,3.1c8.3-7.3,10.4-20.8,3.1-29.1
			c-14.6-17.7-32.3-34.3-52-47.9c-9.4-6.2-21.8-4.2-28.1,5.2S349.9,69.668,359.3,75.968z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M134.6,72.768c16.6-10.4,35.4-18.7,54.1-23.9c11.4-3.1,17.7-14.6,14.6-25c-3.1-11.4-14.6-17.7-25-14.6
			c-22.9,6.2-44.7,15.6-64.5,28.1c-9.4,6.2-12.5,18.7-6.2,28.1C111.7,71.768,120.5,77.968,134.6,72.768z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M468.5,268.368c-11.4-3.1-21.8,4.2-23.9,15.6c-2.1,9.4-8.5,31.3-8.6,33.4c-27.5,71.5-93.5,121.8-169.3,129.9
			c-74.6,7.8-147.2-25.9-189.3-86.5l38.5,8.5c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-81.1-17.7
			c-5.2-1-21.4,0-25,15.6l-17.7,82.2c-2.1,10.4,4.2,21.8,15.6,23.9c12.7,1.3,21.8-6.2,25-16.6l6.2-28.2
			c46.3,62.7,129.9,109.1,223.7,99c94.6-10.2,174.8-73.9,206-163.3c1-2.6,5.7-24.4,7.3-32.3
			C487.3,280.868,480,270.468,468.5,268.368z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M164.6,265.268h95.9c11.4,0,19.8-9.4,20.8-20.8v-142.2c0-11.4-9.4-20.8-20.8-20.8c-11.4,0-20.8,9.4-20.8,20.8v121.4h-75.1
			c-11.4,0-20.8,9.4-20.8,20.8S153.1,265.268,164.6,265.268z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const TairedSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 244 256"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        fill={color ? color : "#f8216f"}
      >
        <path d="M133.781,2c20.372,0,36.888,16.515,36.888,36.888s-16.515,36.888-36.888,36.888S96.893,59.26,96.893,38.888
	S113.408,2,133.781,2z M236.319,219.99l-59.499-59.315l20.221,5.095c1.454,0.366,2.909,0.541,4.342,0.541
	c7.926,0,15.144-5.358,17.169-13.395c2.391-9.49-3.364-19.121-12.853-21.512l-59.028-14.871c0,0-49.027-49.344-51.748-52.032
	c-10.388-10.263-28.497-14.697-43.508,3.387C33.644,89.297,1.893,162.694,1.893,207.654C1.893,233.25,22.643,254,48.239,254
	c12.105,0,22.133-5.969,27.241-10.038l71.886-57.048l61.152,60.963c3.843,3.831,8.871,5.745,13.9,5.745
	c5.048,0,10.097-1.93,13.944-5.788C244.039,240.133,244.019,227.667,236.319,219.99z M100.117,120.179l22.609,22.712
	c2.612,2.624,5.71,4.689,9.095,6.109l-48.014,37.49C87.315,157.998,93.913,137.668,100.117,120.179z"
          fill={color ? color : "#f8216f"}
        />
      </svg>
    </>
  );
};

export const CadenceSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 455.664 455.664"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          id="XMLID_1495_"
          d="M388.933,66.73C345.901,23.698,288.688,0,227.832,0C166.976,0,109.762,23.698,66.731,66.73
		S0,166.976,0,227.832s23.699,118.069,66.73,161.102s100.245,66.73,161.101,66.73c60.856,0,118.069-23.698,161.102-66.73
		s66.73-100.245,66.73-161.102S431.966,109.763,388.933,66.73z M227.832,425.664C118.747,425.664,30,336.917,30,227.832
		S118.747,30,227.832,30s197.832,88.747,197.832,197.832S336.917,425.664,227.832,425.664z"
          fill={color ? color : "#807e7e"}
        />
        <path
          id="XMLID_1498_"
          d="M227.832,158.808c-38.06,0-69.024,30.964-69.024,69.024s30.964,69.024,69.024,69.024
		c38.061,0,69.024-30.964,69.024-69.024S265.892,158.808,227.832,158.808z M227.832,266.856c-21.518,0-39.024-17.506-39.024-39.024
		s17.506-39.024,39.024-39.024c21.519,0,39.024,17.506,39.024,39.024S249.35,266.856,227.832,266.856z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const RhythmSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      {/* <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 455.664 455.664"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      > */}
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill={color ? color : "#807e7e"}
          d="M15.999 1.129c-8.812 0-15.98 7.169-15.98 15.981 0 5.536 2.803 10.6 7.497 13.544 0.467 0.296 1.084 0.152 1.378-0.316s0.152-1.085-0.316-1.378c-1.691-1.061-3.095-2.439-4.17-4.027l1.048-0.605c0.478-0.276 0.643-0.887 0.366-1.366-0.277-0.48-0.889-0.642-1.366-0.366l-1.050 0.606c-0.763-1.579-1.228-3.306-1.353-5.107h1.113c0.552 0 1-0.448 1-1s-0.447-1-1-1h-1.108c0.132-1.834 0.618-3.572 1.393-5.143l1.005 0.58c0.157 0.091 0.329 0.134 0.499 0.134 0.346 0 0.681-0.179 0.867-0.5 0.277-0.479 0.112-1.090-0.366-1.366l-0.995-0.574c1.003-1.463 2.277-2.728 3.75-3.719l0.563 0.975c0.185 0.322 0.521 0.5 0.867 0.5 0.17 0 0.342-0.043 0.499-0.134 0.479-0.277 0.643-0.887 0.366-1.366l-0.561-0.971c1.542-0.744 3.24-1.208 5.030-1.338v1.246c0 0.553 0.447 1 1 1s1-0.447 1-1v-1.25c1.831 0.127 3.567 0.606 5.137 1.373l-0.543 0.939c-0.276 0.479-0.113 1.090 0.366 1.366 0.157 0.091 0.329 0.134 0.499 0.134 0.346 0 0.681-0.178 0.867-0.5l0.54-0.936c1.459 0.993 2.721 2.255 3.715 3.713l-0.936 0.541c-0.479 0.277-0.642 0.887-0.366 1.366 0.186 0.322 0.521 0.5 0.867 0.5 0.17 0 0.342-0.043 0.499-0.134l0.942-0.543c0.768 1.571 1.248 3.307 1.377 5.139h-1.098c-0.552 0-1 0.448-1 1s0.448 1 1 1h1.098c-0.127 1.777-0.581 3.482-1.328 5.041l-0.99-0.572c-0.477-0.276-1.091-0.111-1.366 0.366-0.276 0.479-0.113 1.090 0.366 1.366l0.993 0.573c-1.097 1.633-2.545 3.044-4.292 4.119-0.471 0.29-0.616 0.907-0.327 1.376 0.189 0.306 0.517 0.476 0.852 0.476 0.178 0 0.36-0.048 0.523-0.148 4.764-2.934 7.608-8.024 7.608-13.614 0-8.811-7.169-15.98-15.98-15.98zM23.378 13.992c0.478-0.277 0.642-0.887 0.366-1.366s-0.888-0.642-1.366-0.366l-5.432 3.136c-0.29-0.164-0.62-0.265-0.977-0.265-1.102 0-1.995 0.893-1.995 1.994 0 1.102 0.893 1.995 1.995 1.995s1.995-0.893 1.995-1.995c0-0.002-0-0.005-0-0.007z"></path>
      </svg>
    </>
  );
};

export const PowerSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 297.961 297.961"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M235.443,4.316C234.064,1.675,231.331,0,228.35,0H111.391c-3.537,0-6.654,2.341-7.666,5.729l-41.78,140.009
	c-0.723,2.422-0.259,5.029,1.251,7.055c1.51,2.027,3.889,3.207,6.415,3.207h65.579l-26.419,132.38
	c-0.769,3.85,1.369,7.697,5.047,9.07c0.917,0.342,1.863,0.511,2.796,0.511c2.806,0,5.496-1.48,6.954-4.04l103.03-180.928
	c1.41-2.477,1.396-5.533-0.036-7.997c-1.432-2.464-4.066-3.996-6.916-3.996h-46.392l61.658-88.415
	C236.617,10.14,236.822,6.959,235.443,4.316z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const BreakOneSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 202.978 202.978"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M135.394,180.66c0,12.306-10.012,22.318-22.318,22.318c-12.307,0-22.318-10.012-22.318-22.318c0-2.761,2.238-5,5-5h34.637
	C133.155,175.66,135.394,177.898,135.394,180.66z M202.162,165.108c-0.465,2.34-2.514,4.028-4.9,4.028H28.889
	c-2.39,0-4.445-1.691-4.906-4.035c-0.461-2.345,0.802-4.688,3.013-5.593c0.107-0.044,11.6-4.863,21.729-15.165
	c5.388-5.48,9.121-15.151,11.254-29.261c-0.565,0.016-1.127,0.043-1.695,0.043c-31.74,0-57.563-25.823-57.563-57.563
	C0.72,25.822,26.543,0,58.283,0C73.308,0,87,5.792,97.261,15.252c2.875-5.843,8.873-9.884,15.814-9.884
	c9.723,0,17.632,7.91,17.632,17.632c0,0.574-0.027,1.144-0.083,1.709c14.265,4.931,33.4,18.494,33.4,55.745
	c0,33.82,4.384,54.718,13.401,63.889c10.13,10.302,21.622,15.121,21.737,15.169C201.368,160.423,202.627,162.767,202.162,165.108z
	 M61.16,105.031c0.644-7.269,0.966-15.448,0.966-24.576c0-35.42,17.298-49.417,31.262-54.947C84.684,15.984,72.17,10,58.283,10
	C32.057,10,10.72,31.336,10.72,57.563c0,26.226,21.337,47.563,47.563,47.563C59.25,105.125,60.208,105.088,61.16,105.031z
	 M63.283,57.563V17.959c0-2.761-2.238-5-5-5s-5,2.239-5,5v34.603H28.03c-2.762,0-5,2.239-5,5c0,2.761,2.238,5,5,5h30.253
	C61.045,62.563,63.283,60.324,63.283,57.563z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const BreakTwoSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47.001 47.001"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M22.432,22.429c0,0.591,0.479,1.067,1.068,1.067s1.068-0.479,1.068-1.067c0.001-1.25,0.445-2.465,1.251-3.421
				c1.793-2.126,3.137-4.431,3.988-6.851c0.115-0.327,0.065-0.689-0.135-0.972c-0.201-0.283-0.525-0.451-0.872-0.451H18.199
				c-0.347,0-0.672,0.168-0.873,0.451c-0.2,0.283-0.25,0.645-0.135,0.972c0.853,2.42,2.195,4.725,3.988,6.851
				C21.986,19.964,22.431,21.18,22.432,22.429z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M24.568,26.71c0-0.59-0.479-1.067-1.068-1.067s-1.068,0.479-1.068,1.067c-0.001,1.542-0.922,3.067-2.593,4.304
				c-3.574,2.639-6.249,5.506-7.951,8.52c-0.187,0.332-0.184,0.736,0.009,1.062c0.19,0.329,0.542,0.53,0.922,0.53h21.364
				c0.379,0,0.73-0.201,0.922-0.53c0.191-0.326,0.194-0.73,0.008-1.062c-1.701-3.014-4.377-5.881-7.95-8.52
				C25.49,29.777,24.569,28.252,24.568,26.71z"
          fill={color ? color : "#807e7e"}
        />
        <path
          d="M42.192,42.729h-0.639c-0.734-8.313-5.602-14.695-9.386-19.228c3.785-4.532,8.651-10.915,9.388-19.228h0.639
				c1.18,0,2.136-0.957,2.136-2.137C44.33,0.956,43.374,0,42.194,0H4.807c-1.18,0-2.136,0.957-2.136,2.136
				c0,1.18,0.956,2.137,2.136,2.137h0.639c0.735,8.314,5.601,14.697,9.386,19.228c-3.784,4.532-8.651,10.914-9.387,19.228H4.807
				c-1.18,0-2.136,0.955-2.136,2.135c0,1.181,0.956,2.138,2.136,2.138h2.671h32.044h2.672c1.18,0,2.136-0.957,2.136-2.138
				C44.33,43.684,43.373,42.729,42.192,42.729z M9.728,42.729c0.803-7.511,5.686-13.295,9.335-17.617l0.195-0.231
				c0.672-0.795,0.672-1.959,0-2.755l-0.194-0.23c-3.648-4.323-8.533-10.107-9.336-17.619h27.544
				c-0.803,7.512-5.688,13.296-9.336,17.619l-0.193,0.23c-0.672,0.795-0.672,1.959,0,2.755l0.195,0.231
				c3.648,4.322,8.531,10.106,9.334,17.617H9.728z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const IntensitySVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-4.8 -4.8 57.60 57.60"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >

        <path
          d="M32 9C32 7.34315 33.3431 6 35 6H39C40.6569 6 42 7.34315 42 9V39C42 40.6569 40.6569 42 39 42H35C33.3431 42 32 40.6569 32 39V9ZM35 8C34.4477 8 34 8.44772 34 9V39C34 39.5523 34.4477 40 35 40H39C39.5523 40 40 39.5523 40 39V9C40 8.44772 39.5523 8 39 8H35Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M19 21C19 19.3431 20.3431 18 22 18H26C27.6569 18 29 19.3431 29 21V39C29 40.6569 27.6569 42 26 42H22C20.3431 42 19 40.6569 19 39V21ZM22 20C21.4477 20 21 20.4477 21 21V39C21 39.5523 21.4477 40 22 40H26C26.5523 40 27 39.5523 27 39V21C27 20.4477 26.5523 20 26 20H22Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M6 33C6 31.3431 7.34315 30 9 30H13C14.6569 30 16 31.3431 16 33V39C16 40.6569 14.6569 42 13 42H9C7.34315 42 6 40.6569 6 39V33ZM9 32C8.44772 32 8 32.4477 8 33V39C8 39.5523 8.44772 40 9 40H13C13.5523 40 14 39.5523 14 39V33C14 32.4477 13.5523 32 13 32H9Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M14.364 31.6359L8.00003 37.9999L6.58582 36.5857L12.9498 30.2217L14.364 31.6359Z" fill={color ? color : "#807e7e"} /> <path fillRule="evenodd" clipRule="evenodd" d="M15.682 35.3181L9.79294 41.2072L8.37872 39.793L14.2678 33.9039L15.682 35.3181Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.4247 30.5753L21 37.9999L19.5858 36.5857L27.0104 29.1611L28.4247 30.5753Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.4247 25.5753L21 32.9999L19.5858 31.5857L27.0104 24.1611L28.4247 25.5753Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.4247 20.5753L21 27.9999L19.5858 26.5857L27.0104 19.1611L28.4247 20.5753Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.5356 19.4644L21 22.9999L19.5858 21.5857L23.1213 18.0502L24.5356 19.4644Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.682 35.3179L22.4888 41.5111L21.0746 40.0969L27.2678 33.9037L28.682 35.3179Z" fill={color ? color : "#807e7e"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M39.374 7.62588L34 12.9999L32.5858 11.5857L37.9598 6.21167L39.374 7.62588ZM41.4247 10.5753L34 17.9999L32.5858 16.5857L40.0104 9.16106L41.4247 10.5753ZM41.5 15.4999L34 22.9999L32.5858 21.5857L40.0858 14.0857L41.5 15.4999ZM41.4247 20.5753L34 27.9999L32.5858 26.5857L40.0104 19.1611L41.4247 20.5753ZM41.4247 25.5753L34 32.9999L32.5858 31.5857L40.0104 24.1611L41.4247 25.5753ZM41.4247 30.5753L34 37.9999L32.5858 36.5857L40.0104 29.1611L41.4247 30.5753ZM41.5 35.4999L35.5 41.4999L34.0858 40.0857L40.0858 34.0857L41.5 35.4999Z"
          fill={color ? color : "#807e7e"} />
      </svg>
    </>
  );
};

export const HeartSVG = ({ widthProp, heightProp, color }) => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 45.743 45.743"
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
      >
        <path
          d="M34.199,3.83c-3.944,0-7.428,1.98-9.51,4.997c0,0-0.703,1.052-1.818,1.052c-1.114,0-1.817-1.052-1.817-1.052
		c-2.083-3.017-5.565-4.997-9.51-4.997C5.168,3.83,0,8.998,0,15.376c0,1.506,0.296,2.939,0.82,4.258
		c3.234,10.042,17.698,21.848,22.051,22.279c4.354-0.431,18.816-12.237,22.052-22.279c0.524-1.318,0.82-2.752,0.82-4.258
		C45.743,8.998,40.575,3.83,34.199,3.83z"
          fill={color ? color : "#807e7e"}
        />
      </svg>
    </>
  );
};

export const BellSVG = () => {
  return (
    <>
      <svg
        width={26}
        height={26}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.75 14.8385V12.0463C21.7471 9.88552 20.9385 7.80353 19.4821 6.20735C18.0258 4.61116 16.0264 3.61555 13.875 3.41516V1.625C13.875 1.39294 13.7828 1.17038 13.6187 1.00628C13.4546 0.842187 13.2321 0.75 13 0.75C12.7679 0.75 12.5454 0.842187 12.3813 1.00628C12.2172 1.17038 12.125 1.39294 12.125 1.625V3.41534C9.97361 3.61572 7.97429 4.61131 6.51794 6.20746C5.06159 7.80361 4.25291 9.88555 4.25 12.0463V14.8383C3.26257 15.0412 2.37529 15.5784 1.73774 16.3593C1.10019 17.1401 0.751339 18.1169 0.75 19.125C0.750764 19.821 1.02757 20.4882 1.51969 20.9803C2.01181 21.4724 2.67904 21.7492 3.375 21.75H8.71346C8.91521 22.738 9.45205 23.6259 10.2331 24.2636C11.0142 24.9013 11.9916 25.2497 13 25.2497C14.0084 25.2497 14.9858 24.9013 15.7669 24.2636C16.548 23.6259 17.0848 22.738 17.2865 21.75H22.625C23.321 21.7492 23.9882 21.4724 24.4803 20.9803C24.9724 20.4882 25.2492 19.821 25.25 19.125C25.2486 18.117 24.8998 17.1402 24.2622 16.3594C23.6247 15.5786 22.7374 15.0414 21.75 14.8385ZM6 12.0463C6.00232 10.2113 6.73226 8.45223 8.02974 7.15474C9.32723 5.85726 11.0863 5.12732 12.9212 5.125H13.0788C14.9137 5.12732 16.6728 5.85726 17.9703 7.15474C19.2677 8.45223 19.9977 10.2113 20 12.0463V14.75H6V12.0463ZM13 23.5C12.4589 23.4983 11.9316 23.3292 11.4905 23.0159C11.0493 22.7026 10.716 22.2604 10.5363 21.75H15.4637C15.284 22.2604 14.9507 22.7026 14.5095 23.0159C14.0684 23.3292 13.5411 23.4983 13 23.5ZM22.625 20H3.375C3.14298 19.9999 2.9205 19.9076 2.75644 19.7436C2.59237 19.5795 2.50014 19.357 2.5 19.125C2.50076 18.429 2.77757 17.7618 3.26969 17.2697C3.76181 16.7776 4.42904 16.5008 5.125 16.5H20.875C21.571 16.5008 22.2382 16.7776 22.7303 17.2697C23.2224 17.7618 23.4992 18.429 23.5 19.125C23.4999 19.357 23.4076 19.5795 23.2436 19.7436C23.0795 19.9076 22.857 19.9999 22.625 20Z"
          fill="#3B4CB8"
        />
      </svg>
    </>
  );
};

//suscriptores
export const SubscriptorsSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4,16 L15,16.001 C16.0538182,16.001 16.9181157,16.8164855 16.9945109,17.8516842 L17,18.001 L17,20.5 C16.999,24.7 12.713,26 9.5,26 C6.35126,26 2.1710504,24.75148 2.00510151,20.7485328 L2,20.5 L2,18 C2,16.9461818 2.81639669,16.0818843 3.85080841,16.0054891 L4,16 Z M17.22,16.001 L24,16 C25.0538182,16 25.9181157,16.8163967 25.9945109,17.8508084 L26,18 L26,20 C25.999,23.759 22.57,25 20,25 C18.942,25 17.741,24.785 16.691,24.275 C17.4432667,23.3808667 17.9315867,22.2428222 17.9933526,20.8112714 L18,20.5 L18,18.001 C18,17.2991818 17.7512397,16.6601736 17.3461307,16.1500909 L17.22,16.001 L24,16 L17.22,16.001 Z M9.5,3 C12.537,3 15,5.463 15,8.5 C15,11.537 12.537,14 9.5,14 C6.463,14 4,11.537 4,8.5 C4,5.463 6.463,3 9.5,3 Z M20.5,5 C22.985,5 25,7.015 25,9.5 C25,11.985 22.985,14 20.5,14 C18.015,14 16,11.985 16,9.5 C16,7.015 18.015,5 20.5,5 Z"
          fill="#27BC48"
        ></path>
      </svg>
    </>
  );
};

export const WorkoutsCreatedSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="m224,128h64c8.836,0 16-7.164 16-16v-64c0-26.512-21.492-48-48-48s-48,21.488-48,48v64c0,8.836 7.164,16 16,16zm32-96c8.836,0 16,7.162 16,16 0,8.836-7.164,16-16,16s-16-7.164-16-16c0-8.838 7.164-16 16-16z"
          fill="#A02CFA"
        />
        <path
          d="m416,32h-80v96c0,17.672-14.328,32-32,32h-96c-17.672,0-32-14.328-32-32v-96h-80c-17.672,0-32,14.326-32,32v416c0,17.672 14.328,32 32,32h320c17.672,0 32-14.328 32-32v-416c0-17.674-14.328-32-32-32zm-240,400c-8.836,0-16-7.164-16-16s7.164-16 16-16 16,7.164 16,16-7.164,16-16,16zm0-64c-8.836,0-16-7.164-16-16s7.164-16 16-16 16,7.164 16,16-7.164,16-16,16zm0-64c-8.836,0-16-7.164-16-16s7.164-16 16-16 16,7.164 16,16-7.164,16-16,16zm0-64c-8.836,0-16-7.164-16-16s7.164-16 16-16 16,7.164 16,16-7.164,16-16,16zm160,192h-96c-8.836,0-16-7.164-16-16s7.164-16 16-16h96c8.836,0 16,7.164 16,16s-7.164,16-16,16zm0-64h-96c-8.836,0-16-7.164-16-16s7.164-16 16-16h96c8.836,0 16,7.164 16,16s-7.164,16-16,16zm0-64h-96c-8.836,0-16-7.164-16-16s7.164-16 16-16h96c8.836,0 16,7.164 16,16s-7.164,16-16,16zm0-64h-96c-8.836,0-16-7.164-16-16s7.164-16 16-16h96c8.836,0 16,7.164 16,16s-7.164,16-16,16z"
          fill="#A02CFA"
        />
      </svg>
    </>
  );
};

export const ExercisesCreatedSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 300 300"
      >
        <polygon
          points="101.009,137.203 95.889,132.275 88.692,139.75 101.219,151.805 119.336,133.333 111.931,126.069"
          fill="#FF3282"
        />
        <rect
          x="132.56"
          y="203.818"
          width="72.487"
          height="15.562"
          fill="#FF3282"
        />
        <polygon
          points="101.009,211.697 95.889,206.769 88.692,214.242 101.219,226.302 119.336,207.828 111.931,200.563"
          fill="#FF3282"
        />
        <polygon
          points="101.009,174.525 95.889,169.597 88.692,177.07 101.219,189.13 119.336,170.656 111.931,163.391"
          fill="#FF3282"
        />
        <rect
          x="132.56"
          y="129.321"
          width="72.487"
          height="15.562"
          fill="#FF3282"
        />
        <rect
          x="132.56"
          y="166.643"
          width="72.487"
          height="15.562"
          fill="#FF3282"
        />
        <path
          d="M149.997,0C67.157,0,0,67.159,0,149.997C0,232.838,67.157,300,149.997,300S300,232.838,300,149.997
				C299.997,67.159,232.838,0,149.997,0z M187.911,54.829L224.1,95.28h-36.189V54.829z M231.061,231.71h-0.002
				c0,12.239-9.918,22.16-22.157,22.16h-119.1c-12.245,0-22.16-9.921-22.16-22.16V68.29c0-12.237,9.915-22.16,22.16-22.16h82.547
				v64.708h58.712V231.71z"
          fill="#FF3282"
        />
      </svg>
    </>
  );
};

export const NewSubscribersSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 612 612"
      >
        <path
          d="M269.272,310.198c86.177-0.005,117.184-86.291,125.301-157.169C404.572,65.715,363.282,0,269.272,0
			C175.274,0,133.963,65.71,143.97,153.029C152.095,223.907,183.093,310.204,269.272,310.198z"
          fill="#FFBC11"
        />
        <path
          d="M457.707,346.115c2.773,0,5.528,0.083,8.264,0.235c-4.101-5.85-8.848-11.01-14.403-15.158
			c-16.559-12.359-38.005-16.414-56.964-23.864c-9.229-3.625-17.493-7.226-25.251-11.326
			c-26.184,28.715-60.329,43.736-100.091,43.74c-39.749,0-73.891-15.021-100.072-43.74c-7.758,4.101-16.024,7.701-25.251,11.326
			c-18.959,7.451-40.404,11.505-56.964,23.864c-28.638,21.375-36.039,69.46-41.854,102.26c-4.799,27.076-8.023,54.707-8.964,82.209
			c-0.729,21.303,9.789,24.29,27.611,30.721c22.315,8.048,45.356,14.023,68.552,18.921c44.797,9.46,90.973,16.729,136.95,17.054
			c22.278-0.159,44.601-1.956,66.792-4.833c-16.431-23.807-26.068-52.645-26.068-83.695
			C309.995,412.378,376.258,346.115,457.707,346.115z"
          fill="#FFBC11"
        />
        <path
          d="M457.707,375.658c-65.262,0-118.171,52.909-118.171,118.171S392.444,612,457.707,612s118.172-52.909,118.172-118.171
			C575.878,428.566,522.969,375.658,457.707,375.658z M509.407,514.103h-31.425v31.424c0,11.198-9.077,20.276-20.274,20.276
			c-11.198,0-20.276-9.078-20.276-20.276v-31.424h-31.424c-11.198,0-20.276-9.077-20.276-20.276
			c0-11.198,9.077-20.276,20.276-20.276h31.424v-31.424c0-11.198,9.078-20.276,20.276-20.276c11.198,0,20.274,9.078,20.274,20.276
			v31.424h31.425c11.198,0,20.276,9.078,20.276,20.276C529.682,505.027,520.606,514.103,509.407,514.103z"
          fill="#FFBC11"
        />
      </svg>
    </>
  );
};

export const DaysWithoutTrainingSVG = ({ widthProp, heightProp }) => {
  return (
    <>
      <svg
        width={widthProp ? widthProp : width}
        height={heightProp ? heightProp : height}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 57 57"
      >
        <path
          d="M48.95,38.551c-0.391-0.391-1.023-0.391-1.414,0L44,42.086l-3.536-3.535c-0.391-0.391-1.023-0.391-1.414,0
		s-0.391,1.023,0,1.414l3.536,3.535l-3.536,3.535c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293
		s0.512-0.098,0.707-0.293L44,44.914l3.536,3.535c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293
		c0.391-0.391,0.391-1.023,0-1.414L45.414,43.5l3.536-3.535C49.34,39.574,49.34,38.941,48.95,38.551z"
          fill="#FFBC11"
        />
        <path
          d="M44,30.5c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S51.168,30.5,44,30.5z M44,54.5c-6.065,0-11-4.935-11-11
		s4.935-11,11-11s11,4.935,11,11S50.065,54.5,44,54.5z"
          fill="#FFBC11"
        />
        <path
          d="M27,43.5c-0.552,0-1-0.447-1-1s0.448-1,1-1h1.139c0.754-6.002,4.84-10.968,10.346-13H27c-0.552,0-1-0.447-1-1s0.448-1,1-1
		h18c0.552,0,1,0.447,1,1c0,0.048-0.021,0.089-0.027,0.135c3.014,0.373,5.77,1.582,8.027,3.395V12.676
		C54,5.962,48.538,0.5,41.824,0.5H12.176C5.462,0.5,0,5.962,0,12.676v29.648C0,49.038,5.462,54.5,12.176,54.5h20.236
		C29.685,51.629,28,47.762,28,43.5H27z M27,12.5h18c0.552,0,1,0.447,1,1s-0.448,1-1,1H27c-0.552,0-1-0.447-1-1S26.448,12.5,27,12.5z
		 M20.688,38.15l-6.857,7.999c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,42.87
		c-0.442-0.331-0.531-0.958-0.2-1.399c0.332-0.442,0.958-0.53,1.4-0.2l3.822,2.866l6.248-7.287c0.359-0.42,0.992-0.468,1.41-0.108
		C20.999,37.101,21.047,37.731,20.688,38.15z M20.688,23.15l-6.857,8c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2
		L7.9,27.871c-0.442-0.331-0.531-0.958-0.2-1.399c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866l6.248-7.288
		c0.359-0.42,0.992-0.468,1.41-0.108C20.999,22.101,21.047,22.731,20.688,23.15z M20.688,9.15l-6.857,8
		c-0.197,0.23-0.477,0.35-0.76,0.35c-0.209,0-0.42-0.065-0.6-0.2L7.9,13.871c-0.442-0.331-0.531-0.958-0.2-1.399
		c0.332-0.442,0.958-0.531,1.4-0.2l3.822,2.866l6.248-7.288c0.359-0.42,0.992-0.468,1.41-0.108
		C20.999,8.101,21.047,8.731,20.688,9.15z"
          fill="#FFBC11"
        />
      </svg>
    </>
  );
};

export const ProfileSVG = ({ className }) => {
  return (
    <svg
      id="icon-user1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={18}
      height={18}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
      <circle cx={12} cy={7} r={4} />
    </svg>
  );
};

export const LogoutSVG = ({ className }) => {
  return (
    <svg
      id="icon-logout"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={18}
      height={18}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1={21} y1={12} x2={9} y2={12} />
    </svg>
  );
};

export const SettingsIcon = ({ className, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      className={className}
    >
      <path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" />
    </svg>
  );
};



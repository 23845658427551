import { useContext, useEffect, useState } from "react";
import { useCreateUserCardMutation, useUpdateUserCardMutation } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { useSlicesState } from "../../redux/slices";
import { LanguageContext } from "../../context/LanguageContext";

export function useCreateAndUpdateUserCards() {
  const { lang } = useContext(LanguageContext);
  const { otherUserId } = useSlicesState()

  const [createUserCard, { isError, error, isLoading: isLoadingCreate }] = useCreateUserCardMutation();
  const [updateUserCard, { isError: isErrorUpdate, error: errorUpdate, isLoading: isLoadingUpdate }] = useUpdateUserCardMutation();

  const [formuserCardsValues, handleFormUserCards, resetForm] = useForm({
    note: '',
  });

  const {
    note
  } = formuserCardsValues;


  const [isUpdate, setIsUpdate] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);


  const startUpdated = (note, index) => {
    setIsUpdate(true);
    formuserCardsValues.note = note.note;
    setNoteToEdit(note);
  };

  const handleCreateUserCard = async (e) => {
    e.preventDefault();
    await createUserCard({ userId: otherUserId?._id, note: formuserCardsValues }).unwrap()
    Swal.close();
    resetForm()
  };

  const handleUpdateUserCard = async (e,) => {
    e.preventDefault();
    await updateUserCard({ cardId: noteToEdit?._id, note: formuserCardsValues, userId: otherUserId?._id }).unwrap()
    Swal.close();
    resetForm()
    setIsUpdate(false);
  };

  const handleCancelEdit = () => {
    resetForm()
    setIsUpdate(false);
  }

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorUpdate) showAlert(errorUpdate?.data?.msgCode, errorUpdate?.data?.ok);
    // if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, errorUpdate?.data?.msgCode, errorUpdate?.data?.ok, isError, isErrorUpdate]);

  useEffect(() => {
    if (isLoadingCreate) loadingAlert(lang.alerts.creatingTechnicalSheet)
    if (isLoadingUpdate) loadingAlert(lang.alerts.updatingTechnicalSheet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreate, isLoadingUpdate])

  return {
    handleCreateUserCard,
    handleFormUserCards,
    handleUpdateUserCard,
    note,
    isUpdate,
    startUpdated,
    handleCancelEdit,
  };
}


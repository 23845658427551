import { useMemo } from "react";
import { useGetMySubscriptionsQuery } from "../../api";
import { statusSubscription } from "../../domain/statusSubscription";
import { useStateRedux } from "../useStateAPI";

export const useGetMySubscriptions = () => {

    const { isLoading: isLoadingData, isFetching } = useGetMySubscriptionsQuery(undefined, {
        refetchOnMountOrArgChange: 300,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      });

    const { dataGetMySubscriptions } = useStateRedux()


    const subscriptionsTotalCount = dataGetMySubscriptions?.dataGetMySubscriptions
    const subscriptions = dataGetMySubscriptions?.subscriptions
    const ok = dataGetMySubscriptions?.ok
    const msgCode = dataGetMySubscriptions?.msgCode

    const isLoading = isLoadingData || isFetching

    const iHaveActiveSubscriptions = useMemo(() => {
        return subscriptions?.some(
          (subscription) =>
            subscription.status === statusSubscription.active ||
            subscription.status === statusSubscription.reactivated
        );
      }, [subscriptions]);

      const isSuscriptor = (idUsuario) => {
        return subscriptions.some(suscriptor => 
            (suscriptor.guestId._id === idUsuario || suscriptor.idInviting._id === idUsuario) && suscriptor.status !== statusSubscription.finished
        );
    }
    
    
    return {
        dataGetMySubscriptions,
        subscriptionsTotalCount,
        subscriptions,
        ok,
        msgCode,
        isLoading,
        iHaveActiveSubscriptions,
        isSuscriptor
    };
};



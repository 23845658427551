import { sports } from "../../assets/data/sports";

export const IconSport = (name, widthProp, heightProp) => {
    const nameLoweCase = name.toLowerCase()

    const iconItem = sports.find((icon) => icon.value.toLowerCase() === nameLoweCase)

    if(iconItem) return <iconItem.icon widthProp={widthProp} heightProp={heightProp}/>
    
    return null
}
import swal from "sweetalert";
import Swal from "sweetalert2";
import { getNotificationMessage } from "../assets/data/getNotificationMessage";

/*
title
text
icon
textButtonOne
textButtonTwo
swalText
swalIcon
fun
*/
export const launchAlert = (data, nombre = "", id, action) => {
  swal({
    title: data.title,
    text: data.text + nombre.toUpperCase(),
    icon: data.icon,
    buttons: [data.textButtonOne, data.textButtonTwo],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      data.fun(id, action).then((result) => {
        if (result) {
          swal(
            data.swalText + nombre.toUpperCase(),
            {
              icon: data.swalIcon,
            }
          );
        }
      });
    }
  });
};


//nuevas
const showErrorAlert = (msgCode) => {
Swal.fire("Oops 😥", getNotificationMessage(msgCode), "error");
}

const showSuccessAlert = (msgCode) => {
  Swal.fire("Ok 💪", getNotificationMessage(msgCode), "success");
  }

export const showAlert = (msgCode, ok) => {
  if(ok) return showSuccessAlert(msgCode)
  if(!ok) return showErrorAlert(msgCode)
}

export const loadingAlert = (text) => {
  Swal.fire({
    title: text,
    didOpen: () => {
      Swal.showLoading();
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });
};

export { Swal };

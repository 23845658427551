import React from "react";
import {
  CardProfile,
  CardAboutMeOptions,
  CardSelectTraining,
  CardMeasure,
  CardConfigNotifications,
  CardSelectEquipment,
} from "../components";
import { sports } from "../assets/data/sports";
import { roles } from "../domain/roles";
import { exportSportsAccordingRole } from "../helpers/exportSportsAccordingRole";
import { useGetMyProfile } from "../hooks";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { equipment } from "../assets/data/validateEquipment";


export const Profile = () => {
  const {
    isPersonalTrainer,
    isAthlete,
    isYogaStretching,
  } = useGetMyProfile()

  const configResponsiveMasonry = { 430: 1, 750: 2, 1024: 3, 1800: 4 }

  return (
    <>

      {isAthlete() &&
        <ResponsiveMasonry
          columnsCountBreakPoints={configResponsiveMasonry}
        >
          <Masonry
            gutter="1.5rem"
          >
            <CardProfile />
            <CardAboutMeOptions />
            <CardConfigNotifications />
            <CardMeasure img={'https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244024/fitapp/other-app/wvhb60npsymssomrk4aa.png'} />
            <CardSelectEquipment equipment={equipment} />
          </Masonry>
        </ResponsiveMasonry>
      }


      {isPersonalTrainer() &&
        <ResponsiveMasonry
          columnsCountBreakPoints={configResponsiveMasonry}
        >
          <Masonry
            gutter="1.5rem"
          >
            <CardProfile />
            <CardAboutMeOptions />
            <CardConfigNotifications />
            <CardSelectTraining training={exportSportsAccordingRole(roles.personalTrainer, sports)} />
          </Masonry>
        </ResponsiveMasonry>
      }

      {isYogaStretching() &&
        <ResponsiveMasonry
          columnsCountBreakPoints={configResponsiveMasonry}
        >
          <Masonry
            gutter="1.5rem"
          >
            <CardProfile />
            <CardAboutMeOptions />
            <CardConfigNotifications />
            <CardSelectTraining training={exportSportsAccordingRole(roles.yogaStretching, sports)} />
          </Masonry>
        </ResponsiveMasonry>
      }
    </>
  );
};

// useProfileUpdate.js
import { useState, useEffect, useContext } from "react";
import { useUpdateUserProfileMutation } from "../../api/userProfileAPI";
import { Swal, loadingAlert, showAlert } from "../../helpers/Alerts";
import { useForm, useGetMyProfile } from "..";
import { formatDateForInputDefaultValues, isUnder18 } from "../../helpers/formatDate";
import { useSlicesActions } from "../../redux/slices";
import { LanguageContext } from "../../context/LanguageContext";

export function useProfileUpdate() {
  const { lang } = useContext(LanguageContext);
  const { dispatch, setIsEditingProfileTrue, setIsEditingProfileFalse, } = useSlicesActions()
  const [updateUserProfile, { isError, isSuccess, error, isLoading, data }] = useUpdateUserProfileMutation();

  const {
    isAthlete,
    yearsOfExperience,
    birthdate: actualBirthdate,
    aboutMe: actualAboutMe,
    country: actualCountry,
  } = useGetMyProfile()

  const [ageRange, setAgeRange] = useState(
    yearsOfExperience && yearsOfExperience
  );


  const [formProfileValues, handleFormProfileValues] = useForm({
    birthdate: actualBirthdate
      ? formatDateForInputDefaultValues(actualBirthdate)
      : new Date(),
    aboutMe: actualAboutMe ? actualAboutMe : "",
    country: actualCountry ? actualCountry : "",
  });


  const { aboutMe, birthdate, country } = formProfileValues;

  let newDataProfile = { ...formProfileValues, yearsOfExperience: ageRange };


  const handleStartEditing = () => {
    dispatch(setIsEditingProfileTrue());
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    if (!isAthlete() && isUnder18(birthdate)) return Swal.fire("Oops 😥", 'Debes ser Mayor de 13 años', "info");

    try {
      await updateUserProfile(newDataProfile).unwrap()
      Swal.close();
      dispatch(setIsEditingProfileFalse());
    } catch (error) {
      console.log(error);
    }

  };

  const handleCancelEdit = () => {
    dispatch(setIsEditingProfileFalse());
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      loadingAlert(lang.alerts.updatingProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    aboutMe,
    birthdate,
    country,
    handleFormProfileValues,
    handleStartEditing,
    handleUpdateProfile,
    handleCancelEdit,
    ageRange,
    setAgeRange
  };
}

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import logoGym from "../../assets/img/logoGym.jpg";

import { Items } from "..";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav disabled" onClick={() => onClick()}>
      <div className="owl-next">
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="owl-nav disabled" onClick={() => onClick()}>
      <div className="owl-prev" style={{ zIndex: 1 }}>
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
    </div>
  );
}

export const SliderInfo = ({ dataNewUsers, dataGyms, dataTeams }) => {
  const conditional =
    dataGyms?.length + dataTeams?.length || dataNewUsers.length || 4;

  const settings = {
    dots: false,
    infinite: true, //conditional > 4 ? true : false, /* si tiene solo un elemento false, mas de 1 true */
    arrows: true,
    speed: 500,
    slidesToShow: conditional > 4 ? 4 : conditional, // para que muestre scroll sin dar error
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1740,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 882,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 482,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {dataGyms?.map((d, i) => (
        <Items
          key={d._id}
          img={d.img ? d.img : 'https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244025/fitapp/other-app/ccx4teymnzvm7rqr7vxu.jpg'}
          name={d.name}
          description={d.description}
          type="gym"
        />
      ))}
      {dataTeams?.map((d, i) => (
        <Items
          key={d._id}
          img={d.img ? d.img : 'https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244025/fitapp/other-app/ccx4teymnzvm7rqr7vxu.jpg'}
          name={d.name}
          description={d.description}
          type="team"
        />
      ))}

      {dataNewUsers?.map((user, i) => (
        <Items key={user._id} {...user} img={user?.fullProfile?.urlImage} type="user" />
      ))}
    </Slider>
  );
};

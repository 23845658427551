import React, { useContext } from "react";
import { Card, Table } from "react-bootstrap";
import { TrSportsDetails } from "..";
import { LanguageContext } from "../../context/LanguageContext";

export const CardDetailSearchTraining = ({ training }) => {
  const { lang } = useContext(LanguageContext);

  return (
    <>
      <div className="col-xl-4 h-100">
        <div className="card overflow-hidden ">
          <div className="card-body">
            <div className="profile-skills ">
              <h4 className="text-primary">
                {lang.profile.personalTrainer.services}
              </h4>
              <p className="text-muted">
                {lang.profile.personalTrainer.detailServices}
              </p>

              <Card.Body>
                <Table responsive>
                  <tbody>
                    {training?.map((sport, i) => (
                      <TrSportsDetails
                        key={i}
                        color={sport.color}
                        title={sport.item}
                        action={sport.item}
                        value={sport.value}
                      />
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const analyticsAPI = createApi({
    reducerPath: 'analyticsAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["analyticsAPI"],
    endpoints: (builder) => ({
        getDashboardData: builder.query({
            query: () => "/analytics/get-dashboard-data",
            providesTags: ["analyticsAPI"],
            keepUnusedDataFor: 3800,
        }),
    }),
});

export const { useGetDashboardDataQuery } = analyticsAPI;
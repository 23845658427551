import { useGetDashboardDataQuery } from "../../api";
import { useStateRedux } from "../useStateAPI";

export const useDashboardData = () => {

    const { isLoading: isLoadingData, isFetching } = useGetDashboardDataQuery(undefined, {
    refetchOnMountOrArgChange: 3800,
    refetchOnFocus: false,
    refetchOnReconnect: true,
  });
   
    const { dataDashboardData } = useStateRedux()
    const lastUsers = dataDashboardData?.lastUsers
    const activeSubscriptions = dataDashboardData?.activeSubscriptions
    const lastSubscriptionsOfTheMonth = dataDashboardData?.lastSubscriptionsOfTheMonth
    const finishedSubscriptions = dataDashboardData?.finishedSubscriptions
    const trainingsForActualsMonth = dataDashboardData?.trainingsForActualsMonth
    const newSubscriptions = dataDashboardData?.newSubscriptions
    const trainingContainers = dataDashboardData?.trainingContainers
    const lastTrainingsOfMonth = dataDashboardData?.lastTrainingsOfMonth
    const trainingContainersIncomplete = dataDashboardData?.trainingContainersIncomplete
    const trainginsCompleted = trainingContainers - trainingContainersIncomplete

    const isLoading = isLoadingData || isFetching

    
    return {
        lastUsers,
        isLoading,
        activeSubscriptions,
        lastSubscriptionsOfTheMonth,
        finishedSubscriptions,
        trainingContainers,
        lastTrainingsOfMonth,
        trainingContainersIncomplete,
        trainingsForActualsMonth,
        trainginsCompleted,
        newSubscriptions //FIXME: no se usa    
    };
};



import { useState, useEffect, useContext } from "react";
import { useCreateSportAssociationMutation, userProfileAPI } from "../../api";
import { useGetMyProfile } from "..";
import { useDispatch } from "react-redux";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { LanguageContext } from "../../context/LanguageContext";

export function useSportAssociation() {
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [createSportsAssociation, { isError, isSuccess, error, isLoading, data }] = useCreateSportAssociationMutation();
  const { sports: actualSports } = useGetMyProfile()
  const [sports, setSports] = useState(actualSports ? [...actualSports] : []);

  const handleCreateSportAssociation = async () => {
    await createSportsAssociation({ sports }).unwrap().then(() => {
      dispatch(userProfileAPI.util.invalidateTags(["userProfile"]))
    })
    Swal.close();
  };

  const isDisabled = JSON.stringify(sports) === JSON.stringify(actualSports)

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if (isLoading) loadingAlert(lang.alerts.associatingTrainings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    sports,
    setSports,
    handleCreateSportAssociation,
    isDisabled
  };
}

import { useState, useCallback } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDateForQuery } from "../../helpers/formatDate";
import { useGetTrainingFromAthleta } from "./useGetTrainingFromAthleta";
import { useMemo } from "react";
import { useSlicesState } from "../../redux/slices/hook";
import { useGetTrainingFromProfessional } from "./useGetTrainingFromProfessional";
import { useGetMyProfile } from "../users/useGetMyProfile";
import { Routes } from "../../routers/Routes";



export const useTrainingsRoot = () => {
    const { isAthlete, isPersonalTrainer, isYogaStretching } = useGetMyProfile()
    const { selectUser } = useSlicesState()

    const history = useHistory()

    if ((isPersonalTrainer() || isYogaStretching()) && !selectUser) history.push(Routes.subscriptions)

    const [currentMonthYear, setCurrentMonthYear] = useState(() => {
        const currentDate = new Date();
        return {
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear(),
        };
    });

    const { formatMonth, formatYear } = formatDateForQuery(new Date());

    const query = useMemo(() => {
        return !currentMonthYear.month
            ? {
                userId: selectUser?._id,
                month: formatMonth,
                year: formatYear,
            }
            : { userId: selectUser?._id, month: currentMonthYear.month, year: currentMonthYear.year };
    }, [currentMonthYear, formatMonth, formatYear, selectUser]);



    const {
        isLoadingGetTrainingFromAthleta,
        trainingsFromAtlethe,
        trainingPendingFromAtlethe,
        trainingFinishedFromAtlethe,
    } = useGetTrainingFromAthleta(query);

    const {
        isLoadingGetTrainingFromProfesional,
        isLoadingGetMyWorkout,
        trainingsFromProfessional,
        trainingPendingFromProfessional,
        trainingFinishedFromProfessional,
    } = useGetTrainingFromProfessional(query)

    const increaseMonthn = useCallback(() => {
        setCurrentMonthYear(prev => {
            if (prev.month === 12) {
                return { month: 1, year: prev.year + 1 };
            } else {
                return { month: prev.month + 1, year: prev.year };
            }
        });
    }, []);

    const subtractMonth = useCallback(() => {
        setCurrentMonthYear(prev => {
            if (prev.month === 1) {
                return { month: 12, year: prev.year - 1 };
            } else {
                return { month: prev.month - 1, year: prev.year };
            }
        });
    }, []);

    const actualMonth = () => {
        setCurrentMonthYear({
            month: parseInt(formatMonth),
            year: parseInt(formatYear),
        });
    };

    const isLoading = isLoadingGetTrainingFromAthleta || isLoadingGetTrainingFromProfesional || isLoadingGetMyWorkout;

    return {
        setCustomDate: setCurrentMonthYear,
        increaseMonthn,
        subtractMonth,
        actualMonth,
        selectUser,
        isLoading,
        trainings: isAthlete() ? trainingsFromProfessional : trainingsFromAtlethe,
        trainingPending: isAthlete() ? trainingPendingFromProfessional : trainingPendingFromAtlethe,
        trainingFinished: isAthlete() ? trainingFinishedFromProfessional : trainingFinishedFromAtlethe,
    };
};

import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { LanguageContext } from "../../context/LanguageContext";

export const MeasuresChart = ({ data, dates, colors }) => {
  const { lang } = useContext(LanguageContext);

  const options = {
    chart: {
      fontFamily: "Helvetica, Arial, sans-serif",
      type: "bar",
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },

    legend: {
      show: true,
      fontSize: "12px",
      fontWeight: 300,
      fontFamily: "Helvetica, Arial, sans-serif",

      labels: {
        colors: "black",
      },
      position: "bottom",
      horizontalAlign: "center",
      markers: {
        width: 19,
        height: 19,
        strokeWidth: 0,
        radius: 19,
        strokeColor: "#fff",
        fillColors: colors,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#3e4954",
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 100,
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: dates,
    },
    fill: {
      colors: colors,
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          return (
            val +
            `${
              w?.config?.series[seriesIndex]?.name === "Peso"
                ? lang.generic.kg
                : lang.generic.cm
            }`
          );
        },
      },
    },
  };


  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={data}
          type="bar"
          height={350}
        />
      </div>
    </>
  );
};

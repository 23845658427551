import React from "react";

export const NoSearchResult = ({text, color}) => {

  return (
    <div className={`alertBusqueda rounded bgl-${color} w-auto`}>
      <div>
        <small className="d-block text-black font-weight-bold">{text}</small>
      </div>
    </div>
  );
};

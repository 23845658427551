import { createApi } from '@reduxjs/toolkit/query/react'
import { getBaseQueryConfig } from './getBaseQueryConfig';

export const physiologicalDataAPI = createApi({
    reducerPath: 'physiologicalDataAPI',
    baseQuery: getBaseQueryConfig(),
    tagTypes: ["physiologicalDataAPI"],
    endpoints: (builder) => ({
        getMyPhysiologicalData: builder.query({
            query: () => "/physiological-data/get-my-data",
            providesTags: ["physiologicalDataAPI"]
        }),
        cretePhysiologicalData: builder.mutation({
            query: (newphysiologicalData) => ({
                url: "/physiological-data/create",
                method: 'POST',
                body: newphysiologicalData
            }),
            invalidatesTags: ["physiologicalDataAPI"],
            extraOptions: { maxRetries: 0 }
        }),
        deletePhysiologicalData: builder.mutation({
            query: (idPhysiologicalData) => ({
                url: `/physiological-data/delete/${idPhysiologicalData}`,
                method: 'DELETE',
            }),
            extraOptions: { maxRetries: 0 },
            onQueryStarted: async (idPhysiologicalData, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    physiologicalDataAPI.util.updateQueryData('getMyPhysiologicalData', undefined, (oldData) => {
                        const updatedData = oldData?.physiologicalData?.filter((oneData) => oneData._id !== idPhysiologicalData);
                        return {
                            ...oldData,
                            physiologicalData: updatedData,
                        };
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        reqUserData: builder.mutation({
            query: (idPhysiologicalData) => ({
                url: `/physiological-data/req-user-data/${idPhysiologicalData}`,
                method: 'POST',
            }),
            extraOptions: { maxRetries: 0 }
        }),
        getOneData: builder.query({
            query: (idPhysiologicalData) => ({
                url: `/physiological-data/get-one-data/${idPhysiologicalData}`,
            }),
            extraOptions: { maxRetries: 0 }
        }),
    }),
});

export const { useGetMyPhysiologicalDataQuery, useCretePhysiologicalDataMutation, useDeletePhysiologicalDataMutation, useReqUserDataMutation, useGetOneDataQuery } = physiologicalDataAPI;
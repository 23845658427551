import { useEffect } from "react";
import { useCreateFeedBackMutation, useUpdateFeedBackMessageMutation } from "../../api";
import { showAlert } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { useSlicesActions, useSlicesState } from "../../redux/slices/hook";

export function useCreateAndUpdateFeedback() {

  const { tempSelectRPE, tempUserIdSelected, trainingIdSelected, isEditMessage, messageToEdit } = useSlicesState()
  const { dispatch, setModalFinishTrainingFeedback, setIsEditMessage, setMessageToEdit } = useSlicesActions()

  const [createFeedBack, { isError, error }] = useCreateFeedBackMutation();
  const [updateFeedBack, { isError: isErrorUpdate, error: errorUpdate }] = useUpdateFeedBackMessageMutation();

  const [formValuesMessage, handleFormCreateFeedback, reset] = useForm({
    message: '',
  });

  const { message } = formValuesMessage;

  const isDisabled = message?.length < 2;

  const handleCReateFeedBack = async () => {
    await createFeedBack({ idTo: tempUserIdSelected?._id, idTraining: trainingIdSelected, message: formValuesMessage }).unwrap()
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
    reset();

  };

  const sendMessage = !isEditMessage ? handleCReateFeedBack : null

  const handleCloseModal = () => {
    dispatch(setIsEditMessage(false));
    dispatch(setModalFinishTrainingFeedback(null))
    reset();
  };


  const handleUpdateFeedBackMessage = async () => {
    await updateFeedBack({ idFeedBack: messageToEdit?.idMessage, idTraining: trainingIdSelected, message: formValuesMessage }).unwrap()
      .catch((error) => showAlert(error?.data?.msgCode, error?.data?.ok))
    dispatch(setMessageToEdit(null))
    dispatch(setIsEditMessage(false))
    reset();
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isErrorUpdate) showAlert(errorUpdate?.data?.msgCode, errorUpdate?.data?.ok);
  }, [error?.data?.msgCode, error?.data?.ok, errorUpdate?.data?.msgCode, errorUpdate?.data?.ok, isError, isErrorUpdate]);

  useEffect(() => {
    if (tempSelectRPE) {
      handleFormCreateFeedback({
        target: {
          name: 'message',
          value: tempSelectRPE || '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempSelectRPE])

  useEffect(() => {
    if (isEditMessage) {
      handleFormCreateFeedback({
        target: {
          name: 'message',
          value: messageToEdit?.message || '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMessage, messageToEdit])

  return {
    handleCloseModal,
    sendMessage,
    message,
    handleFormCreateFeedback,
    isDisabled,
    handleUpdateFeedBackMessage,
  };
}


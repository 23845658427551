import { useEffect, useState } from "react";
import { subscriptionsAPI, trainingAPI, useGetMyNotificationsQuery } from "../../api";
import { useStateRedux } from "../useStateAPI";
import { useSlicesActions } from "../../redux/slices";
import { usePath } from "../usePath";

export const useGetNotifications = () => {

    const { path } = usePath(1);

    const { dispatch, setThereAreNewNotificationsTrue } = useSlicesActions()
    const { isLoading: isLoadingData, isFetching } = useGetMyNotificationsQuery(undefined, {
        refetchOnMountOrArgChange: false,
        refetchOnFocus: true,
        refetchOnReconnect: true,
        pollingInterval: 1800000
    });

    const { dataNotifications } = useStateRedux()

    const notificationsCount = dataNotifications?.notificationsCount
    const notificationsList = dataNotifications?.notificationsList

    const isLoading = isLoadingData || isFetching

    const [prevNotificationsCount, setPrevNotificationsCount] = useState(0)
    const [prevNotificationsList, setPrevNotificationsList] = useState([]);

    useEffect(() => {
        if (path !== "workout-plan-suscriptor" && path !== "workout-plan" && path !== "big-calendar") {
            notificationsList?.forEach(notification => {
                if (notification.trainingId !== null) {
                    dispatch(trainingAPI.util.invalidateTags(["trainingAPI"]));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])


    useEffect(() => {
        if (notificationsCount > prevNotificationsCount) {
            setPrevNotificationsCount(notificationsCount);

            // Detectar solo nuevas notificaciones de tipo "subscriptionReq"
            const newNotifications = notificationsList?.filter(notification => 
                !prevNotificationsList.some(prevNotification => prevNotification._id === notification._id)
            );
            const newSubscriptionReqNotifications = newNotifications?.filter(notification => 
                notification?.type === "subscriptionReq"
            );

            if (newSubscriptionReqNotifications?.length > 0) {
                dispatch(setThereAreNewNotificationsTrue());
                dispatch(subscriptionsAPI.util.invalidateTags(["subscriptionsAPI"]));
            } else {
                dispatch(setThereAreNewNotificationsTrue());
            }

            // Actualizar la lista de notificaciones previas
            setPrevNotificationsList(notificationsList);
        }
    }, [dispatch, notificationsCount, prevNotificationsCount, notificationsList, prevNotificationsList, setThereAreNewNotificationsTrue]);




    return {
        notificationsList,
        notificationsCount,
        isLoading
    };
};



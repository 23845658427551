import React, { useContext } from "react";
import axios from "axios";
import { LanguageContext } from "../../context/LanguageContext";
import { useUpdateImageProfileMutation } from "../../api/userProfileAPI";
import { showAlert } from "../../helpers/Alerts";
import { useGetMyProfile } from "../../hooks";
import { LottieComponent } from "../Lottie/LottieComponent";
import camara from '../../assets/animations/camera.json'
import { cloudinaryAPI, cloudinaryPresets } from "../../config/config";

export const CardProfile = () => {
  const { lang } = useContext(LanguageContext);
  const {
    name,
    img,
    yearsOfExperience,
    numberOfSubscriptions,
    isPersonalTrainer,
    isAthlete,
    isYogaStretching,
    trainersContainerCreated,
    trainingContainersComplete,
  } = useGetMyProfile()


  const newYearsOfExperience = yearsOfExperience ? yearsOfExperience : 0;

  const handlePictureClick = () => {
    document.querySelector("#fileSelector").click();
  };


  const [updateImageProfile] = useUpdateImageProfileMutation()

  const updateImage = async (file) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", cloudinaryPresets);

    try {

      const { data } = await axios.post(cloudinaryAPI, formData);


      const imgData = {
        urlImage: data.secure_url,
        urlDelete: data.public_id,
      };

      await updateImageProfile(imgData).unwrap()
      showAlert('SE0021', true)


    } catch (error) {
      showAlert('SE0034', false)
      console.log(error)
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      updateImage(file)
    }
  };


  const style = {
    height: '40px'
  }

  return (
    <>
      <div>
        <div className="card overflow-hidden">
          <div className="card-body">
            <div className="text-center">
              <div className="profile-photo">
                <img
                  src={img ? img : 'https://res.cloudinary.com/dtwdbcfu7/image/upload/v1716244024/fitapp/other-app/ag1b7kf0av0juk2x4pto.png'}
                  width="100"
                  className="img-fluid rounded-circle cursor-pointer"
                  alt={name}
                  onClick={handlePictureClick}
                  loading="lazy"
                />
                <i
                  className="position-absolute cursor-pointer"
                  style={{ marginLeft: '-15px' }}
                  onClick={handlePictureClick}
                >
                  <LottieComponent
                    icon={camara}
                    style={style}
                  /></i>
                <input
                  id="fileSelector"
                  type="file"
                  name="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>

              <h3 className="mt-4 mb-1 text-capitalize">{name}</h3>
              {isAthlete() && (
                <p className="text-muted">{lang.roles.athlete}</p>
              )}
              {isPersonalTrainer() && (
                <p className="text-muted">{lang.roles.personalTrainer}</p>
              )}
              {isYogaStretching() && (
                <p className="text-muted">{lang.roles.yogaStretching}</p>
              )}
            </div>
          </div>

          <div className="card-footer pt-0 pb-0 text-center">
            <div className="row">
              <div className="col-4 pt-3 pb-3 border-right">
                <h3 className="mb-1">{newYearsOfExperience}</h3>
                {(isPersonalTrainer() ||
                  isYogaStretching()) && (
                    <span className="textSmallProfile">
                      {newYearsOfExperience > 1
                        ? lang.profile.yearsOfExperience
                        : lang.profile.oneYearOfExperience}
                    </span>
                  )}
                {isAthlete() && (
                  <span className="textSmallProfile">
                    {newYearsOfExperience > 1
                      ? lang.profile.yearsOfTraining
                      : lang.profile.yeaOfTraining}
                  </span>
                )}
              </div>
              {(isPersonalTrainer() ||
                isYogaStretching()) && (
                  <div className="col-4 pt-3 pb-3 border-right">
                    <h3 className="mb-1">{numberOfSubscriptions}</h3>
                    <span>
                      {numberOfSubscriptions > 1
                        ? lang.generic.athletes
                        : lang.roles.athlete}
                    </span>
                  </div>
                )}
              {isAthlete() && (
                <div className="col-4 pt-3 pb-3 border-right">
                  <h3 className="mb-1">{numberOfSubscriptions}</h3>
                  <span className="textSmallProfile">
                    {numberOfSubscriptions > 1
                      ? lang.generic.trainers
                      : lang.generic.coach}
                  </span>
                </div>
              )}
              {(isPersonalTrainer() ||
                isYogaStretching()) && (
                  <div className="col-4 pt-3 pb-3">
                    <h3 className="mb-1">{trainersContainerCreated || 0}</h3>
                    <span className="textSmallProfile">
                      {trainersContainerCreated > 1
                        ? lang.profile.personalTrainer.workoutsCreated
                        : lang.profile.personalTrainer.workoutsCreated}
                    </span>
                  </div>
                )}
              {isAthlete() && (
                <div className="col-4 pt-3 pb-3">
                  <h3 className="mb-1">{trainingContainersComplete || 0}</h3>
                  <span className="textSmallProfile">
                    {trainingContainersComplete > 1
                      ? lang.profile.athlete.completedWorkouts
                      : lang.profile.athlete.trainingcompleted}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

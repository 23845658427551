import React from "react";
import "./loading.css";
import animation from "../../assets/animations/loader.json";
import { LottieComponent } from "../Lottie/LottieComponent";

export const Loading = ({ show = false }) => {

  const style ={
    height: '140px',
  }

  if (!show) return null;

  return (
    <>
      <div className="loading">
        <div>
          <LottieComponent icon={animation} style={style}/>
        </div>
        <div>
          <h4 className="text">Cargando...</h4>
        </div>
      </div>
    </>
  );
};

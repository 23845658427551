import React from 'react';
import {
  BreakTwoSVG,
  CadenceSVG,
  DistanceSVG,
  FireSVG,
  HeartSVG,
  InfoSVG,
  PowerSVG,
  RhythmSVG,
  SwimmSVG,
  TairedSVG,
  TimeSVG,
  WeithSVG
} from '../../assets/svg/svgs';

export const ListIconsForCardExercises = ({ exercise, lang }) => {
  const iconSize = 20;

  const checkCondition = (optionValue, comparisonValue) => {
    if (typeof optionValue === 'object') {
      return Object.values(optionValue).some(value => value > comparisonValue);
    }
    return optionValue > comparisonValue;
  };

  const checkConditionString = (optionValue) => {
    if (typeof optionValue === 'object') {
      return Object.values(optionValue).some(value => value !== "");
    }
    return optionValue !== "";
  };

  const options = exercise?.options;

  const hasSpecificConditions = options.heating || options.toTheRuling ||
    (options.breakRecovery && checkCondition(options.breakInfo, 1));

  const hasGeneralConditions = (options.weight && checkCondition(options.weightInfo, 0)) ||
    (options.km && checkCondition(options.kmInfo, 1)) ||
    (options.time && checkConditionString(options.timeInfo)) ||
    (options.hearRate && checkCondition(options.hearRateInfo, 30)) ||
    (options.cadence && checkCondition(options.cadenceInfo, 1)) ||
    (options.rhythm && checkCondition(options.rhythmInfo, 1)) ||
    (options.swimmingStyles && checkConditionString(options.swimmingStylesInfo)) ||
    (options.meters && checkCondition(options.metersInfo, 1)) ||
    (options.power && checkCondition(options.powerInfo, 1)) ||
    (options.other && checkConditionString(options.otherInfo));

  return (
    <>
      {hasSpecificConditions && (
        <div className='d-flex justify-content-start align-items-center flex-wrap' style={{ gap: '0.2rem' }}>
          <p style={{ fontSize: '0.8rem' }}>{lang.createTraining.optionsToExercice}</p>
          <div className='d-flex flex-row align-items-center flex-wrap' style={{ gap: '0.2rem' }}>
            {options.heating && <FireSVG widthProp={iconSize} color="#ff8700" />}
            {options.toTheRuling && <TairedSVG widthProp={iconSize} color="#f8216f" />}

            {options.breakRecovery && checkCondition(options.breakInfo, 1) && (
              <BreakTwoSVG widthProp={iconSize} color="orange" />
            )}

          </div>
        </div>
      )}

      {hasGeneralConditions && (
        <div className='d-flex justify-content-start align-items-center flex-wrap' style={{ gap: '0.2rem' }}>
          <p style={{ fontSize: '0.8rem' }}>{lang.createTraining.exerciseParameters}</p>
          <div className='d-flex flex-row align-items-center flex-wrap' style={{ gap: '0.2rem' }}>
            {options.weight && checkCondition(options.weightInfo, 0) && <WeithSVG widthProp={iconSize} color="#808080" />}
            {options.km && checkCondition(options.kmInfo, 1) && <DistanceSVG widthProp={iconSize} color="#00b033" />}
            {options.time && checkConditionString(options.timeInfo) && <TimeSVG widthProp={iconSize} color="#d85195" />}
            {options.hearRate && checkCondition(options.hearRateInfo, 30) && <HeartSVG widthProp={iconSize} color="#d85195" />}
            {options.cadence && checkCondition(options.cadenceInfo, 1) && <CadenceSVG widthProp={iconSize} color="#cc232b" />}
            {options.rhythm && checkCondition(options.rhythmInfo, 1) && <RhythmSVG widthProp={iconSize} color="#3498db" />}
            {options.swimmingStyles && checkConditionString(options.swimmingStylesInfo) && <SwimmSVG widthProp={iconSize} />}
            {options.meters && checkCondition(options.metersInfo, 1) && <DistanceSVG widthProp={iconSize} color="#00b033" />}
            {options.power && checkCondition(options.powerInfo, 1) && <PowerSVG widthProp={iconSize} color="#ede200" />}
            {options.other && checkConditionString(options.otherInfo) && <InfoSVG widthProp={iconSize} color="#2367de" />}

          </div>
        </div>
      )}
    </>
  );
};

import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { LanguageContext } from "../context/LanguageContext";
import { useForm } from "../hooks/useForm";
import { LogoWraper } from "../components";
import { roles } from "../domain/roles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { signup } from "../redux/thunks/authThunks";
import { useSlicesActions, useSlicesState } from "../redux/slices";

export const SignUp = () => {
  const { lang } = useContext(LanguageContext);
  const history = useHistory();
  const { rolePreSigNup } = useSlicesState()
  const { dispatch } = useSlicesActions()

  const [alert, setAlert] = useState(false);

  const [formSigNupValues, handleSigNupInputChange] = useForm({
    name: "",
    email: "",
    password: "",
    role: rolePreSigNup
  });

  const { name, email, password } = formSigNupValues;

  const isDisabled = name.length < 2 || email.length < 5 || password.length < 8

  const handleSignNup = (e) => {
    e.preventDefault();
    dispatch(signup(name, email, password, rolePreSigNup));
  };

  const getProfileSelected = (role) => {
    if (role === roles.personalTrainer) return lang.roles.personalTrainer;
    if (role === roles.athlete) return lang.roles.athlete;
    if (role === roles.yogaStretching) return lang.roles.yogaStretching;
  };

  if (!rolePreSigNup) history.push(Routes.preSignup);

  return (
    <div className="mt-5">
      <PerfectScrollbar className="h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <LogoWraper />
              <div className="card border border-primary">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4 text-black">
                        {lang.signup.title} {lang.generic.for}{" "}
                        {getProfileSelected(rolePreSigNup)}
                      </h4>
                      <form onSubmit={handleSignNup} autoComplete="off">
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.signup.fullname}</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control border border-info"
                            name="name"
                            placeholder={lang.placeHolders.enterYourName}
                            autoFocus={true}
                            value={name}
                            onChange={handleSigNupInputChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.generic.email}</strong>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control border border-info"
                            placeholder={lang.placeHolders.enterYourEmail}
                            value={email}
                            onChange={handleSigNupInputChange}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.generic.password}</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control border border-info"
                            name="password"
                            placeholder={lang.placeHolders.enterYourPassword}
                            value={password}
                            onChange={handleSigNupInputChange}
                            onFocus={() => setAlert(true)}
                            onBlur={() => setAlert(false)}
                            required
                          />
                          {alert && (
                            <div
                              className="alert alert-warning mt-3 border border-warning"
                              role="alert"
                            >
                              {lang.signup.alertPass}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4">
                          <button
                            disabled={isDisabled}
                            type="submit"
                            className="btn bg-primary text-white btn-block"
                          >
                            {lang.buttons.sigNup}
                          </button>
                        </div>
                      </form>
                      <div className="text-center mt-4">
                        <button
                          onClick={() => history.push(Routes.preSignup)}
                          className="btn btn-xs bg-warning text-white btn-block"
                        >
                          {lang.buttons.reselectProfile}
                        </button>
                      </div>
                      <div className="new-account mt-3">
                        <p className="text-black">
                          {lang.preSignup.footer}
                          <Link className="text-primary" to={Routes.login}>
                            {lang.generic.login}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

import { useMediaQuery } from ".";

export const useResponsive = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isMobile = !isDesktop;

  return {
    isDesktop,
    isMobile,
  };
};
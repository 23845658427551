import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../routers/Routes";
import { LanguageContext } from "../context/LanguageContext";
import { useForm } from "../hooks/useForm";
import { Loading, LogoWraper } from "../components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { login } from "../redux/thunks/authThunks";
import { useSlicesActions, useSlicesState } from "../redux/slices";

export const Login = () => {
  const { lang } = useContext(LanguageContext);

  const { dispatch } = useSlicesActions()
  const { startLogin } = useSlicesState()

  const [formLoginValues, handleLoginInputChange] = useForm({
    email: "",
    password: "",
  });

  const { email, password } = formLoginValues;

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  if (startLogin) {
    return <Loading show={startLogin} />;
  }


  return (
    <div className="mt-5">
      <PerfectScrollbar className="h-100">
        <div className="container h-100 ">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6 ">
              <LogoWraper />
              <div className="card border border-primary overflow">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4 text-black">
                        {lang.login.title}
                      </h4>
                      <form onSubmit={handleLogin} autoComplete="off">
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.generic.email}</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control border border-info"
                            name="email"
                            value={email.trim()}
                            onChange={handleLoginInputChange}
                            placeholder={lang.placeHolders.enterYourEmail}
                            required
                            autoFocus={true}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1 text-black">
                            <strong>{lang.generic.password}</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control border border-info"
                            placeholder={lang.placeHolders.enterYourPassword}
                            name="password"
                            value={password}
                            onChange={handleLoginInputChange}
                            required
                          />
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <Link
                              className="text-primary"
                              to={Routes.forgotPassword}
                            >
                              {lang.login.recoveryPassword}
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            disabled={password.length < 8}
                            type="submit"
                            className="btn bg-primary text-white btn-block"
                          >
                            {lang.buttons.login}
                          </button>
                        </div>
                      </form>
                      <div className="new-account mt-3">
                        <p className="text-black">
                          {lang.login.footer}
                          <Link className="text-primary" to={Routes.preSignup}>
                            {lang.generic.register}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

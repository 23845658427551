import { isSeriesTraining } from "../assets/data/typesOfTrainings";

export const isTrainigSeriesInProgress = (training) => {
    return isSeriesTraining(training?.typesOfTraining) && training?.optionsSeriesTraining?.seriesCompleted > 0 && !training?.isComplete;
}

export const isTrainingBlockInProgress = (training) => {
    const blocks = training?.blocksId;
    const totalBlocks = blocks.length
    let blockCompleted = 0;
    let blockPending = 0;

    blocks.forEach(block => {
        if (block.isComplete) {
            blockCompleted++;
        } else {
            blockPending++;
        }
    });

    const isProgress = blockCompleted > 0;

    return {
        isProgress,
        blockCompleted,
        blockPending,
        totalBlocks
    };
}

import { createSlice } from '@reduxjs/toolkit'


export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        thereAreNewNotifications: false,
        tempNotificationId:  undefined,
    },
    reducers: {
        setThereAreNewNotificationsTrue: (state) => {
            state.thereAreNewNotifications = true
        },
        setThereAreNewNotificationsFalse: (state) => {
            state.thereAreNewNotifications = false
        },
        setTempNotificationId: (state, actions) => {
            state.tempNotificationId = actions.payload
        },
        setInitialStateNotifications: (state) => {
            Object.assign(state, notificationsSlice.getInitialState())
        },
    },
})

export const {
    setThereAreNewNotificationsTrue,
    setThereAreNewNotificationsFalse,
    setInitialStateNotifications,
    setTempNotificationId,
} = notificationsSlice.actions
import { useDispatch } from "react-redux";
import {
    setTrainings,
    setSelectUser,
    setImportedExercises,
    setDateTempTraining,
    setCleanSelectUser,
    setExercisesSelected,
    setIsEditingExerciseInTrainingTrue,
    setIsEditingExerciseInTrainingFalse,
    setQuery,
    setTrainingIdSelected,
    setInitialStateTraining,
    setIsCreateExerciseInTrainingFalse,
    setIsCreateExerciseInTrainingTrue,
    setUserSelected,
    setIsCloneTrainingTrue,
    setIsCloneTrainingFalse,
    setTrainingToClone,
    setEreateTrainingFromCalendarTrue,
    setEreateTrainingFromCalendarFalse,
    setToggleActivateViewTrainingSimpleMode,
    setToggleCreateTrainingSimpleMode
} from "../trainingSlice";
import {
    setExerciseSelected,
    setCleanExerciseSelected,
    setInitialStateExercise,
} from "../exerciseSlice";
import {
    setTempSelectRPE,
    setCleanTempSelectRPE,
    setUserIdSelected,
    setMessageToEdit,
    setIsEditMessage,
    setInitialStateFeedBack
} from '../feedbackSlice'
import {
    setModalFinishTrainingRPE,
    setModalFinishTrainingFeedback,
    setModalViewExercise,
    setInitialStateModals,
    setModalViewNotifications,
    setModalViewVideo,
    setModalCreateExercise,
    setModalListMyExercises,
    setModalIconSearch,
    setModalViewEquipment,
} from '../modalsSlice'
import { setUsersSelected, setInitialStateSubscriptions } from "../subscriptionsSlice";
import {
    setOtherUserId,
    setInitialStateUsers,
    setIsEditingProfileTrue,
    setIsEditingProfileFalse,
    setOtherCompleteUser
} from "../usersSlice";
import {
    setInitialStateNotifications,
    setThereAreNewNotificationsTrue,
    setThereAreNewNotificationsFalse,
    setTempNotificationId
} from "../notificationsSlice";

import { setTrainingParameters, setInitialStateUserProfile } from "../userProfileSlice";
import { setOpenMenuSidebarSwitch, setInitialStateApp, } from "../appSlice";
import { setBlockIdSelected, setInitialStateBlocks, setBlockIsBeingEditedTrue, setBlockIsBeingEditedFalse } from "../blocksSlice";


export const useSlicesActions = () => {
    const dispatch = useDispatch()

    return {
        dispatch,
        setTrainings,
        setSelectUser,
        setImportedExercises,
        setDateTempTraining,
        setCleanSelectUser,
        setExercisesSelected,
        setExerciseSelected,
        setCleanExerciseSelected,
        setIsEditingExerciseInTrainingTrue,
        setIsEditingExerciseInTrainingFalse,
        setQuery,
        setTrainingIdSelected,
        setInitialStateTraining,
        setTempSelectRPE,
        setCleanTempSelectRPE,
        setModalFinishTrainingRPE,
        setModalFinishTrainingFeedback,
        setModalViewExercise,
        setUserIdSelected,
        setMessageToEdit,
        setIsEditMessage,
        setUsersSelected,
        setInitialStateSubscriptions,
        setOtherUserId,
        setInitialStateUsers,
        setIsEditingProfileTrue,
        setIsEditingProfileFalse,
        setInitialStateModals,
        setInitialStateFeedBack,
        setInitialStateExercise,
        setThereAreNewNotificationsTrue,
        setThereAreNewNotificationsFalse,
        setInitialStateNotifications,
        setTempNotificationId,
        setModalViewNotifications,
        setOtherCompleteUser,
        setModalViewVideo,
        setModalCreateExercise,
        setModalListMyExercises,
        setModalIconSearch,
        setTrainingParameters,
        setInitialStateUserProfile,
        setOpenMenuSidebarSwitch,
        setInitialStateApp,
        setModalViewEquipment,
        setIsCreateExerciseInTrainingFalse,
        setIsCreateExerciseInTrainingTrue,
        setUserSelected,
        setBlockIdSelected,
        setInitialStateBlocks,
        setBlockIsBeingEditedTrue,
        setBlockIsBeingEditedFalse,
        setIsCloneTrainingTrue,
        setIsCloneTrainingFalse,
        setTrainingToClone,
        setEreateTrainingFromCalendarTrue,
        setEreateTrainingFromCalendarFalse,
        setToggleActivateViewTrainingSimpleMode,
        setToggleCreateTrainingSimpleMode
    };
};



import { createSlice } from '@reduxjs/toolkit'


export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        otherUserId: null,
        editingProfile: false,
        otherCompleteUser: null,
    },
    reducers: {
        setOtherUserId: (state, action) => {
            state.otherUserId = action.payload
        },
        setOtherCompleteUser: (state, action) => {
            state.otherCompleteUser = action.payload
        },
        setIsEditingProfileTrue: (state) => {
            state.editingProfile = true
        },
        setIsEditingProfileFalse: (state) => {
            state.editingProfile = false
        },
        setInitialStateUsers: (state) => {
            Object.assign(state, usersSlice.getInitialState())
        },
    },
})

export const {
    setOtherUserId,
    setInitialStateUsers,
    setIsEditingProfileTrue,
    setIsEditingProfileFalse,
    setOtherCompleteUser,
} = usersSlice.actions
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { Routes } from "../../routers/Routes";
import { filterIdsAndReturnStatusSuscriber } from "../../helpers/filterIDs";
import { useCreateSubscription, useGetMyProfile, useGetMySubscriptions } from "../../hooks";
import { useSlicesActions, useSlicesState } from "../../redux/slices";
import { Avatar } from "../Avatar/Avatar";

export const ListSearchAthletes = (props) => {
  const history = useHistory();
  const { isAthlete } = useGetMyProfile()
  const { handreSubcriberAndRedirect } = useCreateSubscription()
  const { modalIconSearch } = useSlicesState()
  const { dispatch, setOtherUserId, setModalIconSearch } = useSlicesActions()

  const {
    subscriptions
  } = useGetMySubscriptions()


  const { name, imgProfile, yearsOfExperience, _id } = props;
  const { lang } = useContext(LanguageContext);



  const handleOtherProfile = () => {
    dispatch(setOtherUserId(_id));
    modalIconSearch && dispatch(setModalIconSearch(false));
    history.push(Routes.searchDetails);
  };

  return (
    <>
      <li>
        <div className="timeline-panel">
          <Link
            className="media mr-2"
            to="#"
            onClick={() => handleOtherProfile()}
          >
            <Avatar
              name={name ? name : ''}
              img={imgProfile}
              size="50px"
            />
          </Link>
          <div className="media-body ">
            <Link to="#" onClick={() => handleOtherProfile()}>
              <h6 className="mb-1 text-capitalize">
                {name}
                {/* {team && <small className="text-muted"> <TeamLogo widthProp={widthAndHeight} heightProp={widthAndHeight} color="#ff8700" /> </small>} */}
              </h6>
            </Link>
            {/* <small> @{username}</small> */}
            <small className="d-block" onClick={() => handleOtherProfile()}>
              {yearsOfExperience}{" "}
              {yearsOfExperience > 1
                ? lang.profile.yearsOfTraining
                : lang.profile.yeaOfTraining}
            </small>
          </div>
          <ul>
            <li>
              {!isAthlete() && (
                <Link
                  to="#"
                  className={`btn ${filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                    ? "btn-outline-warning"
                    : "btn-outline-success"
                    } btn-xxs   `}
                  onClick={() =>
                    filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                      ? history.push(Routes.subscriptions)
                      : handreSubcriberAndRedirect(props._id)

                  }
                >
                  {filterIdsAndReturnStatusSuscriber(subscriptions, props._id)
                    ? lang.buttons.administrated
                    : lang.buttons.subscribe}
                </Link>
              )}
            </li>
            <li>
              <Link
                to={Routes.searchDetails}
                className="btn btn-outline-info btn-xxs mt-2"
                onClick={() => handleOtherProfile()}
              >
                {lang.buttons.viewProfile}
              </Link>
            </li>
          </ul>
        </div>
      </li>
    </>
  );
};

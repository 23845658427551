import { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useChatIdTelegram } from "../../hooks";

export const Telegram = ({ activeToggle }) => {
    const { lang } = useContext(LanguageContext)

    const {
        formAddChatIdTelegram,
        handleFormAddChatIdTelegram,
        isDisable,
        handleAddTelegramChatID,
        isUpdate,
        telegramChatIdInProfile,
        handleDeleteTelegramChatID
    } = useChatIdTelegram()


    return (
        <div
            id="telegram"
            className={`tab-pane fade mt-3 ${activeToggle === "telegram" ? "active show" : ""
                }`}
        >
            <div>
                <p className="fontSize9Rem">{lang.profile.notifications.stepsGetChatIdTelegram}</p>
                <ul className="list-group fontSize9Rem">
                    <li className="list-group-item">{lang.profile.notifications.step1Telegram}</li>
                    <a href="tg://resolve?domain=FiTool_bot" target="_blank" rel="noreferrer" className="list-group-item">
                        <button className="btn btn-xs btn-info">{lang.buttons.getTelegramId}</button>
                    </a>
                    <li className="list-group-item">{lang.profile.notifications.step2Telegram}</li>
                    <li className="list-group-item">{lang.profile.notifications.step3Telegram}</li>
                </ul>

                <form autoComplete="off">
                    <div className="form-row mt-3">
                        <div className="form-group col-12">
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="telegramChatId"
                                    className="form-control text-capitalize"
                                    placeholder={`${telegramChatIdInProfile ? telegramChatIdInProfile : "Ingrese el Id de Telegram"}`}
                                    value={formAddChatIdTelegram.telegramChatId}
                                    onChange={handleFormAddChatIdTelegram}
                                />
                                <div className="input-group-append">
                                    {
                                        telegramChatIdInProfile && !isUpdate &&
                                        <button
                                            className="btn btn-warning"
                                            style={{ borderRadius: '0 1.25rem 1.25rem 0' }}
                                            onClick={(e) => handleDeleteTelegramChatID(e)}
                                        >
                                            {lang.buttons.delete}
                                        </button>
                                    }

                                    {
                                        isUpdate &&
                                        <button
                                            className="btn btn-secondary"
                                            style={{ borderRadius: '0 1.25rem 1.25rem 0' }}
                                            disabled={isDisable}
                                            onClick={(e) => handleAddTelegramChatID(e)}
                                        >
                                            {lang.buttons.update}
                                        </button>
                                    }

                                    {
                                        !telegramChatIdInProfile &&
                                        <button
                                            className="btn btn-primary"
                                            style={{ borderRadius: '0 1.25rem 1.25rem 0' }}
                                            disabled={isDisable}
                                            onClick={(e) => handleAddTelegramChatID(e)}
                                        >
                                            {lang.buttons.save}
                                        </button>
                                    }

                                </div>
                                <h6 className='mt-3 text-info' style={{fontSize: '0.7rem'}}>{lang.profile.notifications.dataPrivacy}</h6>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
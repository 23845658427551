import { useContext, useEffect } from "react";
import { useCretePhysiologicalDataMutation, } from "../../api";
import { loadingAlert, showAlert, Swal } from "../../helpers/Alerts";
import { useForm } from "../useForm";
import { LanguageContext } from "../../context/LanguageContext";

export function useCreatePhysiologicalData() {
  const { lang } = useContext(LanguageContext);
  const [createPhysiologicalData, { isError, isSuccess, error, isLoading ,data }] = useCretePhysiologicalDataMutation();

  const [formMeasureValues, handleFormMeasureValues] = useForm({
    weight: 0,
    height: 0,
    armMeasure: 0,
    chestMeasure: 0,
    waistMeasure: 0,
    adbomenMeasure: 0,
    buttocksMeasure: 0,
    hipMeasure: 0,
    legMeasure: 0,
  });

  const {
    weight,
    height,
    armMeasure,
    chestMeasure,
    waistMeasure,
    adbomenMeasure,
    buttocksMeasure,
    hipMeasure,
    legMeasure,
  } = formMeasureValues;

  const handleCreatePhysiologicalData = async (e) => {
    e.preventDefault();
    await createPhysiologicalData(formMeasureValues).unwrap()
    Swal.close();
  };

  useEffect(() => {
    if (isError) showAlert(error?.data?.msgCode, error?.data?.ok);
    if (isSuccess) showAlert(data?.msgCode, data?.ok);
  }, [data?.msgCode, data?.ok, error?.data?.msgCode, error?.data?.ok, isError, isSuccess]);

  useEffect(() => {
    if(isLoading) loadingAlert(lang.alerts.creatingPhysiologicalData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  
  return {
    handleCreatePhysiologicalData,
    weight,
    height,
    armMeasure,
    chestMeasure,
    waistMeasure,
    adbomenMeasure,
    buttocksMeasure,
    hipMeasure,
    legMeasure,
    handleFormMeasureValues
  };
}

